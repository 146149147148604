import { DatePicker } from '@mui/x-date-pickers';
import { useController } from 'react-hook-form';

export function DatePickerController({
  name,
  control,
  disabled,
  rules,
  label,
  minDate,
  maxDate,
  sx,
}) {
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <DatePicker
      inputRef={ref}
      value={value ?? null}
      onChange={onChange}
      disabled={disabled}
      label={label}
      minDate={minDate}
      maxDate={maxDate}
      sx={{ minWidth: 240, ...sx }}
      slotProps={{
        textField: {
          size: 'small',
          error: invalid,
          helperText: error?.message,
          onBlur,
        },
      }}
    />
  );
}
