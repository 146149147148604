import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfWeek } from 'date-fns';

export function useObjectiveAttendeeCounts(objectiveId, groupType, groupCode) {
  return useQuery({
    queryKey: ['topFiveObjectiveAttendees', objectiveId, groupType, groupCode],
    queryFn: () => {
      const weekStart = startOfWeek(new Date());
      const json = [
        {
          $match: {
            startTime: { $gt: weekStart },
            'objective.identifier': objectiveId,
            [`groups.person.${groupType}`]: groupCode,
          },
        },
        {
          $group: {
            _id: '$person.collarNumber',
            attendances: { $sum: 1 },
            durationSeconds: { $sum: '$durationSeconds' },
          },
        },
        {
          $project: {
            _id: false,
            collarNumber: '$_id',
            attendances: true,
            durationSeconds: true,
          },
        },
        { $sort: { durationSeconds: -1 } },
      ];

      return api.post('pipeline/personObjectiveAttendances', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
