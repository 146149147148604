import { api } from '@/apis';
import { encodeParams } from '@/utils';
import { useQuery } from '@tanstack/react-query';

/**
 * A hook that fetches a boundary.
 * @param {string} type - The type of boundary to fetch.
 * @param {string} id - The ID of the boundary to fetch.
 * @returns {import('@tanstack/react-query').UseQueryResult<import('@types/geojson').Polygon>}
 */
export function useBoundary(type, id) {
  return useQuery({
    queryKey: [type, id],
    queryFn: async () => {
      switch (type) {
        case 'Location': {
          const location = await api
            .get(`locations/${id}`, {
              searchParams: encodeParams({ projection: { boundary: true } }),
            })
            .json();

          return location?.boundary;
        }
        default:
          return;
      }
    },
    staleTime: 1000 * 60 * 60,
    enabled: !!type && !!id,
  });
}
