import {
  DELETE_TAG,
  UPDATE_LIVE_FILTER_OVERRIDE,
  UPDATE_LIVE_FOLLOWED,
} from '@/actions';
import { ConfirmationDialog } from '@/components/dialogs';
import { useDocumentTitle, usePrevious } from '@/hooks';
import { has, omit, startCase } from '@/utils';
import { liveFilters } from '@/utils/constants';
import {
  Delete as DeleteIcon,
  FilterCenterFocus as FocusIcon,
  GpsFixed as FollowAllIcon,
  GpsOff as StopFollowingIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { dequal } from 'dequal';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EventLiveListItem } from './EventLiveListItem';
import { IncidentLiveListItem } from './IncidentLiveListItem';
import { ItemAvatar } from './ItemAvatar';
import { LiveListItem } from './LiveListItem';
import { LocationLiveListItem } from './LocationLiveListItem';
import { ObjectiveLiveListItem } from './ObjectiveLiveListItem';
import { PeopleLiveListItem } from './PeopleLiveListItem';
import { VehicleLiveListItem } from './VehicleLiveListItem';

// TODO refactor as it's shared with livelist index.js
const typeToListItemMap = {
  vehicles: VehicleLiveListItem,
  locations: LocationLiveListItem,
  people: PeopleLiveListItem,
  events: EventLiveListItem,
  incidents: IncidentLiveListItem,
  objectives: ObjectiveLiveListItem,
};

export function TagDetail({
  item: tag,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  onFollowBulk,
  followedIdsByType,
  hoveredId,
}) {
  useDocumentTitle(
    ['IR3', 'Live', 'Tags', tag?.id].filter(Boolean).join(' | '),
  );
  // need access to all the resources for any that have this tag
  const state = useSelector((state) => state.live);
  const navigate = useNavigate();

  const filterOverride = useSelector(
    (state) => state.live.filteredInIdsByTypeOverride,
  );

  const dispatch = useDispatch();

  const taggedIdsByType = {};
  let taggedCount = 0;
  Object.keys(tag.itemsByType).forEach((type) => {
    const idsOfType = Object.keys(tag.itemsByType[type]);
    if (idsOfType.length > 0) {
      taggedIdsByType[type] = idsOfType;
      taggedCount += idsOfType.length;
    }
  });

  // if the tag overrides the filter it'll be the owner
  const tagOverridesFilter = filterOverride?.owner === tag.id;
  const isFollowType = tag.type === 'follow';
  const [deleteOpen, setDeleteOpen] = useState(false);

  const override = useMemo(
    () => ({
      // make every type filtered out
      ...Object.fromEntries(Object.keys(liveFilters).map((k) => [k, {}])),
      // except for our tagged items and this tag
      ...tag.itemsByType,
      tags: { [tag.id]: true },
      // and set the owner of the override (when we nav away etc.)
      owner: tag.id,
    }),
    [tag],
  );

  const allFollowed = Object.keys(override)
    .filter((type) => !['owner', 'tags'].includes(type))
    .every((type) =>
      Object.keys(override[type]).every((id) => followedIdsByType[type]?.[id]),
    );

  function handleFilterToggle() {
    dispatch({
      type: UPDATE_LIVE_FILTER_OVERRIDE,
      payload: tagOverridesFilter ? null : override,
    });
  }

  // function handleFollowToggle() {
  //   dispatch({
  //     type: UPDATE_LIVE_FOLLOW_OVERRIDE,
  //     payload: tagOverridesFollow ? null : override,
  //   });
  // }

  function handleUnfollowingAll() {
    dispatch({
      type: UPDATE_LIVE_FOLLOWED,
      payload: {},
    });

    if (tagOverridesFilter) {
      handleFilterToggle();
    }
  }

  const handleFollowAllToggle = () => {
    const newFollow = !allFollowed;

    onFollowBulk(omit(override, ['owner', 'tags']), newFollow);
  };

  function handleTagDelete() {
    dispatch({
      type: DELETE_TAG,
      payload: encodeURIComponent(tag.id),
      navigate,
    });
  }

  // if the tagged items change (e.g. they hadn't fully loaded yet)
  // re-dispatch the overrides if necessary
  const prevOverride = usePrevious(override);
  useEffect(() => {
    if (!dequal(override, prevOverride)) {
      if (tagOverridesFilter) {
        dispatch({
          type: UPDATE_LIVE_FILTER_OVERRIDE,
          payload: override,
        });
      }
    }
  }, [dispatch, prevOverride, override, tagOverridesFilter]);

  const showTypeHeader = (type) => {
    const header = startCase(type);
    const headerWithMessage = `${header} (${taggedIdsByType[type].length})`;
    return has(typeToListItemMap, type) ? header : headerWithMessage;
  };

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={<ItemAvatar item={tag} type="tags" />}
        title={tag.id}
        subheader={`${taggedCount} item${taggedCount === 1 ? '' : 's'}`}
        action={
          <Fragment>
            <Tooltip
              title={`Focus on ${isFollowType ? 'followed' : 'tagged'} items`}
            >
              <Box component="span">
                <IconButton
                  onClick={handleFilterToggle}
                  disabled={isFollowType && taggedCount === 0}
                  size="large"
                >
                  <FocusIcon
                    fontSize="small"
                    color={tagOverridesFilter ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Box>
            </Tooltip>

            {isFollowType ? (
              <Tooltip title="Stop following all">
                <Box component="span">
                  <IconButton
                    onClick={handleUnfollowingAll}
                    disabled={taggedCount === 0}
                    size="large"
                  >
                    <StopFollowingIcon
                      fontSize="small"
                      htmlColor={taggedCount === 0 ? grey[500] : red[500]}
                    />
                  </IconButton>
                </Box>
              </Tooltip>
            ) : (
              <Fragment>
                <Tooltip title="Toggle follow on tagged items">
                  <IconButton onClick={handleFollowAllToggle} size="large">
                    <FollowAllIcon
                      fontSize="small"
                      color={allFollowed ? 'primary' : 'inherit'}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete tag">
                  <Box component="span">
                    <IconButton
                      onClick={() => setDeleteOpen(true)}
                      disabled={isFollowType && taggedCount === 0}
                      size="large"
                    >
                      <DeleteIcon fontSize="small" htmlColor={red[500]} />
                    </IconButton>
                  </Box>
                </Tooltip>
                <ConfirmationDialog
                  action="Delete"
                  open={deleteOpen}
                  itemId={tag.id}
                  onOk={handleTagDelete}
                  onCancel={() => setDeleteOpen(false)}
                />
              </Fragment>
            )}
          </Fragment>
        }
      />
      <CardContent>
        {Object.keys(taggedIdsByType)
          // show types that we can show first
          .filter((type) => typeToListItemMap[type])
          .sort()
          .map((type) => (
            <Fragment key={type}>
              <Typography variant="subtitle2" color="textSecondary">
                {showTypeHeader(type)}
              </Typography>
              {taggedIdsByType[type].map((id) => {
                // try to find the item
                const item = state[type]?.[id];
                if (!item) {
                  return (
                    <LiveListItem
                      key={id}
                      item={{ id, secondary: '[Unavailable on live]' }}
                      type={type}
                      primaryPath={'id'}
                      secondaryPath={'secondary'}
                      hideFollow
                    />
                  );
                } else {
                  const ListComponent = typeToListItemMap[type];
                  return (
                    <ListComponent
                      key={id}
                      onClick={onSubItemClick}
                      highlighted={hoveredId === id}
                      onHoverItem={onSubItemHover}
                      onFollowToggle={onFollowToggle}
                      followedIdsByType={followedIdsByType}
                      item={item}
                      // hideFollow={true}
                    />
                  );
                }
              })}
            </Fragment>
          ))}
        {Object.keys(taggedIdsByType)
          // show the types that we can't display on live last
          .filter((type) => !typeToListItemMap[type])
          .sort()
          .map((type) => (
            <Fragment key={type}>
              <Typography variant="subtitle2" color="textSecondary">
                {showTypeHeader(type)}
              </Typography>
            </Fragment>
          ))}
      </CardContent>
    </Card>
  );
}
