import {
  CREATE_RETROSPECTIVE,
  CREATE_RETROSPECTIVE_FAILURE,
  CREATE_RETROSPECTIVE_SUCCESS,
  DELETE_RETROSPECTIVE,
  DELETE_RETROSPECTIVE_FAILURE,
  DELETE_RETROSPECTIVE_SUCCESS,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_CANCELLED,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_FAILURE,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_SUCCESS,
  FETCH_RETROSPECTIVE,
  FETCH_RETROSPECTIVES,
  FETCH_RETROSPECTIVES_FAILURE,
  FETCH_RETROSPECTIVES_SUCCESS,
  FETCH_RETROSPECTIVE_FAILURE,
  FETCH_RETROSPECTIVE_ITEM,
  FETCH_RETROSPECTIVE_ITEM_FAILURE,
  FETCH_RETROSPECTIVE_ITEM_SUCCESS,
  FETCH_RETROSPECTIVE_LAYER,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS,
  FETCH_RETROSPECTIVE_LAYER_CANCELLED,
  FETCH_RETROSPECTIVE_LAYER_FAILURE,
  FETCH_RETROSPECTIVE_LAYER_SUCCESS,
  FETCH_RETROSPECTIVE_SUBITEM,
  FETCH_RETROSPECTIVE_SUBITEM_FAILURE,
  FETCH_RETROSPECTIVE_SUBITEM_SUCCESS,
  FETCH_RETROSPECTIVE_SUCCESS,
  UPDATE_RETROSPECTIVE,
  UPDATE_RETROSPECTIVE_FAILURE,
  UPDATE_RETROSPECTIVE_SUCCESS,
} from '@/actions';
import { api, fromAjax } from '@/apis';
import {
  defaultLayerValues,
  getLimits,
  mongoizeFilters,
} from '@/components/retrospective/constants';
import {
  encodeParams,
  getGeometryCollectionFromMicrobeats,
  getHeaders,
  log,
} from '@/utils';
import {
  dioStates,
  retrospective,
  temp_locationTypeCodes,
} from '@/utils/config';
import { area } from '@turf/turf';
import { intersect } from '@turf/turf';
import {
  addDays,
  addSeconds,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import _ from 'lodash';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  groupBy,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { quantileRankSorted } from 'simple-statistics';

const { boundaryAreaOverlapThreshold } = retrospective;

const controllers = {};
const estimationControllers = {};

let _vehicleDictionary = undefined;
async function getVehicleDictionary() {
  if (!_vehicleDictionary) {
    const vehiclesResponse = await api
      .get('vehicles', {
        searchParams: encodeParams({
          query: {
            telematicsBoxImei: { $nin: [null, ''] },
          },
          projection: projectionForLayer('vehicles'),
        }),
      })
      .json();

    const vehiclesById = _.keyBy(vehiclesResponse, 'identificationNumber');
    const vehiclesByImei = _.keyBy(vehiclesResponse, 'telematicsBoxImei');

    _vehicleDictionary = _.omit(
      _.merge(vehiclesById, vehiclesByImei),
      undefined,
    );
  }

  return _vehicleDictionary;
}

let _personDictionary = undefined;
async function getPersonDictionary() {
  if (!_personDictionary) {
    const peopleResponse = await api
      .get('people', {
        searchParams: encodeParams({
          query: {
            radioSsi: { $nin: [null, ''] },
          },
          projection: projectionForLayer('people'),
        }),
      })
      .json();

    const peopleById = _.keyBy(peopleResponse, 'code');
    const peopleBySsi = _.keyBy(peopleResponse, 'radioSsi');

    _personDictionary = _.omit(_.merge(peopleById, peopleBySsi), undefined);
  }

  return _personDictionary;
}

const locationIdField = 'code';
function reduceByLocation(data, areaType) {
  areaType = areaType?.toLowerCase();
  const dataByLocation = data.reduce((accumulator, { location }) => {
    if (!areaType || location.type.toLowerCase() === areaType) {
      if (accumulator[location[locationIdField]]) {
        accumulator[location[locationIdField]]++;
      } else {
        accumulator[location[locationIdField]] = 1;
      }
    }

    return accumulator;
  }, {});

  return dataByLocation;
}

export function fetchRetrospectivesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVES),
    mergeMap(() =>
      fromAjax('/retrospectives', {
        params: {
          projection: {
            identifier: true,
            title: true,
            created: true,
            lastEdit: true,
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: payload }) => {
          log('Read', 'Retrospectives');

          return {
            type: FETCH_RETROSPECTIVES_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVES_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function fetchBoundary(type, identifier) {
  switch (type) {
    case 'Location': {
      const response = await api
        .get(`locations/${identifier}`, {
          searchParams: encodeParams({
            projection: {
              boundary: true,
            },
          }),
        })
        .json();

      return response.boundary;
    }
    case 'Objective': {
      let response = await api
        .get(`objectives/${identifier}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              boundaryType: true,
              boundaryIdentifier: true,
              boundary: true,
              microbeats: true,
            },
          }),
        })
        .json();

      if (response.boundaryType === 'Custom') {
        return response.boundary;
      } else if (response.boundaryType === 'Location') {
        response = await api
          .get(`locations/${response.boundaryIdentifier}`, {
            searchParams: encodeParams({
              projection: {
                boundary: true,
              },
            }),
          })
          .json();

        return response.boundary;
      } else {
        return getGeometryCollectionFromMicrobeats(response.microbeats);
      }
    }
    default:
      return undefined;
  }
}

async function fetchRetrospectiveRequest(id) {
  const response = await api
    .get(`retrospectives/${id}`, {
      searchParams: encodeParams({
        projection: {
          identifier: true,
          title: true,
          description: true,
          layers: true,
          created: true,
          lastEdit: true,
          layerOrder: true,
        },
      }),
    })
    .json();

  const layers = await Promise.all(
    response.layers.map(async (layer) => {
      const boundaryGeometry = await fetchBoundary(
        layer.boundaryType,
        layer.boundaryIdentifier,
      );

      return { ...defaultLayerValues, boundaryGeometry, ...layer };
    }),
  );

  log('Read', 'Retrospectives', { id });

  return { ...response, layers };
}

export function fetchRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE),
    mergeMap(({ payload: id }) =>
      from(fetchRetrospectiveRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function createRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(CREATE_RETROSPECTIVE),
    mergeMap(({ payload, navigate }) =>
      fromAjax('/retrospectives', {
        body: {
          ...payload,
          layers: payload.layers.map(
            ({
              featureCollection: _0,
              originalMatch: _1,
              originalPrecision: _2,
              estimate: _3,
              window: _4,
              virtualize: _5,
              abortedDueToLimitExceeded: _6,
              clientFilters: _7,
              searchText: _8,
              boundaryGeometry,
              ...layer
            }) => ({
              ...layer,
              boundaryGeometry:
                layer.boundaryType === 'Custom' ? boundaryGeometry : null,
            }),
          ),
        },
        method: 'POST',
        headers: { ...getHeaders(), 'content-type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', 'Retrospective', payload);

          navigate(`/retrospective/${payload.identifier}`, {
            replace: true,
          });

          return {
            type: CREATE_RETROSPECTIVE_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_RETROSPECTIVE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function updateRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_RETROSPECTIVE),
    mergeMap(({ payload }) =>
      fromAjax(`/retrospectives/${payload.identifier}`, {
        body: {
          ...payload,
          layers: payload.layers.map(
            ({
              featureCollection: _0,
              originalMatch: _1,
              originalPrecision: _2,
              estimate: _3,
              window: _4,
              virtualize: _5,
              abortedDueToLimitExceeded: _6,
              clientFilters: _7,
              searchText: _8,
              boundaryGeometry,
              ...layer
            }) => ({
              ...layer,
              boundaryGeometry:
                layer.boundaryType === 'Custom' ? boundaryGeometry : null,
            }),
          ),
        },
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'content-type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: payload }) => ({
          type: UPDATE_RETROSPECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_RETROSPECTIVE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function deleteRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(DELETE_RETROSPECTIVE),
    mergeMap(({ payload: id }) =>
      fromAjax(`/retrospectives/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => ({
          type: DELETE_RETROSPECTIVE_SUCCESS,
          payload: response.identifier,
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_RETROSPECTIVE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

const standardFeatureCollection = (geometryField, data, source) => ({
  type: 'FeatureCollection',
  features: data.map(
    ({ [geometryField]: geometry, identifier: id, ...properties }, index) => {
      return {
        type: 'Feature',
        id: index,
        properties: { ...properties, id, source },
        geometry,
      };
    },
  ),
});

async function fetchLayerData(index, layer, filters) {
  const limits = getLimits(layer);
  const $limit = Math.floor(layer?.estimate?.itemLimit ?? limits.items);
  const query = await matchForLayer(layer, filters);
  const projection = projectionForLayer(layer);

  const pipeline = [{ $match: query }, { $limit }, { $project: projection }];
  const locationPipeline = locationRollupPipeline(
    layer,
    query,
    projection,
    $limit,
  );
  const url = urlForLayer(layer);
  const geo = geometryFieldForLayer(layer);
  const params = { query, projection };

  switch (layer.source) {
    case 'speedInfractions':
    case 'vehicleTrips':
    case 'vehicleStops':
    case 'vehicleVisits':
    case 'vehicleIdles':
    case 'incidents':
    case 'personTrails':
    case 'personVisits': {
      controllers[index] = new AbortController();
      const response = await api
        .post(`pipeline/${url}`, {
          json: pipeline,
          signal: controllers[index].signal,
        })
        .json();

      if (response.length >= $limit) {
        return { abortedDueToLimitExceeded: 'map features size' };
      }

      return standardFeatureCollection(geo, response, layer.source);
    }
    case 'vehicleStopCount':
    case 'vehicleIdleCount':
    case 'vehicleVisitCount':
    case 'personVisitCount':
    case 'incidentCount':
    case 'accelerometerAlertCount': {
      controllers[index] = new AbortController();
      const response = await api
        .post(`pipeline/${url}`, {
          json: locationPipeline,
          signal: controllers[index].signal,
        })
        .json();

      if (response >= $limit) {
        return { abortedDueToLimitExceeded: 'data' };
      }

      return reduceByLocation(response, layer.areaType);
    }
    case 'vehicleTime':
    case 'personTime': {
      controllers[index] = new AbortController();
      const response = await api
        .post(`pipeline/${url}`, {
          json: locationPipeline,
          signal: controllers[index].signal,
        })
        .json();

      if (response.length >= $limit) {
        return { abortedDueToLimitExceeded: 'data' };
      }

      const data = response.reduce((accumulator, row) => {
        const time =
          Math.min(new Date(row.endTime), new Date(layer.endTime)) -
          Math.max(new Date(row.startTime), new Date(layer.startTime));

        if (accumulator[row.location[locationIdField]]) {
          accumulator[row.location[locationIdField]] += time;
        } else {
          accumulator[row.location[locationIdField]] = time;
        }
        return accumulator;
      }, {});

      return data;
    }
    case 'vehiclePolls': {
      controllers[index] = new AbortController();
      const response = await api
        .post(`pipeline/${url}`, {
          json: pipeline,
          signal: controllers[index].signal,
        })
        .json();

      if (response.length >= $limit) {
        return { abortedDueToLimitExceeded: 'map features size' };
      }

      const vehicleDictionary = await getVehicleDictionary();

      const data = response
        .map((p) => {
          p.vehicle =
            vehicleDictionary[p.vehicleIdentificationNumber ?? p.imei];
          return p;
        })
        .filter((p) => !query.$and?.length || p.vehicle);

      return standardFeatureCollection(geo, data, layer.source);
    }
    case 'personPolls': {
      controllers[index] = new AbortController();
      const response = await api
        .post('pipeline/radioPolls', {
          json: pipeline,
          signal: controllers[index].signal,
        })
        .json();

      if (response.length >= $limit) {
        return { abortedDueToLimitExceeded: 'map features size' };
      }

      const personDictionary = await getPersonDictionary();

      const data = response
        .map((p) => {
          p.person = personDictionary[p.personCode ?? p.ssi];
          return p;
        })
        .filter((p) => !query.$and?.length || p.person);

      return standardFeatureCollection(geo, data, layer.source);
    }
    case 'vehicleCustomVisits': {
      // possible to do progress?
      // https://stackblitz.com/edit/rxjs-5-progress-bar-wxdxwe?file=index.ts&devtoolsheight=50
      let pollsByVehicle;
      const vehicleDictionary = await getVehicleDictionary();

      // eslint-disable-next-line no-inner-declarations
      async function calculateVisits(vehiclePollLogs) {
        let visits = [];

        vehiclePollLogs.forEach((vehiclePollLog) => {
          const { polls, vehicleIdentificationNumber } = vehiclePollLog;

          if (polls.length === 0) {
            return;
          }

          async function endVisit(visit) {
            if (visit) {
              // calculate durationSeconds, distanceKilometers
              visit.distanceKilometres = visit.endKm - visit.startKm;
              visit.durationSeconds = differenceInSeconds(
                new Date(visit.endTime),
                new Date(visit.startTime),
              );

              const [code, type, name, subtype] = new Array(4).fill('<custom>');
              visit.location = { code, type, name, subtype };

              visit.vehicle = vehicleDictionary[
                vehicleIdentificationNumber
              ] ?? { unassociated: true };

              if (!visit.vehicle.telematicsBoxImei) {
                visit.vehicle.telematicsBoxImei = visit.imei;
              }

              visits.push(visit);
            }
          }

          // go through polls until out of order
          let lastOrderNumber, visit;
          polls.forEach((poll) => {
            // if there's a gap between the last orderNumber, close the visit
            if (lastOrderNumber !== poll.orderNumber - 1) {
              endVisit(visit);

              visit = {
                maxSpeedKilometresPerHour: poll.speedKilometresPerHour,
                startKm: poll.distanceKilometres,
                endKm: poll.distanceKilometres,
                startTime: poll.time,
                endTime: poll.time,
                polls: [poll.position],
                imei: poll.imei,
              };
            } else {
              visit.maxSpeedKilometresPerHour = Math.max(
                visit.maxSpeedKilometresPerHour,
                poll.speedKilometresPerHour,
              );
              visit.endKm = poll.distanceKilometres;
              visit.endTime = poll.time;
              visit.polls.push(poll.position);
            }

            lastOrderNumber = poll.orderNumber;
          });

          endVisit(visit);
        });

        return {
          type: 'FeatureCollection',
          features: visits.map(
            (
              { polls, vehicleIdentificationNumber: id, ...properties },
              index,
            ) => {
              return {
                type: 'Feature',
                id: index,
                properties: { ...properties, id, source: layer.source },
                geometry:
                  polls.length === 1
                    ? {
                        type: 'Point',
                        coordinates: polls[0].coordinates,
                      }
                    : {
                        type: 'LineString',
                        coordinates: polls.map((p) => p.coordinates),
                      },
              };
            },
          ),
        };
      }

      controllers[index] = new AbortController();
      const response = await api
        .post('pipeline/telematicsBoxPolls', {
          json: pipeline,
          signal: controllers[index].signal,
        })
        .json();

      if (response.length >= $limit) {
        return { abortedDueToLimitExceeded: 'map features size' };
      }

      pollsByVehicle = Object.values(
        _.groupBy(
          response,
          (poll) => poll.vehicleIdentificationNumber || poll.imei,
        ),
      ).map((g) => ({
        vehicleIdentificationNumber:
          g[0].vehicleIdentificationNumber ?? g[0].imei,
        polls: _.sortBy(g, 'orderNumber'),
      }));

      return await calculateVisits(pollsByVehicle);
    }
    case 'accelerometerAlerts': {
      controllers[index] = new AbortController();
      const response = await api
        .post('pipeline/accelerometerAlerts', {
          json: pipeline,
          signal: controllers[index].signal,
        })
        .json();

      if (response.length >= $limit) {
        return { abortedDueToLimitExceeded: 'map features size' };
      }

      return {
        type: 'FeatureCollection',
        features: response.map(
          ({ point: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: {
                ...properties,
                id,
                imei: properties.vehicle.telematicsBoxImei,
                source: layer.source,
              },
              geometry,
            };
          },
        ),
      };
    }
    case 'accelerometerEvents': {
      controllers[index] = new AbortController();
      const response = await api
        .post('pipeline/accelerometerEvents', {
          json: pipeline,
          signal: controllers[index].signal,
        })
        .json();

      if (response.length >= $limit) {
        return { abortedDueToLimitExceeded: 'map features size' };
      }

      return {
        type: 'FeatureCollection',
        features: response.map(
          ({
            path,
            identifier: id,
            accelerometerData,
            deviceProperties,
            ...others
          }) => {
            // the trigger point is the amount of seconds after the start that the
            // event occurred, loop through the data until we get to this time
            // as we don't know the sampling rate of the device
            const triggerStart = addSeconds(
              new Date(others.startTime),
              deviceProperties.triggerPoint,
            );
            const triggerEnd = addSeconds(triggerStart, 1);
            const triggerStartIso = triggerStart.toISOString();
            const triggerEndIso = triggerEnd.toISOString();
            const point = accelerometerData.find(
              (p) => triggerStartIso <= p.time && p.time <= triggerEndIso,
            ).position;

            const properties = {
              ...others,
              accelerometerData,
              deviceProperties,
              id,
              imei: others.vehicle.telematicsBoxImei,
              source: layer.source,
            };

            return {
              type: 'Feature',
              properties,
              geometry: {
                type: 'GeometryCollection',
                geometries: [point, path].filter(Boolean),
              },
            };
          },
        ),
      };
    }
    case 'personCustomVisits': {
      let pollsByPerson;
      const personDictionary = await getPersonDictionary();

      // eslint-disable-next-line no-inner-declarations
      async function calculateVisits(personPollLogs) {
        let visits = [];
        personPollLogs.forEach((personPollLog) => {
          const { polls, personCode } = personPollLog;

          if (polls.length === 0) {
            return;
          }

          async function endVisit(visit) {
            if (visit) {
              visit.durationSeconds = differenceInSeconds(
                new Date(visit.endTime),
                new Date(visit.startTime),
              );

              const [code, type, name, subtype] = new Array(4).fill('<custom>');
              visit.location = { code, type, name, subtype };

              visit.person = personDictionary[personCode] ?? {
                unassociated: true,
              };

              if (visit.person.radioSsi === undefined) {
                visit.person.radioSsi = visit.ssi;
              }

              visits.push(visit);
            }
          }

          // go through polls until out of order
          let lastOrderNumber, visit;
          polls.forEach((poll) => {
            // if there's a gap between the last orderNumber, close the visit
            if (lastOrderNumber !== poll.orderNumber - 1) {
              endVisit(visit);

              visit = {
                // maxSpeedKilometresPerHour: poll.speedKilometresPerHour,
                startTime: poll.time,
                endTime: poll.time,
                polls: [poll.position],
                ssi: poll.ssi,
              };
            } else {
              visit.endTime = poll.time;
              visit.polls.push(poll.position);
            }

            lastOrderNumber = poll.orderNumber;
          });

          endVisit(visit);
        });

        return {
          type: 'FeatureCollection',
          features: visits.map(({ polls, code: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry:
                polls.length === 1
                  ? {
                      type: 'Point',
                      coordinates: polls[0].coordinates,
                    }
                  : {
                      type: 'LineString',
                      coordinates: polls.map((p) => p.coordinates),
                    },
            };
          }),
        };
      }

      controllers[index] = new AbortController();
      const response = await api
        .post('pipeline/radioPolls', {
          json: pipeline,
          signal: controllers[index].signal,
        })
        .json();

      if (response.length >= $limit) {
        return { abortedDueToLimitExceeded: 'map features size' };
      }

      pollsByPerson = Object.values(
        _.groupBy(response, (poll) => poll.personCode || poll.ssi),
      ).map((g) => ({
        personCode: g[0].personCode ?? g[0].ssi,
        polls: _.sortBy(g, 'orderNumber'),
      }));

      return await calculateVisits(pollsByPerson);
    }
    case 'locations': {
      controllers[index] = new AbortController();
      const response = await api
        .get('locations', {
          searchParams: encodeParams(params),
          signal: controllers[index].signal,
        })
        .json();

      return {
        type: 'FeatureCollection',
        features: response.map(
          ({ boundary: geometry, code: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: {
                ...properties,
                id,
                code: id,
                source: 'locations',
              },
              geometry,
            };
          },
        ),
      };
    }
    default:
      return {
        type: 'FeatureCollection',
        features: [],
      };
  }
}

async function fetchAreas(index, layer, data) {
  controllers[index] = new AbortController();
  const response = await api
    .post('pipeline/locations', {
      json: [
        {
          $match: {
            type: layer.areaType,
            boundary: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
          },
        },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectToField: 'code',
            connectFromField: 'parentCodes',
            as: 'groups',
            depthField: 'depth',
          },
        },
        {
          $project: {
            code: true,
            name: true,
            boundary: true,
            groups: {
              $map: {
                input: {
                  $sortArray: {
                    input: '$groups',
                    sortBy: { depth: -1, type: 1, name: 1 },
                  },
                },
                as: 'group',
                in: {
                  code: '$$group.code',
                  name: '$$group.name',
                  type: '$$group.type',
                },
              },
            },
          },
        },
      ],
      signal: controllers[index].signal,
    })
    .json();

  // make sure the matches are at least 5% intersecting with the boundary
  let areas;
  if (layer.boundaryGeometry) {
    const boundaryFeature = {
      type: 'Feature',
      geometry: layer.boundaryGeometry,
    };
    areas = response.filter((shape) => {
      const areaFeature = { type: 'Feature', geometry: shape.boundary };
      const areaOfShape = area(areaFeature);
      const intersectingShape = intersect(boundaryFeature, areaFeature);
      const areaOfIntersection = area(intersectingShape);

      return areaOfIntersection / areaOfShape > boundaryAreaOverlapThreshold;
    });
  } else {
    areas = response;
  }

  if (layer.groupBy) {
    const grouped = Object.values(
      areas.reduce((acc, area) => {
        for (const group of area.groups) {
          if (group.type === layer.groupBy) {
            if (!acc[group.code]) {
              acc[group.code] = {
                code: group.code,
                name: group.name,
                geometry: { type: 'GeometryCollection', geometries: [] },
                count: 0,
              };
            }

            acc[group.code].geometry.geometries.push(area.boundary);
            acc[group.code].count += data[area.code] ?? 0;
          }
        }

        return acc;
      }, {}),
    );
    const counts = grouped.map((group) => group.count).sort((a, b) => a - b);

    return {
      type: 'FeatureCollection',
      features: grouped.map(({ geometry, code: id, name, count }, index) => {
        return {
          type: 'Feature',
          id: index,
          properties: {
            name,
            id,
            source: 'groups',
            measure: layer.source,
            count: count,
            quantile: count ? quantileRankSorted(counts, count) : 0,
          },
          geometry,
        };
      }),
    };
  } else {
    const counts = Object.values(data).sort((a, b) => a - b);

    return {
      type: 'FeatureCollection',
      features: areas.map(
        ({ boundary: geometry, code: id, ...properties }, index) => {
          return {
            type: 'Feature',
            id: index,
            properties: {
              ...properties,
              // code: id,
              id,
              source: 'areas',
              measure: layer.source,
              count: data[id] || 0,
              quantile: data[id] ? quantileRankSorted(counts, data[id]) : 0,
            },
            geometry,
          };
        },
      ),
    };
  }
}

function geometryFieldForLayer(layer) {
  switch (layer.source ?? layer) {
    case 'speedInfractions':
    case 'personTrails':
    case 'vehicleTrips':
    case 'accelerometerEvents':
    case 'vehicleVisits':
    case 'personVisits':
      return 'path';
    case 'vehicleStops':
    case 'vehicleIdles':
    case 'accelerometerAlerts':
    case 'incidents':
      return 'point';
    case 'personCustomVisits':
    case 'personPolls':
    case 'vehicleCustomVisits':
    case 'vehiclePolls':
      return 'position';
    case 'locations':
      return 'boundary';
    default:
      break;
  }
}

function urlForLayer(layer) {
  switch (layer.source) {
    case 'speedInfractions':
      return 'speedInfractions';
    case 'vehicleTrips':
      return 'trips';
    case 'vehicleStopCount':
    case 'vehicleStops':
      return 'stops';
    case 'vehicleIdleCount':
    case 'vehicleIdles':
      return 'idles';
    case 'vehicleCustomVisits':
    case 'vehiclePolls':
      return 'telematicsBoxPolls';
    case 'vehicleVisitCount':
    case 'vehicleTime':
    case 'vehicleVisits':
      return 'intersections';
    case 'accelerometerAlerts':
    case 'accelerometerAlertCount':
      return 'accelerometerAlerts';
    case 'accelerometerEvents':
      return 'accelerometerEvents';
    case 'incidentCount':
    case 'incidents':
      return 'incidents';
    case 'personTrails':
      return 'personTrails';
    case 'personVisitCount':
    case 'personTime':
    case 'personVisits':
      return 'personLocationIntersections';
    case 'personCustomVisits':
    case 'personPolls':
      return 'radioPolls';
    case 'locations':
      return 'locations';
    default:
      break;
  }
}

function locationRollupPipeline(layer, query, projection, limit) {
  function findLocationInArray(types) {
    return {
      $first: {
        $filter: {
          input: '$locations',
          as: 'location',
          cond: {
            $in: ['$$location.type', types],
          },
        },
      },
    };
  }

  function findLocationObject(types) {
    return {
      $cond: {
        if: { $in: ['$location.type', types] },
        then: '$location',
        else: null,
      },
    };
  }

  let findLocationFunction;
  switch (layer.source || layer) {
    case 'vehicleTime':
    case 'vehicleVisits':
    case 'personTime':
    case 'personVisits':
    case 'vehicleVisitCount':
    case 'personVisitCount':
      findLocationFunction = findLocationObject;
      break;
    case 'vehicleIdleCount':
    case 'vehicleStopCount':
    case 'incidentCount':
    case 'accelerometerAlertCount':
      findLocationFunction = findLocationInArray;
      break;
    default:
      return { query, projection };
  }

  const pipeline = [
    { $match: query },
    limit && { $limit: limit },
    {
      $project: {
        ...projection,
        targetLocation: findLocationFunction([layer.areaType]),
        backupLocation: findLocationFunction(temp_locationTypeCodes),
      },
    },
    {
      $lookup: {
        from: 'locations',
        //localField: 'location.code',
        //foreignField: 'code',
        let: { code: '$backupLocation.code' },
        pipeline: [
          {
            $match: {
              $expr: { $eq: ['$code', '$$code'] },
            },
          },
          {
            $project: {
              parent: {
                $first: {
                  $filter: {
                    input: '$areas',
                    as: 'location',
                    cond: {
                      $eq: ['$$location.type', layer.areaType],
                    },
                  },
                },
              },
            },
          },
        ],
        as: 'parentLocationArray',
      },
    },
    {
      $project: {
        ...projection,
        location: {
          $cond: {
            if: '$targetLocation',
            then: {
              code: '$targetLocation.code',
              type: '$targetLocation.type',
            },
            else: {
              $first: '$parentLocationArray.parent',
            },
          },
        },
      },
    },
    {
      $match: { location: { $ne: null } },
    },
  ].filter(Boolean);

  return pipeline;
}

function projectionForLayer(layer) {
  // || layer in case someone just passed the source
  switch (layer.source || layer) {
    case 'speedInfractions':
      return {
        identifier: true,
        vehicle: true,
        driver: true,
        startTime: true,
        endTime: true,
        path: true,
        distanceKilometres: true,
        maxSpeedKilometresPerHour: true,
        durationSeconds: true,
        speedRules: true,
        equipmentActivations: true,
      };
    case 'vehicleTrips':
      return {
        identifier: true,
        vehicle: true,
        driver: true,
        startTime: true,
        endTime: true,
        path: true,
        distanceKilometres: true,
        maxSpeedKilometresPerHour: true,
        durationSeconds: true,
      };
    case 'vehicleVisitCount':
    case 'personVisitCount':
      return { location: true };
    case 'vehicleIdleCount':
    case 'vehicleStopCount':
    case 'incidentCount':
      return { locations: true };
    case 'vehicleStops':
      return {
        identifier: true,
        vehicle: true,
        startTime: true,
        endTime: true,
        point: true,
        durationSeconds: true,
        lastDriver: true,
      };
    case 'vehicleIdles':
      return {
        identifier: true,
        vehicle: true,
        driver: true,
        startTime: true,
        endTime: true,
        point: true,
        durationSeconds: true,
      };
    case 'vehicleCustomVisits':
      return {
        vehicleIdentificationNumber: true,
        time: true,
        position: true,
        orderNumber: true,
        distanceKilometres: true,
        speedKilometresPerHour: true,
        imei: true,
      };
    case 'vehiclePolls':
      return {
        identifier: true,
        imei: true,
        identificationNumber: true,
        time: true,
        position: true,
      };
    case 'vehicleTime':
      return {
        identifier: true,
        location: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
      };
    case 'vehicleVisits':
      return {
        identifier: true,
        vehicle: true,
        driver: true,
        startTime: true,
        endTime: true,
        path: true,
        location: true,
        distanceKilometres: true,
        durationSeconds: true,
      };
    case 'accelerometerEvents':
      return {
        identifier: true,
        vehicle: true,
        // driver: true,
        startTime: true,
        endTime: true,
        path: true,
        startLocations: true,
        endLocations: true,
        accelerometerData: true,
        deviceProperties: true,
        maximumForces: true,
        parentEvent: true,
      };
    case 'accelerometerAlerts':
    case 'accelerometerAlertCount':
      return {
        identifier: true,
        vehicle: true,
        driver: true,
        time: true,
        point: true,
        locations: true,
      };
    case 'incidents':
      return {
        identifier: '$number',
        number: true,
        description: true,
        type: true,
        category: true,
        responseCategory: true,
        responseSeconds: true,
        status: true,
        grade: true,
        point: true,
        openedTime: true,
        closingCodes: true,
        date: true,
        reference: true,
        locations: true,
      };
    case 'personTrails':
      return {
        identifier: true,
        person: true,
        startTime: true,
        endTime: true,
        path: true,
      };
    case 'personTime':
      return {
        identifier: true,
        location: true,
        startTime: true,
        endTime: true,
      };
    case 'personVisits':
      return {
        identifier: true,
        person: true,
        startTime: true,
        endTime: true,
        location: true,
        path: true,
        durationSeconds: true,
      };
    case 'personCustomVisits':
      return {
        ssi: true,
        personCode: true,
        time: true,
        position: true,
        orderNumber: true,
      };
    case 'personPolls':
      return {
        identifier: true,
        ssi: true,
        personCode: true,
        // code: true,
        collarNumber: true,
        time: true,
        position: true,
      };
    case 'locations':
      return {
        code: true,
        name: true,
        subtype: true,
        type: true,
        boundary: true,
      };
    case 'people':
      return {
        forenames: true,
        surname: true,
        code: true,
        collarNumber: true,
        radioSsi: true,
        rank: true,
        role: true,
        homeStation: true,
      };
    case 'vehicles':
      return {
        identificationNumber: true,
        fleetNumber: true,
        registrationNumber: true,
        role: true,
        homeStation: true,
        telematicsBoxImei: true,
        type: true,
      };
    default:
      break;
  }
}

async function matchForLayer(layer, filters) {
  let mongoFilters = mongoizeFilters(filters);

  function extractMongoFiltersForEntity(entity) {
    return mongoFilters
      .filter((f) => Object.keys(f)?.[0]?.startsWith(entity))
      .map((f) => {
        const key = Object.keys(f)[0];

        return {
          [key.replace(`${entity}.`, '')]: f[key],
        };
      });
  }

  // rfidCard.reference will supersede driver.identificationReference so reroute
  mongoFilters = mongoFilters.map((filter) => {
    const key = Object.keys(filter)[0];
    if (key === 'driver.identificationReference') {
      return { 'rfidCard.reference': filter[key] };
    } else if (key === 'lastDriver.identification') {
      return { 'lastRfidCard.reference': filter[key] };
    } else {
      return filter;
    }
  });

  const $and = mongoFilters.length > 0 ? mongoFilters : undefined;

  switch (layer.source) {
    // no drawn boundaries for vehicle/person known visits
    case 'vehicleVisits':
    case 'personVisits':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        $and,
      };
    // path-based
    case 'speedInfractions':
    case 'personTrails':
    case 'vehicleTrips':
    case 'accelerometerEvents':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        path: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        $and,
      };
    // point-based
    case 'vehicleStops':
    case 'vehicleIdles':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        point: layer.boundaryGeometry
          ? {
              $geoWithin: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        $and,
      };
    // count by type
    case 'vehicleTime':
    case 'vehicleVisitCount':
    case 'personTime':
    case 'personVisitCount':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        // 'location.type': layer.areaType, // taken care of in rollup pipeline
        $and,
      };
    case 'vehicleStopCount':
    case 'vehicleIdleCount':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        // 'locations.type': layer.areaType, // taken care of in rollup pipeline
        $and,
      };
    case 'accelerometerAlertCount':
      return {
        time: { $gte: layer.startTime, $lt: layer.endTime },
        'locations.type': layer.areaType,
        $and,
      };
    case 'incidentCount':
      return {
        openedTime: { $gte: layer.startTime, $lt: layer.endTime },
        // 'locations.type': layer.areaType, // taken care of in rollup pipeline
        $and,
      };
    // polls
    case 'personCustomVisits':
    case 'personPolls': {
      // a full person object is not stored on each poll, instead there is a personCode
      // field so we need to find the codes from the people collection first
      // ssi is stored on each poll so can be used separately if it's the only filter
      const personMongoFilters = extractMongoFiltersForEntity('person');
      let personPollFilters = {};

      if (personMongoFilters.length > 0) {
        // if the only filter is for an SSI, it is on each poll and we don't need to
        // get personCodes to match. This also allows searching for unassigned SSIs
        if (personMongoFilters.length === 1 && personMongoFilters[0].radioSsi) {
          personPollFilters = { ssi: personMongoFilters[0].radioSsi };
        } else {
          const codesQuery = await api
            .get('people', {
              searchParams: encodeParams({
                query: {
                  $and: personMongoFilters,
                },
                projection: {
                  code: true,
                },
              }),
            })
            .json();

          const codes = codesQuery.map(({ code }) => code).filter(Boolean);
          personPollFilters = { personCode: { $in: codes } };
        }
      }

      return {
        time: { $gte: layer.startTime, $lt: layer.endTime },
        // 'position.coordinates.0': { $exists: true },
        position: layer.boundaryGeometry
          ? {
              $geoWithin: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        ...personPollFilters,
        $and,
      };
    }
    case 'vehicleCustomVisits':
    case 'vehiclePolls': {
      // a full vehicle object is not stored on each poll, instead there is a
      // vehicleIdentificationNumber field so we need to find the matching
      // identificationNumbers from the vehicle collection first
      // imei is stored on each poll so can be used separately if it's the only filter
      const vehicleMongoFilters = extractMongoFiltersForEntity('vehicle');
      let vehiclePollFilters = {};

      if (vehicleMongoFilters.length > 0) {
        // if the only filter is for an IMEI, it is on each poll and we don't need to
        // get vehicleIdentificationNumbers to match, allows searching for unassigned IMEIs
        if (
          vehicleMongoFilters.length === 1 &&
          vehicleMongoFilters[0].telematicsBoxImei
        ) {
          vehiclePollFilters = {
            imei: vehicleMongoFilters[0].telematicsBoxImei,
          };
        } else {
          const imeisQuery = await api
            .get('vehicles', {
              searchParams: encodeParams({
                query: {
                  $and:
                    vehicleMongoFilters.length > 0
                      ? vehicleMongoFilters
                      : undefined,
                },
                projection: {
                  telematicsBoxImei: true,
                },
              }),
            })
            .json();

          const identificationNumbers = imeisQuery
            .map(({ identificationNumber }) => identificationNumber)
            .filter(Boolean);

          vehiclePollFilters = {
            vehicleIdentificationNumber: { $in: identificationNumbers },
          };
        }
      }

      return {
        time: { $gte: layer.startTime, $lt: layer.endTime },
        // 'position.coordinates.0': { $exists: true },
        position: layer.boundaryGeometry
          ? {
              $geoWithin: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        ...vehiclePollFilters,
        $and,
      };
    }
    case 'accelerometerAlerts':
      return {
        time: { $gte: layer.startTime, $lt: layer.endTime },
        point: layer.boundaryGeometry
          ? {
              $geoWithin: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        $and,
      };
    case 'incidents':
      return {
        openedTime: { $gte: layer.startTime, $lt: layer.endTime },
        point: layer.boundaryGeometry
          ? {
              $geoWithin: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        $and,
      };
    case 'locations':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        boundary: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        $and,
      };
    default:
      // if we don't have a type make sure it doesn't match anything
      return {
        type: 'not found',
      };
  }
}

// estimate how many items will be returned by using the same filters
// for a shorter period of time & doing a count pipeline
const {
  sampleSize = 100,
  sampleDays = 3,
  clusterConstant = 3,
} = retrospective?.estimation || {};
async function estimateLayerResultCount(index, layer, filters) {
  const url = urlForLayer(layer);
  const parsed = parseInt(layer.precision);
  const precision = isNaN(parsed) ? undefined : parsed;
  const isClustered = layer.type === 'heat' && precision && precision < 7;
  const isAreaType = layer.type === 'area';

  if (!url || !layer.startTime || !layer.endTime) {
    return {
      index,
      estimate: {
        items: 0,
        data: 0,
        mapFeaturesSize: 0,
      },
    };
  }

  let startTime = new Date(layer.startTime);
  let endTime = new Date(layer.endTime);

  const dayDifference = differenceInMinutes(endTime, startTime) / 60 / 24;

  // don't bother sampling if it's less than a day
  if (dayDifference > sampleDays) {
    // otherwise get a typical day in the middle of the range
    startTime = addDays(startTime, (dayDifference - sampleDays) / 2);
    endTime = addDays(startTime, sampleDays);
  }

  // to estimate we sample items in a limited time range and a limited
  // number of items over the full time range
  //
  // to avoid doing person lookups twice and to avoid how time is represented
  // (time/startTime/openedTime) we'll get the match twice, a full match and
  // one without filters but with the reduced date range
  // e.g. this will have { time: month, $and: { personCodes: {$in: [1,2,3]}}}
  const $match = await matchForLayer(layer, filters);

  // ... this will have { time: a_day, $and: undefined }
  const matchNoFilters = await matchForLayer(
    {
      ...layer,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
    },
    {},
  );

  // and this will have time, $and from the first full match, with time replaced
  // e.g. { time: a_day, $and: { personCodes: {$in: [1,2,3]}}}
  const $smallMatch = {
    ...$match,
    ..._.omitBy(matchNoFilters, (v) => v === undefined),
  };

  const $project = projectionForLayer(layer);
  const geo = isAreaType ? 'boundary' : geometryFieldForLayer(layer);

  let totalsPipeline = [
    { $match: $smallMatch },
    ...(isClustered
      ? [
          // mongo needs $ to ref the record's geo not a string
          ...clusterProjectAndGroup(`$${geo}`, precision, true),
          {
            $group: {
              _id: null,
              items: { $sum: '$count' },
              clusters: { $sum: 1 },
            },
          },
        ]
      : [
          {
            $group: {
              _id: null,
              items: { $sum: 1 },
            },
          },
        ]),
  ];

  let samplePipeline = [
    { $match },
    // $sample requires all the docs from the previous stage, whereas limit will
    // stop reading documents after sampleSize. It is probably not as representative
    // but it will be much faster
    // https://docs.mongodb.com/manual/reference/operator/aggregation/sample/#behavior
    // https://stackoverflow.com/a/54319812/1350146
    //     { $sample: { size: sampleSize } },
    { $limit: sampleSize },
    { $project },
  ];

  let areasPipeline = [
    {
      $match: {
        type: layer.areaType,
        boundary: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
      },
    },
    {
      $project: {
        size: { $size: { $first: '$boundary.coordinates' } },
      },
    },
    {
      $group: {
        _id: null,
        coords: {
          $sum: '$size',
        },
      },
    },
  ];

  const fetchPipeline = (pipeline) => {
    estimationControllers[index] = new AbortController();
    return api
      .post(
        pipeline === areasPipeline ? 'pipeline/locations' : `pipeline/${url}`,
        {
          json: pipeline,
          signal: estimationControllers[index].signal,
        },
      )
      .json();
  };

  // get each pipeline result in parallel
  const [totalsResult, sampleResult, areasResult] = await Promise.all(
    [totalsPipeline, samplePipeline, isAreaType && areasPipeline]
      .filter(Boolean)
      .map(fetchPipeline),
  );

  let itemsTotal = totalsResult?.[0]?.items || 0;
  let clusters = totalsResult?.[0]?.clusters || 0;
  if (dayDifference > sampleDays) {
    itemsTotal = ((dayDifference * itemsTotal) / sampleDays) | 0; // round down
    clusters = (clusters * clusterConstant) | 0; // round down
  }

  // totalsResult works on a reduced date range and scales up to the full date
  // range. It's possible that no items are picked up in the reduced range if
  // the data are irregular; e.g. data on weekends but a midweek range was chosen.
  //
  // sampleResult works over the full date range but is limited to sampleSize items
  // so it may sample some items that the totals pipeline may have missed.
  //
  // if the itemsTotal is 0 but there are some sampled items in the full range
  // the data are not evenly distributed
  let irregular = false;
  const sampledItems = sampleResult || [];
  if (
    (itemsTotal === 0 && sampledItems.length > 0) ||
    sampledItems.length < sampleSize
  ) {
    itemsTotal = sampledItems.length;

    // if the itemsTotal is less than the sample size, it shouldn't be a lot
    // of data so reget totals for the full range
    if (itemsTotal < sampleSize) {
      totalsPipeline[0] = samplePipeline[0]; // full date range

      const fullRangeTotalsResult = await fetchPipeline(totalsPipeline);

      itemsTotal = fullRangeTotalsResult?.[0]?.items || 0;
      clusters = fullRangeTotalsResult?.[0]?.clusters || 0;
    } else {
      // otherwise all we know is there are more items than the sample size and as
      // a full range query may take too long, mark as irregular and inform user
      irregular = true;
    }
  }

  let data, mapFeaturesSize, itemLimit;
  const limits = getLimits(layer);

  if (isClustered) {
    data = clusters * 150; // hardcoded but roughly for _id, count, lat/lon & position geo
    mapFeaturesSize = clusters;

    // we can't work out an itemLimit here as there's no reliable item to cluster ratio
    // the cluster pipeline will do what it can, but it might be at the end of the query
  } else if (isAreaType) {
    // stringify is expensive so we'll do a sample of 1
    const dataPerItem = JSON.stringify(sampledItems[0] || {}).length;
    data = dataPerItem * itemsTotal;
    mapFeaturesSize = areasResult?.[0]?.coords || 0;

    // the main restricting factor for the area types is the size of the data in RAM
    itemLimit = dataPerItem > 0 ? limits?.items / dataPerItem : undefined;
  } else {
    // stringify is expensive so we'll do a sample of 1
    data = JSON.stringify(sampledItems[0] || {}).length * itemsTotal;

    const averageMapFeaturesPerItem = !geo
      ? 0
      : (isAreaType ? areasResult : sampleResult)?.reduce(
          (sum, record) =>
            sum +
            (!record[geo]
              ? 0
              : Array.isArray(record[geo].coordinates[0])
                ? 0.5 *
                  record[geo].coordinates.reduce((sum, c) => sum + c.length, 0)
                : 1),
          0,
        ) / (sampledItems.length || 1);

    mapFeaturesSize = averageMapFeaturesPerItem * itemsTotal;

    // the main restricting factor for shape types is the number of features
    itemLimit =
      averageMapFeaturesPerItem > 0
        ? limits.mapFeaturesSize / averageMapFeaturesPerItem
        : undefined;
  }

  const estimate = {
    items: itemsTotal,
    data,
    mapFeaturesSize,
    irregular,
    itemLimit,
  };
  // import.meta.env.DEV && console.log(estimate);

  return {
    index,
    estimate,
  };
}

export function estimateLayerResultCountEpic(action$) {
  return action$.pipe(
    ofType(ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT),
    groupBy(({ payload: { index } }) => index),
    mergeMap((indexPipe) =>
      indexPipe.pipe(
        tap(({ payload: { index } }) => {
          estimationControllers[index]?.abort();
        }),
        debounceTime(300),
        switchMap(({ payload: { index, layer, filters } }) =>
          from(estimateLayerResultCount(index, layer, filters)).pipe(
            map((payload) => ({
              type: ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_SUCCESS,
              payload,
            })),
            takeUntil(
              action$.pipe(
                ofType(ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_CANCELLED),
                filter((action) => action.payload === index),
                tap(() => estimationControllers[index]?.abort()),
              ),
            ),
            catchError((error) => {
              console.error(error);

              return of({
                type: ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_FAILURE,
                payload: {
                  message: error.message,
                  index,
                },
              });
            }),
          ),
        ),
      ),
    ),
  );
}

function clusterProjectAndGroup(geo, precision, skipAverage = false) {
  return [
    {
      $project: {
        ...(skipAverage
          ? {}
          : {
              origLon: {
                $arrayElemAt: [`${geo}.coordinates`, 0],
              },
              origLat: {
                $arrayElemAt: [`${geo}.coordinates`, 1],
              },
            }),
        lon: {
          $trunc: [
            {
              $toDecimal: {
                $arrayElemAt: [`${geo}.coordinates`, 0],
              },
            },
            precision,
          ],
        },
        lat: {
          $trunc: [
            {
              $toDecimal: {
                $arrayElemAt: [`${geo}.coordinates`, 1],
              },
            },
            precision,
          ],
        },
      },
    },
    {
      $group: {
        _id: {
          lon: { $toDouble: '$lon' },
          lat: { $toDouble: '$lat' },
        },
        count: {
          $sum: 1,
        },
        ...(skipAverage
          ? {}
          : {
              avgLon: { $avg: '$origLon' },
              avgLat: { $avg: '$origLat' },
            }),
      },
    },
  ];
}

async function fetchAggregatedHeat(index, layer, filters, precision) {
  const clustering = precision < 7;
  const limits = getLimits(layer);

  const $match = await matchForLayer(layer, filters);
  const projection = projectionForLayer(layer);
  const url = urlForLayer(layer);

  // used for getting the centre point
  // const mid = 10 ** -precision / 2;

  // the heat ones are either position or point depending on poll or not
  const geo = `$${geometryFieldForLayer(layer)}`;

  let pipeline = [
    // poll-based queries need a lookup to get ssis/imeis that match person/vehicle filters
    {
      $match,
    },
    { $limit: limits.items },
    ...(clustering
      ? [
          ...clusterProjectAndGroup(geo, precision),
          {
            // TODO this is only so the ui doesn't crash, ideally we have
            // a new object that the ui interprets as a cluster and will
            // decluster it
            $project: {
              // the corner of the cluster
              // all that's needed to decluster: get me all of the polls/incidents
              // between this position and this position + 1^-precision
              // with the same search criteria (homeStation: x/grade: y)
              lat: '$_id.lat',
              lon: '$_id.lon',

              count: true,

              //////////////////////
              // the map position
              //////////////////////

              // corner:
              /*position: {
              coordinates: ['$_id.lon', '$_id.lat'],
              type: 'Point',
            },*/

              // average:
              position: {
                coordinates: ['$avgLon', '$avgLat'],
                type: 'Point',
              },

              // centre:
              // the add, mul, div, abs horror show is used to get the centre of the
              // grid. We need to go in the same direction so if it's -1 go to -1.5 not -0.5
              // abs(x)/x gives us the sign, multiplying by half the precision gives us the
              // distance and add that to the lon/lat
              /*position: {
              coordinates: [
                {
                  $add: [
                    '$_id.lon',
                    {
                      $multiply: [
                        {$divide: [{$abs: '$_id.lon'}, '$_id.lon']},
                        mid,
                      ],
                    },
                  ],
                },
                {
                  $add: [
                    '$_id.lat',
                    {
                      $multiply: [
                        {$divide: [{$abs: '$_id.lat'}, '$_id.lat']},
                        mid,
                      ],
                    },
                  ],
                },
              ],
              type: 'Point',
            }, // end mid
            */
            },
          },
        ]
      : [
          {
            // this includes the identifier of the poll or the item
            // e.g. identifier:"2865150-2020-12-31T21:44:00.000Z" or number: 'inc02'
            // the old ui should work as normal here
            $project: projection,
          },
        ]),
    { $limit: limits.mapFeaturesSize },
  ];

  function pad(number) {
    const string = number.toString();
    const decimalPosition = string.indexOf('.');
    return string.padEnd(decimalPosition + precision + 1, '0');
  }

  controllers[index] = new AbortController();
  const response = await api
    .post(`pipeline/${url}`, {
      json: pipeline,
      signal: controllers[index].signal,
    })
    .json();

  return [
    response.length >= limits.mapFeaturesSize
      ? { abortedDueToLimitExceeded: 'map features size' }
      : {
          type: 'FeatureCollection',
          features: response
            .filter((p) => p.lat && p.lon) // handle items without a location
            .map(
              (
                { position: geometry, lon, lat, identifier, ...properties },
                index,
              ) => {
                return {
                  type: 'Feature',
                  id: index,
                  properties: {
                    ...properties,
                    lon,
                    lat,
                    id: lon
                      ? `${pad(lon)}${String.fromCharCode(176)}, ${pad(
                          lat,
                        )}${String.fromCharCode(176)}`
                      : identifier,
                    originalSource: layer.source,
                    source: 'clusters',
                  },
                  geometry,
                };
              },
            ),
        },
    $match,
  ];
}

async function fetchRetrospectiveLayerRequest(index, layer, filters) {
  const parsed = parseInt(layer.precision);
  const originalPrecision = isNaN(parsed) ? 0 : parsed;
  const aggregateHeat = layer.type === 'heat' && originalPrecision < 7;

  let originalMatch, data;
  if (aggregateHeat) {
    // when we want to decluster one of the features, it's helpful to have the
    // orginal match criteria cached on the layer so this function returns both
    [data, originalMatch] = await fetchAggregatedHeat(
      index,
      layer,
      filters,
      originalPrecision,
    );
  } else {
    data = await fetchLayerData(index, layer, filters);
  }

  log('Read', 'Layers', _.omit(layer, ['featureCollection', 'window']));

  const { abortedDueToLimitExceeded } = data;

  if (abortedDueToLimitExceeded) {
    return {
      index,
      layer: {
        ...layer,
        featureCollection: { type: 'FeatureCollection', features: [] },
        originalMatch,
        originalPrecision,
        abortedDueToLimitExceeded,
      },
    };
  } else {
    switch (layer.type) {
      case 'shape':
      case 'bubble':
      case 'heat':
        return {
          index,
          layer: {
            ...layer,
            featureCollection: data,
            originalMatch,
            originalPrecision: originalPrecision,
            abortedDueToLimitExceeded,
          },
        };
      case 'area': {
        const areas = await fetchAreas(index, layer, data);

        return {
          index,
          layer: {
            ...layer,
            featureCollection: areas,
            abortedDueToLimitExceeded,
          },
        };
      }
      case 'file': {
        const features = layer.file.content.features.map((feature) => ({
          ...feature,
          properties: {
            ...feature.properties,
            source: layer.source,
          },
        }));
        return {
          index,
          layer: {
            ...layer,
            featureCollection: { ...layer.file.content, features },
          },
        };
      }
      default:
        return { index, layer };
    }
  }
}

export function fetchRetrospectiveLayerEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_LAYER),
    tap(({ payload: { index } }) => {
      controllers[index]?.abort();
    }),
    mergeMap(({ payload: { index, layer, filters } }) =>
      from(fetchRetrospectiveLayerRequest(index, layer, filters)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_LAYER_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_RETROSPECTIVE_LAYER_CANCELLED),
            filter((action) => action.payload === index),
            tap(() => controllers[index]?.cancel()),
          ),
        ),
        catchError(({ message }) =>
          of({
            type: FETCH_RETROSPECTIVE_LAYER_FAILURE,
            payload: {
              message,
              index,
            },
          }),
        ),
      ),
    ),
  );
}

async function fetchRetrospectiveLayerBoundaryRequest(index, layer) {
  const boundaryGeometry = await fetchBoundary(
    layer.boundaryType,
    layer.boundaryIdentifier,
  );

  return {
    index,
    layer: { ...layer, boundaryGeometry },
  };
}

export function fetchRetrospectiveLayerBoundaryEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_LAYER_BOUNDARY),
    mergeMap(({ payload: { index, layer } }) =>
      from(fetchRetrospectiveLayerBoundaryRequest(index, layer)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function fetchTrailsForVisit(properties) {
  // trails are only for people so if none is associated, no trails retrieved
  if (properties.person.code) {
    // get any trips that overlap this visit

    const response = await api
      .get('personTrails', {
        searchParams: encodeParams({
          query: {
            startTime: { $lte: properties.endTime },
            endTime: { $gte: properties.startTime },
            'person.code': properties.person.code,
          },
          projection: { ...projectionForLayer('personTrails'), path: true },
        }),
      })
      .json();

    const trails = _.orderBy(response, 'startTime', 'asc').map((t) => ({
      ...t,
      source: 'personTrails',
      id: t.identifier,
    }));

    return {
      trails,
      subItems: trails,
      itemFeatures: standardFeatureCollection('path', trails, 'personTrails'),
    };
  } else {
    return {
      trails: [],
      subItems: [],
      itemFeatures: { type: 'FeatureCollection', features: [] },
    };
  }
}

async function fetchTripsForVisit(properties) {
  // trips are only for vehicles so if none is associated, no trips retrieved
  if (properties.vehicle.identificationNumber) {
    // get any trips that overlap this visit
    const response = await api
      .get('trips', {
        searchParams: encodeParams({
          query: {
            startTime: { $lte: properties.endTime },
            endTime: { $gte: properties.startTime },
            'vehicle.identificationNumber':
              properties.vehicle.identificationNumber,
          },
          projection: { ...projectionForLayer('vehicleTrips'), path: true },
        }),
      })
      .json();

    const trips = _.orderBy(response, 'startTime', 'asc').map((t) => ({
      ...t,
      source: 'vehicleTrips',
      id: t.identifier,
    }));

    return {
      trips,
      subItems: trips,
      itemFeatures: standardFeatureCollection('path', trips, 'vehicleTrips'),
    };
  } else {
    return {
      trips: [],
      subItems: [],
      itemFeatures: { type: 'FeatureCollection', features: [] },
    };
  }
}

async function fetchRetrospectiveItemRequest({ source, ...itemData }) {
  const { id, count, quantile, measure, ...properties } = itemData;
  let response, visitLocation, event;
  switch (source) {
    case 'speedInfractions': {
      response = await api
        .get(`speedInfractions/${id}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              driver: true,
              vehicle: true,
              startTime: true,
              endTime: true,
              distanceKilometres: true,
              maxSpeedKilometresPerHour: true,
              startLocations: true,
              endLocations: true,
              speedRules: true,
              equipmentActivations: true,
            },
          }),
        })
        .json();

      // Need to get the vehicle details because the vechile in trip collection doesn't have make and model properties
      const vehicle = response.vehicle.identificationNumber
        ? await api
            .get(`vehicles/${response.vehicle.identificationNumber}`, {
              searchParams: encodeParams({
                projection: {
                  identificationNumber: true,
                  registrationNumber: true,
                  fleetNumber: true,
                  role: true,
                  type: true,
                  homeStation: true,
                  make: true,
                  model: true,
                  groups: true,
                  telematicsBoxImei: true,
                },
              }),
            })
            .json()
        : { ...response.vehicle, unassociated: true };

      return {
        itemType: 'speedInfraction',
        ...response,
        vehicle,
      };
    }
    case 'vehicleTrips': {
      response = await api
        .get(`trips/${id}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              classification: true,
              driver: true,
              vehicle: true,
              startTime: true,
              endTime: true,
              distanceKilometres: true,
              maxSpeedKilometresPerHour: true,
              startLocations: true,
              endLocations: true,
            },
          }),
        })
        .json();

      // Need to get the vehicle details because the vechile in trip collection doesn't have make and model properties
      const vehicle = response.vehicle.identificationNumber
        ? await api
            .get(`vehicles/${response.vehicle.identificationNumber}`, {
              searchParams: encodeParams({
                projection: {
                  identificationNumber: true,
                  registrationNumber: true,
                  fleetNumber: true,
                  role: true,
                  type: true,
                  homeStation: true,
                  make: true,
                  model: true,
                  groups: true,
                  telematicsBoxImei: true,
                },
              }),
            })
            .json()
        : { ...response.vehicle, unassociated: true };

      return {
        itemType: 'vehicleTrip',
        ...response,
        vehicle,
      };
    }
    case 'vehicleStops':
      response = await api
        .get(`stops/${id}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              lastDriver: true,
              vehicle: true,
              startTime: true,
              endTime: true,
            },
          }),
        })
        .json();

      return { itemType: 'vehicleStop', ...response };
    case 'vehicleIdles':
      response = await api
        .get(`idles/${id}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              driver: true,
              vehicle: true,
              startTime: true,
              endTime: true,
            },
          }),
        })
        .json();

      return { itemType: 'vehicleIdle', ...response };
    case 'vehiclePolls': {
      response = await api
        .get(`vehiclePolls/${id}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              imei: true,
              vehicleIdentificationNumber: true,
              time: true,
              position: true,
              headingDegrees: true,
              speedKilometresPerHour: true,
              malfunctionIndicatorLightOn: true,
              accelerometerAlert: true,
              ignitionOn: true,
              driver: true,
              ...Object.keys(dioStates).reduce((acc, key) => {
                acc[key] = true;
                return acc;
              }, {}),
            },
          }),
        })
        .json();

      const { imei, time } = response;

      let vehicle;
      const trips = await api
        .get('trips', {
          searchParams: encodeParams({
            query: {
              'vehicle.telematicsBoxImei': imei,
              startTime: { $lte: time },
              endTime: { $gte: time },
            },
            projection: {
              vehicle: true,
            },
          }),
        })
        .json();

      if (trips.length > 0) {
        vehicle = trips[0].vehicle;
      } else {
        const stops = await api
          .get('stops', {
            searchParams: encodeParams({
              query: {
                'vehicle.telematicsBoxImei': imei,
                startTime: { $lte: time },
                endTime: { $gte: time },
              },
              projection: {
                vehicle: true,
              },
            }),
          })
          .json();

        if (stops.length > 0) {
          vehicle = stops[0].vehicle;
        }
      }

      if (!vehicle) {
        vehicle = { unassociated: true };
      }

      return {
        itemType: 'vehiclePoll',
        vehicle: { ...vehicle, telematicsBoxImei: imei },
        ...response,
      };
    }
    case 'vehicleVisits':
      response = await api
        .get(`intersections/${id}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              driver: true,
              vehicle: true,
              location: true,
              startTime: true,
              endTime: true,
              distanceKilometres: true,
              maxSpeedKilometresPerHour: true,
            },
          }),
        })
        .json();

      ({ location: visitLocation, ...event } = response);

      return {
        itemType: 'vehicleVisit',
        visitLocation,
        ...(await fetchTripsForVisit(properties)),
        ...event,
      };
    case 'vehicleCustomVisits': {
      return {
        itemType: 'vehicleVisit',
        visitLocation: properties.location,
        ...properties,
        ...(await fetchTripsForVisit(properties)),
      };
    }
    case 'accelerometerAlerts':
      response = await api
        .get(`accelerometerAlerts/${id}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              vehicle: true,
              driver: true,
            },
          }),
        })
        .json();

      return {
        itemType: 'accelerometerAlert',
        ...response,
      };
    case 'accelerometerEvents': {
      response = await api
        .get(`accelerometerEvents/${id}`, {
          searchParams: encodeParams({
            projection: projectionForLayer('accelerometerEvents'),
          }),
          headers: getHeaders(),
        })
        .json();

      event = response;

      let trips = [];
      if (event.vehicle.identificationNumber) {
        trips = await api
          .get('trips', {
            searchParams: encodeParams({
              query: {
                startTime: { $lte: event.startTime },
                endTime: { $gte: event.startTime },
                'vehicle.identificationNumber':
                  event.vehicle.identificationNumber,
              },
              projection: {
                driver: true,
              },
            }),
          })
          .json();
      }

      return {
        itemType: 'accelerometerEvent',
        ...event,
        driver: trips?.[0].driver,
      };
    }
    case 'incidents':
      response = await api
        .get(`incidents/${id}`, {
          searchParams: encodeParams({
            projection: {
              number: true,
              description: true,
              type: true,
              category: true,
              responseCategory: true,
              responseSeconds: true,
              grade: true,
              point: true,
              address: true,
              openedTime: true,
              assignedTime: true,
              attendedTime: true,
              closedTime: true,
              status: true,
              closingCodes: true,
              reference: true,
              date: true,
              locations: true,
            },
          }),
        })
        .json();

      return { itemType: 'incident', ...response };
    case 'personTrails':
      response = await api
        .get(`personTrails/${id}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              startTime: true,
              endTime: true,
              person: true,
              startLocations: true,
              endLocations: true,
            },
          }),
        })
        .json();

      return { itemType: 'personTrail', ...response };
    case 'personVisits':
      response = await api
        .get(`personLocationIntersections/${id}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              startTime: true,
              endTime: true,
              person: true,
              location: true,
            },
          }),
        })
        .json();

      ({ location: visitLocation, ...event } = response);

      return {
        itemType: 'personVisit',
        visitLocation,
        ...event,
        ...(await fetchTrailsForVisit(properties)),
      };
    case 'personCustomVisits': {
      return {
        itemType: 'personVisit',
        visitLocation: properties.location,
        ...properties,
        ...(await fetchTrailsForVisit(properties)),
      };
    }

    case 'clusters': {
      const { lat, lon, originalSource } = properties;
      const url = urlForLayer({ source: originalSource });
      const projection = projectionForLayer({ source: originalSource });

      // get all the polls that are in the cluster's grid, the lat/lon passed serves
      // as a starting point, and the precision dictates how wide & long the grid is
      // a precision of 1 is a diff of .1, precision of 2 is a diff of .01 etc.
      const diff = 10 ** -properties.originalPrecision;
      // eslint-disable-next-line no-inner-declarations
      function grid(lat1, lon1) {
        const lat2 = lat1 < 0 ? lat1 - diff : lat1 + diff;
        const lon2 = lon1 < 0 ? lon1 - diff : lon1 + diff;

        return {
          type: 'Polygon',
          coordinates: [
            [
              [lon1, lat1],
              [lon1, lat2],
              [lon2, lat2],
              [lon2, lat1],
              [lon1, lat1],
            ],
          ],
        };
      }

      // the heat ones are either position or point depending on poll or not
      const geo = geometryFieldForLayer(originalSource);

      const response = await api
        .post(`pipeline/${url}`, {
          json: [
            {
              $match: {
                ...properties.originalMatch,
                [geo]: {
                  $geoWithin: {
                    $geometry: grid(lat, lon),
                  },
                },
              },
            },
            {
              $project: projection,
            },
          ],
        })
        .json();

      return {
        itemType: 'clusters',
        subItems: response.map((p) => ({
          ...p,
          id: p.identifier,
          originalSource,
          source: 'clusters',
        })),
        count,
        ...properties,
      };
    }
    case 'personPolls': {
      response = await api
        .get(`radioPolls/${id}`, {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              ssi: true,
              // code: true,
              personCode: true,
              time: true,
              position: true,
              emergencyButtonOn: true,
            },
          }),
        })
        .json();

      const { ssi, time } = response;

      const personResponse = await api
        .get('personTrails', {
          searchParams: encodeParams({
            query: {
              'person.radioSsi': ssi,
              startTime: { $lte: time },
              endTime: { $gte: time },
            },
            projection: {
              person: true,
            },
          }),
        })
        .json();

      let person = personResponse?.[0]?.person;
      if (!person) {
        const personDictionary = await getPersonDictionary();
        person = personDictionary[ssi] ?? { unassociated: true };
      }

      return {
        itemType: 'personPoll',
        person: { ...person, radioSsi: ssi },
        ...response,
      };
    }
    case 'areas':
      response = await api
        .get(`locations/${id}`, {
          searchParams: encodeParams({
            projection: {
              code: true,
              name: true,
              type: true,
              subtype: true,
            },
          }),
        })
        .json();

      return { itemType: 'area', count, quantile, measure, ...response };
    case 'groups':
      response = await api
        .get(`groups/${id}`, {
          searchParams: encodeParams({
            projection: {
              code: true,
              name: true,
              type: true,
              subtype: true,
            },
          }),
        })
        .json();

      return { itemType: 'area', count, quantile, measure, ...response };
    case 'locations':
      response = await api
        .get(`locations/${id}`, {
          searchParams: encodeParams({
            query: {
              fields: ['code', 'name', 'type', 'areas', 'subtype'],
            },
          }),
        })
        .json();

      return { itemType: 'location', ...response };
    case 'geojson':
    case 'kml':
      return { itemType: 'file', itemData: itemData.fileProperties };
    default:
      return null;
  }
}

export function fetchRetrospectiveItemEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_ITEM),
    mergeMap(({ payload: properties }) =>
      from(fetchRetrospectiveItemRequest(properties)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_ITEM_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_ITEM_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchRetrospectiveSubItemEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_SUBITEM),
    mergeMap(({ payload: { originalSource, source, ...properties } }) =>
      from(
        fetchRetrospectiveItemRequest({
          ...properties,
          source: originalSource ?? source,
        }),
      ).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_SUBITEM_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_SUBITEM_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

// export function pushRetrospectiveFormEpic(action$) {
//   return action$.pipe(
//     ofType(PUSH_RETROSPECTIVE_FORM),
//     // the debounce can lead to some timing issues such as results for a boundary
//     // query coming back before there are any layers saved (pushed & synced)
//     // debounceTime(5000),
//     // HOWEVER, react-final-form & FormSpy seem to need a small delay otherwise
//     // initialValues get wiped with ""
//     debounceTime(5),
//     switchMap(({ payload }) =>
//       of({
//         type: SYNC_RETROSPECTIVE_FORM,
//         payload,
//       })
//     )
//   );
// }
