import { CustomTooltip, GroupCodesPicker } from '@/components/controls';
import {
  useDocumentTitle,
  useEngagements,
  useGroupOptions,
  useOptions,
} from '@/hooks';
import { downloadCSV, randomHsl } from '@/utils';
import { Download as DownloadIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  MRT_TablePagination,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useCallback, useMemo, useState } from 'react';
import {
  Tooltip as ChartTooltip,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import {
  columnsFn,
  formatLabel,
  getBackgroundColor,
  getHoverBackgroundColor,
} from './utils';

const getValue = (id) => (data) => data.counts[id] ?? 0;

export function Engagement() {
  useDocumentTitle('IR3 | Engagement');
  const [hiddenIds, setHiddenIds] = useState({});
  const [filterBy, setFilterBy] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const { data: typeOptions } = useOptions('groupType');
  const groupOptions = useGroupOptions();
  const groupByOptions = useMemo(
    () => [
      { label: 'Home Station', id: 'homeStation', group: 'Other' },
      { label: 'Role', id: 'role', group: 'Other' },
      typeOptions.map(({ value, label }) => ({
        label,
        id: `groups.${value}`,
        group: 'Group',
      })),
    ],
    [typeOptions],
  );
  const { data, isFetching } = useEngagements();
  const engagements = useMemo(() => {
    const engagements = data
      .filter(
        (engagement) =>
          filterBy.length === 0 ||
          (engagement.groupAncestorCodes ?? []).some((code) =>
            filterBy.includes(code),
          ),
      )
      .map(({ groupAncestorCodes, ...engagement }) => ({
        ...engagement,
        groups: (groupAncestorCodes || []).reduce((acc, code) => {
          const group = groupOptions[code];

          if (!group) {
            return acc;
          }

          if (!acc[group.typeCode]) {
            acc[group.typeCode] = [];
          }

          acc[group.typeCode].push(code);

          return acc;
        }, {}),
      }));

    const [field, type] = (groupBy?.id ?? 'username').split('.');
    switch (field) {
      case 'homeStation':
      case 'role':
        return Object.values(
          engagements.reduce((acc, { count, month, ...engagement }) => {
            const id = engagement[groupBy.id] ?? 'UNKNOWN';
            if (!acc[id]) {
              acc[id] = {};
            }

            if (!acc[id][month]) {
              acc[id][month] = { id, month, count: 0 };
            }

            acc[id][month].count += count;

            return acc;
          }, {}),
        )
          .map((entry) => Object.values(entry))
          .flat(2);
      case 'groups':
        return Object.values(
          engagements.reduce((acc, { count, month, ...engagement }) => {
            const ids = engagement.groups[type] ?? ['None'];
            for (const id of ids) {
              if (!acc[id]) {
                acc[id] = {};
              }

              if (!acc[id][month]) {
                acc[id][month] = {
                  id: groupOptions[id]?.name ?? id,
                  month,
                  count: 0,
                };
              }

              acc[id][month].count += count;
            }

            return acc;
          }, {}),
        )
          .map((entry) => Object.values(entry))
          .flat(2);
      case 'username':
      default:
        return engagements.map(({ username, month, count }) => ({
          id: username,
          month,
          count,
        }));
    }
  }, [data, filterBy, groupBy, groupOptions]);
  const lines = useMemo(() => {
    if (!engagements) {
      return [];
    }

    const ids = Array.from(new Set(engagements.map(({ id }) => id))).sort();

    return ids.map((id, index) => ({
      id,
      color: randomHsl(index, ids.length),
    }));
  }, [engagements]);
  const byMonth = useMemo(() => {
    if (!engagements) {
      return {};
    }

    return Object.values(
      engagements.reduce((acc, { month, id, count }) => {
        if (!acc[month]) {
          acc[month] = { month: month.getTime(), counts: {} };
        }
        acc[month].counts[id] = count;

        return acc;
      }, {}),
    ).sort((a, b) => a.month - b.month);
  }, [engagements]);
  const dates = useMemo(
    () =>
      engagements
        ? Array.from(new Set(engagements.map(({ month }) => month.getTime())))
            .sort()
            .map((time) => new Date(time))
        : [],
    [engagements],
  );
  const byId = useMemo(() => {
    if (!engagements) {
      return [];
    }

    return Object.values(
      engagements.reduce((acc, { id, month, count }) => {
        if (!acc[id]) {
          acc[id] = { id, months: {} };
        }

        acc[id].months[month] = count;

        return acc;
      }, {}),
    ).sort((a, b) => a.id.localeCompare(b.id));
  }, [engagements]);

  const getRowStyle = useCallback(
    (id) => {
      const line = lines.find((line) => line.id === id);

      if (!line) {
        return {};
      }

      return {
        bgcolor: (theme) =>
          hiddenIds[id]
            ? undefined
            : getBackgroundColor(line.color, theme.palette.mode),
        '&:hover': {
          bgcolor: (theme) =>
            hiddenIds[id]
              ? undefined
              : getHoverBackgroundColor(line.color, theme.palette.mode),
        },
      };
    },
    [lines, hiddenIds],
  );
  const columns = useMemo(
    () => columnsFn(dates, lines, groupBy?.label),
    [dates, lines, groupBy],
  );

  function handleDownloadClick() {
    const headers = [
      {
        key: 'id',
        label: groupBy?.label ?? 'User',
        type: 'text',
      },
      {
        key: 'month',
        label: 'Month',
        type: 'date',
      },
      {
        key: 'count',
        label: 'Interactions',
        type: 'number',
      },
    ];

    downloadCSV(engagements, 'engagement.csv', headers);
  }

  const table = useMaterialReactTable({
    data: byId,
    columns,
    state: {
      density: 'compact',
      isLoading: isFetching,
      columnPinning: { left: ['id'] },
    },
    defaultColumn: { size: 0 },
    enableTopToolbar: false,
    enableBottomToolbar: true,
    enablePagination: true,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableGlobalFilter: false,
    enableRowSelection: false,
    getRowId: (originalRow) => originalRow.id,
    onRowSelectionChange: setHiddenIds,
    muiTableBodyRowProps: ({ row }) => ({
      hover: false,
      onClick: () =>
        setHiddenIds((prev) => ({
          ...prev,
          [row.id]: !prev[row.id],
        })),
      sx: {
        cursor: 'pointer',
        ...getRowStyle(row.original.id),
      },
    }),
    renderBottomToolbar: () => {
      return (
        <Stack
          direction="row"
          sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box>
            <Tooltip title="Download data">
              <IconButton size="small" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <MRT_TablePagination table={table} />
        </Stack>
      );
    },
  });

  function handleGroupByChange(event, value) {
    setGroupBy(value);
  }

  return (
    <Stack
      sx={{
        backgroundColor: 'background.default',
        p: 1,
        pt: 2,
      }}
    >
      <Stack direction="row" spacing={1}>
        <GroupCodesPicker
          value={filterBy}
          onChange={setFilterBy}
          label="Filter by"
          fullWidth
        />
        <Autocomplete
          value={groupBy}
          onChange={handleGroupByChange}
          options={groupByOptions}
          renderInput={(params) => <TextField label="Group by" {...params} />}
          groupBy={(option) => option.group}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{ minWidth: 240 }}
        />
      </Stack>
      <Box sx={{ fontSize: 12 }}>
        <ResponsiveContainer width="99%" height={400}>
          <LineChart
            data={byMonth}
            margin={{ top: 16, right: 8, left: 8, bottom: 24 }}
          >
            <XAxis dataKey="month" tickFormatter={formatLabel}>
              <Label
                value="Month"
                position="bottom"
                style={{ fontWeight: 'bold' }}
              />
            </XAxis>
            <YAxis>
              <Label
                value="Interactions"
                angle={-90}
                position="left"
                style={{ fontWeight: 'bold' }}
              />
            </YAxis>
            {lines
              .filter(({ id }) => !hiddenIds[id])
              .map(({ id, color }) => (
                <Line
                  key={id}
                  type="monotone"
                  name={id}
                  stroke={color}
                  dataKey={getValue(id)}
                  dot={false}
                />
              ))}
            <ChartTooltip
              content={
                <CustomTooltip
                  labelFormatter={formatLabel}
                  height={170}
                  captureMouseMove
                  sortByValue
                  sortDesc
                />
              }
              wrapperStyle={{ pointerEvents: 'auto' }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <MaterialReactTable table={table} />
    </Stack>
  );
}
