import { useOptionLookup } from '@/hooks';
import { kilometresToMiles, parseIfJSON, startCase } from '@/utils';
import { useDallasKeys } from '@/utils/config';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { format } from 'date-fns';
import { Popup } from 'react-map-gl/maplibre';
import { VehicleEquipmentIcons } from '../shared';
import { DetailsPanel } from './DetailsPanel';
import { Status } from './Status';

function DriverDetailsPanel({ driver, isLastDriver = false }) {
  if (!driver) return null;

  return (
    <DetailsPanel
      title={isLastDriver ? 'Last Driver' : 'Driver'}
      entries={[
        {
          label: 'Name',
          value: [driver.forenames, driver.surname]
            .filter(Boolean)
            .join(' ')
            .trim(),
        },
        { label: 'Staff #', value: driver.code },
        { label: 'Role', value: driver.role },
      ]}
      rightHeader={
        driver.collarNumber && (
          <Box
            sx={{
              fontSize: 10,
              color: 'white',
              bgcolor: blue[900],
              borderRadius: 1,
              px: 0.5,
              my: 0.25,
            }}
          >
            {driver.collarNumber}
          </Box>
        )
      }
    />
  );
}

export function VehiclePopup({
  hoverInfo: {
    longitude,
    latitude,
    feature: { properties },
  },
}) {
  const vehicleGroups = useOptionLookup('vehicleGroup');
  const vehicle = properties.vehicle ? parseIfJSON(properties.vehicle) : null;
  const driver = properties.driver ? parseIfJSON(properties.driver) : null;
  const lastDriver = properties.lastDriver
    ? parseIfJSON(properties.lastDriver)
    : null;

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={16}
      closeButton={false}
    >
      <Stack spacing={0.5}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {vehicle?.registrationNumber ?? properties.imei}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {format(new Date(properties.time), 'dd/MM HH:mm:ss')}
        </Typography>
        <Status status={properties.status} />
        {vehicle && (
          <DetailsPanel
            entries={[
              { label: 'Fleet #', value: vehicle.fleetNumber },
              { label: 'Role', value: vehicle.role },
              { label: 'Home Station', value: vehicle.homeStation },
              ...Object.entries(vehicle.groups ?? {}).map(
                ([type, codes = []]) => ({
                  label: vehicleGroups[type]?.label ?? startCase(type),
                  value: codes.join?.(', ') ?? codes,
                }),
              ),
            ]}
          />
        )}
        <DriverDetailsPanel
          driver={driver ?? lastDriver}
          isLastDriver={!driver}
        />
        {!driver && !lastDriver && properties.rfidCard && (
          <DetailsPanel
            title={useDallasKeys ? 'Dallas Key' : 'RFID Card'}
            entries={[
              {
                label: 'Reference',
                value: properties.rfidCard.reference,
              },
              {
                label: 'Label',
                value: properties.rfidCard.label,
              },
              {
                label: 'Type',
                value: properties.rfidCard.type,
              },
            ]}
          />
        )}
        <Stack
          spacing={1}
          direction="row"
          justifyContent="space-between"
          pt={0.5}
        >
          <VehicleEquipmentIcons properties={properties} />
          <Chip
            size="small"
            label={`${kilometresToMiles(
              properties.speedKilometresPerHour,
            )} MPH`}
            sx={{ fontSize: '.6rem', height: 18 }}
          />
        </Stack>
      </Stack>
    </Popup>
  );
}
