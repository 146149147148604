import { CustomTooltip } from '@/components/controls';
import { useAggregate } from '@/hooks';
import { shortHumanizer } from '@/utils';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { lineString } from '@turf/turf';
import { format } from 'date-fns';
import { MaterialReactTable } from 'material-react-table';
import {
  Tooltip as ChartTooltip,
  Label,
  Line,
  LineChart,
  XAxis,
  YAxis,
} from 'recharts';
import { PathMap } from './PathMap';

const equipmentLabels = {
  BEACONSON: 'Beacons',
  HEADLIGHTSFLASHON: 'Headlights Flash',
  SIRENSON: 'Sirens',
};

const defaultColumns = [
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
  },
];

const equipmentActivationColumns = [
  {
    header: 'Equipment',
    id: 'eventSubtype',
    accessorFn: ({ eventSubtype }) =>
      equipmentLabels[eventSubtype] ?? eventSubtype,
  },
  ...defaultColumns,
];

const responseColumns = [
  {
    header: 'Number',
    id: 'incident.reference',
    accessorFn: ({ incident }) => incident?.reference,
  },
  {
    header: 'Date',
    accessorKey: 'incident.date',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Assigned Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Attended Time',
    accessorKey: 'attendedTime',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Unassigned Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
];

const formatLabel = (time) => (time ? format(time, 'HH:mm:ss') : '');

function Table({ columns, data, title }) {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableColumnFilters={false}
      renderTopToolbar={() => (
        <Box sx={{ pl: 1, pt: 0.5 }}>
          <Typography variant="subtitle2" color="textSecondary">
            {title}
          </Typography>
        </Box>
      )}
      defaultColumn={{ size: 0 }}
      state={{ density: 'compact' }}
      muiTableBodyRowProps={{
        sx: { '&:last-child td, &:last-child th': { border: 0 } },
      }}
      renderEmptyRowsFallback={() => (
        <Box sx={{ p: 1 }}>
          <Typography variant="caption" color="textSecondary">
            No events
          </Typography>
        </Box>
      )}
    />
  );
}

export function TripDetail({ trip }) {
  const { data: equipmentActivations } = useAggregate(
    'vehicleEquipmentActivations',
    [
      { $match: { 'parentEvent.identifier': trip.identifier } },
      {
        $project: {
          _id: false,
          startTime: true,
          endTime: true,
          eventSubtype: true,
          durationSeconds: true,
        },
      },
    ],
    !!trip.identifier,
    1000 * 60 * 60,
  );
  const { data: idles } = useAggregate(
    'idles',
    [
      { $match: { 'parentEvent.identifier': trip.identifier } },
      {
        $project: {
          _id: false,
          startTime: true,
          endTime: true,
          durationSeconds: true,
        },
      },
    ],
    !!trip.identifier,
    1000 * 60 * 60,
  );
  const { data: responses } = useAggregate(
    'personIncidentResponses',
    [
      {
        $match: {
          'person.code': trip.driver.code,
          startTime: { $lt: trip.endTime },
          endTime: { $gt: trip.startTime },
        },
      },
      {
        $project: {
          incident: {
            reference: true,
            date: {
              $dateFromString: { dateString: '$incident.date', timezone: 'GB' },
            },
          },
          startTime: true,
          attendedTime: true,
          endTime: true,
        },
      },
    ],
    !!trip?.driver?.code,
    1000 * 60 * 60,
  );
  const { data: polls } = useAggregate(
    'telematicsBoxPolls',
    [
      {
        $match: {
          imei: trip.vehicle.telematicsBoxImei,
          time: { $gte: trip.startTime, $lte: trip.endTime },
        },
      },
      {
        $project: {
          _id: false,
          time: true,
          position: true,
          speedMilesPerHour: {
            $round: [{ $multiply: ['$speedKilometresPerHour', 0.62137119] }, 2],
          },
          speedLimitMilesPerHour: {
            $round: [
              {
                $multiply: [
                  '$reverseGeocode.speedLimitKilometresPerHour',
                  0.62137119,
                ],
              },
              2,
            ],
          },
          emergencyEquipmentOn: true,
        },
      },
    ],
    !!trip.identifier,
    1000 * 60 * 60,
  );
  const path =
    polls?.length > 0
      ? lineString(polls.map(({ position }) => position.coordinates) ?? [])
      : null;

  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row">
        {responses?.length > 0 && (
          <Table
            columns={responseColumns}
            data={responses}
            title="Incident Responses"
          />
        )}
        {equipmentActivations?.length > 0 && (
          <Table
            columns={equipmentActivationColumns}
            data={equipmentActivations}
            title="Equipment Activations"
          />
        )}
        {idles?.length > 0 && (
          <Table columns={defaultColumns} data={idles} title="Idles" />
        )}
      </Stack>
      <Stack spacing={2} direction="row">
        {path && <PathMap path={path} />}
        <Paper elevation={0} variant="outlined" sx={{ p: 1, fontSize: 12 }}>
          <LineChart data={polls} width={800} height={304}>
            <XAxis
              dataKey="time"
              tickFormatter={formatLabel}
              angle={-90}
              textAnchor="end"
              height={60}
            />
            <YAxis width={48}>
              <Label
                value="Speed (mph)"
                angle={-90}
                position="insideLeft"
                style={{ fontSize: 12, fontWeight: 'bold' }}
              />
            </YAxis>
            <Line
              type="monotone"
              name="Speed (mph)"
              stroke={blue[500]}
              dataKey="speedMilesPerHour"
              dot={false}
            />
            <Line
              type="monotone"
              name="Speed Limit (mph)"
              stroke={grey[500]}
              dataKey="speedLimitMilesPerHour"
              dot={false}
            />
            <ChartTooltip
              content={<CustomTooltip labelFormatter={formatLabel} />}
            />
          </LineChart>
        </Paper>
      </Stack>
    </Stack>
  );
}
