import { Tooltip } from '@mui/material';
import { amber } from '@mui/material/colors';

export function EquipmentStatusIcon({
  Icon,
  label,
  isActive,
  color = amber[700],
}) {
  return isActive !== null && isActive !== undefined ? (
    <Tooltip title={label}>
      <Icon
        sx={{
          fontSize: 16,
          color: isActive ? color : 'text.disabled',
        }}
      />
    </Tooltip>
  ) : null;
}
