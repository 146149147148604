import { FilterField } from '@/components/fields';
import { startCase } from '@/utils';
import { Divider } from '@mui/material';
import { Fragment } from 'react';
import { FieldArray } from 'react-final-form-arrays';

export const sourceFilterMapping = {
  speedInfractions: { speedInfraction: 'event', vehicle: true, driver: true },
  vehicleTrips: { trip: 'event', vehicle: true, driver: true },
  vehicleTime: { vehicle: true, driver: true },
  vehicleIdles: { stop: 'event', vehicle: true, driver: true },
  vehicleIdleCount: { stop: 'event', vehicle: true, driver: true },
  vehicleStops: { stop: 'event', vehicle: true, lastDriver: true },
  vehicleStopCount: { stop: 'event', vehicle: true, lastDriver: true },
  vehiclePolls: { vehicle: true, poll: 'event' },
  // "clientPerson" as can't do it server side unless driver id added to box polls?
  vehicleCustomVisits: { vehicle: true, clientPerson: 'driver' },
  vehicleVisits: {
    visitLocation: 'location',
    clientLocation: true,
    vehicle: true,
    driver: true,
  },
  vehicleVisitCount: { location: true, vehicle: true, driver: true },
  accelerometerAlerts: { vehicle: true, driver: true },
  accelerometerEvents: { vehicle: true },
  accelerometerAlertCount: { vehicle: true, driver: true },
  incidents: { incident: 'event' },
  incidentCount: { incident: 'event' },
  personTrails: { person: true },
  personTime: { person: true },
  personPolls: { person: true, poll: 'event' },
  personVisits: {
    visitLocation: 'location',
    clientLocation: true,
    person: true,
  },
  personCustomVisits: { person: true },
  personVisitCount: { location: true, person: true },
  locations: { location: 'event' },
  aggregates: { aggregate: 'event' },
  kml: { file: true },
  geojson: { file: true },
};

export function SourceFilters({ type, name, filters = {} }) {
  const mapping = sourceFilterMapping[type];

  if (mapping) {
    return (
      <Fragment>
        {Object.entries(mapping)
          .filter(([key]) => key in filters)
          .map(([key, id], index) => {
            id = typeof id === 'string' ? id : key;

            return (
              <Fragment key={key}>
                {index > 0 && (
                  <Divider style={{ marginTop: 16, marginBottom: 8 }} />
                )}
                <FieldArray
                  label={startCase(`${id} Filters`)}
                  name={`${name}.${id}`}
                  filters={filters[key]}
                  component={FilterField}
                />
              </Fragment>
            );
          })}
      </Fragment>
    );
  } else {
    return <Fragment />;
  }
}
