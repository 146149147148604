import { humanizeHours, kilometresToMiles, shortHumanizer } from '@/utils';
import { driverBehaviourOptions } from '@/utils/config';
import { green, orange, red } from '@mui/material/colors';
import { format } from 'date-fns';

const {
  goodTripScoreThreshold: goodThreshold = 95,
  okTripScoreThreshold: okThreshold = 85,
} = driverBehaviourOptions;

export function scoreColour(score) {
  if (!score || score === 0) {
    return '#000';
  }

  return score > goodThreshold
    ? green[500]
    : score < okThreshold
      ? red[500]
      : orange[700];
}

export const formatLabel = (group) => (date) => {
  if (!date) {
    return '';
  }

  switch (group) {
    case 'day':
      return format(date, 'd/M/yyyy');
    case 'month':
      return format(date, 'M/yyyy');
    case 'year':
      return format(date, 'yyyy');
  }
};

export const getValue = (measure) => (data) => {
  switch (measure) {
    case 'score':
      return data.score;
    case 'distanceKilometres':
      return kilometresToMiles(data.distanceKilometres);
    default:
      return data[measure] / 3600;
  }
};

export const formatValue = (measure) => (value) => {
  switch (measure) {
    case 'score':
      return `${Math.round(value)}%`;
    case 'distanceKilometres':
      return `${Math.round(value)}mi`;
    default:
      return humanizeHours(value);
  }
};

export const measures = {
  score: { label: 'Score', unit: '%' },
  distanceKilometres: { label: 'Miles', unit: 'mile' },
  durationSeconds: { label: 'Driving', unit: 'hour' },
  accelerationSeconds: { label: 'Harsh Acceleration', unit: 'hour' },
  brakingSeconds: { label: 'Harsh Braking', unit: 'hour' },
  corneringSeconds: { label: 'Harsh Cornering', unit: 'hour' },
  speedingSeconds: { label: 'Speeding', unit: 'hour' },
};

export const driverColumns = [
  {
    header: 'Staff ID',
    accessorKey: 'person.code',
  },
  {
    header: 'Collar#',
    accessorKey: 'person.collarNumber',
  },
  {
    header: 'Name',
    id: 'name',
    accessorFn: ({ person }) =>
      [person.forenames, person.surname].filter(Boolean).join(' '),
  },
  {
    header: 'Score',
    accessorKey: 'score',
    Cell: ({ cell }) => `${Math.round(cell.getValue())}%`,
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: ({ cell }) => ({
      align: 'right',
      sx: {
        color: scoreColour(cell.getValue()),
      },
    }),
  },
  {
    header: 'Miles',
    accessorKey: 'distanceKilometres',
    Cell: ({ cell }) =>
      `${kilometresToMiles(cell.getValue()).toLocaleString()}mi`,
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Driving',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Harsh Acceleration',
    accessorKey: 'accelerationSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Harsh Braking',
    accessorKey: 'brakingSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Harsh Cornering',
    accessorKey: 'corneringSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Speeding',
    accessorKey: 'speedingSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
];

export const activityColumns = [
  {
    header: 'Staff ID',
    accessorKey: 'driver.code',
  },
  {
    header: 'Collar#',
    accessorKey: 'driver.collarNumber',
  },
  {
    header: 'Name',
    id: 'name',
    accessorFn: ({ driver }) =>
      [driver.forenames, driver.surname].filter(Boolean).join(' '),
  },
  {
    header: 'Fleet#',
    accessorKey: 'vehicle.fleetNumber',
  },
  {
    header: 'Registration',
    accessorKey: 'vehicle.registrationNumber',
  },
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Warning Equipment',
    id: 'emergencyOn',
    accessorFn: ({ equipmentActivations }) =>
      Boolean(equipmentActivations?.emergencyOn),
    Cell: ({ cell }) => (cell.getValue() ? 'Yes' : 'No'),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: ({ cell }) => ({
      align: 'right',
      sx: {
        color: cell.getValue() ? 'primary.main' : 'text.disabled',
      },
    }),
  },
  {
    header: 'Score',
    accessorKey: 'score',
    Cell: ({ cell }) => `${Math.round(cell.getValue())}%`,
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: ({ cell }) => ({
      align: 'right',
      sx: {
        color: scoreColour(cell.getValue()),
      },
    }),
  },
  {
    header: 'Distance',
    accessorKey: 'distanceKilometres',
    Cell: ({ cell }) =>
      `${kilometresToMiles(cell.getValue()).toLocaleString()}mi`,
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Harsh Acceleration',
    accessorKey: 'excessAccelerationSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Harsh Braking',
    accessorKey: 'excessBrakingSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Harsh Cornering',
    accessorKey: 'excessCorneringSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Speeding',
    accessorKey: 'speedInfractionsSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
];

export function secondsToFirstSignificantUnit(seconds) {
  if (seconds < 60) {
    return { score: seconds, unit: seconds === 1 ? 'second' : 'seconds' };
  } else if (seconds < 3600) {
    return {
      score: Math.round(seconds / 60),
      unit: Math.round(seconds / 60) === 1 ? 'minute' : 'minutes',
    };
  } else {
    return {
      score: Math.round(seconds / 3600),
      unit: Math.round(seconds / 3600) === 1 ? 'hour' : 'hours',
    };
  }
}
