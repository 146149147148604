import { format } from 'date-fns';
import { darken, lighten } from '@mui/material';

/**
 * Format a date as MM/yy.
 * @param {Date} time - The date to format.
 */
export const formatLabel = (time) =>
  time ? format(new Date(time), 'MM/yy') : '';

/**
 * Get all the dates between minDate and maxDate inclusive, increasing by a month each time.
 * @param {Date[]} dates - The array of dates.
 */
function* getDateColumns(dates = []) {
  if (dates.length === 0) {
    return;
  }

  let yearColumns = [];
  let currentYear = dates[0].getFullYear();

  for (const date of dates) {
    if (date.getFullYear() !== currentYear) {
      yield {
        header: currentYear,
        id: currentYear,
        key: currentYear,
        columns: yearColumns,
      };
      yearColumns = [];
      currentYear = date.getFullYear();
    }

    yearColumns.push({
      header: date.toLocaleDateString('en-GB', { month: 'long' }),
      id: formatLabel(date),
      key: formatLabel(date),
      accessorFn: ({ months }) => months[date] ?? 0,
    });
  }

  if (yearColumns.length > 0) {
    yield {
      header: currentYear,
      id: currentYear,
      key: currentYear,
      columns: yearColumns,
    };
  }
}

/**
 * Get the columns for the table.
 * @param {Date[]} dates - The array of dates.
 * @param {{ id: string, color: string }[]} lines - The chart line IDs and corresponding colors.
 * @param {string} [header] - The header for the ID column.
 * @returns {import('material-react-table').Column[]} The columns.
 */
export function columnsFn(dates, lines = [], header = 'User') {
  return [
    {
      header,
      accessorKey: 'id',
      muiTableBodyCellProps: ({ cell }) => ({
        sx: {
          color: lines.find((line) => line.id === cell.getValue())?.color,
        },
      }),
    },
    ...getDateColumns(dates),
  ];
}

export function getBackgroundColor(color, mode) {
  if (!color) return null;

  return mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9);
}

export function getHoverBackgroundColor(color, mode) {
  if (!color) return null;

  return mode === 'dark' ? darken(color, 0.75) : lighten(color, 0.75);
}
