import { useMemo } from 'react';
import { useOptions } from './useOptions';

/**
 * @typedef {Object.<string, string>} Options
 * This is an object where each property is an array.
 */

/**
 * A hook that fetches options.
 * @param {string} name - The name of the options to fetch.
 * @param {Object} [filter={}] - The filter to apply to the options.
 * @returns {Options}
 */
export function useOptionLookup(name, filter = {}) {
  const { data: options } = useOptions(name, filter);

  return useMemo(
    () =>
      options.reduce(
        (accumulator, { value, label }) => ({
          ...accumulator,
          [value]: label,
        }),
        {},
      ),
    [options],
  );
}
