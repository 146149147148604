import { CustomTooltip, Parameters, ToggleList } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import {
  dayOptions,
  downloadCSV,
  formatMinutes,
  hourOptions,
  offsetToTime,
} from '@/utils';
import { offsetHours } from '@/utils/config';
import { Download as DownloadIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAtom } from 'jotai';
import { Fragment, useState } from 'react';
import {
  Bar,
  BarChart,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { usePersonHourlyActivities } from './usePersonHourlyActivities';
import { bars, csvColumns, stateAtom } from './utils';

export function HourlyActivity() {
  useDocumentTitle('IR3 | Hourly Activity');
  const [{ hours, days, query, parameters }, setState] = useAtom(stateAtom);
  const [hiddenBars, setHiddenBars] = useState([]);
  const { data, isLoading, isFetching, cancel } = usePersonHourlyActivities(
    query,
    days,
    hours.map((hour) => (hour - offsetHours + 24) % 24),
  );

  const handleStateChange = (name) => (value) => {
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  function handleFetch(event, query) {
    setState((state) => ({
      ...state,
      query,
    }));
  }

  function handleCancel() {
    cancel();
  }

  const handleLegendClick = (selectedBar) => () => {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1)),
      );
    }
  };

  function handleDownloadClick() {
    downloadCSV(
      data.map((record) => ({
        hour: offsetToTime(record.offset),
        loggedInMinutes: record.loggedInMinutes / 1440,
        drivingMinutes: record.drivingMinutes / 1440,
        baseMinutes: record.baseMinutes / 1440,
        respondingMinutes: record.respondingMinutes / 1440,
        attendingMinutes: record.attendingMinutes / 1440,
        homeWardMinutes: record.homeWardMinutes / 1440,
        doubleCrewingMinutes: record.doubleCrewingMinutes / 1440,
      })),
      'hourly-activity.csv',
      csvColumns,
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
      }}
    >
      <Parameters
        collection="personDailyActivities"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading || isFetching}
        value={parameters}
        onChange={handleStateChange('parameters')}
        sx={{ mt: 1, width: 264 }}
        pointEvent
        dateOnly
        person
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          pb: 1,
          pr: 1,
        }}
      >
        <Stack spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              pt: 1.5,
              justifyContent: 'space-between',
              alignItems: 'start',
              position: 'sticky',
              top: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 700,
            }}
          >
            <ToggleList
              disabled={isFetching}
              value={days}
              onChange={handleStateChange('days')}
              values={dayOptions}
              label={'Days'}
            />
            <ToggleList
              disabled={isFetching}
              value={hours}
              onChange={handleStateChange('hours')}
              values={hourOptions}
              label={'Hours'}
            />
            <Tooltip title="Download data">
              <Box component="span">
                <IconButton
                  onClick={handleDownloadClick}
                  disabled={(data ?? []).length === 0}
                >
                  <DownloadIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Stack>
          <Paper sx={{ p: 2, minWidth: 240, fontSize: 12 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ pb: 1 }}
            >
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Activity
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Average time spent at each activity, per person, per hour of the
                day
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{ justifyContent: 'center' }}
            >
              {bars.map((bar) => (
                <Box
                  key={bar.name}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    pb: 2,
                  }}
                  onClick={handleLegendClick(bar.name)}
                >
                  <Avatar
                    sx={{
                      width: 12,
                      height: 12,
                      mr: 0.5,
                      bgcolor: !hiddenBars.includes(bar.name) && bar.colour,
                    }}
                  >
                    <Fragment />
                  </Avatar>
                  <Typography variant="caption">{bar.label}</Typography>
                </Box>
              ))}
            </Stack>
            <ResponsiveContainer width="100%" height={520}>
              <BarChart
                data={data}
                margin={{ top: 16, right: 8, left: 8, bottom: 24 }}
                barGap={0}
                barCategoryGap={4}
              >
                <XAxis
                  dataKey="offset"
                  tickFormatter={offsetToTime}
                  interval={0}
                >
                  <Label
                    value="Hour"
                    position="bottom"
                    style={{ fontWeight: 'bold' }}
                  />
                </XAxis>
                <YAxis>
                  <Label
                    value="Minutes"
                    angle={-90}
                    position="left"
                    style={{ fontWeight: 'bold' }}
                  />
                </YAxis>
                <ChartTooltip
                  cursor={false}
                  content={
                    <CustomTooltip
                      labelFormatter={offsetToTime}
                      valueFormatter={formatMinutes}
                    />
                  }
                />
                {bars.map((bar) => (
                  <Bar
                    key={bar.name}
                    dataKey={bar.name}
                    name={bar.label}
                    fill={bar.colour}
                    hide={hiddenBars.includes(bar.name)}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Stack>
      </Box>
    </Box>
  );
}
