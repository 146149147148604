import {
  CounterTextField,
  SelectField,
  StyledField,
  SwitchField,
  TextField,
  required,
} from '@/components/fields';
import { useOptions, useUserInfo } from '@/hooks';
import { isAuthorised } from '@/utils';
import { useRestricted, vehicleForm } from '@/utils/config';
import { Fragment } from 'react';

export function VehicleKeyFields({ vehicle, disabled }) {
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'vehicles', true);
  const { data: vehicleRoles } = useOptions('vehicleRole');

  function isDisabled(fieldName) {
    return (
      !!disabled || !(canEdit && (vehicleForm[fieldName]?.editable || !vehicle))
    );
  }

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined,
      );

  function validateIfNotDisabled(disabled, validateFunction) {
    if (disabled) {
      return undefined;
    } else {
      return validateFunction;
    }
  }

  /*
    SDB Work In Progress - the checkMinimumLength validtor should be moved to the CounterTextField 
    currently I am unable to support validtors in VehicleKeyFields and in CounterTextField 
  */
  function checkMinimumLength(value) {
    if (vehicleForm.identificationNumber.componentProps.minLength) {
      if (
        value.length < vehicleForm.identificationNumber.componentProps.minLength
      ) {
        return `Minimum Length is ${vehicleForm.identificationNumber.componentProps.minLength}`;
      }
    }
  }

  return (
    <Fragment>
      <StyledField
        name="identificationNumber"
        component={CounterTextField}
        label="VIN"
        validate={validateIfNotDisabled(
          isDisabled('identificationNumber'),
          composeValidators(required, checkMinimumLength),
        )}
        disabled={isDisabled('identificationNumber')}
        {...vehicleForm.identificationNumber.componentProps}
      />
      <StyledField
        name="registrationNumber"
        component={CounterTextField}
        label="Registration"
        validate={validateIfNotDisabled(
          isDisabled('registrationNumber'),
          required,
        )}
        disabled={isDisabled('registrationNumber')}
        {...vehicleForm.registrationNumber.componentProps}
      />
      <StyledField
        name="fleetNumber"
        component={CounterTextField}
        label="Fleet Number"
        validate={validateIfNotDisabled(isDisabled('fleetNumber'), required)}
        disabled={isDisabled('fleetNumber')}
        {...vehicleForm.fleetNumber.componentProps}
      />
      <StyledField
        name="role"
        component={SelectField}
        label="Role"
        validate={validateIfNotDisabled(isDisabled('role'), required)}
        values={vehicleRoles}
        disabled={isDisabled('role')}
      />
      <StyledField
        name="assignments.callSign.code"
        component={TextField}
        label="Call Sign"
        validate={validateIfNotDisabled(isDisabled('callSign'))}
        disabled={isDisabled('callSign')}
      />
      {useRestricted && (
        <StyledField
          name="visibleTo"
          component={SwitchField}
          label="Restricted"
          disabled={isDisabled('visibleTo')}
          parse={(value) => (value ? [] : null)}
          format={(value) => !!value}
        />
      )}
    </Fragment>
  );
}
