import {
  DatePicker,
  SelectMultiple,
  Table,
  TablePagination,
} from '@/components/controls';
import { useDocumentTitle, useVehicleAvailability } from '@/hooks';
import { downloadCSV, getFilenameForDownload, round } from '@/utils';
import { rowsPerPageOptions } from '@/utils/config';
import {
  Autorenew as AutorenewIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { teal } from '@mui/material/colors';
import { addHours, format, getDayOfYear } from 'date-fns';
import { atom, useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

function CustomAvailabilityTooltip({ active, payload }) {
  if (active && payload) {
    const { hour, count } = payload[0].payload;
    const hourString = format(hour, 'dd/MM/yyyy HH:mm');
    const nextHour = addHours(hour, 1);
    const nextHourString = format(
      nextHour,
      getDayOfYear(hour) === getDayOfYear(nextHour)
        ? 'HH:mm'
        : 'dd/MM/yyyy HH:mm',
    );

    return (
      <Box
        className="recharts-default-tooltip"
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
      >
        <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
          {`${count} vehicle${
            count === 1 ? '' : 's'
          } between ${hourString} and ${nextHourString}`}
        </p>
      </Box>
    );
  }

  return null;
}

const stateAtom = atom({
  startTime: null,
  endTime: null,
  homeOnly: true,
  orderBy: 'Group',
  order: 'asc',
  filter: {
    location: [],
    locationType: [],
    grouping: [],
  },
  customConfidence: 50,
  page: 0,
  rowsPerPage: rowsPerPageOptions[0],
  timeDescendingChart: true,
});

export function VehicleAvailability() {
  useDocumentTitle('IR3 | Vehicle Availability');
  const [
    {
      startTime,
      endTime,
      homeOnly,
      orderBy,
      order,
      filter,
      customConfidence,
      page,
      rowsPerPage,
      timeDescendingChart,
    },
    setState,
  ] = useAtom(stateAtom);
  const {
    data: { filteredData: data, filterOptions },
    isFetching,
    isLoading,
    refetch,
  } = useVehicleAvailability(
    startTime,
    endTime,
    filter,
    homeOnly,
    customConfidence,
  );
  const [showFilter, setShowFilter] = useState(false);

  function handleRefreshClick() {
    refetch();
  }

  function handleStartTimeChange(date) {
    setState((state) => ({ ...state, startTime: date }));
  }

  function handleEndTimeChange(date) {
    setState((state) => ({ ...state, endTime: date }));
  }

  function handleGraphSwitch() {
    setState((state) => ({
      ...state,
      timeDescendingChart: !state.timeDescendingChart,
    }));
  }

  function DrillDown({ entry: { histogram, availabilities } }) {
    let mainChartData = histogram;

    if (timeDescendingChart) {
      // do a descending subtractive sum, so instead of
      // 0 for 1 hour, 1 for 3 hours, 2 for 1 hour
      // we get
      // 0 or more for 5 hours, 1 or more for 4 hours, 2 or more for 1 hour
      // turn it into a percentage altogether
      // 0 or more 100% (5/5), 1 or more 80% (4/5), 2 or more 20% (1/5)
      const overallTotal = histogram.reduce((total, h) => total + h.hours, 0);
      // remove the one for 0 as it's redundant...
      mainChartData = histogram
        .filter(({ count }) => count - 0 !== 0)
        .map(({ count }) => ({
          count,
          hours: round(
            (histogram.reduce(
              (total, h) => total + h.hours * (h.count - count >= 0),
              0,
            ) /
              overallTotal) *
              100,
            2,
          ),
        }));
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', p: [0, 2, 2, 2] }}>
        <Typography component="div" sx={{ ml: 1 }}>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Availability percent</Grid>
            <Grid item>
              <Switch
                checked={!timeDescendingChart}
                onChange={handleGraphSwitch}
              />
            </Grid>
            <Grid item>Histogram</Grid>
          </Grid>
        </Typography>
        {/* <Typography variant="subtitle1">
          Histogram
        </Typography>
        <Switch checked={timeDescendingChart} onChange={handleGraphSwitch} /> */}
        <ResponsiveContainer width="99%" height={400}>
          <BarChart
            data={mainChartData}
            margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
            barCategoryGap={4}
          >
            <XAxis
              dataKey="count"
              // angle={-90}
              // textAnchor="end"
              interval={0}
            >
              <Label value="Vehicles available" offset={6} position="bottom" />
            </XAxis>
            <YAxis>
              <Label
                value={timeDescendingChart ? 'Percent' : 'Total Hours'}
                angle={-90}
                position="left"
                offset={-24}
              />
            </YAxis>
            <ChartTooltip
              cursor={false}
              content={<CustomHistogramTooltip data={histogram} />}
            />
            <Bar dataKey="hours" fill={teal[500]} isAnimationActive={false} />
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="99%" height={200}>
          <BarChart
            data={availabilities}
            margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
            // barCategoryGap={4}
          >
            <XAxis
              dataKey="date"
              // angle={-90}
              textAnchor="end"
              // interval={0}
            >
              <Label value="Time" offset={-6} position="bottom" />
            </XAxis>
            <YAxis allowDecimals={false}>
              <Label
                value="Vehicles"
                angle={-90}
                position="left"
                offset={-24}
              />
            </YAxis>
            <ChartTooltip
              cursor={false}
              content={<CustomAvailabilityTooltip />}
            />
            <Bar dataKey="count" fill={teal[500]} isAnimationActive={false} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }

  function CustomHistogramTooltip({ active, label, data }) {
    if (active) {
      const totalHours = data.reduce((total, el) => total + el.hours, 0);
      const atLeast = data.reduce(
        (total, el) => total + (el.count - label >= 0 ? el.hours : 0),
        0,
      );
      const value = data.find((d) => d.count - label === 0)?.hours || 0;
      const more =
        label - 0 !== Math.max(...data.map((d) => parseInt(d.count)));

      return (
        <Box
          className="recharts-default-tooltip"
          style={{
            margin: '0px',
            padding: '10px',
            backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid rgb(204, 204, 204)',
            whiteSpace: 'nowrap',
          }}
        >
          {!timeDescendingChart ? (
            <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
              {`Exactly ${label} vehicle${label === '1' ? ' was' : 's were'}
              available for ${value} out of ${totalHours} hours or 
              ${round(100 * (value / totalHours), 2)}% of the time.`}
            </p>
          ) : (
            <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
              {`${label} ${
                more ? 'or more' : ''
              } vehicles were available ${round(
                100 * (atLeast / totalHours),
                2,
              )}% of the time`}
            </p>
          )}
        </Box>
      );
    }

    return null;
  }

  const dropdownHeader = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: DrillDown,
    },
  ];

  const headers = useMemo(
    () => [
      {
        label: 'Location',
        key: 'location',
        type: 'text',
      },
      {
        label: 'Role',
        key: 'grouping',
        type: 'text',
      },
      {
        label: `${customConfidence}%`,
        key: 'pCustom',
        type: 'number',
      },
      {
        label: '95%',
        key: 'p95',
        type: 'number',
      },
      {
        label: '97.5%',
        key: 'p975',
        type: 'number',
      },
      {
        label: '99%',
        key: 'p99',
        type: 'number',
      },
      {
        label: 'Mean',
        key: 'mean',
        type: 'number',
      },
      {
        label: 'Deviation',
        key: 'std',
        type: 'number',
      },
    ],
    [customConfidence],
  );

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: value || [],
          }
        : {
            ...filter,
            groups: {
              ...filter.groups,
              [name]: value || [],
            },
          };

    setState((state) => ({ ...state, filter: newFilter }));
  }

  function handlePageChange(event, page) {
    setState((state) => ({ ...state, page }));
  }

  function handleRowsPerPageChange(event) {
    setState((state) => ({
      ...state,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  }

  function handleOrderChange(order) {
    setState((state) => ({ ...state, order }));
  }

  function handleOrderByChange(orderBy) {
    setState((state) => ({ ...state, order: 'asc', orderBy }));
  }

  function handleDownloadClick() {
    const filename = getFilenameForDownload(
      'Vehicle Availability',
      'csv',
      startTime,
      endTime,
    );

    downloadCSV(data, filename, headers);
  }

  // const xAxisHeight = 16;
  // data.length === 0
  //   ? 0
  //   : getTextWidth(
  //       data.map((item) => item.Group).sort((a, b) => b.length - a.length)[0],
  //       '12px Roboto'
  //     ) + 16;

  function handleCustomConfidenceChange(event) {
    setState((state) => ({
      ...state,
      customConfidence: +event.target.value,
    }));
  }

  function handleHomeOnlyChanged(event) {
    setState((state) => ({ ...state, homeOnly: event.target.checked }));
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar>
          <Typography variant="subtitle1">Custom Confidence</Typography>
          <TextField
            size="small"
            type="number"
            value={customConfidence}
            onChange={handleCustomConfidenceChange}
            sx={{ width: 100, mr: 2, ml: 1 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            inputProps={{
              min: 1,
              max: 99,
              step: 1,
            }}
          />
          <Checkbox
            checked={homeOnly}
            onChange={handleHomeOnlyChanged}
            datacy="homeStationOnly"
          />
          <Typography variant="subtitle1">Home Station only</Typography>
          <Box sx={{ flexGrow: 1 }} />
          {(isLoading || isFetching) && (
            <CircularProgress sx={{ m: 1 }} size={16} thickness={6} />
          )}
          <DatePicker
            value={startTime}
            onChange={handleStartTimeChange}
            clearable
            maxDate={endTime || new Date('2100-01-01')}
            datacy="start"
            sx={{ width: 172 }}
          />
          <RemoveIcon sx={{ px: 0.5 }} />
          <DatePicker
            value={endTime}
            onChange={handleEndTimeChange}
            clearable
            minDate={startTime || new Date('1900-01-01')}
            disableFuture
            datacy="end"
            sx={{ width: 172 }}
          />
          <Tooltip title={isLoading || isFetching ? 'Cancel' : 'Fetch'}>
            <IconButton onClick={handleRefreshClick} datacy="fetch">
              {isLoading || isFetching ? (
                <CloseIcon color="error" />
              ) : (
                <AutorenewIcon />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
            <IconButton onClick={handleFilterToggle}>
              <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download data">
            <Box component="span">
              <IconButton
                disabled={data.length === 0}
                onClick={handleDownloadClick}
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1} sx={{ flex: 1, p: 1 }}>
            <SelectMultiple
              label="Locations"
              placeholder="Select..."
              value={filter.location ?? []}
              onChange={(value) => handleFilterFieldChanged('location', value)}
              suggestions={filterOptions.location?.map((value) => ({
                label: value,
                value,
              }))}
              labelValue
            />
            <SelectMultiple
              label={'Roles'}
              placeholder="Select..."
              value={filter.grouping ?? []}
              onChange={(value) => handleFilterFieldChanged('grouping', value)}
              suggestions={filterOptions.grouping?.map((value) => ({
                label: value,
                value,
              }))}
              labelValue
            />
          </Stack>
        </Collapse>
        <Paper sx={{ m: 1, minWidth: 240, fontSize: 12 }}>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 182px)',
                overflowY: 'scroll',
              },
            }}
            data={data}
            headers={[...dropdownHeader, ...headers]}
            rowsPerPage={rowsPerPage}
            page={page}
            keyName="stopKey"
            dense={true}
            order={order}
            orderBy={orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Box>
  );
}
