import { useOptions } from '@/hooks';
import { getAllValues } from '@/utils';
import { Stack } from '@mui/material';
import { FilterField } from './FilterField';

export function PersonFilter({
  value = {},
  onChange,
  featureCollection = { features: [] },
}) {
  const { data: personGroups } = useOptions('personGroup');
  const handleFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      [field]: update,
    });
  };

  return (
    <Stack spacing={1} sx={{ maxHeight: 238, overflow: 'scroll', pt: 0.75 }}>
      <FilterField
        label="Staff #"
        value={value.staffNumber}
        onChange={handleFilterChange('staffNumber')}
        options={getAllValues(
          featureCollection.features,
          'properties.person.code',
        )}
      />
      <FilterField
        label="Collar #"
        value={value.collarNumber}
        onChange={handleFilterChange('collarNumber')}
        options={getAllValues(
          featureCollection.features,
          'properties.person.collarNumber',
        )}
      />
      <FilterField
        label="Rank"
        value={value.category}
        onChange={handleFilterChange('category')}
        options={getAllValues(
          featureCollection.features,
          'properties.person.category',
        )}
      />
      <FilterField
        label="Role"
        value={value.role}
        onChange={handleFilterChange('role')}
        options={getAllValues(
          featureCollection.features,
          'properties.person.role',
        )}
      />
      {personGroups.map(({ value: type, label }) => (
        <FilterField
          key={type}
          label={label}
          value={value[type]}
          onChange={handleFilterChange(type)}
          options={getAllValues(
            featureCollection.features,
            `properties.person.groups.${type}`,
          )}
        />
      ))}
    </Stack>
  );
}
