import { useDocumentTitle } from '@/hooks';
import { Stack } from '@mui/material';
import { TypedOptionsTable } from './TypedOptionsTable';

export function LocationOptions() {
  useDocumentTitle('IR3 | Location Options');

  return (
    <Stack direction="row" spacing={1} sx={{ p: 1 }}>
      <TypedOptionsTable name="locationGroup" title="Categories" />
    </Stack>
  );
}
