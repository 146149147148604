import { api } from '@/apis';
import { isEmpty, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { sortBy } from '.';

export function useActiveIntersectionChanges(query) {
  return useQuery({
    queryKey: ['inLocationChanges', query],
    queryFn: async ({ signal }) => {
      const json = [
        { $match: query },
        {
          $project: {
            identifier: true,
            vehicle: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
            location: true,
          },
        },
      ];
      const rawData = await api
        .post('pipeline/intersections', { json, signal })
        .json();
      const start = +query.endTime.$gte;
      const end = +query.startTime.$lt;
      const data = rawData //TODO: Put this into a pipeline
        .map(
          ({
            startTime: eventStartTime,
            endTime: eventEndTime,
            vehicle: {
              registrationNumber,
              fleetNumber,
              role,
              type,
              groups,
              homeStation,
            },
            location: {
              name: locationName,
              type: locationType,
              code: locationCode,
            },
          }) => {
            const atHome =
              homeStation === locationName || homeStation === locationCode;

            return [
              {
                registrationNumber,
                fleetNumber,
                role,
                type,
                groups,
                locationName,
                locationType,
                time: start,
                change: 0,
                atHome,
              },
              {
                registrationNumber,
                fleetNumber,
                role,
                type,
                groups,
                locationName,
                locationType,
                time: +eventStartTime,
                change: 1,
                atHome,
              },
              {
                registrationNumber,
                fleetNumber,
                role,
                type,
                groups,
                locationName,
                locationType,
                time: +eventEndTime,
                change: -1,
                atHome,
              },
              {
                registrationNumber,
                fleetNumber,
                role,
                type,
                groups,
                locationName,
                locationType,
                time: end,
                change: 0,
                atHome,
              },
            ];
          },
        )
        .flat()
        .sort(sortBy('time'));

      log('Read', 'Vehicle In Locations', query);

      return data;
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !isEmpty(query),
  });
}
