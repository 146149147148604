import { useDocumentTitle } from '@/hooks';
import { Stack } from '@mui/material';
import { TypedOptionsTable } from './TypedOptionsTable';
import { OptionsTable } from './OptionsTable';

export function GroupOptions() {
  useDocumentTitle('IR3 | Group & Area Options');

  return (
    <Stack direction="row" spacing={1} sx={{ p: 1 }}>
      <TypedOptionsTable name="groupType" title="Types" />
      <OptionsTable name="aliasType" title="Alias Types" />
    </Stack>
  );
}
