import { Dialog } from '@mui/material';
import { RetentionForm } from './RetentionForm';

export function RetentionEditorDialog({
  open,
  onClose,
  value,
  telematicsBoxes,
}) {
  function handleClose() {
    onClose();
  }

  function submitCallback(values) {
    onClose(values);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <RetentionForm
        key={value?._id}
        defaultValues={value}
        onSubmit={submitCallback}
        onCancel={handleClose}
        telematicsBoxes={telematicsBoxes}
      />
    </Dialog>
  );
}
