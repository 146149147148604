import {
  DOWNLOAD_EVENTS,
  DOWNLOAD_EVENTS_CANCELLED,
  DOWNLOAD_EVENTS_FAILURE,
  DOWNLOAD_EVENTS_PROGRESS,
  DOWNLOAD_EVENTS_SUCCESS,
  FETCH_ACCELEROMETER_EVENTS,
  FETCH_ACCELEROMETER_EVENTS_CANCELLED,
  FETCH_ACCELEROMETER_EVENTS_FAILURE,
  FETCH_ACCELEROMETER_EVENTS_SUCCESS,
  FETCH_ATTENDANCES,
  FETCH_ATTENDANCES_CANCELLED,
  FETCH_ATTENDANCES_FAILURE,
  FETCH_ATTENDANCES_SUCCESS,
  FETCH_PAGED_EVENTS,
  FETCH_PAGED_EVENTS_CANCELLED,
  FETCH_PAGED_EVENTS_FAILURE,
  FETCH_PAGED_EVENTS_SUCCESS,
  FETCH_SPEED_INFRACTIONS,
  FETCH_SPEED_INFRACTIONS_CANCELLED,
  FETCH_SPEED_INFRACTIONS_FAILURE,
  FETCH_SPEED_INFRACTIONS_SUCCESS,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS,
  UPDATE_TRIP_CLASSIFICATION,
  UPDATE_TRIP_CLASSIFICATION_FAILURE,
  UPDATE_TRIP_CLASSIFICATION_SUCCESS,
  UPDATE_TRIP_DRIVER,
  UPDATE_TRIP_DRIVER_FAILURE,
  UPDATE_TRIP_DRIVER_SUCCESS,
} from '@/actions';
import { fromAjax } from '@/apis';
import {
  getHeaders,
  getPrimaryLocation,
  log,
  omit,
  round,
  startCase,
} from '@/utils';
import {
  events,
  minimumDoubleCrewSeconds,
  minimumSpeedInfractionSeconds,
} from '@/utils/config';
import { getMilliseconds } from 'date-fns';
import { dequal } from 'dequal';
import _ from 'lodash';
import nanomemoize from 'nano-memoize';
import { ofType } from 'redux-observable';
import { EMPTY, forkJoin, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  expand,
  map,
  mergeMap,
  scan,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

const {
  eventFilters = {},
  tripOccupantsMinKilometresThreshold = 0,
  tripOccupantsMinPercentageThreshold = 0,
} = events;

function eventFilterPathsFor(eventType) {
  return eventFilters[eventType]?.map((f) => f.name) ?? [];
}

// events etc. will have ordered locations eventually, for now...
const locationOrder = ['Police Station', 'Base', 'Workshop', 'Ward'];
function addDefaultLocationFor(locationField) {
  return {
    $concatArrays: [
      { $ifNull: [`$${locationField}`, []] },
      [
        {
          name: 'Elsewhere',
          type: 'None',
        },
      ],
    ],
  };
}

function primaryLocationFor(locationField) {
  return {
    $arrayElemAt: [
      `$${locationField}`,
      {
        $reduce: {
          input: locationOrder,
          initialValue: -1,
          in: {
            $switch: {
              branches: [
                {
                  case: { $eq: ['$$value', -1] },
                  then: {
                    $indexOfArray: [`$${locationField}.type`, '$$this'],
                  },
                },
              ],
              default: '$$value',
            },
          },
        },
      },
    ],
  };
}

// mongodb doesn't like '.' so replace it temporarily, $addToSet is used to
// get a set of unique values for the "client" filters
function filterValuesFor(path) {
  return { [path.replaceAll('.', '__')]: { $addToSet: `$${path}` } };
}

function urlForEventType(eventType) {
  switch (eventType) {
    case 'vehicleLocationVisits':
      return '/intersections';
    case 'trails':
      return '/personTrails';
    case 'personLocationVisits':
      return '/personLocationIntersections';
    case 'doubleCrews':
      return '/personDoubleCrews';
    case 'outages':
      return '/telematicsBoxOutages';
    default:
      return '/' + eventType;
  }
}

// if we want to do a server side filter for something that was normally calculated
// on the client side before, we need to transform the data in the pipeline to suit
function transformStages(eventType) {
  const primaryStartAndEndLocations = [
    {
      $set: {
        startLocations: addDefaultLocationFor('startLocations'),
        endLocations: addDefaultLocationFor('endLocations'),
      },
    },
    {
      $addFields: {
        startLocation: primaryLocationFor('startLocations'),
        endLocation: primaryLocationFor('endLocations'),
      },
    },
  ];

  // const reduceAreas = (output, path) => ({
  //   $set: {
  //     [output]: {
  //       $arrayToObject: {
  //         $map: {
  //           input: `$${path}`,
  //           as: 'a',
  //           in: {
  //             k: '$$a.type',
  //             v: '$$a.name',
  //           },
  //         },
  //       },
  //     },
  //   },
  // });

  switch (eventType) {
    case 'trips':
    case 'trails':
    case 'outages':
      return primaryStartAndEndLocations;
    case 'idles':
    case 'stops':
      return [
        {
          $set: {
            locations: addDefaultLocationFor('locations'),
          },
        },
        {
          $addFields: {
            location: primaryLocationFor('locations'),
          },
        },
      ];
    case 'doubleCrews':
      return [
        {
          $set: {
            primary: { $arrayElemAt: ['$people', 0] },
            secondary: { $arrayElemAt: ['$people', 1] },
          },
        },
      ];
    default:
      return [];
  }
}

// $group-ing operations to do on the full filtered data to get the totals row
function totalsForEventType(eventType) {
  const durationSeconds = { $sum: '$durationSeconds' };
  const maxSpeedKilometresPerHour = { $max: '$maxSpeedKilometresPerHour' };
  const distanceKilometres = { $sum: '$distanceKilometres' };

  switch (eventType) {
    case 'trips':
      return {
        durationSeconds,
        maxSpeedKilometresPerHour,
        distanceKilometres,
      };
    case 'trails':
    case 'personLocationVisits':
    case 'idles':
    case 'stops':
    case 'doubleCrews':
      return { durationSeconds };
    case 'outages':
    case 'vehicleLocationVisits':
      return { durationSeconds, distanceKilometres };
    default:
      return [];
  }
}

// for each row of the page calculate extra fields, km => miles, etc.
function mappingForEventType(eventType) {
  switch (eventType) {
    case 'trips':
      return mapTrip;
    case 'outages':
    case 'vehicleLocationVisits':
      return (visit) => ({
        ...visit,
        distanceMiles: visit.distanceKilometres * 0.62137119,
      });
    case 'onBoardDiagnostics':
      return (diagnostic) => ({
        ...diagnostic,
        isConfirmed: diagnostic.isConfirmed ? 'Yes' : 'No',
      });
    case 'vehicleEquipmentActivations':
      return (item) => ({
        ...item,
        eventSubtype:
          eventFilters.vehicleEquipmentActivations[0].options.find(
            (e) => e.value === item.eventSubtype,
          )?.label ?? item.eventSubtype,
      });

    default:
      return (item) => item;
  }
}

// non-user defined matches always present
function mandatoryMatchForEventType(eventType) {
  switch (eventType) {
    case 'doubleCrews':
      return {
        $match: {
          durationSeconds: { $gte: minimumDoubleCrewSeconds },
          'people.code': { $nin: ['', null] },
        },
      };
    case 'outages':
      return {
        $match: {
          distanceKilometres: { $gt: 0 },
        },
      };
    default:
      return undefined;
  }
}

// with paging there won't be a huge amount of data to send anyways so
// the main reason for this is it limits the memory the sort stage needs
function projectionForEventType(eventType) {
  switch (eventType) {
    case 'trips':
      return {
        identifier: true,
        equipmentActivations: true,
        driver: true,
        vehicle: true,
        rfidCard: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        distanceKilometres: true,
        maxSpeedKilometresPerHour: true,
        startLocation: true, // note: transformed value (no s)
        endLocation: true, // note: transformed value (no s)
        classification: true,
        occupants: true,
        occupantsReasonCode: true,
      };
    case 'stops':
      return {
        identifier: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        point: true,
        lastDriver: true,
        vehicle: true,
        rfidCard: true,
        location: true, // note: transformed value (no s)
      };
    case 'idles':
      return {
        identifier: true,
        vehicle: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        point: true,
        location: true, // note: transformed value (no s)
      };
    case 'vehicleLocationVisits':
      return {
        identifier: true,
        location: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        distanceKilometres: true,
        vehicle: true,
      };
    case 'onBoardDiagnostics':
      return {
        identifier: true,
        area: true,
        class: true,
        code: true,
        time: true,
        isConfirmed: true,
        description: true,
        vehicle: true,
      };
    case 'trails':
      return {
        identifier: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        startLocations: true,
        endLocations: true,
        person: true,
        startLocation: true, // note: transformed value (no s)
        endLocation: true, // note: transformed value (no s)
      };
    case 'personLocationVisits':
      return {
        identifier: true,
        location: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        person: true,
      };
    case 'doubleCrews':
      return {
        identifier: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        primary: true, // transformed value
        secondary: true, // transformed value
      };
    case 'vehicleEquipmentActivations':
      return {
        identifier: true,
        vehicle: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        eventSubtype: true,
      };
    default:
      return undefined;
  }
}

//
// for each total calculate extra fields, km => miles, etc.
function totalsMappingForEventType(eventType) {
  return (totals) => {
    switch (eventType) {
      case 'trips':
        return {
          maxSpeedMilesPerHour: round(
            (totals?.maxSpeedKilometresPerHour ?? 0) * 0.62137119,
            2,
          ),
          distanceMiles: round(
            (totals?.distanceKilometres ?? 0) * 0.62137119,
            2,
          ),
          durationSeconds: round(totals?.durationSeconds ?? 0, 2),
        };
      case 'idles':
      case 'stops':
      case 'doubleCrews':
      case 'trails':
      case 'personLocationVisits':
        return {
          durationSeconds: round(totals?.durationSeconds ?? 0, 2),
        };
      case 'vehicleLocationVisits':
        return {
          durationSeconds: round(totals?.durationSeconds ?? 0, 2),
          distanceMiles: round(
            (totals?.distanceKilometres ?? 0) * 0.62137119,
            2,
          ),
        };
      default:
        return {};
    }
  };
}

/*
1. match the query values in the parameters control on the left 
  (the indexes should use most of these so should be fast)
2. then it transforms/computes some stuff eg start and end location
3. it matches the result of the prior steps against the "client filters"
4. sort all the results (have to sort here or only the one page will be sorted)
5. split the pipeline into three:
  a: get only one page of the results from step 4
  b: get the "client filters" from step 4
  c: count all the results from step 4
*/
function createEventPipeline({
  eventType,
  query,
  filterOptionsPaths,
  filters = {},
  page = 1,
  perPage = 10,
  order = 'asc',
  orderByServerKey,
  excludeFilterValues = false, // no need to get filter values when downloading
}) {
  const $project = projectionForEventType(eventType);

  // there are multiple match stages, some will apply only to transformed values
  // but mongodb takes care of optimising this into as few $match stages as possible
  return [
    // the parameters component's query
    { $match: query },
    // mandatory matches e.g. for a minimum duration seconds
    mandatoryMatchForEventType(eventType),
    // transform e.g. get the primary location or reduceAreas
    ...transformStages(eventType),
    // these are the filters formally know as "client" filters, they may need to match
    // on the transformed data
    { $match: _.pickBy(filters, (f) => f !== undefined && f !== null) },
    {
      // facet splits the pipeline into three parts here
      $facet: {
        // the single page of rows shown in the table
        results: [
          // projecting here reduces the sort stage's memory load
          $project && { $project },
          // sort the whole lot (not just a page)
          !!orderByServerKey && {
            $sort: { [orderByServerKey]: order === 'asc' ? 1 : -1 },
          },
          { $skip: page * perPage },
          { $limit: perPage },
        ].filter(Boolean),
        // any totals for all the data (the totalCount is always included for pagination)
        totals: [
          {
            $group: {
              _id: null,
              ...totalsForEventType(eventType),
              totalCount: {
                $sum: 1,
              },
            },
          },
        ],
        // the options for the "client" filters
        filterValues: excludeFilterValues
          ? undefined
          : [
              {
                $group: {
                  _id: null,
                  ...filterOptionsPaths.reduce((obj, path) => {
                    return {
                      ...obj,
                      ...filterValuesFor(path),
                    };
                  }, {}),
                },
              },
            ],
      },
    },
  ].filter(Boolean);
}

// when the results, totals and filterValues are returned from the pipeline
// process them into the format expected by the table & convert km/miles etc.
function processPageResponse(response, eventType, state$) {
  const mapping = mappingForEventType(eventType);
  const totalsMapping = totalsMappingForEventType(eventType);
  const list = (response?.[0]?.results || []).map((r) => mapping(r, state$));

  const totals = response?.[0]?.totals?.[0];
  const total = totals?.totalCount;

  const underscoredFilterValues = response?.[0]?.filterValues?.[0];
  let filterValues = {};
  Object.keys(underscoredFilterValues || {}).forEach((key) => {
    filterValues[key.replaceAll('__', '.')] = underscoredFilterValues[key];
  });

  return {
    list,
    total,
    totals: totalsMapping ? totalsMapping(totals) : totals,
    filterValues,
  };
}

const peopleBySsiMemoized = nanomemoize((people) =>
  _.keyBy(people, 'radioSsi'),
);
function mapTrip(trip, state$) {
  const { people } = state$?.value?.people ?? {};
  const peopleBySsi = peopleBySsiMemoized(people);

  const occupants = trip?.occupants
    ?.filter(
      (o) =>
        o.distanceKilometres > tripOccupantsMinKilometresThreshold &&
        (100 * o.distanceKilometres) / trip.distanceKilometres >
          tripOccupantsMinPercentageThreshold,
    )
    .map((o) => ({
      ...o,
      ...(o.code ? {} : peopleBySsi[o.radioSsi] ?? {}),
    }));

  return {
    ...trip,
    occupants,
    classification: trip.classification || 'None',
    durationSeconds: trip.durationSeconds,
    distanceMiles: trip.distanceKilometres * 0.62137119,
    maxSpeedMilesPerHour: trip.maxSpeedKilometresPerHour * 0.62137119,
  };
}

// generic server side paging for any eventType
export function fetchPagedEventsEpic(action$, state$) {
  return action$.pipe(
    ofType(FETCH_PAGED_EVENTS),
    debounceTime(300),
    mergeMap(({ payload }) =>
      fromAjax(urlForEventType(payload.eventType), {
        params: {
          pipeline: createEventPipeline(payload),
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const results = processPageResponse(
            response,
            payload.eventType,
            state$,
          );

          log('Read', startCase(payload.eventType), payload.query);

          return {
            type: FETCH_PAGED_EVENTS_SUCCESS,
            payload: {
              ...results,
              eventType: payload.eventType,
              filters: payload.filters,
            },
          };
        }),
        takeUntil(action$.pipe(ofType(FETCH_PAGED_EVENTS_CANCELLED))),
        catchError((err) =>
          of({
            type: FETCH_PAGED_EVENTS_FAILURE,
            payload: {
              eventType: payload.eventType,
              message: err.message ?? err,
            },
          }),
        ),
      ),
    ),
  );
}

// this uses the paged event pipeline to get data for downloading a chunk at a time
// the expand/scan/map rxjs pipeline allows us to send progress back to the ui
function downloadPipeline({ eventType, ...payload }, headers) {
  const perPage = 1000;

  function downloadChunk(page = 0) {
    const pipeline = createEventPipeline({
      ...payload,
      eventType,
      page,
      perPage,
      excludeFilterValues: true, // no need for filterValues when downloading
    });

    return fromAjax(urlForEventType(eventType), {
      params: { pipeline },
      headers,
    }).pipe(
      map(({ response }) => {
        return {
          ...processPageResponse(response, eventType),
          page: page + 1, // move to the next page
        };
      }),
    );
  }

  return downloadChunk(0).pipe(
    // recursively call downloadChunk until the last page is reached
    expand((data) => {
      return data.page === 100 || data.total < data.page * perPage
        ? EMPTY
        : downloadChunk(data.page);
    }),
    // accumulate all the data
    scan(
      (acc, data) => {
        data.list = acc.list.concat(data.list);
        acc = data;

        return data;
      },
      { list: [] },
    ),
    // if we're done send on SUCCESS with the data, otherwise just send
    // a PROGRESS report
    map((payload) => {
      const progress = (payload.page * perPage) / payload.total;

      if (progress >= 1) {
        return {
          type: DOWNLOAD_EVENTS_SUCCESS,
          payload: {
            download: payload.list,
            eventType,
          },
        };
      } else {
        return {
          type: DOWNLOAD_EVENTS_PROGRESS,
          payload: {
            progress,
            eventType,
          },
        };
      }
    }),
  );
}

export function downloadEventsEpic(action$) {
  return action$.pipe(
    ofType(DOWNLOAD_EVENTS),
    switchMap(({ payload }) =>
      downloadPipeline(payload, getHeaders()).pipe(
        takeUntil(action$.pipe(ofType(DOWNLOAD_EVENTS_CANCELLED))),
        catchError(({ message }) =>
          of({
            type: DOWNLOAD_EVENTS_FAILURE,
            payload: { ...message, eventType: payload.eventType },
          }),
        ),
      ),
    ),
  );
}

export function updateTripClassificationEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_TRIP_CLASSIFICATION),
    mergeMap(({ payload: { id, classification } }) =>
      fromAjax(`/trips/${id}`, {
        body: {
          classification,
        },
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'content-type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: { identifier: id, classification } }) => ({
          type: UPDATE_TRIP_CLASSIFICATION_SUCCESS,
          payload: { id, classification },
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_TRIP_CLASSIFICATION_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function updateTripDriverEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_TRIP_DRIVER),
    mergeMap(({ payload: { id, driver } }) =>
      fromAjax(`/trips/${id}`, {
        body: {
          driver: { ...driver, assigned: true },
        },
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'content-type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: { identifier: id, driver } }) => ({
          type: UPDATE_TRIP_DRIVER_SUCCESS,
          payload: { id, driver },
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_TRIP_DRIVER_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchTripsWithSpeedInfractionsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_SPEED_INFRACTIONS),
    mergeMap(({ payload: { query, tripClassifications } }) =>
      forkJoin({
        infractions: fromAjax('/speedInfractions', {
          params: {
            query: {
              durationSeconds: { $gte: minimumSpeedInfractionSeconds },
              ...query,
            },
            projection: {
              identifier: true,
              startTime: true,
              endTime: true,
              durationSeconds: true,
              distanceKilometres: true,
              maxSpeedKilometresPerHour: true,
              speedRules: true,
              parentEvent: true,
            },
            sort: { startTime: 1 },
          },
          headers: getHeaders(),
        }),
        trips: fromAjax('/trips', {
          params: {
            query: {
              // these are filters for the speed infraction event only, omit them here
              ...omit(query, eventFilterPathsFor('speedInfractions')),
              hasSpeedInfractions: true,
            },
            projection: {
              identifier: true,
              startTime: true,
              endTime: true,
              durationSeconds: true,
              distanceKilometres: true,
              maxSpeedKilometresPerHour: true,
              startLocations: true,
              endLocations: true,
              classification: true,
              equipmentActivations: true,
              driver: true,
              vehicle: true,
              rfidCard: true,
            },
            sort: { startTime: 1 },
          },
          headers: getHeaders(),
        }),
      }).pipe(
        map(
          ({
            infractions: { response },
            trips: { response: tripResponse },
          }) => {
            const mappedSpeedInfractions = (response || []).map(
              (speedInfraction) => {
                const speedLimitBreakdowns = speedInfraction.speedRules
                  ? speedInfraction.speedRules.reduce(
                      (accumulator, rule) => {
                        if (!accumulator.rules.includes(rule.code)) {
                          accumulator.rules.push(rule.code);
                        }

                        rule.speedLimitBreakdown.forEach((breakdown) => {
                          const speedLimitMilesPerHour = breakdown.unknownLimit
                            ? '?'
                            : round(
                                breakdown.kilometresPerHour * 0.62137119,
                                0,
                              );
                          let current = accumulator.rows.find(
                            (row) =>
                              row.limitMilesPerHour === speedLimitMilesPerHour,
                          );

                          if (!current) {
                            current = {
                              limitMilesPerHour: speedLimitMilesPerHour,
                              ruleDurationMinutes: {
                                [rule.code]: breakdown.durationSeconds / 60,
                              },
                              ruleDurationSeconds: {
                                [rule.code]: breakdown.durationSeconds,
                              },
                              maxSpeedMilesPerHour:
                                breakdown.maxSpeedKilometresPerHour *
                                0.62137119,
                              excessMilesPerHour:
                                speedLimitMilesPerHour === '?'
                                  ? 0
                                  : breakdown.maxSpeedKilometresPerHour *
                                      0.62137119 -
                                    speedLimitMilesPerHour,
                            };

                            accumulator.rows.push(current);
                          } else {
                            let currentRuleDurationMinutes =
                              current.ruleDurationMinutes[rule.code];

                            if (!currentRuleDurationMinutes) {
                              currentRuleDurationMinutes =
                                breakdown.durationSeconds / 60;

                              current.ruleDurationMinutes[rule.code] =
                                currentRuleDurationMinutes;
                            } else {
                              currentRuleDurationMinutes +=
                                breakdown.durationSeconds / 60;
                            }

                            current.maxSpeedMilesPerHour = Math.max(
                              current.maxSpeedMilesPerHour,
                              breakdown.maxSpeedKilometresPerHour * 0.62137119,
                            );

                            current.excessMilesPerHour =
                              speedLimitMilesPerHour === '?'
                                ? 0
                                : breakdown.maxSpeedKilometresPerHour *
                                    0.62137119 -
                                  speedLimitMilesPerHour;
                          }
                        });

                        return accumulator;
                      },
                      {
                        rules: [],
                        rows: [],
                      },
                    )
                  : {
                      rules: ['EXCESS'],
                      rows: [
                        {
                          limitMilesPerHour: '?',
                          ruleDurationMinutes: {
                            EXCESS: speedInfraction.durationSeconds / 60,
                          },
                          maxSpeedMilesPerHour:
                            speedInfraction.maxSpeedKilometresPerHour *
                            0.62137119,
                          excessMilesPerHour: 0,
                        },
                      ],
                    };

                const maxExcessMilesPerHour = speedLimitBreakdowns
                  ? Math.max(
                      ...speedLimitBreakdowns.rows.map(
                        (breakdown) => breakdown.excessMilesPerHour,
                      ),
                    )
                  : 0;

                const classificationDurationSeconds = []
                  .concat(
                    ...(speedInfraction.speedRules || []).map((rule) =>
                      tripClassifications
                        .filter(
                          (classification) =>
                            !classification.applicableSpeedRules ||
                            classification.applicableSpeedRules.includes(
                              rule.code,
                            ),
                        )
                        .map((classification) => ({
                          classification: classification.value,
                          durationSeconds: rule.durationSeconds,
                        })),
                    ),
                  )
                  .reduce((accumulator, row) => {
                    if (row.classification in accumulator) {
                      accumulator[row.classification] += row.durationSeconds;
                    } else {
                      accumulator[row.classification] = row.durationSeconds;
                    }

                    return accumulator;
                  }, {});

                return {
                  identifier: speedInfraction.identifier,
                  startTime: speedInfraction.startTime,
                  endTime: speedInfraction.endTime,
                  durationSeconds: speedInfraction.durationSeconds,
                  distanceMiles:
                    speedInfraction.distanceKilometres * 0.62137119,
                  maxSpeedMilesPerHour:
                    speedInfraction.maxSpeedKilometresPerHour * 0.62137119,
                  maxExcessMilesPerHour,
                  speedLimitBreakdowns,
                  tripIdentifier:
                    speedInfraction.parentEvent &&
                    speedInfraction.parentEvent.type === 'TRIP'
                      ? speedInfraction.parentEvent.identifier
                      : 'NONE',
                  classificationDurationSeconds,
                };
              },
            );

            const groupedSpeedInfractions = mappedSpeedInfractions.reduce(
              (accumulator, infraction) => {
                if (infraction.tripIdentifier in accumulator) {
                  accumulator[infraction.tripIdentifier].push(infraction);
                } else {
                  accumulator[infraction.tripIdentifier] = [infraction];
                }

                return accumulator;
              },
              {},
            );

            const trips = (tripResponse || [])
              .map((trip) => {
                const startLocation = getPrimaryLocation(trip.startLocations);
                const endLocation = getPrimaryLocation(trip.endLocations);

                const emergencyEquipmentUsed = trip.equipmentActivations
                  ? trip.equipmentActivations.emergencyOn
                  : false;

                const speedInfractions = (
                  groupedSpeedInfractions[trip.identifier] || []
                ).filter(
                  (infraction) =>
                    !trip.classification ||
                    (infraction.classificationDurationSeconds[
                      trip.classification
                    ] || 0) > (minimumSpeedInfractionSeconds || 45),
                );

                const speedInfractionDurationSeconds = speedInfractions.reduce(
                  (total, infraction) => total + infraction.durationSeconds,
                  0,
                );

                const maxExcessMilesPerHour = Math.max(
                  ...speedInfractions.map(
                    (infraction) => infraction.maxExcessMilesPerHour,
                  ),
                );

                const driver = trip.driver || {};
                const rfidCard = trip.rfidCard || {};

                return {
                  identifier: trip.identifier,
                  driverCode: driver.code,
                  driverName: driver
                    ? `${driver.forenames} ${driver.surname}`
                    : '',
                  rfidCard,
                  collarNumber: driver.collarNumber,
                  personRole: driver.role,
                  registrationNumber: trip.vehicle.registrationNumber,
                  fleetNumber: trip.vehicle.fleetNumber,
                  role: trip.vehicle.role,
                  type: trip.vehicle.type,
                  homeStation: trip.vehicle.homeStation,
                  groups: trip.vehicle.groups,
                  classification: trip.classification || 'None',
                  startTime: trip.startTime,
                  endTime: trip.endTime,
                  durationSeconds: trip.durationSeconds,
                  distanceMiles: trip.distanceKilometres * 0.62137119,
                  maxSpeedMilesPerHour:
                    trip.maxSpeedKilometresPerHour * 0.62137119,
                  startLocationType: startLocation.type,
                  startLocationName: startLocation.name,
                  endLocationType: endLocation.type,
                  endLocationName: endLocation.name,
                  speedInfractions,
                  speedInfractionCount: speedInfractions.length,
                  speedInfractionDurationSeconds,
                  emergencyEquipmentUsed,
                  maxExcessMilesPerHour,
                };
              })
              .filter((trip) => trip.speedInfractionCount > 0);

            log('Read', 'Speed Infractions', query);

            return {
              type: FETCH_SPEED_INFRACTIONS_SUCCESS,
              payload: trips,
            };
          },
        ),
        takeUntil(action$.pipe(ofType(FETCH_SPEED_INFRACTIONS_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_SPEED_INFRACTIONS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchAccelerometerEventsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_ACCELEROMETER_EVENTS),
    mergeMap(({ payload: { time, ...query } }) =>
      forkJoin({
        alerts: fromAjax('/accelerometerAlerts', {
          params: {
            query: {
              time,
              // these are filters for the speed infraction event only, omit them here
              ...omit(query, eventFilterPathsFor('accelerometerEvents')),
            },
            projection: {
              identifier: true,
              time: true,
              point: true,
              vehicle: true,
            },
            sort: { time: 1 },
          },
          headers: getHeaders(),
        }),
        events: fromAjax('/accelerometerEvents', {
          params: {
            query: {
              startTime: { $lt: time.$lt },
              endTime: { $gt: time.$gte },
              ...query,
            },
            projection: {
              identifier: true,
              vehicle: true,
              maximumForces: true,
              accelerometerData: true,
              deviceProperties: true,
              durationSeconds: true,
              path: true,
            },
            sort: { startTime: 1 },
          },
          headers: getHeaders(),
        }),
      }).pipe(
        map(
          ({
            alerts: { response: alertResponse },
            events: { response: eventResponse },
          }) => {
            const events = new Map(
              eventResponse.map(
                ({
                  identifier,
                  vehicle: {
                    identificationNumber,
                    registrationNumber,
                    fleetNumber,
                    role: vehicleRole,
                    type: vehicleType,
                  },
                  maximumForces: {
                    horizontal: maxHorizontalForce,
                    vertical: maxVerticalForce,
                    lateral: maxLateralForce,
                  },
                  deviceProperties: { triggerPoint, time },
                  accelerometerData,
                  path,
                }) => [
                  // `${identificationNumber}-${time}`,
                  `${identificationNumber}-${
                    accelerometerData[triggerPoint * 10].time
                  }`,
                  {
                    identifier,
                    registrationNumber,
                    fleetNumber,
                    vehicleRole,
                    vehicleType,
                    maxHorizontalForce,
                    maxVerticalForce,
                    maxLateralForce,
                    triggerPoint,
                    time,
                    data: accelerometerData.map(
                      ({ speedKilometresPerHour, ...entry }) => ({
                        speedMilesPerHour:
                          getMilliseconds(new Date(entry.time)) === 0
                            ? round(speedKilometresPerHour * 0.62137119, 2)
                            : null,
                        ...entry,
                      }),
                    ),
                    path,
                  },
                ],
              ),
            );

            const alerts = alertResponse.map(
              ({
                identifier,
                vehicle: {
                  identificationNumber,
                  registrationNumber,
                  fleetNumber,
                  role: vehicleRole,
                  type: vehicleType,
                },
                // driver: { code, forenames, surname, collarNumber, role: driverRole },
                time,
                point,
              }) => {
                const { identifier: eventIdentifier, ...event } =
                  events.get(`${identificationNumber}-${time}`) || {};
                events.delete(`${identificationNumber}-${time}`);

                // console.log(
                //   `${identificationNumber}-${time}`,
                //   events.get(`${identificationNumber}-${time}`)
                // );

                return {
                  identifier,
                  // driverCode: code,
                  // driverName: `${forenames} ${surname}`,
                  // collarNumber,
                  // driverRole,
                  registrationNumber,
                  fleetNumber,
                  vehicleRole,
                  vehicleType,
                  time,
                  point,
                  hasData: eventIdentifier ? 'Yes' : 'No',
                  ...event,
                };
              },
            );

            const orphanedEvents = Array.from(events.values(), (event) => {
              const { triggerPoint, data } = event;

              return {
                point: data[triggerPoint * 10].position,
                hasData: 'Yes',
                ...event,
              };
            });

            // if they've queried some event specific stuff e.g. maximum force
            // query vs omit won't be equal if so only include alerts that have data
            const eventsQuery = omit(
              query,
              eventFilterPathsFor('accelerometerEvents'),
            );
            const onlyWithData = !dequal(query, eventsQuery);
            const payload = alerts
              .concat(orphanedEvents)
              .filter((a) => (onlyWithData ? a.hasData === 'Yes' : true));

            log('Read', 'Accelerometer Events', query);

            return { type: FETCH_ACCELEROMETER_EVENTS_SUCCESS, payload };
          },
        ),
        takeUntil(action$.pipe(ofType(FETCH_ACCELEROMETER_EVENTS_CANCELLED))),
        catchError((error) =>
          of({
            type: FETCH_ACCELEROMETER_EVENTS_FAILURE,
            payload: error.message,
          }),
        ),
      ),
    ),
  );
}

export function fetchAttendancesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_ATTENDANCES),
    mergeMap(({ payload: query }) =>
      forkJoin({
        attendances: fromAjax('/personObjectiveAttendances', {
          params: {
            query,
            projection: {
              identifier: true,
              startTime: true,
              endTime: true,
              objective: true,
              durationSeconds: true,
              person: true,
              compliant: true,
            },
          },
          headers: getHeaders(),
        }),
        objectives: fromAjax('/objectives', {
          params: {
            query: {
              startTime: query.startTime,
              endTime: query.endTime,
            },
            projection: {
              identifier: true,
              startTime: true,
              endTime: true,
              schedule: true,
              requiredFrequency: true,
              requiredVisits: true,
            },
          },
          headers: getHeaders(),
        }),
      }).pipe(
        map(
          ({
            attendances: { response: attendanceResponse },
            objectives: { response: objectiveResponse },
          }) => {
            const attendances = (attendanceResponse || []).map((attendance) => {
              const objective = (objectiveResponse || []).find(
                (objective) =>
                  attendance.objective.identifier === objective.identifier,
              );

              return {
                ...attendance,
                groups: attendance.person.groups,
                objective: {
                  ...attendance.objective,
                  ...objective,
                  requiredFrequency: objective?.requiredFrequency || 'total',
                },
              };
            });

            return {
              type: FETCH_ATTENDANCES_SUCCESS,
              payload: attendances,
            };
          },
        ),
        takeUntil(action$.pipe(ofType(FETCH_ATTENDANCES_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_ATTENDANCES_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchTripsForMalfunctionIndicatorLightEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT),
    mergeMap(({ payload: { time, ...query } }) =>
      forkJoin({
        diagnostics: fromAjax('/onBoardDiagnostics', {
          params: {
            query: {
              time,
              ...query,
            },
            projection: {
              identifier: true,
              time: true,
              area: true,
              class: true,
              code: true,
              isConfirmed: true,
              vehicle: true,
            },
            sort: { time: 1 },
          },
          headers: getHeaders(),
        }),
        trips: fromAjax('/trips', {
          params: {
            query: {
              startTime: { $lt: time.$lt },
              endTime: { $gt: time.$gte },
              ...query,
              hasMalfunctionIndicatorLightOn: true,
            },
            projection: {
              identifier: true,
              vehicle: true,
              driver: true,
              startTime: true,
              endTime: true,
              malfunctionIndicatorLightOnTime: true,
            },
            sort: { startTime: 1 },
          },
          headers: getHeaders(),
        }),
      }).pipe(
        map(
          ({
            diagnostics: { response },
            trips: { response: tripResponse },
          }) => {
            const mappedDiagnostics = (response || []).map((diagnostic) => {
              return {
                identifier: diagnostic.identifier,
                vehicle: diagnostic.vehicle,
                area: diagnostic.area,
                class: diagnostic.class,
                code: diagnostic.code,
                time: diagnostic.time,
                isConfirmed: diagnostic.isConfirmed ? 'Yes' : 'No',
                description: diagnostic.description,
              };
            });

            const trips = (tripResponse || []).map((trip) => {
              return {
                identifier: trip.identifier,
                registrationNumber: trip.vehicle.registrationNumber,
                fleetNumber: trip.vehicle.fleetNumber,
                role: trip.vehicle.role,
                type: trip.vehicle.type,
                startTime: trip.startTime,
                endTime: trip.endTime,
                malfunctionIndicatorLightOnTime:
                  trip.malfunctionIndicatorLightOnTime,
                diagnostics: mappedDiagnostics.filter(
                  (diagnostic) =>
                    diagnostic.vehicle.registrationNumber ===
                      trip.vehicle.registrationNumber &&
                    new Date(diagnostic.time) >= new Date(trip.startTime) &&
                    new Date(diagnostic.time) <= new Date(trip.endTime),
                ),
              };
            });

            log('Read', 'Malfunction Indicator Lights', query);

            return {
              type: FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS,
              payload: trips,
            };
          },
        ),
        takeUntil(
          action$.pipe(
            ofType(FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED),
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}
