import { api } from '@/apis';
import { log } from '@/utils';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { DriverScore, DriverScoresParams } from './types';

export function useDriverScores(
  params: DriverScoresParams,
): UseQueryResult<DriverScore[]> {
  return useQuery({
    queryKey: ['driverScores', params],
    queryFn: async () => {
      log('Read', 'Driver Scores', params);

      const scores: DriverScore[] = await api
        .post('driverScores', { json: params })
        .json();

      return scores.sort((a: DriverScore, b: DriverScore) =>
        (a.person?.collarNumber ?? '').localeCompare(
          b.person?.collarNumber ?? '',
        ),
      );
    },
  });
}
