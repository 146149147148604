import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useAuditTotals(
  startTime,
  endTime,
  users = [],
  dataTypes = [],
  actions = [],
) {
  const queryClient = useQueryClient();

  return {
    ...useQuery({
      queryKey: ['auditTotals', startTime, endTime, users, dataTypes, actions],
      queryFn: async ({ signal }) => {
        const json = [
          {
            $match: {
              time: { $gte: startTime, $lt: endTime },
              user: users.length > 0 ? { $in: users } : undefined,
              dataType: dataTypes.length > 0 ? { $in: dataTypes } : undefined,
              action: actions.length > 0 ? { $in: actions } : undefined,
            },
          },
          {
            $group: {
              _id: null,
              total: { $sum: 1 },
            },
          },
        ];

        return api.post('pipeline/audits', { json, signal }).json();
      },
      placeholderData: [],
      staleTime: 1000 * 60 * 60,
      enabled: !!startTime && !!endTime,
    }),
    cancel: () =>
      queryClient.cancelQueries({
        queryKey: [
          'auditTotals',
          startTime,
          endTime,
          users,
          dataTypes,
          actions,
        ],
      }),
  };
}
