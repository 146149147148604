// import { useMemo } from 'react';
import { useMemo } from 'react';
import { useOptionLookup } from '../option';
import { useGroups } from './useGroups';

/**
 * @typedef {object} Value
 * @property {string} name - The name of the group.
 * @property {string} [type] - The type of the group.
 * @property {string} [typeCode] - The type code of the group.
 */

/**
 * @typedef {Object.<string, Value>} GroupOptions
 * This is an object where each property is an array.
 */

/**
 * A hook that fetches group options.
 * @returns {import('@tanstack/react-query').UseQueryResult<GroupOptions>}
 */
export function useGroupOptions({ types, ancestors } = {}) {
  const { data: groups } = useGroups({ types, ancestors });
  const typeOptions = useOptionLookup('groupType');

  return useMemo(() => {
    return groups
      .concat()
      .sort(
        (a, b) =>
          -(typeOptions[b.type] ?? '').localeCompare(typeOptions[a.type] ?? ''),
      )
      .reduce(
        (accumulator, { code, name, type }) => ({
          ...accumulator,
          [code]: { name, type: typeOptions[type], typeCode: type },
        }),
        {},
      );
  }, [groups, typeOptions]);
}
