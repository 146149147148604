import { api } from '@/apis';
import { isEmpty, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import {
  addHours,
  differenceInHours,
  differenceInSeconds,
  startOfHour,
} from 'date-fns';

export function useHourlyIntersections(query) {
  return useQuery({
    queryKey: ['hourlyIntersections', query],
    queryFn: async ({ signal }) => {
      const json = [
        { $match: query },
        {
          $project: {
            identifier: true,
            vehicle: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
            location: true,
          },
        },
      ];
      const rawData = await api
        .post('pipeline/intersections', { json, signal })
        .json();
      const data = [] //TODO: Can this be in a pipeline too?
        .concat(
          ...rawData.map(
            ({
              startTime,
              endTime,
              vehicle: {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                groups,
              },
              location: { name: locationName, type: locationType },
            }) => {
              const count = differenceInHours(
                addHours(startOfHour(new Date(endTime)), 1),
                startOfHour(new Date(startTime)),
              );

              if (count === 1) {
                return [
                  {
                    identificationNumber,
                    registrationNumber,
                    fleetNumber,
                    role,
                    type,
                    groups,
                    locationName,
                    locationType,
                    hour: startOfHour(new Date(startTime)),
                    durationSeconds: differenceInSeconds(
                      new Date(endTime),
                      new Date(startTime),
                    ),
                  },
                ];
              } else if (count === 2) {
                return [
                  {
                    identificationNumber,
                    registrationNumber,
                    fleetNumber,
                    role,
                    type,
                    groups,
                    locationName,
                    locationType,
                    hour: startOfHour(new Date(startTime)),
                    durationSeconds: differenceInSeconds(
                      startOfHour(addHours(new Date(startTime), 1)),
                      new Date(startTime),
                    ),
                  },
                  {
                    identificationNumber,
                    registrationNumber,
                    fleetNumber,
                    role,
                    type,
                    groups,
                    locationName,
                    locationType,
                    hour: startOfHour(new Date(endTime)),
                    durationSeconds: differenceInSeconds(
                      new Date(endTime),
                      startOfHour(new Date(endTime)),
                    ),
                  },
                ];
              } else {
                return [
                  {
                    identificationNumber,
                    registrationNumber,
                    fleetNumber,
                    role,
                    type,
                    groups,
                    locationName,
                    locationType,
                    hour: startOfHour(new Date(startTime)),
                    durationSeconds: differenceInSeconds(
                      startOfHour(addHours(new Date(startTime), 1)),
                      new Date(startTime),
                    ),
                  },
                  ...Array(count - 2)
                    .fill()
                    .map((_, index) => ({
                      identificationNumber,
                      registrationNumber,
                      fleetNumber,
                      role,
                      type,
                      groups,
                      locationName,
                      locationType,
                      hour: addHours(
                        startOfHour(new Date(startTime)),
                        index + 1,
                      ),
                      durationSeconds: 3600,
                    })),
                  {
                    identificationNumber,
                    registrationNumber,
                    fleetNumber,
                    role,
                    type,
                    groups,
                    locationName,
                    locationType,
                    hour: startOfHour(new Date(endTime)),
                    durationSeconds: differenceInSeconds(
                      new Date(endTime),
                      startOfHour(new Date(endTime)),
                    ),
                  },
                ];
              }
            },
          ),
        )
        .filter(
          (record) =>
            new Date(record.hour) >= new Date(query.endTime.$gte) &&
            new Date(record.hour) <= new Date(query.startTime.$lt),
        );

      log('Read', 'Vehicle In Base Time', query);

      return data;
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !isEmpty(query),
  });
}
