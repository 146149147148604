import { featureSortFn, filterType, log } from '@/utils';
import { startOfHour } from 'date-fns';
import { useMemo } from 'react';
import { useLocationSnapshot } from './useLocationSnapshot';
import { useResourceSnapshot } from './useResourceSnapshot';

export function useFilteredSnapshot(time, search, sort, filter) {
  const { data: locationSnapshot, isFetching: fetchingLocations } =
    useLocationSnapshot(startOfHour(time));
  const { data: resourceSnapshot, isFetching: fetchingResources } =
    useResourceSnapshot(time);

  const data = useMemo(() => {
    log('View', 'Snapshot', { time, search, sort, filter });

    return Object.entries({
      ...locationSnapshot,
      ...resourceSnapshot,
    }).reduce(
      (accumulator, [type, featureCollection]) => ({
        ...accumulator,
        [type]: {
          ...featureCollection,
          features: featureCollection.features
            .filter(({ properties }) =>
              filterType(type, filter[type], properties),
            )
            .filter(({ properties }) =>
              JSON.stringify(properties)
                .toLowerCase()
                .includes(search[type].toLowerCase()),
            )
            .sort(featureSortFn(sort[type])),
          total: featureCollection.features.length,
        },
      }),
      {},
    );
  }, [resourceSnapshot, locationSnapshot, search, sort, time, filter]);

  return {
    data,
    isFetching: fetchingLocations || fetchingResources,
  };
}
