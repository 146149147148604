import { getFilterOptionsFromData, isEmpty } from '@/utils';
import { useQueryClient } from '@tanstack/react-query';
import {
  sortBy,
  useActiveIntersectionChanges,
  vehicleIntersectionFilter,
} from '.';

function getVehiclesInLocationFilterValues(data, filter) {
  return getFilterOptionsFromData(data, filter, vehicleIntersectionFilter);
}

function getVehiclesInLocations(data, startTime, endTime) {
  if (!startTime || !endTime || (data || []).length === 0) {
    return [];
  }

  const locationCountChanges = data.reduce((accumulator, record) => {
    if (!(record.locationName in accumulator)) {
      accumulator[record.locationName] = {};
    }

    // quick way to change the Date() to milliseconds timestamp, this
    // is necessary so it can be used as a key, otherwise using Date()
    // as a key will be converted to a string & won't work for sorting
    // i.e. "Friday ... " < x && x < "Tuesday ... "
    const timeKey = +record.time;
    const minuteKey = timeKey - (timeKey % 60000);

    if (!(minuteKey in accumulator[record.locationName])) {
      accumulator[record.locationName][minuteKey] = {
        residentVehicles: 0,
        visitorVehicles: 0,
      };
    }

    accumulator[record.locationName][minuteKey][
      record.atHome ? 'residentVehicles' : 'visitorVehicles'
    ] += record.change;

    return accumulator;
  }, {});

  const startEpoch = +startTime; // shorthand to change Date() to epoch time
  const endEpoch = +endTime;
  const locationTimelines = Object.entries(locationCountChanges).map(
    (record) => {
      const entries = Object.entries(record[1]);

      let residentTally = 0;
      let visitorTally = 0;
      let locationTally = [];

      entries.forEach(([time, { residentVehicles, visitorVehicles }]) => {
        residentTally += residentVehicles;
        visitorTally += visitorVehicles;

        locationTally.push({
          time, //: new Date(date).getTime(),
          residentCount: residentTally,
          visitorCount: visitorTally,
        });
      });

      const values = locationTally
        .sort(sortBy('time'))
        .filter(({ time }) => startEpoch <= time && time < endEpoch);

      return {
        name: record[0],
        values,
      };
    },
  );

  return locationTimelines;
}

export function useFilteredActiveIntersectionChanges(query, filter) {
  const queryClient = useQueryClient();
  const {
    data: hourlyIntersections,
    isFetching,
    isLoading,
  } = useActiveIntersectionChanges(query);

  const filteredData = hourlyIntersections.filter((record) =>
    vehicleIntersectionFilter(record, filter),
  );

  return {
    isFetching,
    isLoading,
    filterValues: getVehiclesInLocationFilterValues(
      hourlyIntersections,
      filter,
    ),
    data: isEmpty(query)
      ? []
      : getVehiclesInLocations(
          filteredData,
          query.endTime.$gte,
          query.startTime.$lt,
        ),
    cancel: () =>
      queryClient.cancelQueries({ queryKey: ['hourlyIntersections', query] }),
  };
}
