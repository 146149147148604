import { api } from '@/apis';
import { log } from '@/utils';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { DriverScoresParams, GroupedDriverScore } from './types';

export function useGroupedDriverScores(
  params: DriverScoresParams,
): UseQueryResult {
  return useQuery({
    queryKey: ['groupedDriverScores', params],
    queryFn: async () => {
      log('Read', 'Grouped Driver Scores', params);

      const scores: GroupedDriverScore[] = await api
        .post('groupedDriverScores', { json: params })
        .json();

      return scores.sort(
        (a: GroupedDriverScore, b: GroupedDriverScore) =>
          a.date.getTime() - b.date.getTime(),
      );
    },
    staleTime: 1000 * 60 * 60,
  });
}
