import {
  Avatar,
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

export function TimelinePanel({ entries }) {
  return (
    <Stepper
      orientation="vertical"
      sx={{
        ['& .MuiStepLabel-root']: {
          py: 0,
        },
        pb: 1,
        overflow: 'auto',
      }}
    >
      {entries.map(
        ({ label, time, color, bgcolor, description, Icon }, index) => (
          <Step active key={index}>
            <StepLabel
              icon={
                Icon ? (
                  <Avatar
                    sx={{
                      width: 24,
                      height: 24,
                      fontSize: 16,
                      color,
                      bgcolor,
                    }}
                  >
                    <Icon fontSize="inherit" />
                  </Avatar>
                ) : (
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      bgcolor,
                      borderRadius: 6,
                      ml: '6.5px',
                    }}
                  />
                )
              }
              optional={
                <Typography color="text.disabled" sx={{ fontSize: 12 }}>
                  {format(time, 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
              sx={{
                '& .MuiStepLabel-label': {
                  fontSize: 12,
                  lineHeight: 1.2,
                },
              }}
            >
              {label}
            </StepLabel>
            <StepContent>
              <Typography variant="caption">{description}</Typography>
            </StepContent>
          </Step>
        ),
      )}
    </Stepper>
  );
}
