import { round } from '@/utils';
import { useQueryClient } from '@tanstack/react-query';
import { differenceInDays, format, getHours, startOfDay } from 'date-fns';
import { useHourlyIntersections, vehicleIntersectionFilter } from '.';

function getVehicleIntersectionFilterValues(data, filter) {
  const { groups: _, ...fields } = filter;
  const result = { groups: {} };
  const groups = Array.from(
    new Set([].concat(...data.map((record) => Object.keys(record.groups)))),
  );

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    result[key] = Array.from(
      new Set(
        data
          .filter((record) => vehicleIntersectionFilter(record, keyFilter))
          .map((record) => record[key]),
      ),
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  for (const group of groups) {
    const keyFilter = { ...filter, groups: { ...filter.groups, [group]: [] } };
    result.groups[group] = Array.from(
      new Set(
        data
          .filter((record) => vehicleIntersectionFilter(record, keyFilter))
          .map((record) => record.groups[group])
          .flatMap((value) => value),
      ),
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  return result;
}

function getEmptyByHourByLocation(locationNames) {
  const hours = Array(24)
    .fill()
    .map((_, index) => index);

  const byHourByBase = {};
  for (let hour of hours) {
    byHourByBase[hour] = { Hour: format(new Date(0, 0, 0, hour, 0), 'HH:mm') };
    for (let locationName of locationNames.sort()) {
      byHourByBase[hour][locationName] = 0;
    }
  }
  return byHourByBase;
}

function getVehicleIntersectionTime(data) {
  const locationsNames = Array.from(
    new Set(data.map((record) => record.locationName)),
  );
  const dates = data.map((record) => record.hour);
  const maxDate = new Date(Math.max.apply(null, dates));
  const minDate = new Date(Math.min.apply(null, dates));
  const count = differenceInDays(startOfDay(maxDate), startOfDay(minDate)) + 1;

  const byHourByBase = getEmptyByHourByLocation(locationsNames);

  for (let record of data) {
    byHourByBase[getHours(new Date(record.hour))][record.locationName] +=
      record.durationSeconds / 3600;
  }

  for (let hour in byHourByBase) {
    for (let locationName in byHourByBase[hour]) {
      if (locationName !== 'Hour') {
        byHourByBase[hour][locationName] = round(
          byHourByBase[hour][locationName] / count,
          2,
        );
      }
    }
  }

  return byHourByBase;
}

export function useFilteredHourlyIntersections(query, filter) {
  const queryClient = useQueryClient();
  const {
    data: hourlyIntersections,
    isFetching,
    isLoading,
  } = useHourlyIntersections(query);

  const filteredData = hourlyIntersections.filter((record) =>
    vehicleIntersectionFilter(record, filter),
  );

  return {
    isFetching,
    isLoading,
    filterValues: getVehicleIntersectionFilterValues(
      hourlyIntersections,
      filter,
    ),
    data: getVehicleIntersectionTime(filteredData),
    cancel: () =>
      queryClient.cancelQueries({ queryKey: ['hourlyIntersections', query] }),
  };
}
