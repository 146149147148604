import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useLegacyAuditTotals(
  startTime,
  endTime,
  users = [],
  sections = [],
  pages = [],
  operations = [],
  types = [],
) {
  const queryClient = useQueryClient();

  return {
    ...useQuery({
      queryKey: [
        'legacyAuditTotals',
        startTime,
        endTime,
        users,
        sections,
        pages,
        operations,
        types,
      ],
      queryFn: async ({ signal }) => {
        const json = [
          {
            $match: {
              LocalAuditDate: { $gte: startTime, $lt: endTime },
              UserName: users.length > 0 ? { $in: users } : undefined,
              Section: sections.length > 0 ? { $in: sections } : undefined,
              WebPage: pages.length > 0 ? { $in: pages } : undefined,
              Operation:
                operations.length > 0 ? { $in: operations } : undefined,
              EntityTypeName: types.length > 0 ? { $in: types } : undefined,
            },
          },
          {
            $group: {
              _id: null,
              total: { $sum: 1 },
            },
          },
        ];

        return api.post('pipeline/legacyAudits', { json, signal }).json();
      },
      placeholderData: [],
      staleTime: 1000 * 60 * 60,
      enabled: !!startTime && !!endTime,
    }),
    cancel: () =>
      queryClient.cancelQueries({
        queryKey: [
          'legacyAuditTotals',
          startTime,
          endTime,
          users,
          sections,
          pages,
          operations,
          types,
        ],
      }),
  };
}
