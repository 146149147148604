import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subMonths, subWeeks } from 'date-fns';

function getCounts(vehicles, today) {
  const sixMonths = vehicles.filter(
    (vehicle) => vehicle.lastPollTime < subMonths(today, 6),
  ).length;
  const oneMonth =
    vehicles.filter((vehicle) => vehicle.lastPollTime < subMonths(today, 1))
      .length - sixMonths;
  const twoWeeks = vehicles.length - oneMonth - sixMonths;

  return {
    twoWeeks,
    oneMonth,
    sixMonths,
  };
}

export function useNonPollingVehicles(groupCode, subgroupType) {
  return useQuery({
    queryKey: ['nonPollingVehicles', groupCode, subgroupType],
    queryFn: async () => {
      const now = new Date();
      const today = startOfDay(now);
      const twoWeeksAgo = subWeeks(today, 2);
      const json = [
        {
          $match: {
            lastPollTime: { $lt: twoWeeksAgo },
            $or: [
              { disposalDate: { $exists: false } },
              { disposalDate: { $gte: now } },
            ],
          },
        },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groups',
          },
        },
        groupCode && { $match: { 'groups.code': groupCode } },
        {
          $project: {
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            homeStation: true,
            lastPollTime: true,
            groups: {
              $map: {
                input: '$groups',
                as: 'group',
                in: { code: '$$group.code', type: '$$group.type' },
              },
            },
          },
        },
        { $sort: { lastPollTime: 1 } },
      ].filter(Boolean);
      const response = await api.post('pipeline/vehicles', { json }).json();
      const vehicles = response.map((vehicle) => ({
        ...vehicle,
        groups: vehicle.groups.reduce(
          (accumulator, group) => ({
            ...accumulator,
            [group.type]: [...(accumulator[group.type] ?? []), group.code],
          }),
          {},
        ),
      }));
      const counts = {};

      if (subgroupType) {
        for (const vehicle of vehicles) {
          const groups = vehicle?.groups?.[subgroupType] ?? ['None'];
          for (const group of groups) {
            counts[group] = [...(counts[group] ?? []), vehicle];
          }
        }
        for (const group in counts) {
          counts[group] = { group, ...getCounts(counts[group], today) };
        }
      }

      return {
        vehicles,
        ...getCounts(vehicles, today),
        counts,
      };
    },
    placeholderData: {
      vehicles: [],
      twoWeeks: 0,
      oneMonth: 0,
      sixMonths: 0,
      counts: {},
    },
    staleTime: 1000 * 60 * 60,
  });
}
