import { useMemo } from 'react';
import { useOptions } from './useOptions';

export function useOptionValues(name) {
  const { data: options } = useOptions(name);

  return useMemo(
    () =>
      options.reduce((accumulator, { value, label, values }) => {
        accumulator[value] = { label, values };

        return accumulator;
      }, {}),
    [options],
  );
}
