import {
  CustomTooltip,
  GroupCodesPicker,
  ScoreCard,
} from '@/components/controls';
import {
  useDocumentTitle,
  useGroupDescendants,
  useGroupedDriverScores,
  usePeople,
} from '@/hooks';
import { kilometresToMiles } from '@/utils';
import { useTheme } from '@emotion/react';
import {
  AllInclusive as AllInclusiveIcon,
  FilterList as FilterListIcon,
  Sensors as SensorsIcon,
  SensorsOff as SensorsOffIcon,
  Star as StarIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Collapse,
  Divider,
  Link,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { endOfDay, endOfMonth, endOfYear } from 'date-fns';
import {
  ArrowDownRight as ArrowDownRightIcon,
  CarBrakeAlert as CarBrakeAlertIcon,
  CarTurbocharger as CarTurbochargerIcon,
  Counter as CounterIcon,
  Speedometer as SpeedometerIcon,
  Steering as SteeringIcon,
} from 'mdi-material-ui';
import { useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Bar,
  BarChart,
  Cell,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { DriverTable } from './DriverTable';
import { ActivityTable } from './ActivityTable';
import {
  formatLabel,
  formatValue,
  getValue,
  measures,
  scoreColour,
  secondsToFirstSignificantUnit,
} from './utils';

export function DriverBehaviour() {
  useDocumentTitle('IR3 | Driver Behaviour');
  const { year, month, day } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { startDate, endDate, group } = useMemo(() => {
    if (day) {
      const startDate = new Date(year, month - 1, day);
      return {
        startDate,
        endDate: endOfDay(startDate, 1),
        group: 'day',
      };
    }

    if (month) {
      const startDate = new Date(year, month - 1, 1);

      return {
        startDate,
        endDate: endOfMonth(startDate),
        group: 'day',
      };
    }

    if (year) {
      const startDate = new Date(year, 0, 1);

      return {
        startDate,
        endDate: endOfYear(startDate),
        group: 'month',
      };
    }

    return {
      startDate: new Date(2000, 0, 1),
      endDate: new Date(2100, 0, 1),
      group: 'year',
    };
  }, [year, month, day]);
  const [
    { showFilter, measure, view, mode, groups, codes, collarNumbers, roles },
    setState,
  ] = useState({
    showFilter: false,
    measure: 'score',
    view: 'drivers',
    mode: 'total',
    groups: [],
    codes: [],
    collarNumbers: [],
    roles: [],
  });
  const { data: descendants } = useGroupDescendants();
  const allGroupCodes = useMemo(
    () =>
      groups
        .map((group) => descendants[group])
        .filter(Boolean)
        .flat(),
    [descendants, groups],
  );
  const query = useMemo(
    () => ({
      groupCodes: allGroupCodes.length > 0 ? { $in: allGroupCodes } : undefined,
      code: codes.length > 0 ? { $in: codes } : undefined,
      collarNumber:
        collarNumbers.length > 0 ? { $in: collarNumbers } : undefined,
      role: roles.length > 0 ? { $in: roles } : undefined,
    }),
    [allGroupCodes, codes, collarNumbers, roles],
  );
  const { data: totalDriverScores } = useGroupedDriverScores({
    startDate,
    endDate,
    mode,
    query,
  });
  const { data: groupedDriverScores } = useGroupedDriverScores({
    startDate,
    endDate,
    group,
    mode,
    query,
  });
  const { data: people } = usePeople();
  const codeOptions = useMemo(
    () =>
      Array.from(new Set(people.map(({ code }) => code)))
        .filter(Boolean)
        .sort(),
    [people],
  );
  const collarNumberOptions = useMemo(
    () =>
      Array.from(new Set(people.map(({ collarNumber }) => collarNumber)))
        .filter(Boolean)
        .sort(),
    [people],
  );
  const roleOptions = useMemo(
    () =>
      Array.from(new Set(people.map(({ role }) => role)))
        .filter(Boolean)
        .sort(),
    [people],
  );

  function handleFilterToggle() {
    setState((state) => ({ ...state, showFilter: !state.showFilter }));
  }

  function handleGroupsChange(groups) {
    setState((state) => ({ ...state, groups }));
  }

  function handleModeChange(event, mode) {
    setState((state) => ({ ...state, mode }));
  }

  function handleCodesChange(event, codes) {
    setState((state) => ({ ...state, codes }));
  }

  function handleCollarNumbersChange(event, collarNumbers) {
    setState((state) => ({ ...state, collarNumbers }));
  }

  function handleRolesChange(event, roles) {
    setState((state) => ({ ...state, roles }));
  }

  function handleViewChange(event, view) {
    setState((state) => ({ ...state, view }));
  }

  const handleMeasureChange = (measure) => () =>
    setState((state) => ({ ...state, measure }));

  function handleBarClick(data) {
    switch (group) {
      case 'day':
        navigate(`../driverbehaviour/${year}/${month}/${data.date.getDate()}`);
        break;
      case 'month':
        navigate(`../driverbehaviour/${year}/${data.date.getMonth() + 1}`);
        break;
      case 'year':
        navigate(`../driverbehaviour/${data.date.getFullYear()}`);
        break;
    }
  }

  return (
    <Stack
      spacing={1}
      sx={{
        backgroundColor: 'background.default',
        p: 1,
      }}
    >
      <Stack
        spacing={1}
        direction="row"
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Breadcrumbs sx={{ pr: 1 }}>
          {day && (
            <Link
              underline="hover"
              component={RouterLink}
              to={`../driverbehaviour/${year}/${month}`}
            >
              {day}
            </Link>
          )}
          {month && (
            <Link
              underline="hover"
              component={RouterLink}
              to={`../driverbehaviour/${year}`}
            >
              {month}
            </Link>
          )}
          {year && (
            <Link
              underline="hover"
              component={RouterLink}
              to="../driverbehaviour"
            >
              {year}
            </Link>
          )}
        </Breadcrumbs>
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={mode}
          exclusive
          onChange={handleModeChange}
        >
          <Tooltip title="Show all">
            <ToggleButton value="total">
              <AllInclusiveIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Show with warning equipment on">
            <ToggleButton value="emergency">
              <SensorsIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Show with warning equipment off">
            <ToggleButton value="nonEmergency">
              <SensorsOffIcon />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
          <ToggleButton
            value="filter"
            size="small"
            color="primary"
            selected={showFilter}
            onChange={handleFilterToggle}
          >
            <FilterListIcon />
          </ToggleButton>
        </Tooltip>
      </Stack>
      <Collapse in={showFilter}>
        <Stack spacing={1}>
          <GroupCodesPicker
            value={groups}
            onChange={handleGroupsChange}
            label="Groups & Areas"
          />
          <Autocomplete
            multiple
            fullWidth
            value={codes}
            onChange={handleCodesChange}
            options={codeOptions}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField label="Staff IDs" {...params} />
            )}
          />
          <Autocomplete
            multiple
            fullWidth
            value={collarNumbers}
            onChange={handleCollarNumbersChange}
            options={collarNumberOptions}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField label="Collar Numbers" {...params} />
            )}
          />
          <Autocomplete
            multiple
            fullWidth
            value={roles}
            onChange={handleRolesChange}
            options={roleOptions}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField label="Roles" {...params} />}
          />
        </Stack>
      </Collapse>
      <Stack
        direction="row"
        spacing={1}
        flexWrap="wrap"
        justifyContent="center"
      >
        <ScoreCard
          score={totalDriverScores?.[0]?.score}
          title="Score"
          unit="%"
          color={scoreColour(totalDriverScores?.[0]?.score ?? 0)}
          Icon={StarIcon}
          selected={measure === 'score'}
          onClick={handleMeasureChange('score')}
        />
        <Divider orientation="vertical" flexItem />
        <ScoreCard
          score={kilometresToMiles(
            totalDriverScores?.[0]?.distanceKilometres ?? 0,
          )}
          title="Miles"
          unit={
            Math.round(
              kilometresToMiles(
                totalDriverScores?.[0]?.distanceKilometres ?? 0,
              ),
            ) === 1
              ? 'mile'
              : 'miles'
          }
          color={theme.palette.primary.main}
          Icon={CounterIcon}
          selected={measure === 'distanceKilometres'}
          onClick={handleMeasureChange('distanceKilometres')}
        />
        <ScoreCard
          {...secondsToFirstSignificantUnit(
            totalDriverScores?.[0]?.durationSeconds ?? 0,
          )}
          title="Driving"
          color={theme.palette.primary.main}
          Icon={SteeringIcon}
          selected={measure === 'durationSeconds'}
          onClick={handleMeasureChange('durationSeconds')}
        />
        <ScoreCard
          {...secondsToFirstSignificantUnit(
            totalDriverScores?.[0]?.accelerationSeconds ?? 0,
          )}
          title="Harsh Acceleration"
          color={theme.palette.primary.main}
          Icon={CarTurbochargerIcon}
          selected={measure === 'accelerationSeconds'}
          onClick={handleMeasureChange('accelerationSeconds')}
        />
        <ScoreCard
          {...secondsToFirstSignificantUnit(
            totalDriverScores?.[0]?.brakingSeconds ?? 0,
          )}
          title="Harsh Braking"
          color={theme.palette.primary.main}
          Icon={CarBrakeAlertIcon}
          selected={measure === 'brakingSeconds'}
          onClick={handleMeasureChange('brakingSeconds')}
        />
        <ScoreCard
          {...secondsToFirstSignificantUnit(
            totalDriverScores?.[0]?.corneringSeconds ?? 0,
          )}
          title="Harsh Cornering"
          color={theme.palette.primary.main}
          Icon={ArrowDownRightIcon}
          selected={measure === 'corneringSeconds'}
          onClick={handleMeasureChange('corneringSeconds')}
        />
        <ScoreCard
          {...secondsToFirstSignificantUnit(
            totalDriverScores?.[0]?.speedingSeconds ?? 0,
          )}
          title="Speeding"
          color={theme.palette.primary.main}
          Icon={SpeedometerIcon}
          selected={measure === 'speedingSeconds'}
          onClick={handleMeasureChange('speedingSeconds')}
        />
      </Stack>
      <Box sx={{ fontSize: '0.75rem' }}>
        <ResponsiveContainer height={400}>
          <BarChart data={groupedDriverScores} margin={{ top: 16, bottom: 8 }}>
            <XAxis dataKey="date" tickFormatter={formatLabel(group)} />
            <YAxis unit={measure === 'score' ? '%' : 'h'} />
            <Bar
              name={measures[measure]?.label}
              dataKey={getValue(measure)}
              onClick={handleBarClick}
            >
              {(groupedDriverScores || []).map((entry) => (
                <Cell
                  key={entry.date}
                  cursor="pointer"
                  fill={
                    measure === 'score'
                      ? scoreColour(entry[measure])
                      : theme.palette.primary.main
                  }
                />
              ))}
            </Bar>
            <ChartTooltip
              content={
                <CustomTooltip
                  labelFormatter={formatLabel(group)}
                  valueFormatter={formatValue(measure)}
                />
              }
              wrapperStyle={{ pointerEvents: 'auto' }}
              cursor={false}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={view}
          exclusive
          onChange={handleViewChange}
        >
          <ToggleButton value="drivers">Drivers</ToggleButton>
          <ToggleButton value="activity">Activity</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {view === 'drivers' ? (
        <DriverTable
          startDate={startDate}
          endDate={endDate}
          mode={mode}
          query={query}
        />
      ) : (
        <ActivityTable
          startTime={startDate}
          endTime={endDate}
          query={query}
          mode={mode}
          excludeExempt={true}
        />
      )}
    </Stack>
  );
}
