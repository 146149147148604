import {
  FETCH_SPEED_INFRACTIONS,
  FETCH_SPEED_INFRACTIONS_CANCELLED,
  UPDATE_EVENTS_PARAMETERS,
  UPDATE_SPEED_INFRACTIONS_FILTER,
} from '@/actions';
import {
  FilterPicker,
  Parameters,
  Table,
  TablePagination,
} from '@/components/controls';
import { useDocumentTitle, useTripClassifications } from '@/hooks';
import {
  downloadCSV,
  filterLocally,
  formatGroups,
  getFilenameForDownload,
  round,
  shortHumanizer,
  startCase,
} from '@/utils';
import { events, rowsPerPageOptions } from '@/utils/config';
import {
  GetApp as GetAppIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const {
  eventFilters: { speedInfractions: eventFilters },
} = events;
const extraSpeedInfractionHeaders = [
  {
    label: 'Staff ID',
    key: 'driverCode',
    type: 'text',
    filter: true,
  },
  {
    label: 'Driver Name',
    key: 'driverName',
    type: 'text',
    filter: true,
  },
  {
    label: 'Collar Number',
    key: 'collarNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Driver Role',
    key: 'personRole',
    type: 'text',
    filter: true,
  },
  {
    label: 'Registration',
    key: 'registrationNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Fleet Number',
    key: 'fleetNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Vehicle Role',
    key: 'role',
    type: 'text',
    filter: true,
  },
  {
    label: 'Home Station',
    key: 'homeStation',
    type: 'text',
    filter: true,
  },
  {
    label: 'RFID Card',
    key: 'rfidCard.reference',
    type: 'text',
    filter: true,
  },
  {
    label: 'RFID Card Type',
    key: 'rfidCard.type',
    type: 'text',
    filter: true,
  },
  {
    label: 'RFID Card Label',
    key: 'rfidCard.label',
    type: 'text',
    filter: true,
  },
];

const headers = [
  ...extraSpeedInfractionHeaders,
  { label: 'Start Time', key: 'startTime', filter: false },
  { label: 'End Time', key: 'endTime', filter: false },
  {
    label: 'Duration',
    key: 'durationSeconds',
    format: (value) => shortHumanizer(value * 1000),
    type: 'text',
    align: 'right',
    filter: false,
  },
  { label: 'Distance (miles)', key: 'distanceMiles', filter: false },
  { label: 'Maximum Speed (mph)', key: 'maxSpeedMilesPerHour', filter: false },
  {
    label: 'Emergency Equipment Used',
    key: 'emergencyEquipmentUsed',
    filter: true,
  },
  { label: 'Infraction Count', key: 'speedInfractionCount', filter: false },
  {
    label: 'Infraction Duration',
    key: 'speedInfractionDurationSeconds',
    format: (value) => shortHumanizer(value * 1000),
    type: 'text',
    align: 'right',
    filter: false,
  },
  { label: 'Speed Infractions', key: 'speedInfractions', filter: false },
];

function TripReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleViewClick = (identifier) => () => {
    navigate(`/eventreplay/trips/${identifier}`);
  };

  return (
    <Tooltip title="View">
      <IconButton size="small" onClick={handleViewClick(entry.identifier)}>
        <PlayArrowIcon />
      </IconButton>
    </Tooltip>
  );
}

function SpeedInfractionReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleViewClick = (identifier) => () => {
    navigate(`/eventreplay/speedInfractions/${identifier}`);
  };

  return (
    <Tooltip title="View">
      <IconButton size="small" onClick={handleViewClick(entry.identifier)}>
        <PlayArrowIcon />
      </IconButton>
    </Tooltip>
  );
}

function SpeedLimits({ entry }) {
  const headers = [
    {
      label: 'Speed Limit (mph)',
      key: 'limitMilesPerHour',
      type: 'text',
    },
    ...entry.speedLimitBreakdowns.rules.map(
      (name) => (
        {
          label: `${name} (minutes)`,
          key: ['ruleDurationMinutes', name],
          type: 'number',
        },
        {
          label: `${name}`,
          key: ['ruleDurationSeconds', name],
          format: (value) => shortHumanizer(value * 1000),
          type: 'text',
          align: 'right',
        }
      ),
    ),
    {
      label: 'Maximum Speed (mph)',
      key: 'maxSpeedMilesPerHour',
      type: 'number',
    },
    {
      label: 'Maximum Excess (mph)',
      key: 'excessMilesPerHour',
      type: 'number',
    },
  ];

  return (
    <Table
      data={entry.speedLimitBreakdowns.rows}
      headers={headers}
      rowsPerPage={entry.speedLimitBreakdowns.rows.length}
      page={0}
      keyName="limitMilesPerHour"
    />
  );
}

const infractionHeaders = [
  {
    label: '',
    key: 'expand',
    type: 'expand',
    component: SpeedLimits,
  },
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
  },
  {
    label: 'Duration',
    key: 'durationSeconds',
    format: (value) => shortHumanizer(value * 1000),
    type: 'text',
    align: 'right',
    filter: false,
  },
  {
    label: 'Distance (miles)',
    key: 'distanceMiles',
    type: 'number',
  },
  {
    label: 'Maximum Speed (mph)',
    key: 'maxSpeedMilesPerHour',
    type: 'number',
  },
  {
    label: 'Maximum Excess (mph)',
    key: 'maxExcessMilesPerHour',
    type: 'number',
  },
  {
    label: '',
    key: 'replay',
    type: 'component',
    component: SpeedInfractionReplayLink,
  },
];

function Infractions({ entry }) {
  return (
    <Table
      data={entry.speedInfractions}
      headers={infractionHeaders}
      rowsPerPage={entry.speedInfractions.length}
      page={0}
      keyName="identifier"
    />
  );
}

export function SpeedInfractions() {
  useDocumentTitle('IR3 | Speed Infractions');
  const dispatch = useDispatch();
  const trips = useSelector(
    (state) => state.events.speedInfractions.list,
    dequal,
  );
  const { data: tripClassifications } = useTripClassifications();

  function calculateTotals(trips) {
    const maxSpeedPerHour = Math.max(
      ...trips.map((trip) => trip.maxSpeedMilesPerHour),
    );
    const maxExcess = Math.max(
      ...trips.map((trip) => trip.maxExcessMilesPerHour),
    );

    const totalDistance = trips
      .map((trip) => trip.distanceMiles)
      .reduce((distance, acc) => distance + acc, 0);

    const totalDuration = trips
      .map((trip) => trip.durationSeconds)
      .reduce((duration, acc) => duration + acc, 0);

    const totalInfractionDurations = trips
      .map((trip) => trip.speedInfractionDurationSeconds)
      .reduce((duration, acc) => duration + acc, 0);

    return {
      maxSpeedMilesPerHour: round(maxSpeedPerHour, 2),
      maxExcessMilesPerHour: round(maxExcess, 2),
      distanceMiles: round(totalDistance, 2),
      durationSeconds: round(totalDuration, 2),
      speedInfractionDurationSeconds: round(totalInfractionDurations, 2),
    };
  }

  const isLoading = useSelector(
    (state) => state.events.speedInfractions.isLoading,
  );
  const error = useSelector((state) => state.events.speedInfractions.error);
  const filter = useSelector(
    (state) => state.events.speedInfractions.filter,
    dequal,
  );
  const parameters = useSelector(
    (state) => state.events.speedInfractions.parameters,
    dequal,
  );
  const groups = Array.from(
    new Set(trips.flatMap((record) => Object.keys(record.groups))),
  );

  const tableHeaders = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: Infractions,
      filter: false,
    },
    ...extraSpeedInfractionHeaders,
    ...groups.map((group) => ({
      label: startCase(group),
      key: ['groups', group],
      type: 'text',
      filter: true,
    })),
    {
      label: 'Classification',
      key: 'classification',
      type: 'text',
      filter: true,
    },
    {
      label: 'Start Time',
      key: 'startTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'End Time',
      key: 'endTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'Duration',
      key: 'durationSeconds',
      format: (value) => shortHumanizer(value * 1000),
      type: 'text',
      align: 'right',
      filter: false,
    },
    {
      label: 'Distance (miles)',
      key: 'distanceMiles',
      type: 'number',
      filter: false,
    },
    {
      label: 'Maximum Speed (mph)',
      key: 'maxSpeedMilesPerHour',
      type: 'number',
      filter: false,
    },
    {
      label: 'Maximum Excess (mph)',
      key: 'maxExcessMilesPerHour',
      type: 'number',
      filter: false,
    },
    {
      label: 'Warning Equipment Used',
      key: 'emergencyEquipmentUsed',
      type: 'boolean',
      filter: true,
    },
    {
      label: 'Infraction Count',
      key: 'speedInfractionCount',
      type: 'number',
      filter: false,
    },
    {
      label: 'Infraction Duration',
      key: 'speedInfractionDurationSeconds',
      format: (value) => shortHumanizer(value * 1000),
      type: 'text',
      align: 'right',
      filter: false,
    },
    {
      label: '',
      key: 'replay',
      type: 'component',
      component: TripReplayLink,
      filter: false,
    },
  ];

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_SPEED_INFRACTIONS,
      payload: { query, tripClassifications },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_SPEED_INFRACTIONS_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_SPEED_INFRACTIONS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const allHeaders = headers
      .slice(0, 7)
      .concat(groups.map((key) => ({ label: startCase(key), key })))
      .concat(headers.slice(7));
    const filename = getFilenameForDownload(
      'Speed Infractions',
      'csv',
      parameters?.startTime,
      parameters?.endTime,
    );
    const data = filteredTrips.map((trip) => ({
      driverName: trip.driverName,
      driverCode: trip.driverCode,
      collarNumber: trip.collarNumber,
      personRole: trip.personRole,
      registrationNumber: trip.registrationNumber,
      fleetNumber: trip.fleetNumber,
      role: trip.role,
      vehicleType: trip.type,
      homeStation: trip.homeStation,
      ...formatGroups(trip.groups),
      classification: trip.classification,
      startTime: format(new Date(trip.startTime), 'yyyy-MM-dd HH:mm:ss'),
      endTime: format(new Date(trip.endTime), 'yyyy-MM-dd HH:mm:ss'),
      emergencyEquipmentUsed: trip.emergencyEquipmentUsed,
      durationSeconds: trip.durationSeconds / 86400,
      distanceMiles: round(trip.distanceMiles || 0.0, 2),
      maxSpeedMilesPerHour: round(trip.maxSpeedMilesPerHour || 0.0, 2),
      maxExcessMilesPerHour: round(trip.maxExcessMilesPerHour || 0.0, 2),
      speedInfractionCount: trip.speedInfractions.length,
      speedInfractionDurationSeconds:
        trip.speedInfractionDurationSeconds / 86400,
      speedInfractions: trip.speedInfractions
        .map(
          (speedInfraction) =>
            `Start Time:${format(
              new Date(speedInfraction.startTime),
              'yyyy-MM-dd HH:mm:ss',
            )},` +
            `End Time:${format(
              new Date(speedInfraction.endTime),
              'yyyy-MM-dd HH:mm:ss',
            )},` +
            `Duration:${round(speedInfraction.durationSeconds, 2)},` +
            `Distance (miles):${round(speedInfraction.distanceMiles, 2)},` +
            `Maximum Speed (mph):${round(
              speedInfraction.maxSpeedMilesPerHour,
              2,
            )},` +
            `Breakdown: [${speedInfraction.speedLimitBreakdowns.rows.map(
              (breakdown) =>
                `Speed Limit (mph):${breakdown.limitMilesPerHour},` +
                `Max Speed (mph):${breakdown.maxSpeedMilesPerHour.toFixed(
                  2,
                )},` +
                Object.entries(breakdown.ruleDurationMinutes)
                  .map((item) => `${item[0]} (minutes):${item[1]}`)
                  .join(),
            )}]`,
        )
        .join('\n'),
    }));

    downloadCSV(data, filename, allHeaders);
  }

  function handleParametersChange(parameters) {
    dispatch({
      type: UPDATE_EVENTS_PARAMETERS,
      payload: {
        eventType: 'speedInfractions',
        parameters,
      },
    });
  }

  const filteredTrips = filterLocally(filter, trips);

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Parameters
        collection="speedInfractions"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        sx={{ mt: 1, width: 264 }}
        value={parameters}
        onChange={handleParametersChange}
        vehicle
        driver
        eventFilters={eventFilters}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Speed Infractions
          </Typography>
          <FilterPicker
            headers={tableHeaders}
            data={trips}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <Tooltip title="Download data">
            <Box component="span">
              <IconButton
                disabled={filteredTrips.length === 0}
                onClick={handleDownloadClick}
                size="large"
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 172px)',
                overflowY: 'scroll',
              },
              table: {
                minWidth: 750,
              },
            }}
            data={filteredTrips}
            headers={tableHeaders}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            keyName="identifier"
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
            totals={calculateTotals(trips)}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredTrips.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Box>
  );
}
