import { stripKey } from '@/utils';
import { Autocomplete, Chip, TextField } from '@mui/material';

export function GroupCodesField({
  input: { onChange, value, ...input },
  meta: { error },
  groupOptions,
}) {
  function handleChange(event, option) {
    onChange(option);
  }

  return (
    <Autocomplete
      {...input}
      value={value || []}
      fullWidth
      size="small"
      multiple
      error={error}
      onChange={handleChange}
      options={Object.keys(groupOptions || {})}
      groupBy={(option) => groupOptions?.[option].type}
      getOptionLabel={(option) => groupOptions?.[option]?.name ?? option}
      // isOptionEqualToValue={(option, value) =>
      //   value ? option.value === value : false
      // }
      sx={{ minWidth: 200 }}
      renderInput={(params) => <TextField label="Groups & Areas" {...params} />}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option}
            label={groupOptions?.[option]?.name}
            icon={
              <Chip
                color="primary"
                label={groupOptions?.[option]?.type}
                size="small"
              />
            }
            {...stripKey(getTagProps({ index }))}
          />
        ))
      }
    />
  );
}
