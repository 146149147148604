import {
  CREATE_VEHICLE,
  DELETE_VEHICLE,
  FETCH_HOME_STATIONS,
  FETCH_VEHICLE,
  FETCH_VEHICLE_SUCCESS,
  UPDATE_EVENTS_PARAMETERS,
  UPDATE_VEHICLE,
} from '@/actions';
import { commissioningStateAtom } from '@/atoms';
import { GroupAncestors, Section, TagControl } from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import {
  AutosuggestField,
  AvatarField,
  DateTimeField,
  Field,
  GroupCodesField,
  GroupsField,
  SelectField,
  SelectMultipleField,
  StyledField,
  SwitchField,
  TextField,
  isDateValidOrEmpty,
} from '@/components/fields';
import {
  useDocumentTitle,
  useGroupOptions,
  useOptionValues,
  useOptions,
  useUserInfo,
  useVehicles,
} from '@/hooks';
import { doesIdExist, imeiValid, isAuthorised } from '@/utils';
import { useDallasKeys, vehicleForm } from '@/utils/config';
import { DirectionsCar as CarIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { format } from 'date-fns';
import { dequal } from 'dequal';
import { useAtom } from 'jotai';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { TelematicsBoxVehicles } from '../telematics-boxes';
import { VehicleKeyFields } from './VehicleKeyFields';

const NO_DRIVER_ID = 'Not installed';

export function Vehicle() {
  const [, setCommissioningState] = useAtom(commissioningStateAtom);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicles.vehicle, dequal);
  const error = useSelector((state) => state.vehicles.error);
  const homeStationNames = useSelector(
    (state) => state.locations.homeStationNames,
    dequal,
  );
  const groupOptions = useGroupOptions();
  const typeOptions = useOptionValues('groupType');
  const { data: vehicleTypes } = useOptions('vehicleType');
  const { data: vehicleFuelTypes } = useOptions('vehicleFuelType');
  const { data: vehicleEquipment } = useOptions('vehicleEquipment');
  const { data: activeDirectoryGroups } = useOptions('activeDirectoryGroup');
  const { data: vehicleAttributes } = useOptions('vehicleAttribute');
  const vehicleGroups = useOptionValues('vehicleGroup');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'vehicles', true);
  const canCommission = userInfo?.authorisation?.commissioning.write && canEdit;
  const { data: vehicles } = useVehicles();
  const installLocations = useMemo(
    () =>
      [...new Set(vehicles.map((v) => v.installLocation))]
        .filter(Boolean)
        .sort()
        .map((location) => ({ label: location, value: location })),
    [vehicles],
  );
  const driverIdLocations = useMemo(
    () =>
      [...new Set(vehicles.map((v) => v.driverIdLocation))]
        .filter(Boolean)
        .sort()
        .map((location) => ({ label: location, value: location })),
    [vehicles],
  );
  useDocumentTitle(
    `IR3 | Vehicle${
      vehicle?.registrationNumber ? ` | ${vehicle.registrationNumber}` : ''
    }`,
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_VEHICLE_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_VEHICLE,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
  }, [id, dispatch]);

  async function onSubmit(values) {
    const validImei = await imeiValid(
      values.telematicsBoxImei,
      values.identificationNumber,
    );
    if (!validImei) {
      return { telematicsBoxImei: 'In use' };
    }

    if (vehicle) {
      dispatch({
        type: UPDATE_VEHICLE,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('vehicles', values.identificationNumber);
      if (exists) {
        return { identificationNumber: 'Exists' };
      }

      dispatch({
        type: CREATE_VEHICLE,
        payload: {
          ...values,
          redirect: true,
        },
        navigate,
      });
    }
  }

  function handleEventsClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleEventsClose() {
    setAnchorEl(null);
  }

  function handleDelete() {
    if (vehicle) {
      dispatch({
        type: DELETE_VEHICLE,
        payload: vehicle.identificationNumber,
        navigate,
      });
    }
  }

  function isDisabled(fieldName) {
    return !(canEdit && (vehicleForm[fieldName]?.editable || vehicle === null));
  }

  const navigateToEvents = (eventType, pathname) => () => {
    dispatch({
      type: UPDATE_EVENTS_PARAMETERS,
      payload: {
        eventType,
        parameters: {
          'vehicle.identificationNumber': {
            condition: 'includes',
            selection: [id],
          },
        },
      },
    });

    navigate(`/events/vehicles/${pathname || eventType.toLowerCase()}`);
  };

  const noVehicle = vehicle === null;
  const needToRemoveAssociation = !!vehicle?.telematicsBoxImei;

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <Form
        initialValues={vehicle || {}}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          form: { reset },
          submitting,
          dirty,
          pristine,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={1}>
              <CardHeader
                avatar={
                  <Field
                    name="picture"
                    component={AvatarField}
                    icon={<CarIcon />}
                    disabled={isDisabled('picture')}
                  />
                }
                title={values.registrationNumber}
                subheader={values.fleetNumber}
              />
              <Box>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <Section>
                  <VehicleKeyFields vehicle={vehicle} />
                </Section>
                <Divider />
                {values.visibleTo && (
                  <Fragment>
                    <ListSubheader disableSticky>Visible to</ListSubheader>
                    <Section>
                      <Field
                        name="visibleTo"
                        sx={{ pb: 1 }}
                        fullWidth
                        component={SelectMultipleField}
                        suggestions={activeDirectoryGroups}
                        disabled={isDisabled('visibleTo')}
                      />
                    </Section>
                    <Divider />
                  </Fragment>
                )}
                <ListSubheader disableSticky>Details</ListSubheader>
                <Section>
                  <StyledField
                    name="type"
                    component={SelectField}
                    label="Type"
                    values={vehicleTypes}
                    disabled={isDisabled('type')}
                  />
                  <StyledField
                    name="make"
                    component={TextField}
                    label="Make"
                    disabled={isDisabled('make')}
                  />
                  <StyledField
                    name="model"
                    component={TextField}
                    label="Model"
                    disabled={isDisabled('model')}
                  />
                  <StyledField
                    name="colour"
                    component={TextField}
                    label="Colour"
                    disabled={isDisabled('colour')}
                  />
                  <StyledField
                    name="fuelType"
                    component={SelectField}
                    label="Fuel Type"
                    values={vehicleFuelTypes}
                    disabled={isDisabled('fuelType')}
                  />
                  <StyledField
                    name="keyNumber"
                    component={TextField}
                    label="Key Number"
                    type="number"
                    disabled={isDisabled('keyNumber')}
                  />
                  <StyledField
                    name="marked"
                    component={SwitchField}
                    label="Marked"
                    disabled={isDisabled('marked')}
                  />
                </Section>
                {vehicleAttributes.length > 0 && (
                  <Fragment>
                    <Divider />
                    <ListSubheader disableSticky>Attributes</ListSubheader>
                    <Section>
                      {vehicleAttributes.map(({ value, label, values }) => (
                        <StyledField
                          key={value}
                          name={`attributes.${value}`}
                          component={values ? SelectField : TextField}
                          label={label}
                          values={values}
                          disabled={!canEdit}
                        />
                      ))}
                    </Section>
                  </Fragment>
                )}
                <Divider />
                <ListSubheader disableSticky>Home</ListSubheader>
                <Section>
                  <StyledField
                    name="homeStation"
                    component={SelectField}
                    label="Station"
                    values={homeStationNames.map((station) => ({
                      label: station.name,
                      value: station.code,
                    }))}
                    InputProps={{
                      readOnly: !canEdit,
                    }}
                    disabled={isDisabled('homeStation')}
                  />
                  <Field
                    name="groupCodes"
                    component={GroupCodesField}
                    groupOptions={groupOptions}
                    disabled={isDisabled('groupCodes')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>
                  Ancestor Groups & Areas
                </ListSubheader>
                <Section>
                  <GroupAncestors
                    groupAncestors={values?.groupAncestors}
                    typeOptions={typeOptions}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Categories</ListSubheader>
                <Section>
                  <Field
                    name="groups"
                    groups={vehicleGroups}
                    component={GroupsField}
                    disabled={isDisabled('groups')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Equipment</ListSubheader>
                <Section>
                  <Field
                    name="equipment"
                    sx={{ mb: 1 }}
                    fullWidth
                    component={SelectMultipleField}
                    suggestions={vehicleEquipment}
                    disabled={isDisabled('equipment')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Telematics Box</ListSubheader>
                <Box sx={{ px: 2 }}>
                  <TelematicsBoxVehicles
                    identificationNumber={vehicle?.identificationNumber}
                  />
                  <Section sx={{ pt: 1 }}>
                    <StyledField
                      variant="standard"
                      label="Last Poll Time"
                      name="lastPollTime"
                      component={TextField}
                      format={(value) =>
                        value
                          ? format(new Date(value), 'dd/MM/yyyy HH:mm:ss')
                          : 'never'
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                    />
                    {canCommission && (
                      <FormControl>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={
                            noVehicle || needToRemoveAssociation || dirty
                          } // save vehicle info first so no conflict
                          onClick={() =>
                            setCommissioningState({
                              open: true,
                              vehicle,
                              redirect: false,
                            })
                          }
                        >
                          commission new
                        </Button>
                        <FormHelperText>
                          {(() => {
                            if (noVehicle || dirty) {
                              return 'Must save vehicle first';
                            } else if (needToRemoveAssociation) {
                              return 'Remove existing association first';
                            }
                          })()}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Section>
                </Box>
                <Section>
                  <StyledField
                    name="installLocation"
                    suggestions={installLocations}
                    label="Unit Location"
                    component={AutosuggestField}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                  />
                  <StyledField
                    name="driverIdLocation"
                    suggestions={driverIdLocations.concat({
                      label: NO_DRIVER_ID,
                      value: NO_DRIVER_ID,
                    })}
                    label={`${
                      useDallasKeys ? 'Dallas Key' : 'RFID Card'
                    } Reader Location`}
                    component={AutosuggestField}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                  />
                  <StyledField
                    name="commissionDate"
                    component={DateTimeField}
                    label="Install Date & Time"
                    validate={isDateValidOrEmpty}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Notes</ListSubheader>
                <Section>
                  <Field
                    fullWidth
                    sx={{ mb: 1 }}
                    name="notes"
                    placeholder="Add notes..."
                    component={TextField}
                    multiline
                    minRows={4}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Odometer</ListSubheader>
                <Section>
                  <StyledField
                    name="lastOdometerReading.time"
                    component={DateTimeField}
                    label="Reading Date & Time"
                    disabled={isDisabled('lastOdometerReading')}
                    validate={isDateValidOrEmpty}
                  />
                  <StyledField
                    name="lastOdometerReading.distanceMiles"
                    type="number"
                    component={TextField}
                    label="Reading Miles"
                    disabled={isDisabled('lastOdometerReading')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Disposed</ListSubheader>
                <Section>
                  <StyledField
                    name="disposalDate"
                    component={DateTimeField}
                    label="Date & Time"
                    disabled={isDisabled('disposalDate')}
                    validate={isDateValidOrEmpty}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Tags</ListSubheader>
                {/* TODO: add/remove tag items should be part of the form and control by save/cancle buttons  */}
                <Section>
                  <TagControl
                    item={{ id }}
                    type={'vehicles'}
                    sx={{ pb: 1 }}
                    disabled={id === 'new' || !id}
                  />
                </Section>
              </Box>
              <CardActions>
                {canEdit && (
                  <Fragment>
                    <Button
                      color="primary"
                      //type="submit"
                      onClick={handleSubmit}
                      disabled={pristine || submitting}
                    >
                      Save
                    </Button>
                    <Button
                      color="primary"
                      disabled={pristine || submitting}
                      onClick={reset}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      aria-owns={anchorEl ? 'events-menu' : undefined}
                      aria-haspopup="true"
                      onClick={handleEventsClick}
                      disabled={dirty || submitting || vehicle === null}
                    >
                      Events
                    </Button>
                    <Menu
                      id="events-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleEventsClose}
                    >
                      <MenuItem onClick={navigateToEvents('trips')}>
                        Trips
                      </MenuItem>
                      <MenuItem onClick={navigateToEvents('stops')}>
                        Stops
                      </MenuItem>
                      <MenuItem onClick={navigateToEvents('idles')}>
                        Idles
                      </MenuItem>
                      <MenuItem onClick={navigateToEvents('speedInfractions')}>
                        Speed Infractions
                      </MenuItem>
                      <MenuItem
                        onClick={navigateToEvents('accelerometerEvents')}
                      >
                        Accelerometer Events
                      </MenuItem>
                      <MenuItem
                        onClick={navigateToEvents(
                          'vehicleLocationVisits',
                          'locationVisits',
                        )}
                      >
                        Location Visits
                      </MenuItem>
                      <MenuItem
                        onClick={navigateToEvents('malfunctionIndicatorLight')}
                      >
                        Malfunction Indicator Light
                      </MenuItem>
                      <MenuItem
                        onClick={navigateToEvents('onBoardDiagnostics')}
                      >
                        On-Board Diagnostics
                      </MenuItem>
                    </Menu>
                    <Button
                      color="error"
                      onClick={() => setDeleteOpen(true)}
                      disabled={vehicle === null}
                    >
                      Delete
                    </Button>
                  </Fragment>
                )}
              </CardActions>
            </Stack>
            <ConfirmationDialog
              action="Delete"
              open={deleteOpen}
              itemId={values.registrationNumber || values.fleetNumber}
              onOk={handleDelete}
              onCancel={() => setDeleteOpen(false)}
            />
          </form>
        )}
      />
    </Box>
  );
}
