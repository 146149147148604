import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useRetentions() {
  return useQuery({
    queryKey: ['retentions'],
    queryFn: () => {
      const json = [
        {
          $lookup: {
            from: 'vehicles',
            localField: 'imei',
            foreignField: 'telematicsBoxImei',
            as: 'vehicle',
          },
        },
        { $set: { vehicle: { $first: '$vehicle' } } },
        {
          $project: {
            identifier: true,
            imei: true,
            startTime: true,
            endTime: true,
            notes: true,
            vehicle: {
              identificationNumber: true,
              registrationNumber: true,
              fleetNumber: true,
              groups: true,
              role: true,
              type: true,
            },
          },
        },
      ];

      log('Read', 'Data Retentions');

      return api.post('pipeline/telematicsBoxRetentions', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
