import { Button, Divider, Stack, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AliasPickerController,
  BoundaryEditorController,
  GroupAncestors,
  GroupCodesController,
  TextFieldController,
} from '../controls';
import { ConfirmationDialog } from '../dialogs';

export function GroupForm({ defaultValues, onSubmit, onDelete, typeOptions }) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const {
    control,
    formState: { dirtyFields, isSubmitting },
    getValues,
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues });
  const isDirty = Object.keys(dirtyFields).length > 0;

  function handleCancel() {
    reset();
  }

  function toggleDeleteDialog() {
    setDeleteOpen((prev) => !prev);
  }

  return (
    <Fragment>
      <form id="group-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Typography variant="h6" sx={{ p: 1 }}>
            {defaultValues?._id ? defaultValues.name : 'New Group'}
          </Typography>
          <Stack spacing={1} direction="row" flexWrap="wrap">
            <TextFieldController
              name="name"
              label="Name"
              control={control}
              rules={{ required: 'Required', maxLength: 200 }}
            />
            <TextFieldController
              name="type"
              label="Type"
              options={Object.entries(typeOptions).map(
                ([value, { label }]) => ({ label, value }),
              )}
              control={control}
              rules={{ required: 'Required' }}
            />
            {typeOptions[watch('type')]?.values && (
              <TextFieldController
                name="subtype"
                label="Subtype"
                options={[
                  ...(typeOptions[getValues('type')]?.values ?? []),
                ].sort((a, b) => (a.label ?? a).localeCompare(b.label ?? b))}
                control={control}
                clearable
              />
            )}
          </Stack>
          <GroupCodesController
            name="parentCodes"
            control={control}
            label="Groups"
          />
          {defaultValues?.ancestors?.length > 0 && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Ancestors
                </Typography>
              </Divider>
              <GroupAncestors
                groupAncestors={defaultValues?.ancestors}
                typeOptions={typeOptions}
              />
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Aliases
            </Typography>
          </Divider>
          <AliasPickerController name="aliases" control={control} />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Boundary
            </Typography>
          </Divider>
          <BoundaryEditorController name="boundary" control={control} />
          <Stack spacing={1} direction="row">
            <Button
              color="primary"
              type="submit"
              disabled={!isDirty || isSubmitting}
            >
              Save
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || isSubmitting}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              color="error"
              onClick={toggleDeleteDialog}
              disabled={!watch('_id')}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </form>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={defaultValues?.name || defaultValues?.code}
        onOk={onDelete}
        onCancel={toggleDeleteDialog}
      />
    </Fragment>
  );
}
