import { getAllValues } from '@/utils';
import { Stack } from '@mui/material';
import { FilterField } from './FilterField';
import { useOptions } from '@/hooks';

export function EventFilter({
  value = {},
  onChange,
  featureCollection = { features: [] },
}) {
  const { data: vehicleGroups } = useOptions('vehicleGroup');

  const handleFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      [field]: update,
    });
  };

  return (
    <Stack spacing={1} sx={{ maxHeight: 238, overflow: 'scroll', pt: 0.75 }}>
      <FilterField
        label="Registration"
        value={value.registrationNumber}
        onChange={handleFilterChange('registrationNumber')}
        options={getAllValues(
          featureCollection.features,
          'properties.vehicle.registrationNumber',
        )}
      />
      <FilterField
        label="Fleet #"
        value={value.fleetNumber}
        onChange={handleFilterChange('fleetNumber')}
        options={getAllValues(
          featureCollection.features,
          'properties.vehicle.fleetNumber',
        )}
      />
      <FilterField
        label="Role"
        value={value.role}
        onChange={handleFilterChange('role')}
        options={getAllValues(
          featureCollection.features,
          'properties.vehicle.role',
        )}
      />
      <FilterField
        label="Home Station"
        value={value.homeStation}
        onChange={handleFilterChange('homeStation')}
        options={getAllValues(
          featureCollection.features,
          'properties.vehicle.homeStation',
        )}
      />
      {vehicleGroups.map(({ value: type, label }) => (
        <FilterField
          key={type}
          label={label}
          value={value[type]}
          onChange={handleFilterChange(type)}
          options={getAllValues(
            featureCollection.features,
            `properties.vehicle.groups.${type}`,
          )}
        />
      ))}
    </Stack>
  );
}
