import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { addSeconds, differenceInSeconds, startOfSecond } from 'date-fns';

const groupTypes = new Map([
  ['vehicle', 'vehicles'],
  ['location', 'locations'],
  ['event', 'events'],
  ['incident', 'incidents'],
  ['person', 'people'],
  ['objective', 'objectives'],
]);

function getInitialFeature(initialState) {
  const { stateKey, point, position, boundary, stateType, ...properties } =
    initialState;
  return {
    type: 'Feature',
    id: stateKey,
    geometry: point || position || boundary,
    properties: { ...properties, type: stateType },
  };
}

function getPaths(initialStates, stateChanges, type) {
  return initialStates
    .filter((state) => type === state.stateType)
    .map((state) => {
      const initialFeature = getInitialFeature(state);
      return {
        id: initialFeature.id,
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            initialFeature.geometry?.coordinates,
            ...stateChanges
              .filter(
                (change) =>
                  change.stateKey === initialFeature.id && change.position,
              )
              .map((change) => change.position.coordinates),
          ].filter(Boolean), // the initial features may not have a geometry
        },
      };
    });
}

export function useEventReplay(type, id) {
  return useQuery({
    queryKey: ['replay', type, id],
    queryFn: async ({ signal }) => {
      const { initialStates, stateChanges } = await api
        .get(`replay/${type}/${id}`, { signal })
        .json();

      // a location's start/end shouldn't determine the replay start/end so let's
      // get the min/max of moving object times and clamp the non-moving to it
      const movingObjects = ['vehicle', 'person'];
      let { startTime, endTime } = stateChanges[0];
      stateChanges
        .filter((change) => movingObjects.includes(change.stateType))
        .forEach((change) => {
          startTime =
            startTime > change.startTime ? change.startTime : startTime;
          endTime = endTime < change.endTime ? change.endTime : endTime;
        });

      // update any locations to start when the earliest moving object starts
      stateChanges
        .filter((change) => !movingObjects.includes(change.stateType))
        .forEach((change) => {
          change.startTime =
            startTime > change.startTime ? startTime : change.startTime;
          change.endTime = endTime < change.endTime ? endTime : change.endTime;
        });

      const replay = Array.from(
        new Set(
          stateChanges
            .map((stateChange) =>
              startTime > stateChange.startTime
                ? startTime
                : stateChange.startTime,
            )
            .sort((a, b) => a - b),
        ),
      ).reduce(
        (replay, time) => {
          const frames = replay.frames.concat(
            stateChanges
              .filter(
                (stateChange) =>
                  stateChange.startTime <= time && time < stateChange.endTime,
              )
              .reduce(
                (frame, stateChange) => {
                  const { stateKey, point, position, boundary, ...properties } =
                    stateChange;
                  const groupType = groupTypes.get(stateChange.stateType);

                  if (!(groupType in frame.featureCollections)) {
                    frame.featureCollections[groupType] = {
                      type: 'FeatureCollection',
                      features: [],
                    };
                  }

                  const index = frame.featureCollections[
                    groupType
                  ].features.findIndex((feature) => feature.id === stateKey);

                  if (index === -1) {
                    const initialState = initialStates.find(
                      (state) => state.stateKey === stateKey,
                    );
                    const initialFeature = getInitialFeature(initialState);
                    frame.featureCollections[groupType].features =
                      frame.featureCollections[groupType].features.concat({
                        ...initialFeature,
                        geometry:
                          point ||
                          position ||
                          boundary ||
                          initialFeature.geometry,
                        properties: {
                          ...initialFeature.properties,
                          ...properties,
                        },
                      });
                  } else {
                    const existingFeature =
                      frame.featureCollections[groupType].features[index];
                    frame.featureCollections[groupType].features =
                      frame.featureCollections[groupType].features
                        .slice(0, index)
                        .concat({
                          ...existingFeature,
                          geometry:
                            point ||
                            position ||
                            boundary ||
                            existingFeature.geometry,
                          properties: {
                            ...existingFeature.properties,
                            ...properties,
                          },
                        })
                        .concat(
                          frame.featureCollections[groupType].features.slice(
                            index + 1,
                          ),
                        );
                  }

                  return frame;
                },
                {
                  time,
                  featureCollections: {},
                },
              ),
          );

          const vehiclePaths = getPaths(initialStates, stateChanges, 'vehicle');
          const personPaths = getPaths(initialStates, stateChanges, 'person');
          let paths = {};
          if (vehiclePaths.length > 0) {
            paths.vehicles = {
              type: 'FeatureCollection',
              features: vehiclePaths,
            };
          }
          if (personPaths.length > 0) {
            paths.people = {
              type: 'FeatureCollection',
              features: personPaths,
            };
          }

          return {
            ...replay,
            frames,
            paths,
          };
        },
        {
          id,
          frames: [],
          paths: {
            vehicles: {
              type: 'FeatureCollection',
              features: [],
            },
            people: {
              type: 'FeatureCollection',
              features: [],
            },
          },
        },
      );

      let frameBySecond = {},
        s = 0;
      let start = startOfSecond(new Date(startTime));
      replay.frames.forEach((frame, index) => {
        // how many seconds after the start is this frame?
        const diff = differenceInSeconds(new Date(frame.time), start);

        // fill in all the seconds with the previous frame (-1 is ok as )
        for (let i = 0; i < diff; s++, i++) {
          frameBySecond[s] = index - 1;
        }

        start = addSeconds(start, diff);
      });
      frameBySecond[++s] = replay.frames.length - 1;

      log('View', 'Replay', { id, type });

      return {
        ...replay,
        frameBySecond,
      };
    },
    placeholderData: { frames: [], paths: [], frameBySecond: [] },
    staleTime: 1000 * 60 * 60,
    enabled: !!type && !!id,
  });
}
