import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subMonths, subYears } from 'date-fns';

export function useIncidentCountByGrade(groupType, groupCode) {
  return useQuery({
    queryKey: ['incidentCountByGrade', groupType, groupCode],
    queryFn: () => {
      const today = startOfDay(new Date());
      const oneYearAgo = subYears(startOfDay(today), 1);
      const sixMonthsAgo = subMonths(startOfDay(today), 6);
      const oneMonthAgo = subMonths(startOfDay(today), 1);
      const json = [
        {
          $match: {
            openedTime: { $gt: oneYearAgo },
            [`ward.groups.${groupType}`]: groupCode,
          },
        },
        {
          $group: {
            _id: '$grade',
            oneYear: { $sum: 1 },
            sixMonths: {
              $sum: {
                $cond: [{ $gte: ['$openedTime', sixMonthsAgo] }, 1, 0],
              },
            },
            oneMonth: {
              $sum: { $cond: [{ $gte: ['$openedTime', oneMonthAgo] }, 1, 0] },
            },
          },
        },
        {
          $project: {
            _id: false,
            grade: '$_id',
            oneYear: true,
            sixMonths: true,
            oneMonth: true,
          },
        },
        { $sort: { grade: 1 } },
      ];

      return api.post('pipeline/incidents', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
