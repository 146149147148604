import { SearchBox } from '@/components/controls';
import { Person as PersonIcon } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';

export function PersonDialog({ onClose, open, people }) {
  const [id, setId] = useState('');
  const [filterText, setFilterText] = useState('');

  function handleCancel() {
    onClose(null);
  }

  function handleSelect() {
    onClose(id);
  }

  function handleListItemClick(event) {
    setId(event.currentTarget.dataset.id);
  }

  function handleListItemDoubleClick(event) {
    onClose(event.currentTarget.dataset.id);
  }

  function handleFilterTextChange(event) {
    setFilterText(event.target.value);
  }

  const searchFilter = (person) =>
    filterText &&
    (`${person.forenames || ''} ${person.surname || ''}`
      .toLowerCase()
      .includes(filterText.toLowerCase()) ||
      (person.collarNumber || '')
        .toLowerCase()
        .includes(filterText.toLowerCase()));

  return (
    <Dialog onClose={handleCancel} open={open}>
      <SearchBox
        value={filterText}
        onChange={handleFilterTextChange}
        sx={{ p: 1 }}
      />
      <DialogContent sx={{ p: 0 }}>
        <List disablePadding>
          {people
            .filter(searchFilter)
            .slice(0, 5)
            .map((person) => (
              <ListItemButton
                data-id={person.code}
                onClick={handleListItemClick}
                onDoubleClick={handleListItemDoubleClick}
                selected={id === person.code}
                key={person.code}
              >
                <ListItemAvatar>
                  <Avatar src={person.picture}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${person.forenames || ''}  ${person.surname || ''}`}
                  secondary={person.collarNumber}
                />
              </ListItemButton>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button disabled={id === ''} onClick={handleSelect} color="primary">
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}
