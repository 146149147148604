import {
  CREATE_PERSON,
  DELETE_PERSON,
  FETCH_HOME_STATIONS,
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  FETCH_WARDS,
  UPDATE_EVENTS_PARAMETERS,
  UPDATE_PERSON,
} from '@/actions';
import { GroupAncestors, Section, TagControl } from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import {
  AutosuggestField,
  AvatarField,
  ChipField,
  CounterTextField,
  DateTimeField,
  Field,
  GroupCodesField,
  GroupsField,
  SelectField,
  SelectMultipleField,
  StyledField,
  SwitchField,
  TextField,
  TypesField,
  isDateValidOrEmpty,
  required,
} from '@/components/fields';
import {
  useDocumentTitle,
  useGroupOptions,
  useOptionLookup,
  useOptionValues,
  useOptions,
  useUserInfo,
} from '@/hooks';
import { doesIdExist, getRfidErrors, isAuthorised, ssiValid } from '@/utils';
import {
  isFleet,
  personForm,
  useDallasKeys,
  useRestricted,
  useRfidCardCategory,
} from '@/utils/config';
import { Person as PersonIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardHeader,
  Divider,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { format } from 'date-fns';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export function Person() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const person = useSelector((state) => state.people.person, dequal);
  const error = useSelector((state) => state.people.error);
  const people = useSelector((state) => state.people.people, dequal);
  const homeStationNames = useSelector(
    (state) => state.locations.homeStationNames,
    dequal,
  );
  const wards = useSelector((state) => state.locations.wardNames, dequal);
  useDocumentTitle(
    [
      'IR3',
      'People',
      [person?.forenames, person?.surname].filter(Boolean).join(' '),
    ]
      .filter(Boolean)
      .join(' | '),
  );
  const groupOptions = useGroupOptions();
  const typeOptions = useOptionValues('groupType');
  const { data: personRoles } = useOptions('personRole');
  const { data: personRanks } = useOptions('personRank');
  const { data: activeDirectoryGroups } = useOptions('activeDirectoryGroup');
  const { data: personAttributes } = useOptions('personAttribute');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'people', true);
  const personGroups = useOptionValues('personGroup');
  const personSkills = useOptionValues('personSkill');
  const tripClassifications = useOptionLookup('tripClassification');

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_PERSON_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_PERSON,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
    dispatch({
      type: FETCH_WARDS,
    });
  }, [id, dispatch]);

  async function onSubmit(values) {
    const validSsi = await ssiValid(values.radioSsi, values.code);
    if (!validSsi) {
      return { radioSsi: 'In use' };
    }

    const rfidErrors = await getRfidErrors(values.code, values.rfidCards);

    if (rfidErrors) {
      return { rfidCards: rfidErrors };
    }

    if (person) {
      dispatch({
        type: UPDATE_PERSON,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('people', values.code);
      if (exists) {
        return { code: 'Exists' };
      }

      dispatch({
        type: CREATE_PERSON,
        payload: values,
        navigate,
      });
    }
  }

  function handleEventsClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleEventsClose() {
    setAnchorEl(null);
  }

  const navigateToEvents = (eventType, pathname, type) => () => {
    const key = type === 'people' ? 'person.code' : 'driver.code';
    dispatch({
      type: UPDATE_EVENTS_PARAMETERS,
      payload: {
        eventType,
        parameters: {
          [key]: {
            condition: 'includes',
            selection: [id],
          },
        },
      },
    });

    navigate(`/events/${type}/${pathname || eventType.toLowerCase()}`);
  };

  function handleDelete() {
    if (person) {
      dispatch({
        type: DELETE_PERSON,
        payload: person.code,
        navigate,
      });
    }
  }

  function isDisabled(fieldName) {
    return !(canEdit && (personForm[fieldName]?.editable || person === null));
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <Form
        initialValues={person || {}}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          form: { reset },
          submitting,
          dirty,
          pristine,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={1}>
              <CardHeader
                avatar={
                  <Field
                    name="picture"
                    component={AvatarField}
                    icon={<PersonIcon />}
                    disabled={isDisabled('picture')}
                  />
                }
                title={`${values.forenames || ''}  ${values.surname || ''}`}
                subheader={values.collarNumber}
              />
              <Box>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <Section>
                  <StyledField
                    name="code"
                    component={CounterTextField}
                    label="Payroll Number"
                    validate={required}
                    disabled={!(canEdit && person === null)}
                    {...personForm.code.componentProps}
                  />
                  <StyledField
                    name="forenames"
                    component={CounterTextField}
                    label="Forenames"
                    validate={required}
                    disabled={isDisabled('forenames')}
                    {...personForm.forenames.componentProps}
                  />
                  <StyledField
                    name="surname"
                    component={CounterTextField}
                    label="Surname"
                    validate={required}
                    disabled={isDisabled('surname')}
                    {...personForm.surname.componentProps}
                  />
                  <StyledField
                    name="collarNumber"
                    component={CounterTextField}
                    label="Collar Number"
                    disabled={isDisabled('collarNumber')}
                    {...personForm.collarNumber.componentProps}
                  />
                  <StyledField
                    name="rank.code"
                    component={SelectField}
                    label="Rank"
                    values={personRanks}
                    disabled={isDisabled('rank')}
                  />
                  <StyledField
                    name="role"
                    component={SelectField}
                    label="Role"
                    values={personRoles}
                    disabled={isDisabled('role')}
                  />
                  <StyledField
                    name="emailAddress"
                    type="email"
                    component={TextField}
                    label="Email"
                    disabled={isDisabled('emailAddress')}
                  />
                  <StyledField
                    name="mobileNumber"
                    type="tel"
                    component={TextField}
                    label="Mobile Number"
                    disabled={isDisabled('mobileNumber')}
                  />
                  <StyledField
                    name="supervisorCode"
                    component={AutosuggestField}
                    label="Supervisor"
                    disabled={isDisabled('supervisorCode')}
                    suggestions={people
                      .sort((a, b) =>
                        `${a.surname}, ${a.forenames}`.localeCompare(
                          `${b.surname}, ${b.forenames}`,
                        ),
                      )
                      .map((person) => ({
                        label: `${person.forenames} ${person.surname}`,
                        value: person.code,
                      }))}
                  />
                  <StyledField
                    name="leavingDate"
                    component={DateTimeField}
                    label="Leaving Date"
                    disabled={isDisabled('leavingDate') || id === 'new'}
                    validate={isDateValidOrEmpty}
                  />
                  <StyledField
                    name="assignments.callSign.code"
                    component={TextField}
                    label="Call Sign"
                    disabled={isDisabled('callSign')}
                  >
                    {(props) => (
                      <TextField
                        {...props}
                        value={person?.assignments?.callSign?.code || ''}
                      />
                    )}
                  </StyledField>
                  {useRestricted && (
                    <StyledField
                      name="visibleTo"
                      component={SwitchField}
                      label="Restricted"
                      disabled={isDisabled('visibleTo')}
                      parse={(value) => (value ? [] : null)}
                      format={(value) => !!value}
                    />
                  )}
                </Section>
                <Divider />
                {values.visibleTo && (
                  <Fragment>
                    <ListSubheader disableSticky>Visible to</ListSubheader>
                    <Section>
                      <Field
                        name="visibleTo"
                        sx={{ pb: 1 }}
                        fullWidth
                        component={SelectMultipleField}
                        suggestions={activeDirectoryGroups}
                        disabled={isDisabled('visibleTo')}
                      />
                    </Section>
                    <Divider />
                  </Fragment>
                )}
                {personAttributes.length > 0 && (
                  <Fragment>
                    <ListSubheader disableSticky>Attributes</ListSubheader>
                    <Section>
                      {personAttributes.map(({ value, label, values }) => (
                        <StyledField
                          key={value}
                          name={`attributes.${value}`}
                          component={values ? SelectField : TextField}
                          label={label}
                          values={values}
                          disabled={!canEdit}
                        />
                      ))}
                    </Section>
                    <Divider />
                  </Fragment>
                )}
                <ListSubheader disableSticky>Home</ListSubheader>
                <Section>
                  <StyledField
                    name="homeStation"
                    component={SelectField}
                    label="Station"
                    values={homeStationNames.map((station) => ({
                      label: station.name,
                      value: station.code,
                    }))}
                    disabled={isDisabled('homeStation')}
                  />
                  <Field
                    name="wards"
                    label="Wards"
                    sx={{ pb: 1 }}
                    fullWidth
                    component={SelectMultipleField}
                    suggestions={wards.map((ward) => ({
                      label: ward.name,
                      value: ward.code,
                    }))}
                    disabled={isDisabled('wards')}
                  />
                  <Field
                    name="groupCodes"
                    component={GroupCodesField}
                    groupOptions={groupOptions}
                    disabled={isDisabled('groupCodes')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>
                  Ancestor Groups & Areas
                </ListSubheader>
                <Section>
                  <GroupAncestors
                    groupAncestors={values?.groupAncestors}
                    typeOptions={typeOptions}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Categories</ListSubheader>
                <Section>
                  <Field
                    name="groups"
                    groups={personGroups}
                    component={GroupsField}
                    disabled={isDisabled('groups')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Skills</ListSubheader>
                <Section>
                  <Field
                    name="skills"
                    types={personSkills}
                    component={TypesField}
                    disabled={isDisabled('skills')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Radio</ListSubheader>
                <Section>
                  <StyledField
                    name="radioSsi"
                    component={TextField}
                    label="SSI"
                    disabled={isDisabled('radioSsi')}
                  />
                  <StyledField
                    label="Last Poll Time"
                    name="lastPollTime"
                    component={TextField}
                    format={(value) =>
                      value
                        ? format(new Date(value), 'dd/MM/yyyy HH:mm:ss')
                        : 'never'
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>
                  {useDallasKeys ? 'Dallas Keys' : 'RFID Cards'}
                </ListSubheader>
                <Section>
                  <Field
                    name={'rfidCards'}
                    sx={{ pb: 1 }}
                    component={ChipField}
                    categories={useRfidCardCategory ? tripClassifications : {}}
                    disabled={isDisabled('rfidCards')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Tags</ListSubheader>
                <Section>
                  <TagControl
                    item={{ id }}
                    type={'people'}
                    sx={{ pb: 1 }}
                    disabled={id === 'new' || !id}
                  />
                </Section>
              </Box>
              <CardActions>
                <Button
                  color="primary"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  aria-owns={anchorEl ? 'events-menu' : undefined}
                  aria-haspopup="true"
                  onClick={handleEventsClick}
                  disabled={dirty || submitting || person === null}
                >
                  Events
                </Button>
                <Menu
                  id="events-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleEventsClose}
                >
                  {!isFleet && (
                    <MenuItem
                      onClick={navigateToEvents('trails', 'trails', 'people')}
                    >
                      Trails
                    </MenuItem>
                  )}
                  {!isFleet && (
                    <MenuItem
                      onClick={navigateToEvents(
                        'personLocationVisits',
                        'locationVisits',
                        'people',
                      )}
                    >
                      Location Visits
                    </MenuItem>
                  )}
                  {!isFleet && (
                    <MenuItem
                      onClick={navigateToEvents(
                        'doubleCrews',
                        'doubleCrews',
                        'people',
                      )}
                    >
                      Double Crews
                    </MenuItem>
                  )}
                  {!isFleet && (
                    <MenuItem
                      onClick={navigateToEvents(
                        'attendances',
                        'attendances',
                        'people',
                      )}
                    >
                      Attendances
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={navigateToEvents('trips', 'trips', 'vehicles')}
                  >
                    Trips
                  </MenuItem>
                  <MenuItem
                    onClick={navigateToEvents('stops', 'stops', 'vehicles')}
                  >
                    Stops
                  </MenuItem>
                  <MenuItem
                    onClick={navigateToEvents('idles', 'idles', 'vehicles')}
                  >
                    Idles
                  </MenuItem>

                  <MenuItem
                    onClick={navigateToEvents(
                      'speedInfractions',
                      'speedInfractions',
                      'vehicles',
                    )}
                  >
                    Speed Infractions
                  </MenuItem>
                  <MenuItem
                    onClick={navigateToEvents(
                      'accelerometerEvents',
                      'accelerometerEvents',
                      'vehicles',
                    )}
                  >
                    Accelerometer Events
                  </MenuItem>
                </Menu>
                {canEdit && (
                  <Button
                    color="error"
                    onClick={() => setDeleteOpen(true)}
                    disabled={person === null}
                  >
                    Delete
                  </Button>
                )}
              </CardActions>
            </Stack>
            <ConfirmationDialog
              action="Delete"
              open={deleteOpen}
              itemId={values.name || values.collarNumber}
              onOk={handleDelete}
              onCancel={() => setDeleteOpen(false)}
            />
          </form>
        )}
      />
    </Box>
  );
}
