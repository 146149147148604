import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

export function CustomTooltip({
  active,
  payload,
  label,
  unit,
  height,
  variant,
  labelFormatter,
  valueFormatter,
  captureMouseMove,
  sortByValue,
  sortDesc,
  sx,
}) {
  function handleMouseMove(event) {
    if (captureMouseMove) {
      event.stopPropagation();
    }
  }

  if (active) {
    return (
      <Paper onMouseMove={handleMouseMove} variant={variant}>
        <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
          {labelFormatter ? labelFormatter(label) : label}
        </Typography>
        <Divider />
        <Box
          sx={{
            maxHeight: height,
            overflowY: height ? 'auto' : undefined,
            ...sx,
          }}
        >
          <Table size="small">
            <TableBody>
              {(payload ?? [])
                .sort((a, b) => {
                  if (sortByValue) {
                    return sortDesc ? b.value - a.value : a.value - b.value;
                  }

                  return 0;
                })
                .map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>
                      <Typography
                        variant="caption"
                        sx={{ color: row.stroke || row.fill }}
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: row.stroke || row.fill,
                        textAlign: 'right',
                      }}
                    >
                      <Typography variant="caption">{`${
                        valueFormatter ? valueFormatter(row.value) : row.value
                      }${
                        unit
                          ? ` ${row.value === 1 ? unit.replace(/s+$/, '') : unit}`
                          : ''
                      }`}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    );
  }

  return null;
}
