import { useAuth } from 'react-oidc-context';
import { LoggedInAvatar } from './LoggedInAvatar';
import { LoggedOutAvatar } from './LoggedOutAvatar';

export function LoginAvatar() {
  const auth = useAuth();

  return auth.isAuthenticated ? (
    <LoggedInAvatar auth={auth} />
  ) : (
    <LoggedOutAvatar auth={auth} />
  );
}
