import {
  CREATE_LOCATION,
  CREATE_LOCATION_FAILURE,
  CREATE_LOCATION_SUCCESS,
  DELETE_LOCATION,
  DELETE_LOCATION_FAILURE,
  DELETE_LOCATION_SUCCESS,
  FETCH_HOME_STATIONS,
  FETCH_HOME_STATIONS_FAILURE,
  FETCH_HOME_STATIONS_SUCCESS,
  FETCH_LOCATION,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATION_FAILURE,
  FETCH_LOCATION_SUCCESS,
  FETCH_WARDS,
  FETCH_WARDS_FAILURE,
  FETCH_WARDS_SUCCESS,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS,
} from '@/actions';
import { fromAjax } from '@/apis';
import { getHeaders, log } from '@/utils';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

export function fetchHomeStationsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_HOME_STATIONS),
    mergeMap(() =>
      fromAjax('/pipeline/locations', {
        body: [
          {
            $lookup: {
              from: 'options',
              let: { type: '$type' },
              pipeline: [
                {
                  $match: {
                    name: 'locationType',
                    $expr: { $eq: ['$$type', '$value'] },
                  },
                },
                { $project: { _id: false, isHomeStation: true } },
              ],
              as: 'locationType',
            },
          },
          { $unwind: '$locationType' },
          { $match: { 'locationType.isHomeStation': true } },
          { $project: { code: true, name: true } },
        ],
        method: 'POST',
        headers: { ...getHeaders(), 'content-type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => ({
          type: FETCH_HOME_STATIONS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_HOME_STATIONS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchWardsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_WARDS),
    mergeMap(() =>
      fromAjax('/locations', {
        params: {
          query: {
            type: 'Ward',
          },
          projection: { code: true, name: true },
          sort: { name: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: payload }) => ({
          type: FETCH_WARDS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_WARDS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchLocationsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LOCATIONS),
    mergeMap(({ payload: type }) =>
      fromAjax('/locations', {
        params: {
          pipeline: [
            ...(type !== 'All' ? [{ $match: { type } }] : []),
            {
              $graphLookup: {
                from: 'groups',
                startWith: '$groupCodes',
                connectFromField: 'parentCodes',
                connectToField: 'code',
                as: 'groupAncestorCodes',
              },
            },
            {
              $project: {
                code: true,
                name: true,
                type: true,
                picture: true,
                tranmanIdentifier: true,
                groupAncestorCodes: {
                  $map: {
                    input: '$groupAncestorCodes',
                    as: 'group',
                    in: '$$group.code',
                  },
                },
              },
            },
            {
              $sort: { name: 1 },
            },
          ],
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const payload = response.map((location) => ({
            ...location,
            searchString: [location.name, location.code]
              .join('+')
              .toLowerCase(),
          }));

          log('Read', 'Locations', { type });

          return {
            type: FETCH_LOCATIONS_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LOCATIONS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchLocationEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LOCATION),
    mergeMap(({ payload: id }) =>
      fromAjax('/locations', {
        params: {
          pipeline: [
            { $match: { code: id } },
            {
              $graphLookup: {
                from: 'groups',
                startWith: '$groupCodes',
                connectFromField: 'parentCodes',
                connectToField: 'code',
                as: 'groupAncestors',
                depthField: 'depth',
              },
            },
            {
              $project: {
                code: true,
                name: true,
                type: true,
                district: true,
                subtype: true,
                boundary: true,
                startTime: true,
                endTime: true,
                picture: true,
                tranmanIdentifier: true,
                groups: true,
                attributes: true,
                groupCodes: true,
                groupAncestors: {
                  $map: {
                    input: {
                      $sortArray: {
                        input: {
                          $filter: {
                            input: '$groupAncestors',
                            cond: {
                              $not: [
                                { $in: ['$$ancestor.code', '$groupCodes'] },
                              ],
                            },
                            as: 'ancestor',
                          },
                        },
                        sortBy: { depth: -1, type: 1, name: 1 },
                      },
                    },
                    as: 'group',
                    in: {
                      code: '$$group.code',
                      name: '$$group.name',
                      type: '$$group.type',
                    },
                  },
                },
              },
            },
          ],
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: [payload] }) => {
          log('Read', 'Locations', { id });

          return {
            type: FETCH_LOCATION_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LOCATION_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function createLocationEpic(action$) {
  return action$.pipe(
    ofType(CREATE_LOCATION),
    mergeMap(({ payload: { groupAncestors: _, ...body }, navigate }) =>
      fromAjax('/locations', {
        body,
        method: 'POST',
        headers: { ...getHeaders(), 'content-type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', 'Location', payload);

          navigate(`../${payload.code}`, {
            replace: true,
            state: { created: true },
          });

          return {
            type: CREATE_LOCATION_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_LOCATION_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function updateLocationEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_LOCATION),
    mergeMap(({ payload: { groupAncestors: _, ...body } }) =>
      fromAjax(`/locations/${body.code}`, {
        body,
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'content-type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: payload }) => ({
          type: UPDATE_LOCATION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_LOCATION_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function deleteLocationEpic(action$) {
  return action$.pipe(
    ofType(DELETE_LOCATION),
    mergeMap(({ payload: id, navigate }) =>
      fromAjax(`/locations/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          navigate('..', { replace: true });

          return {
            type: DELETE_LOCATION_SUCCESS,
            payload: response.code,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_LOCATION_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}
