import { groupsFilter } from '@/utils';

export * from './useActiveIntersectionChanges';
export * from './useFilteredHourlyIntersections';
export * from './useFilteredActiveIntersectionChanges';
export * from './useHourlyIntersections';

export function sortBy(key) {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
}

export function vehicleIntersectionFilter(record, filter) {
  if (
    filter.registrationNumber.length !== 0 &&
    !filter.registrationNumber.includes(record.registrationNumber)
  ) {
    return false;
  }

  if (
    filter.fleetNumber.length !== 0 &&
    !filter.fleetNumber.includes(record.fleetNumber)
  ) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  if (filter.type.length !== 0 && !filter.type.includes(record.type)) {
    return false;
  }

  if (
    filter.locationName.length !== 0 &&
    !filter.locationName.includes(record.locationName)
  ) {
    return false;
  }

  if (
    filter.locationType.length !== 0 &&
    !filter.locationType.includes(record.locationType)
  ) {
    return false;
  }

  return groupsFilter(record, filter);
}
