import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteOption() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ name, value }) => {
      if (name && value) {
        return api.delete(`options/${name}-${value}`);
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['options'] }),
  });
}
