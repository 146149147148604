import { useOptionLookup } from '@/hooks';
import { parseIfJSON, startCase } from '@/utils';
import { Box, Stack, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { format } from 'date-fns';
import { Popup } from 'react-map-gl/maplibre';
import { DetailsPanel } from './DetailsPanel';
import { Status } from './Status';

export function PersonPopup({
  hoverInfo: {
    longitude,
    latitude,
    feature: { properties },
  },
}) {
  const personGroups = useOptionLookup('personGroup');
  const person = properties.person ? parseIfJSON(properties.person) : null;
  const radio = properties.radio ? parseIfJSON(properties.radio) : null;

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={16}
      closeButton={false}
    >
      <Stack spacing={0.5}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {person
              ? [person.forenames, person.surname]
                  .filter(Boolean)
                  .join(' ')
                  .trim()
              : radio?.label ?? 'Unassigned radio'}
          </Typography>
          {person?.collarNumber && (
            <Box
              sx={{
                fontSize: 10,
                color: 'white',
                bgcolor: blue[900],
                borderRadius: 1,
                px: 0.5,
              }}
            >
              {person?.collarNumber}
            </Box>
          )}
        </Stack>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {format(new Date(properties.time), 'dd/MM HH:mm:ss')}
        </Typography>
        <Status status={properties.status} />
        {person ? (
          <DetailsPanel
            entries={[
              { label: 'Staff #', value: person.code },
              { label: 'Rank', value: person.category },
              { label: 'Role', value: person.role },
              // { label: 'Home Station', value: person.homeStation },
              ...Object.entries(person.groups ?? {}).map(
                ([type, codes = []]) => ({
                  label: personGroups[type] ?? startCase(type),
                  value: codes.join?.(', ') ?? codes,
                }),
              ),
            ]}
          />
        ) : (
          <DetailsPanel
            entries={[
              { label: 'SSI', value: properties.ssi },
              { label: 'Type', value: radio?.type },
            ]}
          />
        )}
      </Stack>
    </Popup>
  );
}
