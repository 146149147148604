import {
  AutocompleteController,
  ContentEditorController,
  DatePickerController,
  SwitchController,
  TextFieldController,
  ToggleButtonGroupController,
} from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import { isNotTooBig, startCase } from '@/utils';
import {
  objectiveForm,
  occurrenceNumberOnObjectives,
  useRestricted,
} from '@/utils/config';
import {
  Button,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { format, startOfDay } from 'date-fns';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ApplicableToControllers } from './ApplicableToControllers';
import { BoundaryViewer } from './BoundaryViewer';
import { LocationPicker } from './LocationPicker';
import { WeeklySchedulePickerController } from './WeeklySchedulePickerController';

export function ObjectiveForm({
  defaultValues,
  canEdit,
  onSubmit,
  onDelete,
  emailAddresses,
  activeDirectoryGroups,
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const {
    control,
    formState: { dirtyFields, isSubmitting },
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({ defaultValues });
  const isDirty = Object.keys(dirtyFields).length > 0;

  function isEditable(fieldName) {
    return (
      canEdit && (objectiveForm[fieldName]?.editable || !defaultValues?._id)
    );
  }

  function isDisabled(fieldName) {
    if (fieldName === 'endTime') {
      return !isEditable(fieldName) && defaultValues?.endTime < new Date();
    }

    return !isEditable(fieldName);
  }

  function handleResourceTypeChange() {
    setValue('personCodes', undefined);
    setValue('vehicleIdentificationNumbers', undefined);
    setValue('personGroupCodes', undefined);
    setValue('vehicleGroupCodes', undefined);
  }

  function handleBoundaryTypeChange() {
    setValue('boundary', null);
    setValue('microbeats', []);
    setValue('boundaryIdentifier', '');
    setValue('boundarySubtype', '');
  }

  function handleCancel() {
    reset();
  }

  function toggleDeleteDialog() {
    setDeleteOpen((prev) => !prev);
  }

  function handleDelete() {
    onDelete(defaultValues._id);
  }

  return (
    <Fragment>
      <form id="objective-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <Typography variant="h6" sx={{ p: 1 }}>
              {defaultValues?._id ? defaultValues.title : 'New Objective'}
            </Typography>
            <Stack>
              {watch('created') && (
                <Typography variant="caption" textAlign="right">
                  {`Created by ${getValues('created').userId} ${format(
                    getValues('created').time,
                    'dd/MM/yyyy HH:mm',
                  )}`}
                </Typography>
              )}
              {watch('lastEdit') && (
                <Typography variant="caption" textAlign="right">
                  {`Last edited by ${getValues('lastEdit').userId} ${format(
                    getValues('lastEdit').time,
                    'dd/MM/yyyy HH:mm',
                  )}`}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack spacing={1} direction="row" flexWrap="wrap">
            <TextFieldController
              name="title"
              label="Title"
              control={control}
              disabled={isDisabled('title')}
              rules={{ required: 'Required', maxLength: 50 }}
            />
            {occurrenceNumberOnObjectives && (
              <TextFieldController
                name="occurrenceNumber"
                label="Occurrence Number"
                control={control}
                disabled={isDisabled('occurenceNumber')}
                rules={{ maxLength: 30 }}
              />
            )}
            {useRestricted && (
              <SwitchController
                name="visibleTo"
                label="Restricted"
                control={control}
                disabled={isDisabled('visibleTo')}
                parse={(value) => !!value}
                format={(value) => (value ? [] : undefined)}
              />
            )}
          </Stack>
          {watch('visibleTo') && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Visible to
                </Typography>
              </Divider>
              <AutocompleteController
                name="visibleTo"
                control={control}
                fullWidth
                options={activeDirectoryGroups}
                disabled={isDisabled('visibleTo')}
              />
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Description
            </Typography>
          </Divider>
          <ContentEditorController
            name="description"
            placeholder="Write something..."
            control={control}
            disabled={isDisabled('description')}
            rules={{ required: 'Required', validate: isNotTooBig }}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Active Time
            </Typography>
          </Divider>
          <Stack spacing={1} direction="row" flexWrap="wrap">
            <DatePickerController
              name="startTime"
              label="Start Date"
              control={control}
              disabled={isDisabled('startTime')}
              rules={{ required: 'Required' }}
              minDate={startOfDay(new Date())}
              maxDate={watch('endTime') ?? new Date('2100-01-01')}
            />
            <DatePickerController
              name="endTime"
              label="End Date"
              control={control}
              disabled={isDisabled('endTime')}
              rules={{ required: 'Required' }}
              minDate={watch('startTime') ?? startOfDay(new Date())}
              maxDate={new Date('2100-01-01')}
            />
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Weekly Schedule
            </Typography>
          </Divider>
          <WeeklySchedulePickerController
            name="schedule"
            control={control}
            disabled={isDisabled('schedule')}
            rules={{ required: 'Required' }}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Requirements
            </Typography>
          </Divider>
          <Stack spacing={1} direction="row" flexWrap="wrap">
            <TextFieldController
              name="requiredVisits"
              label="Number of Visits"
              control={control}
              disabled={isDisabled('requiredVisits')}
              rules={{ required: 'Required' }}
              defaultValue={0}
              parse={(value) => (value == null || value === '' ? '' : value)}
              format={(value) => (value === '' ? '' : +value)}
            />
            <TextFieldController
              name="requiredFrequency"
              label="Frequency"
              control={control}
              options={['total', 'daily', 'hourly'].map((item) => ({
                label: startCase(item),
                value: item,
              }))}
              disabled={isDisabled('requiredFrequency')}
              rules={{ required: 'Required' }}
            />
            <Divider orientation="vertical" flexItem />
            <TextFieldController
              name="complianceSeconds"
              label="Compliant Minutes"
              control={control}
              disabled={isDisabled('complianceSeconds')}
              rules={{ required: 'Required' }}
              defaultValue={0}
              parse={(value) =>
                value == null || value === '' ? '' : value / 60
              }
              format={(value) => (value === '' ? '' : value * 60)}
            />
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Applicable to
            </Typography>
          </Divider>
          <Stack spacing={1}>
            <ToggleButtonGroupController
              name="resourceType"
              control={control}
              rules={{ required: 'Required' }}
              disabled={isDisabled('resourceType')}
              values={[
                { label: 'People', value: 'people' },
                { label: 'Vehicles', value: 'vehicles' },
                { label: 'Groups of People', value: 'personGroups' },
                { label: 'Groups of Vehicles', value: 'vehicleGroups' },
              ]}
              onChange={handleResourceTypeChange}
            />
            <ApplicableToControllers
              control={control}
              resourceType={watch('resourceType')}
              isDisabled={isDisabled}
            />
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Notifications
            </Typography>
          </Divider>
          <Stack spacing={1}>
            <FormControl
              sx={{ pl: 1.5 }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">
                <Typography variant="caption">Type</Typography>
              </FormLabel>
              <FormGroup row>
                <SwitchController
                  name="notifications.onEntry"
                  label="Entry"
                  control={control}
                />
                <SwitchController
                  name="notifications.onExit"
                  label="Exit"
                  control={control}
                />
              </FormGroup>
            </FormControl>
            {(watch('notifications.onEntry') ||
              watch('notifications.onExit')) && (
              <AutocompleteController
                name="notifications.recipients"
                control={control}
                label="Recipients"
                fullWidth
                options={emailAddresses}
                disabled={!canEdit}
              />
            )}
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Boundary
            </Typography>
          </Divider>
          <Stack spacing={1} direction="row" flexWrap="wrap">
            <TextFieldController
              name="boundaryType"
              control={control}
              label="Type"
              options={['Location', 'Microbeats', 'Custom']}
              disabled={isDisabled('boundary')}
              rules={{ required: 'Required' }}
              onChange={handleBoundaryTypeChange}
            />
            {watch('boundaryType') === 'Location' && (
              <LocationPicker
                control={control}
                subtype={watch('boundarySubtype')}
                setValue={setValue}
                disabled={isDisabled('boundary')}
              />
            )}
          </Stack>
          <BoundaryViewer
            control={control}
            type={watch('boundaryType')}
            id={watch('boundaryIdentifier')}
            disabled={isDisabled('boundary')}
          />
          {canEdit && (
            <Stack spacing={1} direction="row">
              <Button
                color="primary"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={!isDirty || isSubmitting}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                color="error"
                onClick={toggleDeleteDialog}
                disabled={!watch('_id')}
              >
                Delete
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={defaultValues?.title || defaultValues?.identifier}
        onOk={handleDelete}
        onCancel={toggleDeleteDialog}
      />
    </Fragment>
  );
}
