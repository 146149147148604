import { EventFilter } from './EventFilter';
import { IncidentFilter } from './IncidentFilter';
import { LocationFilter } from './LocationFilter';
import { ObjectiveFilter } from './ObjectiveFilter';
import { PersonFilter } from './PersonFilter';
import { VehicleFilter } from './VehicleFilter';

export function Filter({ tab, ...props }) {
  switch (tab) {
    case 'vehicles':
      return <VehicleFilter {...props} />;
    case 'people':
      return <PersonFilter {...props} />;
    case 'locations':
      return <LocationFilter {...props} />;
    case 'incidents':
      return <IncidentFilter {...props} />;
    case 'objectives':
      return <ObjectiveFilter {...props} />;
    case 'events':
      return <EventFilter {...props} />;
    default:
      return null;
  }
}
