import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useLegacyAuditOptions() {
  return useQuery({
    queryKey: ['legacyAuditOptions'],
    queryFn: async () => {
      const json = [
        {
          $group: {
            _id: null,
            users: { $addToSet: '$UserName' },
            sections: { $addToSet: '$Section' },
            pages: { $addToSet: '$WebPage' },
            operations: { $addToSet: '$Operation' },
            types: { $addToSet: '$EntityTypeName' },
          },
        },
        {
          $project: {
            _id: false,
            users: { $sortArray: { input: '$users', sortBy: 1 } },
            sections: { $sortArray: { input: '$sections', sortBy: 1 } },
            pages: { $sortArray: { input: '$pages', sortBy: 1 } },
            operations: { $sortArray: { input: '$operations', sortBy: 1 } },
            types: { $sortArray: { input: '$types', sortBy: 1 } },
          },
        },
      ];

      const response = await api.post('pipeline/legacyAudits', { json }).json();

      return {
        users:
          response[0].users?.filter(Boolean).map((value) => ({
            label: value,
            value,
          })) ?? [],
        sections:
          response[0].sections?.filter(Boolean).map((value) => ({
            label: value,
            value,
          })) ?? [],
        pages:
          response[0].pages?.filter(Boolean).map((value) => ({
            label: value,
            value,
          })) ?? [],
        operations:
          response[0].operations?.filter(Boolean).map((value) => ({
            label: value,
            value,
          })) ?? [],
        types:
          response[0].types?.filter(Boolean).map((value) => ({
            label: value,
            value,
          })) ?? [],
      };
    },
    placeholderData: (previousData) =>
      previousData ?? {
        users: [],
        sections: [],
        pages: [],
        operations: [],
        types: [],
      },
    staleTime: 1000 * 60 * 60 * 24,
  });
}
