export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';
export const FETCH_TAG = 'FETCH_TAG';
export const FETCH_TAG_SUCCESS = 'FETCH_TAG_SUCCESS';
export const FETCH_TAG_FAILURE = 'FETCH_TAGS_FAILURE';
export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAILURE = 'CREATE_TAGS_FAILURE';
export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';
export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';
export const ADD_TAG_ITEM = 'ADD_TAG_ITEM';
export const ADD_TAG_ITEM_SUCCESS = 'ADD_TAG_ITEM_SUCCESS';
export const ADD_TAG_ITEM_FAILURE = 'ADD_TAG_ITEM_FAILURE';
export const DELETE_TAG_ITEM = 'DELETE_TAG_ITEM';
export const DELETE_TAG_ITEM_SUCCESS = 'DELETE_TAG_ITEM_SUCCESS';
export const DELETE_TAG_ITEM_FAILURE = 'DELETE_TAG_ITEM_FAILURE';

export const UPDATE_FILTER_START_TIME = 'UPDATE_FILTER_START_TIME';
export const UPDATE_FILTER_END_TIME = 'UPDATE_FILTER_END_TIME';
export const UPDATE_FILTER_LOCATION_TYPE = 'UPDATE_FILTER_LOCATION_TYPE';
export const UPDATE_FILTER_CARD_USED = 'UPDATE_FILTER_CARD_USED';
export const UPDATE_FILTER_EMERGENCY_EQUIPMENT_USED =
  'UPDATE_FILTER_EMERGENCY_EQUIPMENT_USED';
export const FETCH_USER_IDS = 'FETCH_USER_IDS';
export const FETCH_USER_IDS_SUCCESS = 'FETCH_USER_IDS_SUCCESS';
export const FETCH_USER_IDS_FAILURE = 'FETCH_USER_IDS_FAILURE';
export const UPDATE_FILTER_USER_ID = 'UPDATE_FILTER_USER_ID';

export const FETCH_HOME_STATIONS = 'FETCH_HOME_STATIONS';
export const FETCH_HOME_STATIONS_SUCCESS = 'FETCH_HOME_STATIONS_SUCCESS';
export const FETCH_HOME_STATIONS_FAILURE = 'FETCH_HOME_STATIONS_FAILURE';
export const FETCH_WARDS = 'FETCH_WARDS';
export const FETCH_WARDS_SUCCESS = 'FETCH_WARDS_SUCCESS';
export const FETCH_WARDS_FAILURE = 'FETCH_WARDS_FAILURE';
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATIONS_FAILURE';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATIONS_FAILURE';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATIONS_FAILURE';

export const FETCH_OBJECTIVES = 'FETCH_OBJECTIVES';
export const FETCH_OBJECTIVES_SUCCESS = 'FETCH_OBJECTIVES_SUCCESS';
export const FETCH_OBJECTIVES_FAILURE = 'FETCH_OBJECTIVES_FAILURE';
export const FETCH_OBJECTIVE = 'FETCH_OBJECTIVE';
export const FETCH_OBJECTIVE_SUCCESS = 'FETCH_OBJECTIVE_SUCCESS';
export const FETCH_OBJECTIVE_FAILURE = 'FETCH_OBJECTIVES_FAILURE';
export const CREATE_OBJECTIVE = 'CREATE_OBJECTIVE';
export const CREATE_OBJECTIVE_SUCCESS = 'CREATE_OBJECTIVE_SUCCESS';
export const CREATE_OBJECTIVE_FAILURE = 'CREATE_OBJECTIVES_FAILURE';
export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE';
export const UPDATE_OBJECTIVE_SUCCESS = 'UPDATE_OBJECTIVE_SUCCESS';
export const UPDATE_OBJECTIVE_FAILURE = 'UPDATE_OBJECTIVES_FAILURE';
export const DELETE_OBJECTIVE = 'DELETE_OBJECTIVE';
export const DELETE_OBJECTIVE_SUCCESS = 'DELETE_OBJECTIVE_SUCCESS';
export const DELETE_OBJECTIVE_FAILURE = 'DELETE_OBJECTIVES_FAILURE';
export const FETCH_ATTENDANCES = 'FETCH_ATTENDANCES';
export const FETCH_ATTENDANCES_SUCCESS = 'FETCH_ATTENDANCES_SUCCESS';
export const FETCH_ATTENDANCES_FAILURE = 'FETCH_ATTENDANCES_FAILURE';
export const FETCH_ATTENDANCES_CANCELLED = 'FETCH_ATTENDANCES_CANCELLED';
export const UPDATE_ATTENDANCES_PARAMETERS = 'UPDATE_ATTENDANCES_PARAMETERS';
export const UPDATE_ATTENDANCES_FILTER = 'UPDATE_ATTENDANCES_FILTER';
export const FETCH_PEOPLE = 'FETCH_PEOPLE';
export const FETCH_PEOPLE_SUCCESS = 'FETCH_PEOPLE_SUCCESS';
export const FETCH_PEOPLE_FAILURE = 'FETCH_PEOPLE_FAILURE';
export const FETCH_PERSON = 'FETCH_PERSON';
export const FETCH_PERSON_SUCCESS = 'FETCH_PERSON_SUCCESS';
export const FETCH_PERSON_FAILURE = 'FETCH_PEOPLE_FAILURE';
export const CREATE_PERSON = 'CREATE_PERSON';
export const CREATE_PERSON_SUCCESS = 'CREATE_PERSON_SUCCESS';
export const CREATE_PERSON_FAILURE = 'CREATE_PEOPLE_FAILURE';
export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';
export const UPDATE_PERSON_FAILURE = 'UPDATE_PEOPLE_FAILURE';
export const DELETE_PERSON = 'DELETE_PERSON';
export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';
export const DELETE_PERSON_FAILURE = 'DELETE_PEOPLE_FAILURE';
export const FETCH_RFID_CARDS = 'FETCH_RFID_CARDS';
export const FETCH_RFID_CARDS_SUCCESS = 'FETCH_RFID_CARDS_SUCCESS';
export const FETCH_RFID_CARDS_FAILURE = 'FETCH_PEOPLE_FAILURE';
export const FETCH_RFID_CARD = 'FETCH_RFID_CARD';
export const FETCH_RFID_CARD_SUCCESS = 'FETCH_RFID_CARD_SUCCESS';
export const FETCH_RFID_CARD_FAILURE = 'FETCH_RFID_CARD_FAILURE';
export const CREATE_RFID_CARD = 'CREATE_RFID_CARD';
export const CREATE_RFID_CARD_SUCCESS = 'CREATE_RFID_CARD_SUCCESS';
export const CREATE_RFID_CARD_FAILURE = 'CREATE_RFID_CARD_FAILURE';
export const UPDATE_RFID_CARD = 'UPDATE_RFID_CARD';
export const UPDATE_RFID_CARD_SUCCESS = 'UPDATE_RFID_CARD_SUCCESS';
export const UPDATE_RFID_CARD_FAILURE = 'UPDATE_RFID_CARD_FAILURE';
export const DELETE_RFID_CARD = 'DELETE_RFID_CARD';
export const DELETE_RFID_CARD_SUCCESS = 'DELETE_RFID_CARD_SUCCESS';
export const DELETE_RFID_CARD_FAILURE = 'DELETE_RFID_CARD_FAILURE';
export const REMOVE_RFID_CARD = 'REMOVE_RFID_CARD';
export const REMOVE_RFID_CARD_SUCCESS = 'REMOVE_RFID_CARD_SUCCESS';
export const REMOVE_RFID_CARD_FAILURE = 'REMOVE_RFID_CARD_FAILURE';
export const FETCH_POST_HIERARCHY = 'FETCH_POST_HIERARCHY';
export const FETCH_POST_HIERARCHY_SUCCESS = 'FETCH_POST_HIERARCHY_SUCCESS';
export const FETCH_POST_HIERARCHY_FAILURE = 'FETCH_POST_HIERARCHY_FAILURE';
export const ADD_LIVE_RESOURCE = 'ADD_LIVE_RESOURCE';
export const UPDATE_LIVE_RESOURCE = 'UPDATE_LIVE_RESOURCE';
export const DELETE_LIVE_RESOURCE = 'DELETE_LIVE_RESOURCE';
export const FILTER_LIVE_LIST = 'FILTER_LIVE_LIST';
export const UPDATE_LIVE_FOLLOW_OVERRIDE = 'UPDATE_LIVE_FOLLOW_OVERRIDE';
export const UPDATE_LIVE_FILTER_OVERRIDE = 'UPDATE_LIVE_FILTER_OVERRIDE';
export const UPDATE_LIVE_RESOURCES = 'UPDATE_LIVE_RESOURCES';
export const UPDATE_LIVE_FOLLOWED = 'UPDATE_LIVE_FOLLOWED';
export const UPDATE_LIVE_FILTERS = 'UPDATE_LIVE_FILTERS';
export const UPDATE_LIVE_SEARCHTEXTS = 'UPDATE_LIVE_SEARCHTEXTS';
export const UPDATE_LIVE_LAYER_VISIBILITIES = 'UPDATE_LIVE_LAYER_VISIBILITIES';
export const UPDATE_LIVE_ADVANCED_FILTERS = 'UPDATE_LIVE_ADVANCED_FILTERS';
export const UPDATE_LIVE_SORTS = 'UPDATE_LIVE_SORTS';
export const ADD_LIVE_TAG = 'ADD_LIVE_TAG';
export const ADD_LIVE_TAG_SUCCESS = 'ADD_LIVE_TAG_SUCCESS';
export const ADD_LIVE_TAG_FAILURE = 'ADD_LIVE_TAG_FAILURE';
export const DELETE_LIVE_TAG = 'DELETE_LIVE_TAG';
export const DELETE_LIVE_TAG_SUCCESS = 'DELETE_LIVE_TAG_SUCCESS';
export const DELETE_LIVE_TAG_FAILURE = 'DELETE_LIVE_TAG_FAILURE';
export const UPDATE_LIVE_VIEW_KEY = 'UPDATE_LIVE_VIEW_KEY';
export const UPDATE_LIVE_SETTINGS = 'UPDATE_LIVE_SETTINGS';
export const UPDATE_LIVE_STATUS = 'UPDATE_LIVE_STATUS';
export const UPDATE_LIVE_RESOURCES_IN_LOCATIONS =
  'UPDATE_LIVE_RESOURCES_IN_LOCATIONS';

export const FETCH_VEHICLE_AVAILABILITY = 'FETCH_VEHICLE_AVAILABILITY';
export const FETCH_VEHICLE_AVAILABILITY_SUCCESS =
  'FETCH_VEHICLE_AVAILABILITY_SUCCESS';
export const FETCH_VEHICLE_AVAILABILITY_FAILURE =
  'FETCH_VEHICLE_AVAILABILITY_FAILURE';
export const FETCH_VEHICLE_AVAILABILITY_CANCELLED =
  'FETCH_VEHICLE_AVAILABILITY_CANCELLED';
export const FILTER_VEHICLE_AVAILABILITY = 'FILTER_VEHICLE_AVAILABILITY';
export const UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY =
  'UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY';

export const FETCH_PERSON_AVAILABILITY = 'FETCH_PERSON_AVAILABILITY';
export const FETCH_PERSON_AVAILABILITY_SUCCESS =
  'FETCH_PERSON_AVAILABILITY_SUCCESS';
export const FETCH_PERSON_AVAILABILITY_FAILURE =
  'FETCH_PERSON_AVAILABILITY_FAILURE';
export const FETCH_PERSON_AVAILABILITY_CANCELLED =
  'FETCH_PERSON_AVAILABILITY_CANCELLED';
export const FILTER_PERSON_AVAILABILITY = 'FILTER_PERSON_AVAILABILITY';

export const FILTER_VEHICLE_DAILY_UTILISATION =
  'FILTER_VEHICLE_DAILY_UTILISATION';
export const FETCH_VEHICLE_DAILY_UTILISATION =
  'FETCH_VEHICLE_DAILY_UTILISATION';
export const FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS =
  'FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS';
export const FETCH_VEHICLE_DAILY_UTILISATION_FAILURE =
  'FETCH_VEHICLE_DAILY_UTILISATION_FAILURE';
export const FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED =
  'FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED';
export const LOAD_VEHICLE_DAILY_UTILISATION_QUERY =
  'LOAD_VEHICLE_DAILY_UTILISATION_QUERY';
export const LOAD_VEHICLE_DAILY_UTILISATION_QUERY_SUCCESS =
  'LOAD_VEHICLE_DAILY_UTILISATION_QUERY_SUCCESS';
export const LOAD_VEHICLE_DAILY_UTILISATION_QUERY_FAILURE =
  'LOAD_VEHICLE_DAILY_UTILISATION_FAILURE';
export const UPDATE_VEHICLE_DAILY_UTILISATION_PARAMETERS =
  'UPDATE_VEHICLE_DAILY_UTILISATION_PARAMETERS';
export const FILTER_VEHICLE_HOURLY_UTILISATION =
  'FILTER_VEHICLE_HOURLY_UTILISATION';
export const FETCH_VEHICLE_HOURLY_UTILISATION =
  'FETCH_VEHICLE_HOURLY_UTILISATION';
export const FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS =
  'FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS';
export const FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE =
  'FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE';
export const FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED =
  'FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED';
export const LOAD_VEHICLE_HOURLY_UTILISATION_QUERY =
  'LOAD_VEHICLE_HOURLY_UTILISATION_QUERY';
export const LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_SUCCESS =
  'LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_SUCCESS';
export const LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_FAILURE =
  'LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_FAILURE';
export const UPDATE_VEHICLE_HOURLY_UTILISATION_PARAMETERS =
  'UPDATE_VEHICLE_HOURLY_UTILISATION_PARAMETERS';
export const FETCH_PERSON_DAILY_ACTIVITY = 'FETCH_PERSON_DAILY_ACTIVITY';
export const FETCH_PERSON_DAILY_ACTIVITY_SUCCESS =
  'FETCH_PERSON_DAILY_ACTIVITY_SUCCESS';
export const FETCH_PERSON_DAILY_ACTIVITY_FAILURE =
  'FETCH_PERSON_DAILY_ACTIVITY_FAILURE';
export const FETCH_PERSON_DAILY_ACTIVITY_CANCELLED =
  'FETCH_PERSON_DAILY_ACTIVITY_CANCELLED';
export const FILTER_PERSON_DAILY_ACTIVITY = 'FILTER_PERSON_DAILY_ACTIVITY';
export const LOAD_PERSON_DAILY_ACTIVITY_QUERY =
  'LOAD_PERSON_DAILY_ACTIVITY_QUERY';
export const LOAD_PERSON_DAILY_ACTIVITY_QUERY_SUCCESS =
  'LOAD_PERSON_DAILY_ACTIVITY_QUERY_SUCCESS';
export const LOAD_PERSON_DAILY_ACTIVITY_QUERY_FAILURE =
  'LOAD_PERSON_DAILY_ACTIVITY_QUERY_FAILURE';
export const UPDATE_PERSON_DAILY_ACTIVITY_PARAMETERS =
  'UPDATE_PERSON_DAILY_ACTIVITY_PARAMETERS';
export const FETCH_PERSON_HOURLY_ACTIVITY = 'FETCH_PERSON_HOURLY_ACTIVITY';
export const FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS =
  'FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS';
export const FETCH_PERSON_HOURLY_ACTIVITY_FAILURE =
  'FETCH_PERSON_HOURLY_ACTIVITY_FAILURE';
export const FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED =
  'FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED';
export const LOAD_PERSON_HOURLY_ACTIVITY = 'LOAD_PERSON_HOURLY_ACTIVITY';
export const LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS =
  'LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS';
export const LOAD_PERSON_HOURLY_ACTIVITY_FAILURE =
  'LOAD_PERSON_HOURLY_ACTIVITY_FAILURE';
export const UPDATE_PERSON_HOURLY_ACTIVITY_PARAMETERS =
  'UPDATE_PERSON_HOURLY_ACTIVITY_PARAMETERS';
export const FETCH_AUDIT_LOG_ENTRIES = 'FETCH_AUDIT_LOG_ENTRIES';
export const FETCH_AUDIT_LOG_ENTRIES_SUCCESS =
  'FETCH_AUDIT_LOG_ENTRIES_SUCCESS';
export const FETCH_AUDIT_LOG_ENTRIES_FAILURE =
  'FETCH_AUDIT_LOG_ENTRIES_FAILURE';
export const FETCH_AUDIT_LOG_ENTRIES_CANCELLED =
  'FETCH_AUDIT_LOG_ENTRIES_CANCELLED';
export const FETCH_VEHICLE_IN_BASE_TIME = 'FETCH_VEHICLE_IN_BASE_TIME';
export const FETCH_VEHICLE_IN_BASE_TIME_SUCCESS =
  'FETCH_VEHICLE_IN_BASE_TIME_SUCCESS';
export const FETCH_VEHICLE_IN_BASE_TIME_FAILURE =
  'FETCH_VEHICLE_IN_BASE_TIME_FAILURE';
export const FETCH_VEHICLE_IN_BASE_TIME_CANCELLED =
  'FETCH_VEHICLE_IN_BASE_TIME_CANCELLED';
export const LOAD_VEHICLE_IN_BASE_TIME = 'LOAD_VEHICLE_IN_BASE_TIME';
export const LOAD_VEHICLE_IN_BASE_TIME_SUCCESS =
  'LOAD_VEHICLE_IN_BASE_TIME_SUCCESS';
export const LOAD_VEHICLE_IN_BASE_TIME_FAILURE =
  'LOAD_VEHICLE_IN_BASE_TIME_FAILURE';
export const UPDATE_VEHICLE_IN_BASE_TIME_PARAMETERS =
  'UPDATE_VEHICLE_IN_BASE_TIME_PARAMETERS';
export const FETCH_VEHICLES_IN_LOCATIONS = 'FETCH_VEHICLES_IN_LOCATIONS';
export const FETCH_VEHICLES_IN_LOCATIONS_SUCCESS =
  'FETCH_VEHICLES_IN_LOCATIONS_SUCCESS';
export const FETCH_VEHICLES_IN_LOCATIONS_FAILURE =
  'FETCH_VEHICLES_IN_LOCATIONS_FAILURE';
export const FETCH_VEHICLES_IN_LOCATIONS_CANCELLED =
  'FETCH_VEHICLES_IN_LOCATIONS_CANCELLED';
export const LOAD_VEHICLES_IN_LOCATIONS = 'LOAD_VEHICLES_IN_LOCATIONS';
export const LOAD_VEHICLES_IN_LOCATIONS_SUCCESS =
  'LOAD_VEHICLES_IN_LOCATIONS_SUCCESS';
export const LOAD_VEHICLES_IN_LOCATIONS_FAILURE =
  'LOAD_VEHICLES_IN_LOCATIONS_FAILURE';
export const UPDATE_VEHICLES_IN_LOCATIONS_PARAMETERS =
  'UPDATE_VEHICLES_IN_LOCATIONS_PARAMETERS';
export const FETCH_RETROSPECTIVES = 'FETCH_RETROSPECTIVES';
export const FETCH_RETROSPECTIVES_SUCCESS = 'FETCH_RETROSPECTIVES_SUCCESS';
export const FETCH_RETROSPECTIVES_FAILURE = 'FETCH_RETROSPECTIVES_FAILURE';
export const FETCH_RETROSPECTIVE = 'FETCH_RETROSPECTIVE';
export const FETCH_RETROSPECTIVE_SUCCESS = 'FETCH_RETROSPECTIVE_SUCCESS';
export const FETCH_RETROSPECTIVE_FAILURE = 'FETCH_RETROSPECTIVES_FAILURE';
export const CREATE_RETROSPECTIVE = 'CREATE_RETROSPECTIVE';
export const CREATE_RETROSPECTIVE_SUCCESS = 'CREATE_RETROSPECTIVE_SUCCESS';
export const CREATE_RETROSPECTIVE_FAILURE = 'CREATE_RETROSPECTIVES_FAILURE';
export const UPDATE_RETROSPECTIVE = 'UPDATE_RETROSPECTIVE';
export const UPDATE_RETROSPECTIVE_SUCCESS = 'UPDATE_RETROSPECTIVE_SUCCESS';
export const UPDATE_RETROSPECTIVE_FAILURE = 'UPDATE_RETROSPECTIVES_FAILURE';
export const PUSH_RETROSPECTIVE_FORM = 'PUSH_RETROSPECTIVE_FORM';
export const SYNC_RETROSPECTIVE_FORM = 'SYNC_RETROSPECTIVE_FORM';
export const DELETE_RETROSPECTIVE = 'DELETE_RETROSPECTIVE';
export const DELETE_RETROSPECTIVE_SUCCESS = 'DELETE_RETROSPECTIVE_SUCCESS';
export const DELETE_RETROSPECTIVE_FAILURE = 'DELETE_RETROSPECTIVES_FAILURE';
export const CLEAR_RETROSPECTIVE = 'CLEAR_RETROSPECTIVE';
export const FETCH_RETROSPECTIVE_LAYER = 'FETCH_RETROSPECTIVE_LAYER';
export const FETCH_RETROSPECTIVE_LAYER_SUCCESS =
  'FETCH_RETROSPECTIVE_LAYER_SUCCESS';
export const FETCH_RETROSPECTIVE_LAYER_FAILURE =
  'FETCH_RETROSPECTIVE_LAYERS_FAILURE';
export const FETCH_RETROSPECTIVE_LAYER_CANCELLED =
  'FETCH_RETROSPECTIVE_LAYER_CANCELLED;';
export const FETCH_RETROSPECTIVE_LAYER_BOUNDARY =
  'FETCH_RETROSPECTIVE_LAYER_BOUNDARY';
export const FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS =
  'FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS';
export const FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE =
  'FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE';
export const FETCH_RETROSPECTIVE_ITEM = 'FETCH_RETROSPECTIVE_ITEM';
export const FETCH_RETROSPECTIVE_ITEM_SUCCESS =
  'FETCH_RETROSPECTIVE_ITEM_SUCCESS';
export const FETCH_RETROSPECTIVE_ITEM_FAILURE =
  'FETCH_RETROSPECTIVE_ITEM_FAILURE';
export const FETCH_RETROSPECTIVE_SUBITEM = 'FETCH_RETROSPECTIVE_SUBITEM';
export const FETCH_RETROSPECTIVE_SUBITEM_SUCCESS =
  'FETCH_RETROSPECTIVE_SUBITEM_SUCCESS';
export const FETCH_RETROSPECTIVE_SUBITEM_FAILURE =
  'FETCH_RETROSPECTIVE_SUBITEM_FAILURE';
export const UPDATE_RETROSPECTIVE_LAYER_VIRTUALIZATION =
  'UPDATE_RETROSPECTIVE_LAYER_VIRTUALIZATION';
export const ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT =
  'ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT';
export const ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_SUCCESS =
  'ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_SUCCESS';
export const ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_FAILURE =
  'ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_FAILURE';
export const ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_CANCELLED =
  'ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_CANCELLED';
export const UPDATE_RETROSPECTIVE_LAYER_FILE =
  'UPDATE_RETROSPECTIVE_LAYER_FILE';

export const FETCH_VEHICLES = 'FETCH_VEHICLES';
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS';
export const FETCH_VEHICLES_FAILURE = 'FETCH_VEHICLES_FAILURE';
export const FETCH_VEHICLE = 'FETCH_VEHICLE';
export const FETCH_VEHICLE_SUCCESS = 'FETCH_VEHICLE_SUCCESS';
export const FETCH_VEHICLE_FAILURE = 'FETCH_VEHICLES_FAILURE';
export const CREATE_VEHICLE = 'CREATE_VEHICLE';
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS';
export const CREATE_VEHICLE_FAILURE = 'CREATE_VEHICLES_FAILURE';
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAILURE = 'UPDATE_VEHICLES_FAILURE';
export const REMOVE_VEHICLE_TELEMATICS_BOX = 'REMOVE_VEHICLE_TELEMATICS_BOX';
export const REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS =
  'REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS';
export const REMOVE_VEHICLE_TELEMATICS_BOX_FAILURE =
  'REMOVE_VEHICLE_TELEMATICS_BOX_FAILURE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const DELETE_VEHICLE_FAILURE = 'DELETE_VEHICLES_FAILURE';
export const FETCH_TELEMATICS_BOX_POLLS = 'FETCH_TELEMATICS_BOX_POLLS';
export const FETCH_TELEMATICS_BOX_POLLS_SUCCESS =
  'FETCH_TELEMATICS_BOX_POLLS_SUCCESS';
export const FETCH_TELEMATICS_BOX_POLLS_FAILURE =
  'FETCH_TELEMATICS_BOX_POLLS_FAILURE';
export const FETCH_TELEMATICS_BOXES = 'FETCH_TELEMATICS_BOXES';
export const FETCH_TELEMATICS_BOXES_SUCCESS = 'FETCH_TELEMATICS_BOXES_SUCCESS';
export const FETCH_TELEMATICS_BOXES_FAILURE = 'FETCH_TELEMATICS_BOXES_FAILURE';
export const UPDATE_TELEMATICS_BOXES_FILTER = 'UPDATE_TELEMATICS_BOXES_FILTER';
export const UPDATE_TELEMATICS_BOXES_SORT = 'UPDATE_TELEMATICS_BOXES_SORT';
export const START_TELEMATICS_BOX_POLLS_STREAM =
  'START_TELEMATICS_BOX_POLLS_STREAM';
export const START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS =
  'START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS';
export const START_TELEMATICS_BOX_POLLS_STREAM_FAILURE =
  'START_TELEMATICS_BOX_POLLS_STREAM_FAILURE';
export const END_TELEMATICS_BOX_POLLS_STREAM =
  'END_TELEMATICS_BOX_POLLS_STREAM';
export const END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS =
  'END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS';
export const RECEIVE_TELEMATICS_BOX_POLL = 'RECEIVE_TELEMATICS_BOX_POLL';
// export const FETCH_TELEMATICS_BOX_AUDIT_LOG = 'FETCH_TELEMATICS_BOX_AUDIT_LOG';
// export const FETCH_TELEMATICS_BOX_AUDIT_LOG_SUCCESS =
//   'FETCH_TELEMATICS_BOX_AUDIT_LOG_SUCCESS';
// export const FETCH_TELEMATICS_BOX_AUDIT_LOG_FAILURE =
//   'FETCH_TELEMATICS_BOX_AUDIT_LOG_FAILURE';
export const UPDATE_VEHICLE_IMEI = 'UPDATE_VEHICLE_IMEI';
export const UPDATE_VEHICLE_IMEI_SUCCESS = 'UPDATE_VEHICLE_IMEI_SUCCESS';
export const UPDATE_VEHICLE_IMEI_FAILURE = 'UPDATE_VEHICLE_IMEIS_FAILURE';
export const CREATE_TELEMATICS_BOX = 'CREATE_TELEMATICS_BOX';
export const CREATE_TELEMATICS_BOX_SUCCESS = 'CREATE_TELEMATICS_BOX_SUCCESS';
export const CREATE_TELEMATICS_BOX_FAILURE = 'CREATE_TELEMATICS_BOX_FAILURE';
export const UPDATE_TELEMATICS_BOX = 'UPDATE_TELEMATICS_BOX';
export const UPDATE_TELEMATICS_BOX_SUCCESS = 'UPDATE_TELEMATICS_BOX_SUCCESS';
export const UPDATE_TELEMATICS_BOX_FAILURE = 'UPDATE_TELEMATICS_BOX_FAILURE';
export const DELETE_TELEMATICS_BOX = 'DELETE_TELEMATICS_BOX';
export const DELETE_TELEMATICS_BOX_SUCCESS = 'DELETE_TELEMATICS_BOX_SUCCESS';
export const DELETE_TELEMATICS_BOX_FAILURE = 'DELETE_TELEMATICS_BOX_FAILURE';
export const FETCH_DRIVER_ID = 'FETCH_DRIVER_ID';
export const FETCH_DRIVER_ID_SUCCESS = 'FETCH_DRIVER_ID_SUCCESS';
export const FETCH_DRIVER_ID_FAILURE = 'FETCH_DRIVER_ID_FAILURE';
export const UPDATE_DRIVER_ID = 'UPDATE_DRIVER_ID';
export const UPDATE_DRIVER_ID_SUCCESS = 'UPDATE_DRIVER_ID_SUCCESS';
export const UPDATE_DRIVER_ID_FAILURE = 'UPDATE_DRIVER_ID_FAILURE';
export const CANCEL_DRIVER_ID = 'CANCEL_DRIVER_ID';
export const CANCEL_DRIVER_ID_SUCCESS = 'CANCEL_DRIVER_ID_SUCCESS';
export const CANCEL_DRIVER_ID_FAILURE = 'CANCEL_DRIVER_ID_FAILURE';
export const FETCH_TRACKING = 'FETCH_TRACKING';
export const FETCH_TRACKING_SUCCESS = 'FETCH_TRACKING_SUCCESS';
export const FETCH_TRACKING_FAILURE = 'FETCH_TRACKING_FAILURE';
export const UPDATE_TRACKING = 'UPDATE_TRACKING';
export const UPDATE_TRACKING_SUCCESS = 'UPDATE_TRACKING_SUCCESS';
export const UPDATE_TRACKING_FAILURE = 'UPDATE_TRACKING_FAILURE';
export const CANCEL_TRACKING = 'CANCEL_TRACKING';
export const CANCEL_TRACKING_SUCCESS = 'CANCEL_TRACKING_SUCCESS';
export const CANCEL_TRACKING_FAILURE = 'CANCEL_TRACKING_FAILURE';

export const START_LIVE_STREAM = 'START_LIVE_STREAM';
export const START_LIVE_STREAM_SUCCESS = 'START_LIVE_STREAM_SUCCESS';
export const START_LIVE_STREAM_FAILURE = 'START_LIVE_STREAM_FAILURE';
export const LIVE_STREAM_WARNING = 'LIVE_STREAM_WARNING';
export const END_LIVE_STREAM = 'END_LIVE_STREAM';
export const END_LIVE_STREAM_SUCCESS = 'END_LIVE_STREAM_SUCCESS';

export const FETCH_LIVE_VIEWS = 'FETCH_LIVE_VIEWS';
export const FETCH_LIVE_VIEWS_SUCCESS = 'FETCH_LIVE_VIEWS_SUCCESS';
export const FETCH_LIVE_VIEWS_FAILURE = 'FETCH_LIVE_VIEWS_FAILURE';
export const ADD_LIVE_VIEW = 'ADD_LIVE_VIEW';
export const ADD_LIVE_VIEW_SUCCESS = 'ADD_LIVE_VIEW_SUCCESS';
export const ADD_LIVE_VIEW_FAILURE = 'ADD_LIVE_VIEW_FAILURE';
export const DELETE_LIVE_VIEW = 'DELETE_LIVE_VIEW';
export const DELETE_LIVE_VIEW_SUCCESS = 'DELETE_LIVE_VIEW_SUCCESS';
export const DELETE_LIVE_VIEW_FAILURE = 'DELETE_LIVE_VIEW_FAILURE';

export const FETCH_LIVE_INCIDENT = 'FETCH_LIVE_INCIDENT';
export const FETCH_LIVE_INCIDENT_SUCCESS = 'FETCH_LIVE_INCIDENT_SUCCESS';
export const FETCH_LIVE_INCIDENT_FAILURE = 'FETCH_LIVE_INCIDENT_FAILURE';
export const FETCH_LIVE_LOCATION = 'FETCH_LIVE_LOCATION';
export const FETCH_LIVE_LOCATION_SUCCESS = 'FETCH_LIVE_LOCATION_SUCCESS';
export const FETCH_LIVE_LOCATION_FAILURE = 'FETCH_LIVE_LOCATION_FAILURE';
export const FETCH_LIVE_VEHICLE = 'FETCH_LIVE_VEHICLE';
export const FETCH_LIVE_VEHICLE_SUCCESS = 'FETCH_LIVE_VEHICLE_SUCCESS';
export const FETCH_LIVE_VEHICLE_FAILURE = 'FETCH_LIVE_VEHICLE_FAILURE';
export const FETCH_LIVE_PERSON = 'FETCH_LIVE_PERSON';
export const FETCH_LIVE_PERSON_SUCCESS = 'FETCH_LIVE_PERSON_SUCCESS';
export const FETCH_LIVE_PERSON_FAILURE = 'FETCH_LIVE_PERSON_FAILURE';
export const FETCH_LIVE_ACCEL_POLL = 'FETCH_LIVE_ACCEL_POLL';
export const FETCH_LIVE_ACCEL_POLL_SUCCESS = 'FETCH_LIVE_ACCEL_POLL_SUCCESS';
export const FETCH_LIVE_ACCEL_POLL_FAILURE = 'FETCH_LIVE_ACCEL_POLL_FAILURE';

export const FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL =
  'FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL';
export const FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_SUCCESS =
  'FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_SUCCESS';
export const FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_FAILURE =
  'FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_FAILURE';
export const DELETE_LIVE_ESTIMATED_TIME_OF_ARRIVAL =
  'DELETE_LIVE_ESTIMATED_TIME_OF_ARRIVAL';

export const FETCH_VEHICLE_ODOMETERS = 'FETCH_VEHICLE_ODOMETERS';
export const FETCH_VEHICLE_ODOMETERS_SUCCESS =
  'FETCH_VEHICLE_ODOMETERS_SUCCESS';
export const FETCH_VEHICLE_ODOMETERS_FAILURE =
  'FETCH_VEHICLE_ODOMETERS_FAILURE';
export const UPDATE_AUDIT_LOG_FILTER = 'UPDATE_AUDIT_LOG_FILTER';

export const UPDATE_VEHICLE_ODOMETERS_FILTER =
  'UPDATE_VEHICLE_ODOMETERS_FILTER';

export const DOWNLOAD_EVENTS = 'DOWNLOAD_EVENTS';
export const DOWNLOAD_EVENTS_PROGRESS = 'DOWNLOAD_EVENTS_PROGRESS';
export const DOWNLOAD_EVENTS_SUCCESS = 'DOWNLOAD_EVENTS_SUCCESS';
export const DOWNLOAD_EVENTS_FAILURE = 'DOWNLOAD_EVENTS_FAILURE';
export const DOWNLOAD_EVENTS_CANCELLED = 'DOWNLOAD_EVENTS_CANCELLED';

export const FETCH_PAGED_EVENTS = 'FETCH_PAGED_EVENTS';
export const FETCH_PAGED_EVENTS_SUCCESS = 'FETCH_PAGED_EVENTS_SUCCESS';
export const FETCH_PAGED_EVENTS_FAILURE = 'FETCH_PAGED_EVENTS_FAILURE';
export const FETCH_PAGED_EVENTS_CANCELLED = 'FETCH_PAGED_EVENTS_CANCELLED';

export const UPDATE_EVENTS_FILTER = 'UPDATE_EVENTS_FILTER';
export const UPDATE_EVENTS_PARAMETERS = 'UPDATE_EVENTS_PARAMETERS';

export const FETCH_TRIPS = 'FETCH_TRIPS';
export const FETCH_TRIPS_SUCCESS = 'FETCH_TRIPS_SUCCESS';
export const FETCH_TRIPS_FAILURE = 'FETCH_TRIPS_FAILURE';
export const FETCH_TRIPS_CANCELLED = 'FETCH_TRIPS_CANCELLED';
export const UPDATE_TRIP_CLASSIFICATION = 'UPDATE_TRIP_CLASSIFICATION';
export const UPDATE_TRIP_CLASSIFICATION_SUCCESS =
  'UPDATE_TRIP_CLASSIFICATION_SUCCESS';
export const UPDATE_TRIP_CLASSIFICATION_FAILURE =
  'UPDATE_TRIP_CLASSIFICATIONS_FAILURE';
export const UPDATE_TRIP_DRIVER = 'UPDATE_TRIP_DRIVER';
export const UPDATE_TRIP_DRIVER_SUCCESS = 'UPDATE_TRIP_DRIVER_SUCCESS';
export const UPDATE_TRIP_DRIVER_FAILURE = 'UPDATE_TRIP_DRIVERS_FAILURE';

export const FETCH_SPEED_INFRACTIONS = 'FETCH_SPEED_INFRACTIONS';
export const FETCH_SPEED_INFRACTIONS_SUCCESS =
  'FETCH_SPEED_INFRACTIONS_SUCCESS';
export const FETCH_SPEED_INFRACTIONS_FAILURE =
  'FETCH_SPEED_INFRACTIONS_FAILURE';
export const FETCH_SPEED_INFRACTIONS_CANCELLED =
  'FETCH_SPEED_INFRACTIONS_CANCELLED';
export const UPDATE_SPEED_INFRACTIONS_FILTER =
  'UPDATE_SPEED_INFRACTIONS_FILTER';

export const FETCH_ACCELEROMETER_EVENTS = 'FETCH_ACCELEROMETER_EVENTS';
export const FETCH_ACCELEROMETER_EVENTS_SUCCESS =
  'FETCH_ACCELEROMETER_EVENTS_SUCCESS';
export const FETCH_ACCELEROMETER_EVENTS_FAILURE =
  'FETCH_ACCELEROMETER_EVENTS_FAILURE';
export const FETCH_ACCELEROMETER_EVENTS_CANCELLED =
  'FETCH_ACCELEROMETER_EVENTS_CANCELLED';
export const UPDATE_ACCELEROMETER_EVENTS_FILTER =
  'UPDATE_ACCELEROMETER_EVENTS_FILTER';

export const FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT =
  'TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT';
export const FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS =
  'FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS';
export const FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE =
  'FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE';
export const FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED =
  'FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED';
export const UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER =
  'UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER';

export const FETCH_GAZETTEER_RESULTS = 'FETCH_GAZETTEER_RESULTS';
export const FETCH_GAZETTEER_RESULTS_SUCCESS =
  'FETCH_GAZETTEER_RESULTS_SUCCESS';
export const FETCH_GAZETTEER_RESULTS_FAILURE =
  'FETCH_GAZETTEER_RESULTS_FAILURE';

export const FETCH_RADIOS = 'FETCH_RADIOS';
export const FETCH_RADIOS_SUCCESS = 'FETCH_RADIOS_SUCCESS';
export const FETCH_RADIOS_FAILURE = 'FETCH_RADIOS_FAILURE';
export const FETCH_RADIO = 'FETCH_RADIO';
export const FETCH_RADIO_SUCCESS = 'FETCH_RADIO_SUCCESS';
export const FETCH_RADIO_FAILURE = 'FETCH_RADIO_FAILURE';
export const CREATE_RADIO = 'CREATE_RADIO';
export const CREATE_RADIO_SUCCESS = 'CREATE_RADIO_SUCCESS';
export const CREATE_RADIO_FAILURE = 'CREATE_RADIO_FAILURE';
export const UPDATE_RADIO = 'UPDATE_RADIO';
export const UPDATE_RADIO_SUCCESS = 'UPDATE_RADIO_SUCCESS';
export const UPDATE_RADIO_FAILURE = 'UPDATE_RADIO_FAILURE';
export const DELETE_RADIO = 'DELETE_RADIO';
export const DELETE_RADIO_SUCCESS = 'DELETE_RADIO_SUCCESS';
export const DELETE_RADIO_FAILURE = 'DELETE_RADIO_FAILURE';
export const FETCH_RADIO_PEOPLE = 'FETCH_RADIO_PEOPLE';
export const FETCH_RADIO_PEOPLE_SUCCESS = 'FETCH_RADIO_PEOPLE_SUCCESS';
export const FETCH_RADIO_PEOPLE_FAILURE = 'FETCH_RADIO_PEOPLE_FAILURE';
export const REMOVE_RADIO = 'REMOVE_RADIO';
export const REMOVE_RADIO_SUCCESS = 'REMOVE_RADIO_SUCCESS';
export const REMOVE_RADIO_FAILURE = 'REMOVE_RADIO_FAILURE';
