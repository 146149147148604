import { RemoveCircle as RemoveIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef } from 'react';

export function ValueList({ values, handleChange, handleDeleteClick }) {
  const parentRef = useRef();
  const rowVirtualizer = useVirtualizer({
    count: values.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48,
    overscan: 5,
  });

  return (
    <Box ref={parentRef} sx={{ overflow: 'auto', maxHeight: 240 }}>
      <Box
        sx={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: 1,
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map(({ index, size, start }) => (
          <Stack
            key={index}
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: `${size}px`,
              transform: `translateY(${start}px)`,
            }}
          >
            <TextField
              value={values[index]}
              onChange={handleChange(index)}
              size="small"
              fullWidth
            />
            <Tooltip title="Remove" placement="right">
              <IconButton onClick={handleDeleteClick(index)} size="small">
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ))}
      </Box>
    </Box>
  );
}
