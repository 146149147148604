import { Parameters, Table, TablePagination } from '@/components/controls';
import { useAggregate, useDocumentTitle } from '@/hooks';
import { isEmpty } from '@/utils';
import { events, rowsPerPageOptions } from '@/utils/config';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { atom, useAtom } from 'jotai';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { downloadPipelineFn, headers, pipelineFn } from './utils';

const {
  eventFilters: { personDoubleCrews: eventFilters },
} = events;

function ReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleViewClick = (identifier) => () => {
    navigate(`/eventreplay/personDoubleCrews/${identifier}`);
  };

  return (
    <Tooltip title="View">
      <IconButton
        size="small"
        aria-label="View"
        onClick={handleViewClick(entry.identifier)}
      >
        <PlayArrowIcon />
      </IconButton>
    </Tooltip>
  );
}

const replayColumn = {
  label: '',
  key: 'replay',
  type: 'component',
  component: ReplayLink,
};

const stateAtom = atom({
  orderBy: 'startTime',
  order: 'asc',
  page: 0,
  rowsPerPage: rowsPerPageOptions[0],
  query: {},
  parameters: {},
});

export function DoubleCrews() {
  useDocumentTitle('IR3 | Double Crews');
  const [{ orderBy, order, page, rowsPerPage, query, parameters }, setState] =
    useAtom(stateAtom);
  const collection = 'personDoubleCrews';
  const pipeline = useMemo(
    () => pipelineFn(orderBy, order, page, rowsPerPage, query),
    [orderBy, order, page, rowsPerPage, query],
  );
  const totalsPipeline = [
    { $match: query },
    {
      $group: {
        _id: null,
        total: { $sum: 1 },
        durationSeconds: { $sum: '$durationSeconds' },
      },
    },
    { $unset: '_id' },
  ];
  const { data, isFetching, cancel } = useAggregate(
    collection,
    pipeline,
    !isEmpty(query),
    1000 * 60 * 60,
  );
  const {
    data: aggregated,
    isFetching: totalsFetching,
    cancel: cancelTotals,
  } = useAggregate(collection, totalsPipeline, !isEmpty(query), 1000 * 60 * 60);
  const { total, ...totals } = aggregated[0] || {};
  const csvColumns = headers.map(({ label, key }) => ({ header: label, key }));

  function handleCancel() {
    cancel();
    cancelTotals();
  }

  const handleStateChange = (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleStateChangeWithEvent = (name) => (event, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  function handleRowsPerPageChange(event) {
    setState((state) => ({
      ...state,
      rowsPerPage: event.target.value,
      page: 0,
    }));
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Parameters
        collection={collection}
        onFetch={handleStateChangeWithEvent('query')}
        onCancel={handleCancel}
        isFetching={isFetching || totalsFetching}
        sx={{ mt: 1, width: 264 }}
        value={parameters}
        onChange={handleStateChange('parameters')}
        people
        eventFilters={eventFilters}
        pipelineFn={downloadPipelineFn}
        columns={csvColumns}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Double Crews
          </Typography>
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Box sx={isFetching ? { opacity: 0.5 } : undefined}>
            <Table
              styles={{
                tableContainer: {
                  height: 'calc(100vh - 172px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={data}
              headers={headers.concat(replayColumn)}
              rowsPerPage={rowsPerPage}
              page={0}
              order={order}
              orderBy={orderBy}
              onOrderChange={handleStateChange('order')}
              onOrderByChange={handleStateChange('orderBy')}
              totals={totals}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={total ?? 0}
              rowsPerPage={rowsPerPage}
              page={total ? page : 0}
              onPageChange={handleStateChangeWithEvent('page')}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
