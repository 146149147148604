import { useDriverScores } from '@/hooks';
import { downloadCSV } from '@/utils';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import {
  MRT_TablePagination,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { driverColumns } from './utils';

export function DriverTable({ startDate, endDate, mode, query }) {
  const { data, isLoading, isFetching } = useDriverScores({
    startDate,
    endDate,
    mode,
    query,
  });

  function handleDownloadClick() {
    const formattedData = data.map(
      ({
        person,
        score,
        distanceKilometres,
        durationSeconds,
        accelerationSeconds,
        brakingSeconds,
        corneringSeconds,
        speedingSeconds,
      }) => ({
        code: person?.code ?? '',
        collarNumber: person?.collarNumber ?? '',
        name: [person?.forenames, person?.surname].join(' '),
        score,
        distanceMiles: distanceKilometres * 0.62137119,
        durationDays: durationSeconds / 86400,
        accelerationDays: accelerationSeconds / 86400,
        brakingDays: brakingSeconds / 86400,
        corneringDays: corneringSeconds / 86400,
        speedingDays: speedingSeconds / 86400,
      }),
    );

    const columns = [
      { label: 'Staff ID', key: 'code', type: 'text' },
      { label: 'Collar#', key: 'collarNumber', type: 'text' },
      { label: 'Name', key: 'name', type: 'text' },
      { label: 'Score (%)', key: 'score', type: 'number' },
      { label: 'Miles', key: 'distanceMiles', type: 'number' },
      { label: 'Duration', key: 'durationDays', type: 'number' },
      { label: 'Acceleration', key: 'accelerationDays', type: 'number' },
      { label: 'Braking', key: 'brakingDays', type: 'number' },
      { label: 'Cornering', key: 'corneringDays', type: 'number' },
      { label: 'Speeding', key: 'speedingDays', type: 'number' },
    ];

    downloadCSV(formattedData, 'drivers.csv', columns);
  }

  const table = useMaterialReactTable({
    data: data || [],
    columns: driverColumns,
    state: {
      density: 'compact',
      isLoading: isLoading || isFetching,
      columnPinning: { left: ['id'] },
    },
    defaultColumn: { size: 0 },
    enableTopToolbar: false,
    enableBottomToolbar: true,
    enablePagination: true,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableGlobalFilter: false,
    renderBottomToolbar: () => {
      return (
        <Stack
          direction="row"
          sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box>
            <Tooltip title="Download data">
              <IconButton size="small" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <MRT_TablePagination table={table} />
        </Stack>
      );
    },
  });

  return <MaterialReactTable table={table} />;
}
