import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subDays } from 'date-fns';

export function useSubgroupIncidentCounts(
  groupType,
  groupCode,
  subgroupType,
  days,
) {
  return useQuery({
    queryKey: [
      'subgroupIncidentCounts',
      groupType,
      groupCode,
      subgroupType,
      days,
    ],
    queryFn: async () => {
      const start = subDays(startOfDay(new Date()), days);
      const json = [
        {
          $match: {
            openedTime: { $gt: start },
            [`ward.groups.${groupType}`]: groupCode,
          },
        },
        subgroupType !== 'WARD' && {
          $unwind: {
            path: `$ward.groups.${subgroupType}`,
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $group: {
            _id: {
              identifier: {
                $ifNull: [
                  subgroupType === 'WARD'
                    ? '$ward.code'
                    : `$ward.groups.${subgroupType}`,
                  'UNKNOWN',
                ],
              },
              grade: '$grade',
            },
            count: { $sum: 1 },
          },
        },
        {
          $project: {
            _id: false,
            identifier: '$_id.identifier',
            grade: '$_id.grade',
            count: true,
          },
        },
        { $sort: { count: -1 } },
      ].filter(Boolean);

      const counts = await api.post('pipeline/incidents', { json }).json();

      return counts.reduce(
        (accumulator, { identifier, grade, count }) => {
          if (identifier in accumulator.groups === false) {
            accumulator.groups[identifier] = {};
          }
          accumulator.groups[identifier][grade] = count;

          if (grade in accumulator.grades === false) {
            accumulator.grades[grade] = 0;
          }
          accumulator.grades[grade] += count;

          return accumulator;
        },
        { grades: {}, groups: {} },
      );
    },
    placeholderData: { grades: {}, groups: {} },
    staleTime: 1000 * 60 * 60,
  });
}
