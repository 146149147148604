import {
  FETCH_TELEMATICS_BOXES,
  REMOVE_VEHICLE_TELEMATICS_BOX,
  UPDATE_VEHICLE_IMEI,
} from '@/actions';
import { ConfirmationDialog } from '@/components/dialogs';
import { Field } from '@/components/fields';
import { useUserInfo } from '@/hooks';
import { isAuthorised } from '@/utils';
import {
  Router as BoxIcon,
  DirectionsCar as CarIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Button,
  CardActions,
  CardHeader,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { isBefore } from 'date-fns';
import _ from 'lodash';
import {
  Children,
  Fragment,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8; // px

const Option = ({
  Icon,
  style,
  primary,
  secondaryLeft,
  secondaryRight,
  tertiary,
  itemProps,
}) => {
  const element = useMemo(() => {
    return (
      <ListItem dense {...itemProps} style={style}>
        <ListItemAvatar>
          <Avatar>
            <Icon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={primary}
          primaryTypographyProps={{
            variant: 'body2',
            style: { lineHeight: 1, marginTop: 4 },
          }}
          secondaryTypographyProps={{
            component: 'span',
            noWrap: true,
            lineheight: 1,
          }}
          secondary={
            <Fragment>
              <div style={{ display: 'flex' }}>
                {secondaryLeft}
                {secondaryRight && (
                  <Tooltip title={secondaryRight}>
                    <Typography
                      sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        flex: 1,
                        textAlign: 'right',
                        fontSize: 12,
                        ml: 2,
                      }}
                    >
                      {secondaryRight}
                    </Typography>
                  </Tooltip>
                )}
              </div>
              <div style={{ display: 'flex', fontSize: 12 }}>{tertiary}</div>
            </Fragment>
          }
        />
      </ListItem>
    );
  }, [
    itemProps,
    style,
    Icon,
    primary,
    secondaryLeft,
    secondaryRight,
    tertiary,
  ]);

  return cloneElement(element);
};

const RenderItem = ({ data, index, style }) => {
  const { options, isListOfVehicles } = data;
  const { children: id } = data[index].props;
  const option = options[id];
  const commonProps = { style, itemProps: data[index].props };

  return isListOfVehicles
    ? RenderVehicleOption(option, commonProps)
    : RenderTelematicsBoxOption(option, commonProps);
};

const RenderVehicleOption = (vehicle = {}, commonProps) => (
  <Option
    Icon={CarIcon}
    primary={vehicle.identificationNumber}
    secondaryLeft={vehicle.fleetNumber}
    secondaryRight={vehicle.registrationNumber}
    tertiary={vehicle.telematicsBoxImei}
    {...commonProps}
  />
);

const RenderTelematicsBoxOption = (box = {}, commonProps) => (
  <Option
    Icon={BoxIcon}
    primary={box.imei}
    secondaryLeft={box.fleetNumber}
    secondaryRight={box.registrationNumber}
    tertiary={box.identificationNumber}
    {...commonProps}
  />
);

const OuterElementContext = createContext({});

const OuterElementType = forwardRef(function OuterElementType(props, ref) {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, options, isListOfVehicles, ...other } = props;
  const itemData = Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 56;

  const getChildSize = (child) => {
    if (isValidElement(child) && child.type === ListSubheader) {
      return 56;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);
  const optionsById = _.keyBy(
    options,
    isListOfVehicles ? 'identificationNumber' : 'imei',
  );

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={{
            ...itemData,
            options: optionsById,
            isListOfVehicles,
          }}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {RenderItem}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const vehicleHasNoTelematicsBox = (value) => {
  if (value?.telematicsBoxImei) {
    return 'Vehicle is already associated, remove the association first.';
  }

  return undefined;
};

const telematicsBoxHasNoVehicle = (value) => {
  if (value?.identificationNumber) {
    return 'Telematics box is already associated, remove the association first.';
  }

  return undefined;
};

export function TelematicsBoxVehicles({ imei, identificationNumber }) {
  // if we are looking at an imei, the list will be a selection of vehicles
  const isListOfVehicles = !!imei;

  const dispatch = useDispatch();

  const boxes = useSelector((state) => state.telematicsBoxes.boxesByImei);
  const updatingImei = useSelector(
    (state) => state.telematicsBoxes.updatingImei,
  );
  const availableBoxes = Object.values(boxes); //.filter(b => !!b.active);
  const box = boxes[imei];

  const vehicles = useSelector((state) => state.telematicsBoxes.vehiclesById);
  const availableVehicles = Object.values(vehicles).filter(
    (v) => !isBefore(new Date(v.disposalDate ?? '2099-01-01'), new Date()),
  );
  const vehicle = vehicles[identificationNumber];

  const [removeOpen, setRemoveOpen] = useState(false);
  const [selected, setSelected] = useState();
  const { data: userInfo } = useUserInfo();
  const hasVehicleAuth = isAuthorised(
    userInfo?.authorisation,
    'vehicles',
    true,
  );

  // get the tbs when starting up if there's none there already
  useEffect(() => {
    if (availableBoxes.length === 0) {
      dispatch({ type: FETCH_TELEMATICS_BOXES });
    }
  }, [dispatch, availableBoxes]);

  function handleRemove() {
    const payload = {
      identificationNumber: encodeURIComponent(
        identificationNumber || box.identificationNumber,
      ),
      previousImei: imei || vehicle.telematicsBoxImei,
      telematicsBoxImei: '',
    };

    dispatch({
      type: REMOVE_VEHICLE_TELEMATICS_BOX,
      payload,
    });

    setRemoveOpen(false);
    setSelected();
  }

  function handleRemoveClick(vehicle) {
    setSelected(vehicle);
    setRemoveOpen(true);
  }

  function handleCancel() {
    setSelected({});
    setRemoveOpen(false);
  }

  function addImeiToVehicle() {
    // console.log(vehicle);
    const vehicle = {
      identificationNumber:
        identificationNumber || selected.identificationNumber,
    };
    const telematicsBoxImei = imei || selected.imei;

    dispatch({
      type: UPDATE_VEHICLE_IMEI,
      payload: {
        vehicle,
        telematicsBoxImei,
      },
    });
  }

  const renderVehicle = () =>
    (box.isMultiAssigned ? box.multiAssignments : [box]).map(
      (vehicle, index) => (
        <div key={index}>
          <CardHeader
            sx={{ pt: 0, pb: 0 }}
            avatar={
              <Avatar aria-label="Vehicle">
                <CarIcon />
              </Avatar>
            }
            title={vehicle.registrationNumber}
            subheader={vehicle.fleetNumber}
          />
          <CardActions>
            <Button
              color="primary"
              aria-label="View"
              component={Link}
              to={`/resources/vehicles/${vehicle.identificationNumber}`}
            >
              View
            </Button>
            {hasVehicleAuth && (
              <Button
                color="error"
                onClick={handleRemoveClick}
                disabled={updatingImei}
              >
                {updatingImei ? 'Removing' : 'Remove'}
              </Button>
            )}
            {updatingImei && <CircularProgress size={16} />}
          </CardActions>
        </div>
      ),
    );

  const renderBox = () => {
    const box = boxes[vehicle.telematicsBoxImei];
    // if no box, invalid imei!
    return (
      <div>
        <CardHeader
          sx={{ py: 0 }}
          avatar={
            <Avatar
              style={!box ? { background: red[500] } : {}}
              aria-label="Telematics box"
            >
              <BoxIcon />
            </Avatar>
          }
          title={vehicle.telematicsBoxImei}
          subheader={!box ? 'IMEI does not exist' : ''}
        />
        <CardActions>
          <Button
            color="primary"
            aria-label="View"
            component={Link}
            disabled={!box}
            to={`/resources/telematicsBoxes/${vehicle.telematicsBoxImei}`}
          >
            View
          </Button>
          {hasVehicleAuth && (
            <Button color="error" onClick={handleRemoveClick}>
              {updatingImei ? 'Removing' : 'Remove'}
            </Button>
          )}
          {updatingImei && <CircularProgress size={16} />}
        </CardActions>
      </div>
    );
  };

  function renderChooser() {
    return (
      <div>
        <div>
          <Field
            type="autocomplete"
            name="vehicle"
            validate={
              isListOfVehicles
                ? vehicleHasNoTelematicsBox
                : telematicsBoxHasNoVehicle
            }
          >
            {(props) => (
              <Autocomplete
                size="small"
                ListboxComponent={ListboxComponent}
                ListboxProps={{ options, isListOfVehicles }}
                // fullWidth
                // value={!!props.input.value ? props.input.value : undefined}
                options={options}
                getOptionLabel={(option) =>
                  option.imei || option.identificationNumber || ''
                }
                isOptionEqualToValue={(option, selected) => {
                  return option === selected;
                }}
                onChange={(e, value) => {
                  props.input.onChange(value);
                  setSelected(value);
                }}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((o) =>
                    [
                      'identificationNumber',
                      'fleetNumber',
                      'registrationNumber',
                      'imei',
                      'telematicsBoxImei',
                    ].some((p) =>
                      o[p]?.toLowerCase().includes(inputValue.toLowerCase()),
                    ),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...props}
                    error={!!props.meta.error}
                    helperText={props.meta.error}
                    label={`Search for a ${
                      isListOfVehicles ? 'vehicle' : 'telematics box'
                    }...`}
                    variant="outlined"
                  />
                )}
              ></Autocomplete>
            )}
          </Field>
        </div>
        <div>
          <Button
            color="primary"
            onClick={addImeiToVehicle}
            disabled={!selected || unavailable || updatingImei}
          >
            {updatingImei ? 'Adding' : 'Add'}
          </Button>
          {updatingImei && <CircularProgress size={16} />}
          <Button
            color="primary"
            aria-label="View"
            component={Link}
            disabled={!selected || !unavailable}
            to={`/resources/vehicles/${selected?.identificationNumber}`}
          >
            View Vehicle
          </Button>
          <Button
            color="primary"
            aria-label="View"
            component={Link}
            disabled={!selected || !unavailable}
            to={`/resources/telematicsBoxes/${selected?.telematicsBoxImei}`}
          >
            View Telematics Box
          </Button>
        </div>
      </div>
    );
  }

  function renderContent() {
    // if there's no association let the user add a vehicle if they have permission
    // to add vehicles, or let them add a telematics box (no permission needed as
    // they'll be seeing this on the vehicle page anyways)
    // otherwise this control will be blank: no association to show and no
    // permission to do anything about it
    if (!hasAssociation) {
      if (hasVehicleAuth) {
        return renderChooser();
      } else {
        return isListOfVehicles
          ? 'No vehicle associated'
          : 'No telematics box associated';
      }
    } else {
      return isListOfVehicles ? renderVehicle() : renderBox();
    }
  }

  const options = isListOfVehicles ? availableVehicles : availableBoxes;
  const unavailable = isListOfVehicles
    ? !!selected?.telematicsBoxImei
    : !!selected?.identificationNumber;
  const hasAssociation = isListOfVehicles
    ? box?.identificationNumber
    : vehicle?.telematicsBoxImei;

  return (
    <Fragment>
      {renderContent()}
      <ConfirmationDialog
        action="Remove"
        open={removeOpen}
        itemId={
          selected?.registrationNumber ||
          selected?.fleetNumber ||
          selected?.telematicsBoxImei ||
          ''
        }
        onOk={handleRemove}
        onCancel={handleCancel}
      />
    </Fragment>
  );
}
