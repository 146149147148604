import {
  useLocations,
  useLocationTypes,
  useOptions,
  usePeople,
  useRadios,
  useRfidCards,
  useTelematicsBoxes,
  useVehicles,
} from '@/hooks';
import { baseType, retrospective, useDallasKeys } from '@/utils/config';
import { incidentFilters } from '@/utils/constants';
import { format, isValid } from 'date-fns';
import { useMemo } from 'react';

function getGroupsFilters(groups = []) {
  return groups.reduce((acc, { value, label, values = [] }) => {
    acc[`groups.${value}`] = {
      label,
      type: 'multiselect',
      values: values.sort((a, b) => a.label.localeCompare(b.label)),
    };

    return acc;
  }, {});
}

function fieldValueToOptions(items, field) {
  return items
    .map((item) => item[field])
    .filter(Boolean)
    .sort()
    .map((item) => ({ value: item.value ?? item, label: item.label ?? item }));
}

export function useFilters() {
  const { data: vehicles } = useVehicles();
  const { data: people } = usePeople();
  const { data: telematicsBoxes } = useTelematicsBoxes();
  const { data: radios } = useRadios();
  const { data: rfidCards } = useRfidCards();
  const { data: vehicleTypes } = useOptions('vehicleType');
  const { data: vehicleRoles } = useOptions('vehicleRole');
  const { data: locations } = useLocations();
  const { data: homeStationNames } = useLocations({ homeStations: true });
  const { data: incidentGrades } = useOptions('incidentGrade');
  const { data: personRanks } = useOptions('personRank');
  const { data: personRoles } = useOptions('personRole');
  const { data: vehicleGroups } = useOptions('vehicleGroup');
  const { data: personGroups } = useOptions('personGroup');
  const { data: locationGroups } = useOptions('locationGroup');
  const { data: locationTypes } = useLocationTypes();

  return useMemo(() => {
    const identificationNumber = {
      label: 'VIN',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(vehicles, 'identificationNumber'),
    };
    const registrationNumber = {
      label: 'Registration',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(vehicles, 'registrationNumber'),
    };
    const fleetNumber = {
      label: 'Fleet Number',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(vehicles, 'fleetNumber'),
    };
    const type = {
      label: 'Type',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: vehicleTypes,
    };
    const role = {
      label: 'Role',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: vehicleRoles,
    };
    const telematicsBoxImei = {
      label: 'IMEI',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(telematicsBoxes, 'imei'),
    };
    const homeStation = {
      label: `Home ${baseType.label}`,
      type: 'multiselect',
      showNonExistantAsError: true,
      values: homeStationNames
        .map(({ name, code }) => ({
          label: name,
          value: code,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    };

    const forenames = {
      label: 'Forenames',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(people, 'forenames'),
    };
    const surname = {
      label: 'Surname',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(people, 'surname'),
    };
    const code = {
      label: 'Payroll Number',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(people, 'code'),
    };
    const collarNumber = {
      label: 'Collar Number',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(people, 'collarNumber'),
    };
    const radioSsi = {
      label: 'Radio SSI',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(radios, 'ssi'),
    };
    const identificationReference = {
      label: useDallasKeys ? 'Dallas Key' : 'RFID Card',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(rfidCards, 'reference'),
    };
    const rank = {
      label: 'Rank',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: personRanks,
    };
    const roleP = {
      label: 'Role',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: personRoles,
    };

    const locationCode = {
      label: 'Code',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(locations, 'code'),
    };
    const locationName = {
      label: 'Name',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(locations, 'name'),
    };
    const locationType = {
      label: 'Type',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: Object.values(locationTypes)
        .map(({ value, label }) => ({ value, label }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    };
    const locationSubtype = {
      label: 'Subtype',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: Object.values(locationTypes)
        .map(({ values }) => values)
        .flat()
        .sort((a, b) => a.label.localeCompare(b.label)),
    };

    // intersections have visits precalculated but only for a select few types
    const visitLocationCode = {
      label: 'Code',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(locations, 'code'),
    };
    const visitLocationName = {
      label: 'Name',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(locations, 'name'),
    };
    const visitLocationType = {
      label: 'Type',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: Object.values(locationTypes)
        .filter(({ value }) => retrospective.visitLocationTypes.includes(value))
        .map(({ value, label }) => ({ value, label }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    };

    const durationSeconds = {
      label: 'Duration',
      type: 'duration',
    };

    const startTime = {
      label: 'Start Time',
      type: 'date',
    };

    const endTime = {
      label: 'End Time',
      type: 'date',
    };

    const maxSpeedKilometresPerHour = {
      label: 'Max Speed',
      type: 'miles',
      unit: 'mph',
    };
    const startLocationName = {
      label: 'Start Location Name',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(locations, 'name'),
    };
    const startLocationType = {
      label: 'Start Location Type',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: Object.values(locationTypes)
        .map(({ value, label }) => ({ value, label }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    };
    const endLocationName = {
      label: 'End Location Name',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(locations, 'name'),
    };
    const endLocationType = {
      label: 'End Location Type',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: Object.values(locationTypes)
        .map(({ value, label }) => ({ value, label }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    };

    const incidentLocationName = {
      label: 'Location Name',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: fieldValueToOptions(locations, 'name'),
    };
    const incidentLocationType = {
      label: 'Location Type',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: Object.values(locationTypes)
        .map(({ value, label }) => ({ value, label }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    };
    const reference = {
      label: 'Incident Number',
      type: 'number',
      onlyEqual: true,
      // parse: (x) => x?.toString() || '',
    };
    const date = {
      label: 'Date',
      type: 'date',
      onlyEqual: true,
      parse: (x) => {
        const date = new Date(x);
        if (isValid(date)) {
          return format(date, 'yyyy-MM-dd');
        }
        return '';
      },
    };
    const incidentTypeCode = {
      label: 'Type',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: Object.entries(incidentFilters.types)
        .map(([value, label]) => ({ value, label }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    };
    const responseCategory = {
      label: 'Response Category',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: incidentFilters.categories,
    };
    const responseSeconds = {
      label: 'Response Duration',
      type: 'duration',
    };
    const wardName = {
      label: 'Ward',
      type: 'multiselect',
      values: fieldValueToOptions(
        locations.filter(({ type }) => type === 'Ward'),
        'name',
      ),
    };
    const wardType = {
      label: 'Ward Type',
      type: 'select',
      values: locationTypes.wards?.values ?? [],
    };
    const grade = {
      label: 'Grade',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: incidentGrades,
    };
    const status = {
      label: 'Status',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: incidentFilters.status.sort().map((value) => ({
        value,
        label: value,
      })),
    };
    const closingCode = {
      label: 'Closing Code',
      type: 'multiselect',
      showNonExistantAsError: true,
      values: Object.entries(incidentFilters.closingCodes)
        .map(([value, label]) => ({ value, label }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    };
    const microbeat = {
      label: 'Microbeat',
      type: 'microbeat',
    };
    const groupCodes = {
      label: 'Groups & Areas',
      type: 'groups',
    };
    const vehicleGroupFilters = getGroupsFilters(vehicleGroups);
    const personGroupFilters = getGroupsFilters(personGroups);
    const locationGroupFilters = getGroupsFilters(locationGroups);

    const filters = {
      vehicle: {
        identificationNumber,
        registrationNumber,
        fleetNumber,
        role,
        type,
        homeStation,
        telematicsBoxImei,
        groupCodes,
        ...vehicleGroupFilters,
      },
      person: {
        forenames,
        surname,
        code,
        collarNumber,
        radioSsi,
        'rank.code': rank,
        role: roleP,
        homeStation,
        groupCodes,
        ...personGroupFilters,
      },
      driver: {
        forenames,
        surname,
        code,
        collarNumber,
        identificationReference,
        'rank.code': rank,
        role: roleP,
        homeStation,
        groupCodes,
        ...personGroupFilters,
      },
      lastDriver: {
        forenames,
        surname,
        code,
        collarNumber,
        identificationReference,
        'rank.code': rank,
        role: roleP,
        homeStation,
        groupCodes,
        ...personGroupFilters,
      },
      location: {
        code: locationCode,
        name: locationName,
        type: locationType,
        subtype: locationSubtype,
        ...locationGroupFilters,
      },
      visitLocation: {
        code: visitLocationCode,
        name: visitLocationName,
        type: visitLocationType,
      },
      incident: {
        // number,
        reference,
        date,
        'type.code': incidentTypeCode,
        'responseCategory.code': responseCategory,
        responseSeconds,
        grade,
        status,
        'closingCodes.code': closingCode,
        'locations.name': incidentLocationName,
        'locations.type': incidentLocationType,
        'ward.name': wardName,
        'ward.type': wardType,
        microbeat,
      },
      trip: {
        startTime,
        endTime,
        durationSeconds,
        maxSpeedKilometresPerHour,
        'startLocations.name': startLocationName,
        'startLocations.type': startLocationType,
        'endLocations.name': endLocationName,
        'endLocations.type': endLocationType,
      },
      personVisit: {
        startTime,
        endTime,
        durationSeconds,
      },
      accelerometerEvent: {
        startTime,
        endTime,
      },
      stop: {
        durationSeconds,
        'locations.name': locationName,
        'locations.type': locationType,
      },
      poll: {
        microbeat,
      },
    };

    return filters;
  }, [
    vehicles,
    vehicleTypes,
    vehicleRoles,
    telematicsBoxes,
    homeStationNames,
    people,
    radios,
    rfidCards,
    personRanks,
    personRoles,
    locations,
    incidentGrades,
    locationGroups,
    vehicleGroups,
    personGroups,
    locationTypes,
  ]);
}
