import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useRetention(id) {
  return useQuery({
    queryKey: ['retentions', id],
    queryFn: async () => {
      if (id === 'new') {
        return {
          notes: '',
        };
      }

      const json = [
        { $match: { identifier: id } },
        {
          $lookup: {
            from: 'vehicles',
            localField: 'imei',
            foreignField: 'telematicsBoxImei',
            as: 'vehicle',
          },
        },
        { $set: { vehicle: { $first: '$vehicle' } } },
        {
          $project: {
            identifier: true,
            imei: true,
            startTime: true,
            endTime: true,
            notes: true,
            vehicle: {
              identificationNumber: true,
              registrationNumber: true,
              fleetNumber: true,
              groups: true,
              role: true,
              type: true,
            },
          },
        },
      ];

      const retentions = await api
        .post('pipeline/telematicsBoxRetentions', { json })
        .json();

      log('Read', 'Data Retentions', { id });

      return retentions[0] ?? null;
    },
    staleTime: 1000 * 60 * 60,
    enabled: !!id,
  });
}
