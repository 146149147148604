import { useEffect } from 'react';

/**
 * Set the document title.
 * @param {string} title - The title to set.
 * @returns {null}
 */
export function useDocumentTitle(title) {
  useEffect(() => {
    document.title = title ?? 'IR3';
  }, [title]);

  return null;
}
