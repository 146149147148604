import { useDocumentTitle } from '@/hooks';
import { Stack } from '@mui/material';
import { OptionsTable } from './OptionsTable';
import { TypedOptionsTable } from './TypedOptionsTable';

export function PersonOptions() {
  useDocumentTitle('IR3 | Person Options');

  return (
    <Stack direction="row" spacing={1} sx={{ p: 1 }}>
      <OptionsTable name="personRank" title="Ranks" />
      <OptionsTable name="personRole" title="Roles" />
      <TypedOptionsTable name="personAttribute" title="Attributes" />
      <TypedOptionsTable name="personGroup" title="Categories" />
      <TypedOptionsTable name="personSkill" title="Skills" />
    </Stack>
  );
}
