import { getAllValues } from '@/utils';
import { Stack } from '@mui/material';
import { FilterField } from './FilterField';

export function IncidentFilter({
  value = {},
  onChange,
  featureCollection = { features: [] },
}) {
  const handleFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      [field]: update,
    });
  };

  return (
    <Stack spacing={1} sx={{ maxHeight: 238, overflow: 'scroll', pt: 0.75 }}>
      <FilterField
        label="Grade"
        value={value.grade}
        onChange={handleFilterChange('grade')}
        options={getAllValues(featureCollection.features, 'properties.grade')}
      />
      <FilterField
        label="Code"
        value={value.code}
        onChange={handleFilterChange('code')}
        options={getAllValues(
          featureCollection.features,
          'properties.type.code',
        )}
      />
      <FilterField
        label="Type"
        value={value.type}
        onChange={handleFilterChange('type')}
        options={getAllValues(
          featureCollection.features,
          'properties.type.name',
        )}
      />
      {/* <FilterField
        label="Status"
        value={value.status}
        onChange={handleFilterChange('status')}
        options={getAllValues(featureCollection.features, 'properties.status')}
      /> */}
    </Stack>
  );
}
