import { getBackgroundColor } from '@/utils';
import { Text, View } from '@react-pdf/renderer';

export function ScoreCardView({ title, value, color }) {
  return (
    <View
      style={{
        padding: 8,
        color,
        backgroundColor: getBackgroundColor(color),
        borderRadius: 4,
      }}
    >
      <Text style={{ fontSize: 8 }}>{title}</Text>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        <Text style={{ fontSize: 24, fontWeight: 'bold' }}>
          {Math.round(value)}
        </Text>
        <Text style={{ fontSize: 12 }}>%</Text>
      </View>
    </View>
  );
}
