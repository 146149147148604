import { api } from '@/apis';
import { isEmpty, log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useVehicleHourlyActivities(query, days, offsets = ['all']) {
  const queryClient = useQueryClient();
  const queryHook = useQuery({
    queryKey: ['vehicleHourlyActivities', query, days, offsets],
    queryFn: ({ signal }) => {
      const json = [
        {
          $match: {
            ...query,
            ...(days
              ? {
                  $expr: {
                    $in: [{ $dayOfWeek: '$time' }, days.map((day) => day + 1)],
                  },
                }
              : {}),
          },
        },
        {
          $group: {
            _id: null,
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_movingSeconds`]: {
                  $avg: { $ifNull: [`$moving.${offset}.durationSeconds`, 0] },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_stoppedHomeBaseSeconds`]: {
                  $avg: {
                    $ifNull: [`$stopped.${offset}.homeBaseSeconds`, 0],
                  },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_stoppedOtherBaseSeconds`]: {
                  $avg: {
                    $ifNull: [`$stopped.${offset}.otherBaseSeconds`, 0],
                  },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_stoppedWorkshopSeconds`]: {
                  $avg: {
                    $ifNull: [`$stopped.${offset}.workshopSeconds`, 0],
                  },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_stoppedElsewhereSeconds`]: {
                  $avg: { $ifNull: [`$stopped.${offset}.elsewhereSeconds`, 0] },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_idleHomeBaseSeconds`]: {
                  $avg: { $ifNull: [`$idle.${offset}.homeBaseSeconds`, 0] },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_idleOtherBaseSeconds`]: {
                  $avg: { $ifNull: [`$idle.${offset}.otherBaseSeconds`, 0] },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_idleWorkshopSeconds`]: {
                  $avg: { $ifNull: [`$idle.${offset}.workshopSeconds`, 0] },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_idleElsewhereSeconds`]: {
                  $avg: { $ifNull: [`$idle.${offset}.elsewhereSeconds`, 0] },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_unaccountableSeconds`]: {
                  $avg: {
                    $ifNull: [`$unaccountable.${offset}.durationSeconds`, 0],
                  },
                },
              }),
              {},
            ),
          },
        },
        {
          $project: {
            offsets: offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [offset]: {
                  offset: { $toInt: offset },
                  movingMinutes: {
                    $round: [
                      {
                        $divide: [
                          {
                            $subtract: [
                              `$${offset}_movingSeconds`,
                              {
                                $sum: [
                                  `$${offset}_idleHomeBaseSeconds`,
                                  `$${offset}_idleOtherBaseSeconds`,
                                  `$${offset}_idleWorkshopSeconds`,
                                  `$${offset}_idleElsewhereSeconds`,
                                ],
                              },
                            ],
                          },
                          60,
                        ],
                      },
                      2,
                    ],
                  },
                  stoppedHomeBaseMinutes: {
                    $round: [
                      { $divide: [`$${offset}_stoppedHomeBaseSeconds`, 60] },
                      2,
                    ],
                  },
                  stoppedOtherBaseMinutes: {
                    $round: [
                      { $divide: [`$${offset}_stoppedOtherBaseSeconds`, 60] },
                      2,
                    ],
                  },
                  stoppedWorkshopMinutes: {
                    $round: [
                      { $divide: [`$${offset}_stoppedWorkshopSeconds`, 60] },
                      2,
                    ],
                  },
                  stoppedElsewhereMinutes: {
                    $round: [
                      { $divide: [`$${offset}_stoppedElsewhereSeconds`, 60] },
                      2,
                    ],
                  },
                  idleHomeBaseMinutes: {
                    $round: [
                      { $divide: [`$${offset}_idleHomeBaseSeconds`, 60] },
                      2,
                    ],
                  },
                  idleOtherBaseMinutes: {
                    $round: [
                      { $divide: [`$${offset}_idleOtherBaseSeconds`, 60] },
                      2,
                    ],
                  },
                  idleWorkshopMinutes: {
                    $round: [
                      { $divide: [`$${offset}_idleWorkshopSeconds`, 60] },
                      2,
                    ],
                  },
                  idleElsewhereMinutes: {
                    $round: [
                      { $divide: [`$${offset}_idleElsewhereSeconds`, 60] },
                      2,
                    ],
                  },
                  unaccountableMinutes: {
                    $round: [
                      { $divide: [`$${offset}_unaccountableSeconds`, 60] },
                      2,
                    ],
                  },
                },
              }),
              {},
            ),
          },
        },
        { $project: { offsets: { $objectToArray: '$offsets' } } },
        { $unwind: '$offsets' },
        { $replaceRoot: { newRoot: '$offsets.v' } },
      ];

      log('Read', 'Vehicle Hourly Activities', {
        query,
        days,
        offsets,
      });

      return api
        .post('pipeline/vehicleDailyActivities', { json, signal })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !isEmpty(query),
  });

  return {
    ...queryHook,
    cancel: () =>
      queryClient.cancelQueries({
        queryKey: ['vehicleHourlyActivities', query, days, offsets],
      }),
  };
}
