import { getColor } from '@/utils';
import { useDallasKeys } from '@/utils/config';
import { Card, CardContent, Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { useRfidCardAssignmentCounts } from './useRfidCardAssignmentCounts';

const columns = [
  {
    accessorKey: 'assignments',
    header: 'Assignments',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    size: 0,
  },
  {
    accessorKey: 'cards',
    header: 'Cards',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    size: 0,
  },
];

export function CardAssignmentsCard({ groupCode }) {
  const { data } = useRfidCardAssignmentCounts();

  return (
    <Card variant="outlined" sx={{ flex: 1 }}>
      <CardContent>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          {useDallasKeys ? 'Dallas Key' : 'RFID Card'} Assignments
        </Typography>
        <MaterialReactTable
          data={data}
          columns={columns}
          initialState={{
            density: 'compact',
          }}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          enableSorting={false}
          muiTableBodyRowProps={({ row }) => ({
            sx: getColor(row.getValue('assignments')),
            hover: false,
          })}
        />
      </CardContent>
    </Card>
  );
}
