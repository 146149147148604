import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useInsertOption() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ name, value, label }) => {
      return api.post('options', { json: { name, value, label } }).json();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['options'] }),
  });
}
