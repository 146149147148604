import { useOptionLookup } from '@/hooks';
import { parseIfJSON, startCase } from '@/utils';
import { Box, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Popup } from 'react-map-gl/maplibre';
import { DetailsPanel } from './DetailsPanel';
import { Status } from './Status';

export function EventPopup({
  hoverInfo: {
    longitude,
    latitude,
    feature: { properties },
  },
}) {
  const vehicleGroups = useOptionLookup('vehicleGroup');
  const vehicle = parseIfJSON(properties.vehicle);
  const maximumForces = parseIfJSON(properties.maximumForces);

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={16}
      closeButton={false}
    >
      <Stack spacing={0.5}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">Accelerometer Event</Typography>
          {vehicle?.registrationNumber && (
            <Box
              sx={{
                fontSize: 10,
                color: 'secondary.contrastText',
                bgcolor: 'secondary.main',
                borderRadius: 1,
                px: 0.5,
                fontWeight: 'bold',
              }}
            >
              {vehicle?.registrationNumber}
            </Box>
          )}
        </Stack>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {format(new Date(properties.startTime), 'dd/MM HH:mm:ss')}
        </Typography>
        <Status status={properties.status} />
        <DetailsPanel
          entries={[
            { label: 'Vertical', value: `${maximumForces.vertical}G` },
            { label: 'Horizontal', value: `${maximumForces.horizontal}G` },
            { label: 'Lateral', value: `${maximumForces.lateral}G` },
          ]}
        />
        {vehicle && (
          <DetailsPanel
            title="Vehicle"
            entries={[
              { label: 'Fleet #', value: vehicle.fleetNumber },
              { label: 'Role', value: vehicle.role },
              { label: 'Home Station', value: vehicle.homeStation },
              ...Object.entries(vehicle.groups ?? {}).map(
                ([type, codes = []]) => ({
                  label: vehicleGroups[type]?.label ?? startCase(type),
                  value: codes.join?.(', ') ?? codes,
                }),
              ),
            ]}
          />
        )}
      </Stack>
    </Popup>
  );
}
