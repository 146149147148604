import { api } from '@/apis';
import { encodeParams, log, round } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useOdometers(date) {
  return useQuery({
    queryKey: ['odometers', date],
    queryFn: async ({ signal }) => {
      const response = await api
        .get('vehicleOdometers', {
          searchParams: encodeParams({ time: date }),
          signal,
        })
        .json();

      log('Read', 'Vehicle Mileage', { date });

      return (response || []).map(
        ({
          latestPoll,
          lastReadingPoll,
          lastOdometerReading,
          calculatedOdometerReading,
          ...reading
        }) => {
          return {
            ...reading,
            readingTime: lastOdometerReading
              ? new Date(lastOdometerReading.time)
              : null,
            latestPollTime: latestPoll ? new Date(latestPoll.time) : null,
            pollAfterGapHours: lastReadingPoll
              ? round(
                  lastReadingPoll.odometerReadingDifferenceSeconds / 3600,
                  2,
                )
              : null,
            pollAfterReadingTime: lastReadingPoll
              ? new Date(lastReadingPoll.time)
              : null,
            readingMiles: lastOdometerReading
              ? round(lastOdometerReading.distanceKilometres * 0.62137119, 2)
              : null,
            pollAfterReadingMiles: lastReadingPoll
              ? round(lastReadingPoll.distanceKilometres * 0.62137119, 2)
              : null,
            latestPollMiles: latestPoll
              ? round(latestPoll.distanceKilometres * 0.62137119, 2)
              : null,
            calculatedMiles: calculatedOdometerReading
              ? round(
                  calculatedOdometerReading.distanceKilometres * 0.62137119,
                  2,
                )
              : null,
          };
        },
      );
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!date,
  });
}
