import { useDocumentTitle } from '@/hooks';
import { Stack } from '@mui/material';
import { OptionsTable } from './OptionsTable';
import { TypedOptionsTable } from './TypedOptionsTable';

export function VehicleOptions() {
  useDocumentTitle('IR3 | Vehicle Options');

  return (
    <Stack direction="row" spacing={1} sx={{ p: 1 }}>
      <OptionsTable name="vehicleRole" title="Roles" />
      <OptionsTable name="vehicleType" title="Types" />
      <OptionsTable name="vehicleFuelType" title="Fuel Types" />
      <TypedOptionsTable name="vehicleAttribute" title="Attributes" />
      <TypedOptionsTable name="vehicleGroup" title="Categories" />
      <OptionsTable name="vehicleEquipment" title="Equipment" />
    </Stack>
  );
}
