import { api } from '@/apis';
import { disableNotifications } from '@/utils/config';
import { useQuery } from '@tanstack/react-query';

export function useNotifications() {
  return useQuery({
    queryKey: ['notifications'],
    queryFn: () => {
      return api
        .post('pipeline/userNotifications', {
          json: [
            {
              $project: {
                identifier: true,
                time: true,
                user: true,
                type: true,
                title: true,
                message: true,
              },
            },
            { $sort: { time: -1 } },
          ],
        })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !disableNotifications,
  });
}
