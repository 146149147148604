import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { endOfDay, isValid } from 'date-fns';

export function useUpsertObjective() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ _id, endTime, ...values }) => {
      const json = {
        endTime: isValid(endTime) ? endOfDay(endTime) : endTime,
        ...values,
      };

      if (_id) {
        return api
          .patch(`objectives/${json.identifier}`, {
            headers: { 'content-type': 'application/merge-patch+json' },
            json,
          })
          .json();
      } else {
        return api.post('objectives', { json }).json();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['objectives'] }),
  });
}
