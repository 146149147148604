import { Text, View } from '@react-pdf/renderer';

function VehicleRow({ first, fleetNumber, registrationNumber, measure }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        borderTopWidth: first ? 0 : 1,
        borderTopColor: '#ccc',
        borderTopStyle: 'solid',
      }}
    >
      <View style={{ width: '40%', padding: 4 }}>
        <Text>{fleetNumber}</Text>
      </View>
      <View
        style={{
          width: '40%',
          padding: 4,
          borderLeftWidth: 1,
          borderLeftColor: '#ccc',
          borderLeftStyle: 'solid',
        }}
      >
        <Text>{registrationNumber}</Text>
      </View>
      <View
        style={{
          width: '20%',
          padding: 4,
          borderLeftWidth: 1,
          borderLeftColor: '#ccc',
          borderLeftStyle: 'solid',
          textAlign: 'right',
          fontWeight: 'bold',
        }}
      >
        <Text>{Math.round(measure)}%</Text>
      </View>
    </View>
  );
}

export function VehicleTableView({ title, rows }) {
  return (
    <View style={{ gap: 8, paddingBottom: 8 }}>
      <Text
        style={{
          fontSize: 12,
          fontWeight: 'bold',
        }}
      >
        {title}
      </Text>
      <View
        style={{
          border: 1,
          borderColor: '#ccc',
          borderStyle: 'solid',
          borderRadius: 4,
          fontSize: 10,
        }}
      >
        {rows.map((row, index) => (
          <VehicleRow key={row.code} first={index === 0} {...row} />
        ))}
      </View>
    </View>
  );
}
