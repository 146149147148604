import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useUserQueryUserIds() {
  return useQuery({
    queryKey: ['userQueries', 'users'],
    queryFn: async () => {
      const json = [
        { $group: { _id: null, userId: { $addToSet: '$created.userId' } } },
        { $unwind: '$userId' },
        { $project: { _id: 0 } },
        { $sort: { userId: 1 } },
      ];
      const results = await api.post('pipeline/userQueries', { json }).json();

      return results.map(({ userId }) => userId);
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
