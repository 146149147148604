import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { startOfToday, subMonths } from 'date-fns';

export function useVehicleUtilisationSummary(
  groupCodes = [],
  subgroupType,
  months = 3,
  roles = [],
) {
  const endTime = startOfToday();
  const startTime = subMonths(endTime, months);

  return useQuery({
    queryKey: [
      'vehicleUtilisationSummary',
      groupCodes,
      subgroupType,
      startTime,
      endTime,
      roles,
    ],
    queryFn: async ({ signal }) => {
      const json = [
        {
          $match: {
            time: { $gte: startTime, $lt: endTime },
            ...(groupCodes.length > 0
              ? { 'vehicle.groupCodes': { $all: groupCodes } }
              : {}),
            ...(roles.length > 0 ? { 'vehicle.role': { $in: roles } } : {}),
          },
        },
        {
          $project: {
            time: true,
            vehicle: {
              identificationNumber: true,
              registrationNumber: true,
              fleetNumber: true,
              groupCodes: true,
              homeStation: true,
            },
            usedSeconds: {
              $subtract: [
                {
                  $sum: [
                    '$moving.all.durationSeconds',
                    '$stopped.all.elsewhereSeconds',
                  ],
                },
                {
                  $sum: ['$idle.all.baseSeconds', '$idle.all.workshopSeconds'],
                },
              ],
            },
            unusedSeconds: {
              $sum: ['$stopped.all.baseSeconds', '$idle.all.baseSeconds'],
            },
            unavailableSeconds: {
              $sum: [
                '$stopped.all.workshopSeconds',
                '$idle.all.workshopSeconds',
              ],
            },
            unaccountableSeconds: '$unaccountable.all.durationSeconds',
          },
        },
        ...(subgroupType && subgroupType !== 'HOME_STATION'
          ? [
              {
                $lookup: {
                  from: 'groups',
                  localField: 'vehicle.groupCodes',
                  foreignField: 'code',
                  as: 'group',
                  pipeline: [
                    { $match: { type: subgroupType } },
                    { $project: { _id: false, code: true, type: true } },
                  ],
                },
              },
              { $unwind: '$group' },
            ]
          : []),
        {
          $group: {
            _id: subgroupType
              ? subgroupType === 'HOME_STATION'
                ? '$vehicle.homeStation'
                : '$group.code'
              : null,
            usedSeconds: { $sum: '$usedSeconds' },
            unusedSeconds: { $sum: '$unusedSeconds' },
            unavailableSeconds: { $sum: '$unavailableSeconds' },
            unaccountableSeconds: { $sum: '$unaccountableSeconds' },
          },
        },
        {
          $set: {
            totalSeconds: {
              $add: [
                '$usedSeconds',
                '$unusedSeconds',
                '$unavailableSeconds',
                '$unaccountableSeconds',
              ],
            },
          },
        },
        {
          $project: {
            _id: false,
            code: '$_id',
            usedPercentage: {
              $multiply: [{ $divide: ['$usedSeconds', '$totalSeconds'] }, 100],
            },
            unusedPercentage: {
              $multiply: [
                { $divide: ['$unusedSeconds', '$totalSeconds'] },
                100,
              ],
            },
            unavailablePercentage: {
              $multiply: [
                { $divide: ['$unavailableSeconds', '$totalSeconds'] },
                100,
              ],
            },
            unaccountablePercentage: {
              $multiply: [
                { $divide: ['$unaccountableSeconds', '$totalSeconds'] },
                100,
              ],
            },
          },
        },
      ];

      log('Read', 'Fleet Utilisation Summary', {
        groupCodes,
        subgroupType,
        startTime,
        endTime,
        roles,
      });

      return api
        .post('pipeline/vehicleDailyActivities', { json, signal })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60 * 8,
  });
}
