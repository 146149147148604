import { Autocomplete, TextField } from '@mui/material';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';

export function AutocompleteController({
  name,
  label,
  control,
  disabled,
  rules,
  fullWidth,
  options,
  single,
  freeSolo,
  selectOnFocus,
  clearOnBlur,
  handleHomeEndKeys,
  sx,
}) {
  const {
    field: { value, onChange, ref },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
  });
  const allOptions = useMemo(
    () =>
      (value
        ? [value]
            .flat()
            .filter(
              (value) =>
                !options.find(
                  (option) =>
                    (option.value ?? option) === (value.value ?? value),
                ),
            )
        : []
      ).concat(options),

    [value, options],
  );

  function handleChange(_, selections) {
    if (single) {
      onChange(selections?.value ?? selections);
    } else {
      onChange(selections.map((selection) => selection.value ?? selection));
    }
  }

  function isOptionEqualToValue(option, selected) {
    return (option.value ?? option) === (selected.value ?? selected);
  }

  function getOptionLabel(option) {
    return (
      option.label ??
      allOptions.find((o) => o.value === option)?.label ??
      option
    );
  }

  function renderInput(params) {
    return (
      <TextField
        inputRef={ref}
        label={label}
        error={invalid}
        helperText={error?.message}
        {...params}
      />
    );
  }

  function renderOption(props, option) {
    return (
      <li {...props} key={option.value ?? option}>
        {option.label ?? option}
      </li>
    );
  }

  return (
    <Autocomplete
      value={value ?? (!single ? [] : null)}
      size="small"
      disabled={disabled}
      fullWidth={fullWidth}
      multiple={!single}
      freeSolo={freeSolo}
      selectOnFocus={selectOnFocus}
      clearOnBlur={clearOnBlur}
      handleHomeEndKeys={handleHomeEndKeys}
      onChange={handleChange}
      options={allOptions}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput}
      sx={{ minWidth: 240, ...sx }}
    />
  );
}
