import { Clear as ClearIcon } from '@mui/icons-material';
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';

export function TextFieldController({
  name,
  label,
  control,
  defaultValue,
  disabled,
  rules,
  parse,
  format,
  fullWidth,
  placeholder,
  multiline,
  minRows,
  options = [],
  sx,
  onChange: callback,
  helperText,
  clearable,
  exclude,
}) {
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
    disabled: exclude,
  });
  const valuesNotInOptions = useMemo(
    () =>
      value
        ? [value]
            .flat()
            .filter(
              (value) =>
                !options.find(
                  (option) =>
                    (option.value ?? option) === (value.value ?? value),
                ),
            )
        : [],

    [value, options],
  );

  function handleChange(event) {
    onChange(format ? format(event.target.value) : event.target.value);
    if (callback) {
      callback(event);
    }
  }

  function handleClearClick() {
    onChange('');
    if (callback) {
      callback({ target: { value: '' } });
    }
  }

  return (
    <TextField
      inputRef={ref}
      value={parse ? parse(value) : (value ?? '')}
      onChange={handleChange}
      disabled={disabled}
      onBlur={onBlur}
      size="small"
      label={label}
      select={options.length > 0}
      fullWidth={fullWidth}
      placeholder={placeholder}
      multiline={multiline}
      minRows={minRows}
      error={invalid}
      helperText={error?.message ?? helperText}
      sx={{ minWidth: 240, ...sx }}
      InputProps={{
        endAdornment: clearable ? (
          <InputAdornment
            position="start"
            disableTypography
            sx={{ alignItems: 'center' }}
          >
            <IconButton
              size="small"
              sx={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={handleClearClick}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
    >
      {valuesNotInOptions.map((option) => (
        <MenuItem key={option.value ?? option} value={option.value ?? option}>
          {option.label ?? option}
        </MenuItem>
      ))}
      {options.map((option) => (
        <MenuItem key={option.value ?? option} value={option.value ?? option}>
          {option.label ?? option}
        </MenuItem>
      ))}
    </TextField>
  );
}
