import { ExportPicker, RetrospectiveTypeIcon } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import {
  getVehiclePolls,
  round,
  shortPersonHeaders,
  shortVehicleHeaders,
  startCase,
  telematicsBoxPollHeaders,
} from '@/utils';
import {
  Flag as FlagIcon,
  OutlinedFlag as OutlinedFlagIcon,
  Person as PersonIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import humanizeDuration from 'humanize-duration';
import { Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getPrimaryText, getSecondaryText } from '../constants';
import { LocationTypeIcon } from './LocationTypeIcon';

export function VehicleVisit({
  color,
  background,
  identifier,
  driver,
  vehicle,
  visitLocation,
  startTime,
  endTime,
  distanceKilometres,
  maxSpeedKilometresPerHour,
  trips,
}) {
  const navigate = useNavigate();
  const { id, layerIndex, itemIndex } = useParams();

  function handleViewClick() {
    navigate(`/eventreplay/intersections/${identifier}`);
  }

  async function fetchDataToConvert() {
    const polls = await getVehiclePolls(
      vehicle.telematicsBoxImei,
      startTime,
      endTime,
    );

    const data = polls.map((poll) => ({
      ...poll,
      name: driver ? driver.forenames + ' ' + driver.surname : '',
      collarNumber: driver?.collarNumber,
      personRole: driver?.role,
      vehicleRole: vehicle.role,
      identificationNumber: vehicle.identificationNumber,
      registrationNumber: vehicle.registrationNumber,
      fleetNumber: vehicle.fleetNumber,
      time: new Date(poll.time),
      longitude: poll.position ? poll.position.coordinates[0] : 0,
      latitude: poll.position ? poll.position.coordinates[1] : 0,
      speedLimitMilesPerHour: poll.speedLimitMilesPerHour,
    }));

    return data;
  }

  const vehicleVisitHeaders = [
    ...shortPersonHeaders,
    ...shortVehicleHeaders,
    ...telematicsBoxPollHeaders,
  ];

  const cardHeaderButtons = (
    <Box>
      {identifier && (
        <Tooltip title="View Replay">
          <IconButton onClick={handleViewClick} size="large">
            <PlayArrowIcon />
          </IconButton>
        </Tooltip>
      )}
      <ExportPicker
        fetchDataToConvert={fetchDataToConvert}
        filename="Vehicle Visit"
        headers={vehicleVisitHeaders}
      />
    </Box>
  );

  const { title, subheader } = vehicle?.unassociated
    ? {
        title: 'Unassociated IMEI',
        subheader: vehicle?.telematicsBoxImei,
      }
    : {
        title: vehicle?.registrationNumber,
        subheader: vehicle?.fleetNumber,
      };
  useDocumentTitle(['IR3', 'Vehicle Visit', title].filter(Boolean).join(' | '));

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Tooltip title="Vehicle Visit">
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="vehicleVisits" />
            </Avatar>
          </Tooltip>
        }
        title={title}
        subheader={subheader}
        action={cardHeaderButtons}
      />
      <CardContent>
        {vehicle.identificationNumber && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Vehicle
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>
                {!!vehicle.role && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{vehicle.role}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!!vehicle.homeStation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Home Station
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {vehicle.homeStation}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {Object.entries(vehicle.groups ?? {}).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {startCase(key)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {(value ?? []).join(', ')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {visitLocation.name !== '<custom>' && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Location
            </Typography>
            <ListItem component="div" sx={{ my: 1 }}>
              <ListItemAvatar>
                <Tooltip title={visitLocation.type}>
                  <Avatar>
                    <LocationTypeIcon type={visitLocation.type} />
                  </Avatar>
                </Tooltip>
              </ListItemAvatar>
              <ListItemText
                primary={visitLocation.name}
                secondary={visitLocation.type}
              />
            </ListItem>
          </Fragment>
        )}
        {(driver || {}).identificationReference && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Driver
            </Typography>
            <ListItem component="div" sx={{ my: 1 }}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${driver?.forenames || ''}  ${driver?.surname || ''}`}
                secondary={driver?.collarNumber}
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical">
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <FlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              Start
            </StepLabel>
            <StepContent />
          </Step>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <OutlinedFlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              End
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
        {trips?.length ? (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Trips
            </Typography>
            <List>
              {trips.map((trip, index) => {
                const feature = {
                  properties: {
                    ...trip,
                    source: 'vehicleTrips',
                  },
                };

                const { driver } = trip;
                let driverName = undefined;
                if (driver.forenames || driver.surname) {
                  driverName = [driver.forenames, driver.surname].join(' ');
                }

                return (
                  <ListItem
                    dense
                    button
                    key={index}
                    component={Link}
                    to={`/retrospective/${
                      id || 'untitled'
                    }/${layerIndex}/${itemIndex}/${index}`}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          background,
                          color,
                        }}
                      >
                        <RetrospectiveTypeIcon type={'vehicleTrips'} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={driverName ?? getPrimaryText(feature)}
                      secondary={getSecondaryText(feature)}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Fragment>
        ) : (
          <Fragment> </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Distance
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${round(distanceKilometres * 0.62137119, 2)} miles`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Maximum Speed
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${round(maxSpeedKilometresPerHour * 0.62137119, 2)} MPH`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {humanizeDuration(new Date(endTime) - new Date(startTime))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
