import { FormControl, FormHelperText } from '@mui/material';
import { useController } from 'react-hook-form';
import { ContentEditor } from '..';

export function ContentEditorController({
  name,
  placeholder,
  control,
  disabled,
  rules,
  sx,
}) {
  const {
    field: { value, onChange },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <FormControl error={invalid} sx={sx}>
      <ContentEditor
        placeholder={placeholder ?? 'Write something...'}
        value={value ?? ''}
        disabled={disabled}
        onChange={onChange}
        invalidState={invalid}
        sx={sx}
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}
