import { Text, View } from '@react-pdf/renderer';
import { VehicleTableView } from './VehicleTableView';

export function TopBottomView({ label, data }) {
  return (
    <View style={{ gap: 8 }}>
      <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{label}</Text>
      <View style={{ flexDirection: 'row', gap: 8 }}>
        <View style={{ width: '50%' }}>
          <Text style={{ fontSize: 10, fontWeight: 'bold' }}>Top 10</Text>
          <VehicleTableView rows={data.top} />
        </View>
        <View style={{ width: '50%' }}>
          <Text style={{ fontSize: 10, fontWeight: 'bold' }}>Bottom 10</Text>
          <VehicleTableView rows={data.bottom} />
        </View>
      </View>
    </View>
  );
}
