import { useController } from 'react-hook-form';
import { BoundaryEditor } from '..';

export function BoundaryEditorController({
  name,
  control,
  disabled,
  rules,
  sx,
}) {
  const {
    field: { value, onChange },
    fieldState: { error, isDirty },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <BoundaryEditor
      value={value}
      onChange={onChange}
      disabled={disabled}
      error={error?.message}
      isDirty={isDirty}
      sx={{ minWidth: 240, height: 'calc(100vh - 108px)', ...sx }}
    />
  );
}
