import {
  FETCH_ATTENDANCES,
  FETCH_ATTENDANCES_CANCELLED,
  UPDATE_ATTENDANCES_FILTER,
  UPDATE_ATTENDANCES_PARAMETERS,
} from '@/actions';
import {
  FilterPicker,
  Parameters,
  Table,
  TablePagination,
} from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import {
  downloadCSV,
  filterLocally,
  formatGroups,
  getFilenameForDownload,
  getKeyLabel,
  shortHumanizer,
} from '@/utils';
import { events, rowsPerPageOptions } from '@/utils/config';
import {
  GetApp as GetAppIcon,
  BarChart as GroupByIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  addHours,
  addWeeks,
  format,
  isAfter,
  isBefore,
  startOfDay,
  startOfHour,
  startOfWeek,
} from 'date-fns';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const {
  eventFilters: { attendances: eventFilters },
} = events;
const attendanceFiltersAndHeaders = [
  {
    label: 'Collar Number',
    key: 'person.collarNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Forenames',
    key: 'person.forenames',
    type: 'text',
    filter: true,
  },
  {
    label: 'Surname',
    key: 'person.surname',
    type: 'text',
    filter: true,
  },
  {
    label: 'SSI',
    key: 'person.radioSsi',
    type: 'text',
    filter: true,
  },
  { label: 'Role', key: 'person.role', type: 'text', filter: true },
  {
    label: 'Team',
    key: ['groups', 'team'],
    type: 'text',
    filter: true,
  },
  {
    label: 'Area',
    key: ['groups', 'area'],
    type: 'text',
    filter: true,
  },
  {
    label: 'Specialist',
    key: ['groups', 'specialist'],
    type: 'text',
    filter: true,
  },
  {
    label: 'Sector',
    key: ['groups', 'sector'],
    type: 'text',
    filter: true,
  },
  {
    label: 'Operation',
    key: ['groups', 'operation'],
    type: 'text',
    filter: true,
  },
  {
    label: 'BCU',
    key: ['groups', 'bcu'],
    type: 'text',
    filter: true,
  },
  {
    label: 'Force',
    key: ['groups', 'force'],
    type: 'text',
    filter: true,
  },
];

function ReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleViewClick = (identifier) => () => {
    navigate(`/eventreplay/personObjectiveAttendances/${identifier}`);
  };

  return (
    <Tooltip title="View">
      <IconButton onClick={handleViewClick(entry.identifier)} size="large">
        <PlayArrowIcon />
      </IconButton>
    </Tooltip>
  );
}

function AttendancePeriod({ entry, groupedBy }) {
  const [orderBy, setOrderBy] = useState('period');
  const [order, setOrder] = useState('asc');

  function handleOrderChange(order) {
    setOrder(order);
  }

  function handleOrderByChange(orderBy) {
    setOrderBy(orderBy);
    setOrder('asc');
  }

  const headers = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: Attendance,
      filter: false,
    },
  ];

  let data;
  switch (groupedBy) {
    case 'hourly':
      headers.push(
        { label: 'Hour', key: 'period', type: 'text', filter: false },
        {
          label: 'Compliant',
          key: 'hourCompliant',
          type: 'boolean',
          filter: false,
        },
      );
      data = entry.attendancesByHour;
      break;

    case 'daily':
      headers.push(
        { label: 'Date', key: 'period', type: 'dateonly', filter: false },
        {
          label: 'Compliant',
          key: 'dateCompliant',
          type: 'boolean',
          filter: false,
        },
      );
      data = entry.attendancesByDay;
      break;
    case 'person':
      headers.push(
        {
          label: 'Collar Number',
          key: 'collarNumber',
          type: 'text',
          filter: false,
        },
        {
          label: 'Compliant #',
          key: 'compliantCount',
          type: 'text',
          filter: false,
        },
        {
          label: 'Non-Compliant #',
          key: 'nonCompliantCount',
          type: 'text',
          filter: false,
        },
        {
          label: 'Compliant Time',
          key: 'compliantCumulativeSeconds',
          format: (value) => shortHumanizer(value * 1000),
          type: 'text',
          filter: false,
        },
        {
          label: 'Non Compliant Time',
          key: 'nonCompliantCumulativeSeconds',
          format: (value) => shortHumanizer(value * 1000),
          type: 'text',
          filter: false,
        },
      );
      data = entry.attendancesByDriver;
      break;
    default:
      break;
  }

  return (
    <Table
      data={data}
      headers={headers}
      rowsPerPage={data.length}
      orderBy={orderBy}
      order={order}
      onOrderChange={handleOrderChange}
      onOrderByChange={handleOrderByChange}
      page={0}
      keyName="identifier"
      disableSticky
    />
  );
}

function Attendance({ entry }) {
  const [orderBy, setOrderBy] = useState('startTime');
  const [order, setOrder] = useState('asc');

  const attendanceHeaders = [
    ...attendanceFiltersAndHeaders,
    {
      label: 'Start Time',
      key: 'startTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'End Time',
      key: 'endTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'Compliant',
      key: 'compliant',
      type: 'boolean',
      filter: true,
    },
    {
      label: '',
      key: 'replay',
      type: 'component',
      component: ReplayLink,
      filter: false,
    },
  ];

  function handleOrderChange(order) {
    setOrder(order);
  }

  function handleOrderByChange(orderBy) {
    setOrderBy(orderBy);
    setOrder('asc');
  }

  return (
    <Table
      data={entry.attendances}
      headers={attendanceHeaders}
      rowsPerPage={entry.attendances.length}
      page={0}
      keyName="identifier"
      orderBy={orderBy}
      order={order}
      onOrderChange={handleOrderChange}
      onOrderByChange={handleOrderByChange}
      disableSticky
    />
  );
}

const objectiveFilterHeaders = [
  {
    label: 'Objective Identifier',
    key: 'identifier',
    type: 'text',
    filter: true,
  },
  {
    label: 'Objective Title',
    key: 'title',
    type: 'text',
    filter: true,
  },
  {
    label: 'Objective Type',
    key: 'type',
    type: 'text',
    filter: true,
  },
];

function objectifyKey(headers) {
  return headers.map((header) => ({
    ...header,
    key: 'objective.' + header.key,
  }));
}

export function Attendances() {
  useDocumentTitle('IR3 | Objective Attendances');
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.events.attendances.isLoading);
  const error = useSelector((state) => state.events.attendances.error);

  const [groupMenuAnchor, setGroupMenuAnchor] = useState(null);

  function handleGroupMenuOpen(target) {
    setGroupMenuAnchor(target);
  }

  function handleGroupMenuClose() {
    setGroupMenuAnchor(null);
  }

  const groupByValues = ['frequency', 'person'];

  const [groupedBy, setGroupedBy] = useState('frequency');

  function handleGroupByFieldChanged(groupedBy) {
    setGroupedBy(groupedBy);
    setGroupMenuAnchor(null);
  }

  const attendances = useSelector(
    (state) => state.events.attendances.list,
    dequal,
  );

  const filter = useSelector(
    (state) => state.events.attendances.filter,
    dequal,
  );

  const parameters = useSelector(
    (state) => state.events.attendances.parameters,
    dequal,
  );

  function getGroupingForObjective(objective) {
    if (groupedBy === 'frequency') {
      return objective.requiredFrequency;
    } else {
      return groupedBy;
    }
  }

  const objectiveHeaders = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: ({ entry }) => {
        if (entry.requiredFrequency === 'total' && groupedBy === 'frequency') {
          return Attendance({ entry });
        } else {
          return AttendancePeriod({
            entry,
            groupedBy: getGroupingForObjective(entry),
          });
        }
      },
      filter: false,
    },
    {
      label: 'Identifier',
      key: 'identifier',
      type: 'text',
      filter: true,
    },
    {
      label: 'Title',
      key: 'title',
      type: 'text',
      filter: true,
    },
    {
      label: 'Start Time',
      key: 'startTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'End Time',
      key: 'endTime',
      type: 'date',
      filter: false,
    },
    {
      label: '# Compliant',
      key: 'compliantCount',
      type: 'text',
      filter: false,
    },
    {
      label: '# Non-Compliant',
      key: 'nonCompliantCount',
      type: 'text',
      filter: false,
    },
    groupedBy === 'frequency' && {
      label: 'Achieved',
      key: 'objectiveAchieved',
      type: 'boolean',
      filter: false,
    },
  ];

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  function getData() {
    const filteredAttendances = filterLocally(filter, attendances);

    function groupBy(objectArray, keyFunction) {
      return objectArray.reduce(function (acc, obj) {
        let key = keyFunction(obj);
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    }

    const attendancesGroupedByObjective = Object.values(
      groupBy(filteredAttendances, (item) => item.objective.identifier),
    );

    function isTotalObjectiveAchieved(objective) {
      const compliantAttendances = objective.attendances.filter(
        (a) => a.compliant,
      );
      return objective.requiredVisits <= compliantAttendances;
    }

    function isObjectiveAchieved(objective) {
      // special handling for total as the algorithm is much simpler and doesn't require any magic
      if (objective.requiredFrequency === 'total') {
        return isTotalObjectiveAchieved(objective);
      }

      // daily and hourly handled below
      const listOfStartDates = [];

      // generate an array of beginnings of all weeks that fall between startTime and endtime
      let startDate = new Date(objective.startTime);
      const endDate = new Date(objective.endTime);
      while (isBefore(startDate, endDate)) {
        let start = startOfWeek(startDate);
        listOfStartDates.push(start);
        startDate = addWeeks(startDate, 1);
      }
      startDate = new Date(objective.startTime);

      let hours;
      let normalizeTimeFunction;

      if (objective.requiredFrequency === 'hourly') {
        normalizeTimeFunction = (attendance) =>
          startOfHour(new Date(attendance.startTime)).getTime();
        // convert our schedule to a list of integers,
        // each representing number of hours since the beginning of the week when the attendance is expected to happen
        hours = objective.schedule
          .flat()
          .map((e, index) => {
            if (e) {
              return index;
            } else {
              return null;
            }
          })
          .filter((e) => e !== null);
      } else if (objective.requiredFrequency === 'daily') {
        normalizeTimeFunction = (attendance) =>
          startOfDay(new Date(attendance.startTime)).getTime();
        hours = objective.schedule
          .map((e, index) => {
            if (e.includes(true)) {
              // for daily we're only interested in beginning of days,
              // but to keep is consistent we still represent these in hours since the beginning of the week
              return index * 24;
            } else {
              return null;
            }
          })
          .filter((e) => e !== null);
      } else {
        return false;
      }

      const scheduleTimes = listOfStartDates.flatMap((startOfWeek) =>
        hours
          .map((hourSinceStartOfWeek) =>
            addHours(startOfWeek, hourSinceStartOfWeek),
          )
          .filter(
            (entry) => isAfter(entry, startDate) && isBefore(entry, endDate),
          )
          .map((entry) => entry.getTime()),
      );

      // we use Map for a better lookup performance
      // keys in this map represent times where attendance is required, values represent number of required visits
      // (at the beginning values are identical for all keys and are equal to a requiredVisits property of the objective)
      const scheduleMap = new Map(
        scheduleTimes.map((hour) => [hour, +objective.requiredVisits]),
      );

      objective.attendances.forEach((attendance) => {
        if (attendance.compliant) {
          const attendanceTimeNormalized = normalizeTimeFunction(attendance);
          const requiredVisitsLeft =
            scheduleMap.get(attendanceTimeNormalized) ?? 1;
          if (requiredVisitsLeft === 1) {
            // if it's the last required visit left
            // or the key wasn't found, which might happen when there are more attendances than required -> delete the key
            scheduleMap.delete(attendanceTimeNormalized);
          } else {
            // if there's more than 1 required visit left, just decrement
            scheduleMap.set(attendanceTimeNormalized, requiredVisitsLeft - 1);
          }
        }
      });

      // for achieved objectives, we expect the entire scheduleMap to be empty
      return scheduleMap.size === 0;
    }

    return attendancesGroupedByObjective.map((attendancesPerObjective) => {
      const objective = attendancesPerObjective[0].objective;
      const requiredCompliantVisitsCount = objective.requiredVisits;
      const compliantAttendancesCount = attendancesPerObjective.filter(
        (item) => item.compliant,
      ).length;
      const nonCompliantAttendancesCount =
        attendancesPerObjective.length - compliantAttendancesCount;

      switch (getGroupingForObjective(objective)) {
        case 'hourly': {
          const attendancesByHour = Object.entries(
            groupBy(attendancesPerObjective, (item) =>
              format(new Date(item.startTime), 'yyyy/MM/dd HH:00'),
            ),
          ).map(([period, attendances]) => ({
            period,
            hourCompliant:
              attendances.filter((attendance) => attendance.compliant).length >=
              requiredCompliantVisitsCount,
            attendances,
          }));
          const compliantHours = attendancesByHour.filter(
            (item) => item.hourCompliant,
          ).length;
          const nonCompliantHours = attendancesByHour.length - compliantHours;
          return {
            ...objective,
            compliantCount: compliantHours,
            nonCompliantCount: nonCompliantHours,
            objectiveAchieved: isObjectiveAchieved({
              ...objective,
              attendances: attendancesPerObjective,
            }),
            attendancesByHour,
          };
        }
        case 'daily': {
          const attendancesByDay = Object.entries(
            groupBy(attendancesPerObjective, (item) =>
              startOfDay(new Date(item.startTime)).toISOString(),
            ),
          ).map(([key, attendances]) => ({
            period: new Date(key),
            dateCompliant:
              attendances.filter((attendance) => attendance.compliant).length >=
              requiredCompliantVisitsCount,
            attendances,
          }));
          const compliantDays = attendancesByDay.filter(
            (item) => item.dateCompliant,
          ).length;
          const nonCompliantDays = attendancesByDay.length - compliantDays;
          return {
            ...objective,
            compliantCount: compliantDays,
            nonCompliantCount: nonCompliantDays,
            attendancesByDay,
            objectiveAchieved: isObjectiveAchieved({
              ...objective,
              attendances: attendancesPerObjective,
            }),
          };
        }
        case 'total':
          return {
            ...objective,
            compliantCount: compliantAttendancesCount,
            nonCompliantCount: nonCompliantAttendancesCount,
            attendances: attendancesPerObjective,
            objectiveAchieved: isObjectiveAchieved({
              ...objective,
              attendances: attendancesPerObjective,
            }),
          };
        case 'person': {
          const attendancesByDriver = Object.entries(
            groupBy(
              attendancesPerObjective,
              (item) => item.person.collarNumber,
            ),
          ).map(([collarNumber, attendances]) => {
            const compliantCountForDriver = attendances.filter(
              (item) => item.compliant,
            ).length;

            const nonCompliantCountForDriver =
              attendances.length - compliantCountForDriver;

            const compliantCumulativeSeconds = attendances
              .filter((item) => item.compliant)
              .map((compliantAttendance) => compliantAttendance.durationSeconds)
              .reduce((duration, acc) => duration + acc, 0);

            const nonCompliantCumulativeSeconds = attendances
              .filter((item) => !item.compliant)
              .map(
                (nonCompliantAttendance) =>
                  nonCompliantAttendance.durationSeconds,
              )
              .reduce((duration, acc) => duration + acc, 0);

            return {
              collarNumber,
              compliantCount: compliantCountForDriver,
              nonCompliantCount: nonCompliantCountForDriver,
              compliantCumulativeSeconds,
              nonCompliantCumulativeSeconds,
              attendances,
            };
          });

          return {
            ...objective,
            compliantCount: compliantAttendancesCount,
            nonCompliantCount: nonCompliantAttendancesCount,
            attendancesByDriver,
          };
        }
        default:
          return null;
      }
    });
  }

  function handleDownloadClick() {
    const filename = getFilenameForDownload(
      'Objective Attendances',
      'csv',
      parameters?.startTime,
      parameters?.endTime,
    );
    const attendanceHeaders = [
      ...attendanceFiltersAndHeaders,
      {
        label: 'Start Time',
        key: 'startTime',
        type: 'date',
      },
      {
        label: 'End Time',
        key: 'endTime',
        type: 'date',
      },
      {
        label: 'Compliant',
        key: 'compliant',
        type: 'text',
      },
      {
        label: 'Duration',
        key: 'durationSeconds',
        type: 'text',
      },
      {
        label: 'Objective Identifier',
        key: 'objective.identifier',
        type: 'text',
      },
      {
        label: 'Objective Title',
        key: 'objective.title',
        type: 'text',
      },
    ];

    const dataToDownload = attendances.map((attendance) => ({
      ...attendance,
      'person.collarNumber': attendance.person.collarNumber,
      'person.forenames': attendance.person.forenames,
      'person.surname': attendance.person.surname,
      'person.radioSsi': attendance.person.radioSsi,
      'person.role': attendance.person.role,
      ...formatGroups(attendance.groups),
      startTime: new Date(attendance.startTime),
      endTime: new Date(attendance.endTime),
      compliant: attendance.compliant ? 'Yes' : 'No',
      durationSeconds: attendance.durationSeconds / 86400,
      'objective.identifier': attendance.objective.identifier,
      'objective.title': attendance.objective.title,
    }));
    downloadCSV(dataToDownload, filename, attendanceHeaders);
  }

  function handleParametersChange(parameters) {
    dispatch({
      type: UPDATE_ATTENDANCES_PARAMETERS,
      payload: parameters,
    });
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_ATTENDANCES,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_ATTENDANCES_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_ATTENDANCES_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  const groupedAttendanceData = getData();

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Parameters
        collection="personObjectiveAttendances"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={parameters}
        onChange={handleParametersChange}
        sx={{ mt: 1, width: 264 }}
        person
        objective
        eventFilters={eventFilters}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Objective Attendances
          </Typography>
          <Tooltip title="Group by">
            <IconButton
              aria-owns={groupMenuAnchor ? 'date-menu' : undefined}
              aria-haspopup="true"
              onClick={(event) => handleGroupMenuOpen(event.target)}
            >
              <GroupByIcon />
            </IconButton>
          </Tooltip>

          <Menu
            id="date-menu"
            anchorEl={groupMenuAnchor}
            open={Boolean(groupMenuAnchor)}
            onClose={() => handleGroupMenuClose()}
          >
            <ListSubheader disableSticky>Group by</ListSubheader>

            {Object.values(groupByValues).map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === groupedBy}
                onClick={() => handleGroupByFieldChanged(value)}
              >
                {getKeyLabel(value)}
              </MenuItem>
            ))}
          </Menu>
          <FilterPicker
            headers={objectifyKey(objectiveFilterHeaders).concat(
              attendanceFiltersAndHeaders,
            )}
            data={attendances}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <Tooltip title="Download">
            <Box component="span">
              <IconButton
                disabled={groupedAttendanceData.length === 0}
                onClick={handleDownloadClick}
                size="large"
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 172px)',
                overflowY: 'scroll',
              },
              table: {
                minWidth: 750,
              },
            }}
            data={groupedAttendanceData}
            headers={objectiveHeaders}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={groupedAttendanceData.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Box>
  );
}
