import { CustomTooltip } from '@/components/controls';
import { useGroupOptions, useOptions } from '@/hooks';
import { getTextWidth } from '@/utils';
import { useTheme } from '@emotion/react';
import { ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Fragment, useState } from 'react';
import {
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useGroupedUncheckedVehicles } from './useGroupedUncheckedVehicles';
import { useNonPollingVehicles } from './useNonPollingVehicles';

const groupComparisonStateAtom = atomWithStorage('groupComparison', '');

export function GroupComparisonCard({ groupCode }) {
  const { data: typeOptions } = useOptions('groupType');
  const [groupType, setGroupType] = useAtom(groupComparisonStateAtom);
  const [[sortBy, sortDesc], setSort] = useState(['nonPolling', false]);
  const [hiddenBars, setHiddenBars] = useState([]);
  const { data: nonPollingVehicles } = useNonPollingVehicles(
    groupCode,
    groupType,
  );
  const { data: uncheckedVehicles } = useGroupedUncheckedVehicles(groupType);
  const options = useGroupOptions();
  const theme = useTheme();

  const dataMap = new Map(Object.entries(nonPollingVehicles.counts));
  for (const group in uncheckedVehicles) {
    dataMap.set(group, {
      ...dataMap.get(group),
      group,
      unchecked: uncheckedVehicles[group].count,
    });
  }

  const data = Array.from(dataMap.values()).sort(compareGroups);

  const nonPollingBars = [
    {
      key: 'twoWeeks',
      label: '2 Weeks - 1 Month',
      color: theme.palette.primary.light,
    },
    {
      key: 'oneMonth',
      label: '1 - 6 Months',
      color: theme.palette.primary.main,
    },
    {
      key: 'sixMonths',
      label: '> 6 Months',
      color: theme.palette.primary.dark,
    },
  ];

  const formatLabel = (value) => options[value]?.name ?? value;

  const xAxisHeight =
    data.length > 0
      ? Math.ceil(
          Math.max(
            ...data.map((item) =>
              getTextWidth(
                item.group ? formatLabel(item.group) : '',
                '12px Roboto',
              ),
            ),
          ),
        )
      : 30;

  function getSortValue(group) {
    if (sortBy === 'unchecked') {
      return group.unchecked ?? 0;
    } else {
      return (
        Object.entries(group)
          .filter(
            ([key]) =>
              key !== 'group' &&
              key !== 'unchecked' &&
              !hiddenBars.includes(key),
          )
          .reduce((acc, [, count]) => (acc += count), 0) ?? 0
      );
    }
  }

  function compareGroups(a, b) {
    return sortDesc
      ? getSortValue(b) - getSortValue(a)
      : getSortValue(a) - getSortValue(b);
  }

  function handleGroupTypeChange(event) {
    setGroupType(event.target.value);
  }

  function handleSortByChange(event) {
    setSort(([, sortDesc]) => [event.target.value, sortDesc]);
  }

  function handleSortToggle() {
    setSort(([sortBy, sortDesc]) => [sortBy, !sortDesc]);
  }

  const handleLegendClick = (selectedBar) => () => {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1)),
      );
    }
  };

  return (
    <Card variant="outlined" sx={{ flexGrow: 1 }}>
      <CardContent>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Group Comparison
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{ width: 1, textAlign: 'center' }}
            variant="caption"
            color="text.secondary"
          >
            Non-polling Vehicles
          </Typography>
          {nonPollingBars.map((bar) => (
            <Box
              key={bar.key}
              sx={{
                p: 0.5,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleLegendClick(bar.key)}
            >
              <Avatar
                sx={{
                  width: 12,
                  height: 12,
                  mr: 0.5,
                  bgcolor: !hiddenBars.includes(bar.key) && bar.color,
                }}
              >
                <Fragment />
              </Avatar>
              <Typography variant="caption">{bar.label}</Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            p: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={handleLegendClick('unchecked')}
        >
          <Avatar
            sx={{
              width: 12,
              height: 12,
              mr: 0.5,
              bgcolor:
                !hiddenBars.includes('unchecked') && theme.palette.warning.main,
            }}
          >
            <Fragment />
          </Avatar>
          <Typography variant="caption">Unchecked Vehicles</Typography>
        </Box>
        <Box sx={{ pt: 1, fontSize: 12 }}>
          <ResponsiveContainer width="99%" height={520}>
            <BarChart data={data} margin={{ right: 16, bottom: 32, left: 16 }}>
              <XAxis
                dataKey="group"
                angle={-90}
                textAnchor="end"
                interval={0}
                height={xAxisHeight}
                tickFormatter={formatLabel}
              />
              <YAxis>
                <Label value="Vehicles" angle={-90} position="left" />
              </YAxis>
              <Tooltip
                cursor={false}
                content={
                  <CustomTooltip unit="vehicles" labelFormatter={formatLabel} />
                }
              />
              {nonPollingBars
                .filter((bar) => !hiddenBars.includes(bar.key))
                .map((bar) => (
                  <Bar
                    key={bar.key}
                    stackId="nonPollingVehicles"
                    dataKey={bar.key}
                    name={bar.label}
                    fill={bar.color}
                  />
                ))}
              {!hiddenBars.includes('unchecked') && (
                <Bar
                  stackId="uncheckedVehicles"
                  dataKey="unchecked"
                  name="Unchecked"
                  fill={theme.palette.warning.main}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Stack
          direction="row"
          spacing={1}
          sx={{ w: 1, display: 'flex', justifyContent: 'center' }}
        >
          <TextField
            sx={{ width: 240 }}
            size="small"
            select
            label="Group by"
            value={groupType}
            onChange={handleGroupTypeChange}
          >
            {typeOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: 240 }}
            size="small"
            select
            label="Sort by"
            value={sortBy}
            onChange={handleSortByChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    title={sortDesc ? 'Descending' : 'Ascending'}
                    sx={(theme) => ({
                      transform: sortDesc ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest,
                      }),
                    })}
                    onClick={handleSortToggle}
                    size="small"
                  >
                    <ArrowUpwardIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="nonPolling">Non-polling Vehicles</MenuItem>
            <MenuItem value="unchecked">Unchecked Vehicles</MenuItem>
          </TextField>
        </Stack>
      </CardContent>
    </Card>
  );
}
