import { useController } from 'react-hook-form';
import { AliasPicker } from '..';

export function AliasPickerController({ name, control, disabled }) {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  return (
    <AliasPicker value={value ?? {}} onChange={onChange} disabled={disabled} />
  );
}
