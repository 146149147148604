import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function ReplayLink({ collection, identifier }) {
  const navigate = useNavigate();

  function handleClick(event) {
    event.stopPropagation();

    navigate(`/eventreplay/${collection}/${identifier}`);
  }

  return (
    <Tooltip title="View replay">
      <IconButton size="small" onClick={handleClick}>
        <PlayArrowIcon />
      </IconButton>
    </Tooltip>
  );
}
