import { EventPopup } from './EventPopup';
import { IncidentPopup } from './IncidentPopup';
import { LocationPopup } from './LocationPopup';
import { ObjectivePopup } from './ObjectivePopup';
import { PersonPopup } from './PersonPopup';
import { VehiclePopup } from './VehiclePopup';

export function Popup({ hoverInfo }) {
  switch (hoverInfo.feature.source) {
    case 'vehicles':
      return <VehiclePopup hoverInfo={hoverInfo} />;
    case 'people':
      return <PersonPopup hoverInfo={hoverInfo} />;
    case 'locations':
      return <LocationPopup hoverInfo={hoverInfo} />;
    case 'incidents':
      return <IncidentPopup hoverInfo={hoverInfo} />;
    case 'events':
      return <EventPopup hoverInfo={hoverInfo} />;
    case 'objectives':
      return <ObjectivePopup hoverInfo={hoverInfo} />;
    default:
      return null;
  }
}
