import { FETCH_LIVE_LOCATION } from '@/actions/types';
import { TagControl } from '@/components/controls';
import {
  useDocumentTitle,
  useLocationTypes,
  useOptionValues,
  usePrevious,
} from '@/hooks';
import {
  Edit as EditIcon,
  LocationSearching as FollowIcon,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Table,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTypesInLocation } from '../utilities';
import { groupsToLabelAccessors } from '../utilities.js';
import { ItemAvatar } from './ItemAvatar';
import { ItemRows } from './ItemRows';
import { PeopleLiveListItem } from './PeopleLiveListItem';
import { TotalTypesInLocation } from './TotalTypesInLocation';
import { VehicleLiveListItem } from './VehicleLiveListItem';

// example object
// $type: "add"
// areas: Array(0)
// boundary:
// $reql_type$: "GEOMETRY"
// coordinates: [Array(6)]
// type: "Polygon"
// code: "Braunstone"
// subtype: ""
// name: "Braunstone"
// searchString: "+[object object]+braunstone++braunstone+police station+add+true"
// tagChanged: true
// type: "Police Station"

export function LocationLiveItem({
  item,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  followedIdsByType,
  hoveredId,
}) {
  useDocumentTitle(
    ['IR3', 'Live', 'Locations', item?.name].filter(Boolean).join(' | '),
  );
  const hideFollow = !item.boundary;
  const state = useSelector((state) => state.live);
  const locationRecord = useSelector(
    (state) => state.live.locationRecordsById[item.id],
  );
  const layerVisibilities = useSelector(
    (state) => state.live.layerVisibilities,
  );
  const locationGroups = useOptionValues('locationGroup');
  const { data: locationTypes } = useLocationTypes();
  const showStale = layerVisibilities.stale;
  const resourcesInLocation = getTypesInLocation(
    state.people,
    item.code,
    'people',
    showStale,
  );
  const vehiclesInLocation = getTypesInLocation(
    state.vehicles,
    item.code,
    'vehicles',
    showStale,
  );

  const typesInLocation = { ...resourcesInLocation, ...vehiclesInLocation };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevId = usePrevious(item.id);
  useEffect(() => {
    if (prevId !== item.id && !locationRecord) {
      dispatch({
        type: FETCH_LIVE_LOCATION,
        payload: item.id,
      });
    }
  }, [dispatch, item.id, prevId, locationRecord]);

  const rowItems = [
    { label: 'Type', value: item.type },
    { label: 'Subtype', value: item.subtype },
    ...groupsToLabelAccessors(locationGroups, item.groups),
  ];

  const type = 'locations';
  const following = followedIdsByType?.[type]?.[item.id];
  const subtype =
    Object.keys(locationTypes).find(
      (type) => locationTypes[type].value === item.type,
    ) || item.type?.toLowerCase() + 's';
  const link = `/${type}/${subtype}/${item.id ?? item.code}`;

  const typeToListItemMap = {
    vehicles: VehicleLiveListItem,
    people: PeopleLiveListItem,
  };

  const orderedTypes = {
    people: 'People',
    vehicles: 'Vehicles',
  };

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={<ItemAvatar item={item} type="locations" />}
        title={item.name}
        subheader={item.code}
        action={
          <Fragment>
            <TotalTypesInLocation typesInLocation={typesInLocation} />
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(link)} size="large">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {!hideFollow && (
              <Tooltip title="Toggle follow">
                <IconButton
                  onClick={() => onFollowToggle(type, item.id)}
                  size="large"
                >
                  <FollowIcon
                    fontSize="small"
                    color={following ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Fragment>
        }
      />
      {locationRecord && locationRecord.picture && (
        <CardMedia style={{ height: 420 }} image={locationRecord.picture} />
      )}

      <CardContent>
        <TagControl
          live
          item={item}
          type={'locations'}
          sx={{ py: 2 }}
          label="Tags"
        />
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>{ItemRows(rowItems, item)}</TableBody>
        </Table>

        {Object.keys(typesInLocation).map((type) => (
          <Fragment key={type}>
            <Typography variant="subtitle2" color="textSecondary">
              {typesInLocation[type].length > 0 && orderedTypes[type]}
            </Typography>
            {typesInLocation[type].map((obj) => {
              const ListComponent = typeToListItemMap[type];
              return (
                <ListComponent
                  key={obj.id}
                  onClick={onSubItemClick}
                  highlighted={hoveredId === obj.id}
                  onHoverItem={onSubItemHover}
                  onFollowToggle={onFollowToggle}
                  followedIdsByType={followedIdsByType}
                  item={obj}
                />
              );
            })}
          </Fragment>
        ))}
      </CardContent>
    </Card>
  );

  /*return (
    <Fragment>
      <div className={{ root: { flexGrow: 1 } }}>
        <Grid container style={{ padding: '8px' }}>
          <Grid item xs={12}>
            <h4>Resource Status</h4>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.cardText}>
              Code: {item.code}
            </Typography>
            <Typography className={classes.cardText}>
              Type: {item.type}
            </Typography>
            <Typography className={classes.cardText}>
              Subype: {item.subtype}
            </Typography>
            <Typography className={classes.cardText}>
              District: {item.district}
            </Typography>
            <Typography className={classes.cardText}>Areas: </Typography>
            <Grid style={{ paddingLeft: '8px' }}>
              {item.areas &&
                item.areas.length > 0 &&
                item.areas.map((area, i) => (
                  <Typography className={classes.cardText} key={i}>
                    {area.name}
                  </Typography>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );*/
}
