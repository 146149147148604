import { getAllValues } from '@/utils';
import { Stack } from '@mui/material';
import { FilterField } from './FilterField';
import { useOptions } from '@/hooks';

export function LocationFilter({
  value = {},
  onChange,
  featureCollection = { features: [] },
}) {
  const { data: locationGroups } = useOptions('locationGroup');
  const handleFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      [field]: update,
    });
  };

  return (
    <Stack spacing={1} sx={{ maxHeight: 238, overflow: 'scroll', pt: 0.75 }}>
      <FilterField
        label="Type"
        value={value.type}
        onChange={handleFilterChange('type')}
        options={getAllValues(featureCollection.features, 'properties.type')}
      />
      <FilterField
        label="Subtype"
        value={value.subtype}
        onChange={handleFilterChange('subtype')}
        options={getAllValues(featureCollection.features, 'properties.subtype')}
      />
      {locationGroups.map(({ value: type, label }) => (
        <FilterField
          key={type}
          label={label}
          value={value[type]}
          onChange={handleFilterChange(type)}
          options={getAllValues(
            featureCollection.features,
            `properties.groups.${type}`,
          )}
        />
      ))}
    </Stack>
  );
}
