import { useGroupOptions, useLocationOptions, useOptionLookup } from '@/hooks';
import { highlights } from '@/utils/config';
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  darken,
  lighten,
} from '@mui/material';
import { startCase } from 'lodash';
import { MaterialReactTable } from 'material-react-table';
import {
  ArrowDown as ArrowDownIcon,
  ArrowRight as ArrowRightIcon,
} from 'mdi-material-ui';
import { useMemo } from 'react';
import { useSubgroupIncidentCounts } from './useSubgroupIncidentCounts';

const {
  totalIncidents: { lookbackDays },
} = highlights;

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

export function TotalIncidentsCard({
  groupType,
  groupCode,
  subgroupType,
  subgroupId,
}) {
  const {
    data: { grades, groups },
  } = useSubgroupIncidentCounts(
    groupType,
    groupCode,
    subgroupType,
    lookbackDays,
  );
  const typeOptions = useOptionLookup('groupType');
  const groupOptions = useGroupOptions({ types: [subgroupType] });
  const locationOptions = useLocationOptions();
  const columns = useMemo(
    () => [
      {
        accessorKey: 'identifier',
        header: (
          <Box>
            {`${
              typeOptions[subgroupType] ?? startCase(subgroupType.toLowerCase())
            } `}
            {
              <ArrowDownIcon
                fontSize="inherit"
                sx={{ verticalAlign: 'middle' }}
              />
            }
            {' Grade '}
            {
              <ArrowRightIcon
                fontSize="inherit"
                sx={{ verticalAlign: 'middle' }}
              />
            }
          </Box>
        ),
        size: 0,
        muiTableBodyCellProps: {
          sx: { fontWeight: 'bold', color: 'text.secondary' },
        },
        footer: 'Total',
      },
      ...Object.keys(grades).map((grade) => ({
        key: grade,
        header: grade,
        id: grade,
        accessorFn: (row) => row[grade] ?? 0,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        size: 0,
        footer: grades[grade],
      })),
      {
        header: 'All',
        id: 'all',
        accessorFn: ({ identifier: _, ...row }) =>
          Object.values(row).reduce((a, b) => a + b, 0),
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
          sx: { fontWeight: 'bold', color: 'text.secondary' },
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        size: 0,
        footer: Object.values(grades).reduce((a, b) => a + b, 0),
      },
    ],
    [typeOptions, subgroupType, grades],
  );
  const data = useMemo(
    () =>
      Object.entries(groups).map(([identifier, group]) => ({
        identifier:
          subgroupType === 'WARD'
            ? locationOptions[identifier]?.name
            : (groupOptions[identifier]?.name ?? identifier),
        ...group,
      })),
    [groupOptions, groups, locationOptions, subgroupType],
  );

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Total Incidents
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {`in the last ${lookbackDays} days`}
          </Typography>
        </Stack>
        <MaterialReactTable
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          data={data}
          columns={columns}
          initialState={{
            density: 'compact',
          }}
          muiTableBodyRowProps={({ row }) => ({
            hover: row.original.identifier !== subgroupId,
            sx:
              row.original.identifier === subgroupId
                ? {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.primary.light,
                        theme.palette.mode,
                      ),
                    '&:hover': {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.primary.light,
                          theme.palette.mode,
                        ),
                    },
                  }
                : undefined,
          })}
        />
      </CardContent>
    </Card>
  );
}
