import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteObjective() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      if (id) {
        return api.delete(`objectives/${id}`);
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['objectives'] }),
  });
}
