import {
  FETCH_TAGS,
  UPDATE_LIVE_FILTER_OVERRIDE,
  UPDATE_LIVE_FOLLOWED,
  UPDATE_LIVE_FOLLOW_OVERRIDE,
} from '@/actions/types';
import { usePrevious } from '@/hooks';
import { get, log } from '@/utils';
import { liveOptions } from '@/utils/config';
import { Box, List } from '@mui/material';
import _ from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as VirtualList } from 'react-window';
import { listComponentsByType } from '../constants';
import { FilterControl } from './FilterControl';

// just for testing
// const renderRowItem = (item, handleClick, style) => {
//   console.count("render item");
//   return <Box onClick={handleClick} style={style}>{item.id}</Box>;
// };

const Row = ({ data, index, style }) => {
  const {
    finalList,
    handleClick,
    hoveredItem,
    selectedItem,
    handleHoverItem,
    handleFollowToggle,
    handleFollowBulk,
    type,
    followedIdsByType,
    sort,
  } = data;

  const item = finalList[index];
  const SpecificListItemComponent =
    listComponentsByType[type].listItemComponent;

  // refactoring to reduce duplication
  // - in the original, PlanListItem didn't have onClick={selectItem}
  return useMemo(
    () =>
      item &&
      // for testing renderRowItem(item, () => {}, style)
      SpecificListItemComponent && (
        <SpecificListItemComponent
          style={style}
          onClick={handleClick}
          // key={key}
          item={item}
          highlighted={
            item.id === hoveredItem.id || item.id === selectedItem.id
          }
          onHoverItem={handleHoverItem}
          onFollowToggle={handleFollowToggle}
          onFollowBulk={handleFollowBulk}
          followedIdsByType={followedIdsByType}
          tertiaryPath={
            liveOptions.showSortFieldInListItems ? sort?.path : undefined
          }
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [followedIdsByType, hoveredItem.id, item, selectedItem.id, style, sort],
  );
};

export function LiveList({
  scrollToItem,
  onSelect,
  hoveredItem,
  selectedItem,
  onHover,
}) {
  const { type = 'vehicles', id: encodedId } = useParams();
  const id = encodedId && decodeURIComponent(encodedId);
  // used to see when changes occur for incident filtering
  const prevType = usePrevious(type);

  const allItemsById = useSelector((state) => state.live[type]);

  const filteredInIdsByTypeOverride = useSelector(
    (state) => state.live.filteredInIdsByTypeOverride,
  );
  const filteredInIdsByType = useSelector(
    (state) =>
      state.live.filteredInIdsByTypeOverride || state.live.filteredInIdsByType,
  );
  const filteredInIds = filteredInIdsByType[type];

  const followedIdsByTypeOverride = useSelector(
    (state) => state.live.followedIdsByTypeOverride,
  );
  const followedIdsByType = useSelector(
    (state) =>
      state.live.followedIdsByTypeOverride || state.live.followedIdsByType,
  );

  const filters = useSelector((state) => state.live.filters);
  const [showFilter, setShowFilter] = useState(false);
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const [listsForType, setlistsForType] = useState({
    baseList: [],
    filteredSortedList: [],
    type,
  });

  const sorts = useSelector((state) => state.live.sorts);

  const prevScrollToItemId = usePrevious(scrollToItem.id);

  const layerVisibilities = useSelector(
    (state) => state.live.layerVisibilities,
  );
  const STALE = 'stale';
  const showStale = layerVisibilities[STALE];

  // get the final sorted, filtered resource list
  // only when sorting, filtering or resources change
  const prevAllItemsById = usePrevious(allItemsById);
  const prevSorts = usePrevious(sorts);
  const prevFilteredInIds = usePrevious(filteredInIds);
  const prevShowStale = usePrevious(showStale);
  useEffect(() => {
    if (
      prevAllItemsById !== allItemsById ||
      prevSorts !== sorts ||
      prevFilteredInIds !== filteredInIds ||
      prevType !== type ||
      prevShowStale !== showStale
    ) {
      const filterStaleIfHidden = (i) => i && (showStale || !i.stale);

      const baseList = Object.values(allItemsById || {}).filter(
        filterStaleIfHidden,
      );

      // are there filtered in ids for this type
      const filteredList = filteredInIds
        ? Object.keys(filteredInIds)
            .map((id) => allItemsById[id])
            .filter(filterStaleIfHidden)
        : baseList;

      const sort = (sorts && sorts[type]) || { path: 'id', desc: false };
      // put elements with undefined sort.path at the end of the list
      const lowestChar = 0;
      const highestChar = 0xffff;
      const unsortedChar = String.fromCharCode(
        sort.desc ? lowestChar : highestChar,
      );
      const topChar = String.fromCharCode(sort.desc ? highestChar : lowestChar);

      const getSortValue = (o) => {
        const value = get(o, sort.path);
        if (typeof value !== 'undefined') {
          return typeof value === 'string' ? value.toLowerCase() : value;
        }
        return unsortedChar;
      };

      const getTagSortValue = (tag) => {
        return tag.type === 'follow' ? topChar : getSortValue(tag);
      };

      const getSortValueFunction =
        type === 'tags' ? getTagSortValue : getSortValue;

      const filteredSortedList = _.orderBy(
        filteredList,
        [getSortValueFunction, 'id'],
        [sort.desc ? 'desc' : 'asc', 'asc'],
      );

      setlistsForType({
        baseList,
        filteredSortedList,
        type,
      });
    }
  }, [
    filteredInIds,
    sorts,
    allItemsById,
    prevFilteredInIds,
    prevSorts,
    prevAllItemsById,
    prevShowStale,
    showStale,
    prevType,
    type,
  ]);

  const dispatch = useDispatch();

  const showList = id == null;

  // if there was a filter override and we nav away wipe it
  useEffect(() => {
    if (
      filteredInIdsByTypeOverride &&
      !filteredInIdsByTypeOverride[type]?.[id]
    ) {
      dispatch({
        type: UPDATE_LIVE_FILTER_OVERRIDE,
        payload: null,
      });
    }
  }, [dispatch, filteredInIdsByTypeOverride, type, id]);

  // if there was a follow override and we nav away wipe it
  useEffect(() => {
    if (followedIdsByTypeOverride && !followedIdsByTypeOverride[type]?.[id]) {
      dispatch({
        type: UPDATE_LIVE_FOLLOW_OVERRIDE,
        payload: null,
      });
    }
  }, [dispatch, followedIdsByTypeOverride, type, id]);

  const handleFilterToggle = () => {
    setShowFilter(!showFilter);
  };

  const handleFollowBulk = useCallback(
    (resourceIdsByType, follow = undefined) => {
      const newFollowedIdsByType = _.cloneDeep(followedIdsByType);
      let updateNeeded = false;

      Object.keys(resourceIdsByType).forEach((type) => {
        // if we haven't followed anything of this type before create a new dict
        if (!(type in newFollowedIdsByType)) {
          newFollowedIdsByType[type] = {};
        }

        Object.keys(resourceIdsByType[type]).forEach((id) => {
          // if this item wasn't followed already add it and follow, else remove it from the dict
          const alreadyFollowed = !!followedIdsByType[type]?.[id];
          const changeNeeded =
            typeof follow === 'undefined' || follow !== alreadyFollowed;
          if (changeNeeded) {
            updateNeeded = true;
            if (!alreadyFollowed) {
              newFollowedIdsByType[type][id] = true;
            } else {
              delete newFollowedIdsByType[type][id];
            }

            log(alreadyFollowed ? 'Unfollow' : 'Follow', 'Live Resource', {
              type,
              id,
            });
          }
        });
      });

      if (updateNeeded) {
        // update state so map can follow too
        dispatch({ type: UPDATE_LIVE_FOLLOWED, payload: newFollowedIdsByType });
      }
    },
    [followedIdsByType, dispatch],
  );

  const handleFollowToggle = useCallback(
    (type, id, follow = undefined) => {
      handleFollowBulk({ [type]: { [id]: true } }, follow);
    },
    [handleFollowBulk],
  );

  const handleClick = useCallback((item) => onSelect(item), [onSelect]);

  // Only scrollToItem if it changes, not if fullResourceList or finalList change
  // nevermind:eslint-disable-next-line react-hooks/exhaustive-deps

  // if the scrollToItem changes, get the index and scroll the list to the item
  useEffect(() => {
    if (scrollToItem.id && scrollToItem.id !== prevScrollToItemId) {
      // console.log("scroll to new id");
      const item = allItemsById[scrollToItem.id];
      if (item) {
        const index = listsForType.filteredSortedList?.indexOf(item);
        setScrollToIndex(index > 0 ? index : 0);
      }
    }
  }, [scrollToItem, listsForType, allItemsById, prevScrollToItemId]);

  const easeInOut = (t) => {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  };

  useEffect(() => {
    dispatch({
      type: FETCH_TAGS,
    });
  }, [id, dispatch]);

  const renderList = () => {
    // console.log("renderList");
    return (
      listsForType?.filteredSortedList &&
      listsForType.filteredSortedList.length > 0 && (
        <List
          id="resourceList"
          dense
          sx={(theme) => ({
            p: 0,
            flex: [1, 1, 'auto'],
            [theme.breakpoints.up('xs')]: {
              mt: 0,
            },
          })}
        >
          <AutoSizer>
            {({ width, height }) => (
              <VirtualList
                style={{ outline: 'none' }}
                //ref="List" // not sure what this was for
                duration={1500}
                easing={easeInOut}
                width={width}
                height={height}
                overscanCount={10}
                itemCount={listsForType.filteredSortedList.length}
                itemSize={56}
                // rowRenderer={renderRow}
                // scrollToIndex={this.state.scrollToIndex} // old way of going to index
                scrollToRow={scrollToIndex}
                scrollToAlignment="start"
                onAnimationComplete={() =>
                  scrollToIndex === -1 || setScrollToIndex(-1)
                }
                itemData={{
                  finalList: listsForType.filteredSortedList,
                  handleClick,
                  hoveredItem,
                  selectedItem,
                  handleHoverItem: onHover,
                  handleFollowToggle,
                  handleFollowBulk,
                  type: listsForType.type || type,
                  followedIdsByType,
                  sort: sorts[type],
                }}
              >
                {Row}
              </VirtualList>
            )}
          </AutoSizer>
        </List>
      )
    );
  };

  const renderItem = () => {
    const item = allItemsById[id];
    // the list can lag a little as it does a setState after filtering & sorting
    const listHasCorrectType = (listsForType?.type || type) === type;
    if ((!item && type !== 'incidents') || !listHasCorrectType) {
      return;
    }

    const SpecificItemComponent = listComponentsByType[type].itemComponent;

    return (
      SpecificItemComponent && (
        <SpecificItemComponent
          id="itemDetails"
          item={item || { id }}
          hoveredId={hoveredItem.id || selectedItem.id}
          onFollowToggle={handleFollowToggle}
          onFollowBulk={handleFollowBulk}
          followedIdsByType={followedIdsByType}
          //following={followedIdsByType?.[type]?.[item.id]}
          onSubItemClick={handleClick}
          onSubItemHover={onHover}
        />
      )
    );
  };

  return (
    <Fragment>
      <Box sx={{ height: 1, display: 'flex' }}>
        <Box
          sx={(theme) => ({
            pt: 6,
            width: 1,
            height: 'calc(100vh - 48px)',
            [theme.breakpoints.up('xs')]: {
              pt: 0,
              height: 'calc(100vh - 200px)',
              minHeight: 'calc(100vh - 350px)',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: showList ? 'flex' : 'none',
              flex: [1, 1, 'auto'],
              flexDirection: 'column',
              overflow: 'hidden',
              [theme.breakpoints.up('xs')]: {
                height: 'calc(100vh - 49px)',
              },
            })}
          >
            <Box>
              <FilterControl
                sx={{ maxHeight: 0.5 }}
                showFilter={showFilter}
                filters={filters[type]}
                fullList={listsForType.baseList}
                filteredListLength={listsForType.filteredSortedList.length}
                type={type}
                onFilterToggle={handleFilterToggle}
              />
            </Box>
            {renderList()}
          </Box>
          <Box
            sx={(theme) => ({
              display: showList ? 'none' : 'flex',
              flex: [1, 1, 'auto'],
              flexDirection: 'column',
              overflow: 'hidden',
              [theme.breakpoints.up('xs')]: {
                height: 'calc(100vh - 49px)',
              },
            })}
            style={{}}
          >
            {id && <Box style={{ overflow: 'auto' }}>{renderItem()}</Box>}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}
