import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useRfidCardAssignmentCounts() {
  return useQuery({
    queryKey: ['rfidCardAssignmentCounts'],
    queryFn: () => {
      const json = [
        { $project: { _id: true } },
        {
          $lookup: {
            from: 'people',
            localField: '_id',
            foreignField: 'rfidCards.reference',
            as: 'people',
          },
        },
        {
          $set: {
            assignmentCount: {
              $size: {
                $filter: {
                  input: '$people',
                  as: 'person',
                  cond: { $ne: ['$$person.deleted', true] },
                },
              },
            },
          },
        },
        { $group: { _id: '$assignmentCount', cards: { $sum: 1 } } },
        { $project: { _id: false, assignments: '$_id', cards: true } },
        { $sort: { assignments: 1 } },
      ];

      return api.post('pipeline/rfidCards', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
