import { getBackgroundColor, getHoverBackgroundColor } from '@/utils';
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';

export function ScoreCard({
  score,
  title,
  unit,
  color,
  Icon,
  selected,
  onClick,
}) {
  const Surface = onClick ? CardActionArea : Box;

  return (
    <Card
      sx={{
        borderColor: (theme) =>
          color
            ? getHoverBackgroundColor(color, theme.palette.mode)
            : undefined,
      }}
    >
      <Surface
        sx={{
          p: 1,
          bgcolor: (theme) =>
            selected
              ? getBackgroundColor(
                  color ?? theme.palette.text.primary,
                  theme.palette.mode,
                )
              : undefined,
          '&:hover': {
            bgcolor: (theme) =>
              selected && onClick
                ? getHoverBackgroundColor(
                    color ?? theme.palette.text.primary,
                    theme.palette.mode,
                  )
                : undefined,
          },
        }}
        onClick={onClick}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="caption" sx={{ color }}>
            {title}
          </Typography>
          {Icon && <Icon sx={{ color, fontSize: '0.75rem' }} />}
        </Stack>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ alignItems: 'baseline', justifyContent: 'flex-end' }}
        >
          <Typography variant="h3" sx={{ fontWeight: 'bold', color }}>
            {Math.round(score ?? 0).toLocaleString()}
          </Typography>
          {unit && (
            <Typography variant="h5" sx={{ color }}>
              {unit}
            </Typography>
          )}
        </Stack>
      </Surface>
    </Card>
  );
}
