import {
  CREATE_OBJECTIVE,
  CREATE_OBJECTIVE_FAILURE,
  CREATE_OBJECTIVE_SUCCESS,
  DELETE_OBJECTIVE,
  DELETE_OBJECTIVE_FAILURE,
  DELETE_OBJECTIVE_SUCCESS,
  FETCH_OBJECTIVE,
  FETCH_OBJECTIVES,
  FETCH_OBJECTIVES_FAILURE,
  FETCH_OBJECTIVES_SUCCESS,
  FETCH_OBJECTIVE_FAILURE,
  FETCH_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE,
  UPDATE_OBJECTIVE_FAILURE,
  UPDATE_OBJECTIVE_SUCCESS,
} from '@/actions';
import { fromAjax } from '@/apis';
import { getHeaders, log } from '@/utils';
import { endOfDay, isValid } from 'date-fns';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

export function fetchObjectivesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_OBJECTIVES),
    mergeMap(() =>
      fromAjax('/objectives', {
        params: {
          projection: {
            identifier: true,
            title: true,
            startTime: true,
            endTime: true,
            wards: true,
            created: true,
            boundaryType: true,
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: payload }) => {
          log('Read', 'Objectives');

          return {
            type: FETCH_OBJECTIVES_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_OBJECTIVES_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchObjectiveEpic(action$) {
  return action$.pipe(
    ofType(FETCH_OBJECTIVE),
    mergeMap(({ payload: id }) =>
      fromAjax(`/objectives/${id}`, {
        params: {
          projection: {
            identifier: true,
            title: true,
            description: true,
            startTime: true,
            endTime: true,
            complianceSeconds: true,
            requiredVisits: true,
            requiredFrequency: true,
            boundaryType: true,
            boundarySubtype: true,
            boundaryIdentifier: true,
            boundary: true,
            compliantPeople: true,
            created: true,
            lastEdit: true,
            occurrenceNumber: true,
            schedule: true,
            applicableTo: true,
            visibleTo: true,
            notifications: true,
            microbeats: true,
            resourceType: true,
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: payload }) => {
          log('Read', 'Objectives', { id });

          return {
            type: FETCH_OBJECTIVE_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_OBJECTIVE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function createObjectiveEpic(action$) {
  return action$.pipe(
    ofType(CREATE_OBJECTIVE),
    mergeMap(({ payload: { endTime, ...body }, navigate }) =>
      fromAjax('/objectives', {
        body: {
          endTime: isValid(endTime) ? endOfDay(endTime) : endTime,
          ...body,
        },
        method: 'POST',
        headers: { ...getHeaders(), 'content-type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', 'Objective', payload);

          navigate(`../${payload.identifier}`, {
            replace: true,
            state: { created: true },
          });

          return {
            type: CREATE_OBJECTIVE_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_OBJECTIVE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function updateObjectiveEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_OBJECTIVE),
    mergeMap(({ payload: { endTime, ...body } }) =>
      fromAjax(`/objectives/${body.identifier}`, {
        body: {
          endTime: isValid(endTime) ? endOfDay(endTime) : endTime,
          ...body,
        },
        method: 'PUT',
        headers: {
          ...getHeaders(),
          'content-type': 'application/json',
        },
      }).pipe(
        map(({ response: payload }) => ({
          type: UPDATE_OBJECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_OBJECTIVE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function deleteObjectiveEpic(action$) {
  return action$.pipe(
    ofType(DELETE_OBJECTIVE),
    mergeMap(({ payload: id, navigate }) =>
      fromAjax(`/objectives/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          navigate('..', { replace: true });

          return {
            type: DELETE_OBJECTIVE_SUCCESS,
            payload: response.identifier,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_OBJECTIVE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}
