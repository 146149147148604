import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {object} Group
 * @property {string} code - The code of the group.
 * @property {string} name - The name of the group.
 * @property {string?} type - The type of the group.
 * @property {string[]} [parentCodes] - The parent codes of the group.
 * @property {string[]} [ancestorCodes] - The ancestor codes of the group.
 * @property {string[]} [aliasTypes] - The alias types of the group.
 * @property {import('@types/geojson').Polygon} [boundary] - The boundary of the group as a GeoJSON polygon.
 */

/**
 * A hook that fetches groups.
 * @param {Object} [params] - The parameters for the query.
 * @param {Array.<string>} [params.types] - The types of groups to fetch.
 * @param {Array.<string>} [params.ancestors] - The ancestor codes of the groups to fetch.
 * @returns {import('@tanstack/react-query').UseQueryResult<Group[]>}
 */
export function useGroups({ types, ancestors } = {}) {
  return useQuery({
    queryKey: ['groups', types, ancestors],
    queryFn: () => {
      const json = [
        ...(types ? [{ $match: { type: { $in: types } } }] : []),
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$parentCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'ancestorCodes',
          },
        },
        {
          $project: {
            _id: false,
            code: true,
            name: true,
            type: true,
            parentCodes: true,
            boundary: true,
            ancestorCodes: {
              $map: {
                input: '$ancestorCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
            aliasTypes: {
              $map: {
                input: {
                  $filter: {
                    input: { $objectToArray: '$aliases' },
                    as: 'entry',
                    cond: { $ne: ['$$entry', []] },
                  },
                },
                as: 'alias',
                in: '$$alias.k',
              },
            },
          },
        },
        ancestors && {
          $match: {
            $or: [
              { ancestorCodes: { $in: ancestors } },
              { code: { $in: ancestors } },
            ],
          },
        },
        { $sort: { name: 1 } },
      ].filter(Boolean);

      log('Read', 'Groups', { types, ancestors });

      return api.post('pipeline/groups', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60 * 12,
  });
}
