import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocationTypeIcon } from './LocationTypeIcon';
import { startCase } from '@/utils';
import { useDocumentTitle } from '@/hooks';

export function Location({ color, background, name, type, subtype, groups }) {
  useDocumentTitle(['IR3', 'Location', name].filter(Boolean).join(' | '));

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Tooltip title={type}>
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <LocationTypeIcon type={type} />
            </Avatar>
          </Tooltip>
        }
        title={name}
        subheader={subtype}
      />
      <CardContent>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            {Object.entries(groups ?? {}).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {startCase(key)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {(value ?? []).join(', ')}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
