import { api } from '@/apis';
import { ReplayLink } from '@/components/controls';
import {
  useOptions,
  useTripAccelerationSummaries,
  useTripAccelerationSummariesCount,
} from '@/hooks';
import { downloadData } from '@/utils';
import { Download as DownloadIcon } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  MRT_TablePagination,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { DownloadPollsLink } from './DownloadPollsLink';
import { activityColumns } from './utils';

export function ActivityTable({
  startTime,
  endTime,
  query,
  mode,
  excludeExempt,
}) {
  const { data: classifications } = useOptions('tripClassification', {
    restricted: true,
  });
  const exemptTripClassifications = useMemo(
    () => classifications.map(({ value }) => value),
    [classifications],
  );
  const [{ sorting, pagination, isDownloading }, setState] = useState({
    sorting: [{ id: 'startTime', desc: true }],
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    isDownloading: false,
  });
  const { data, isLoading, isFetching } = useTripAccelerationSummaries({
    startTime,
    endTime,
    query,
    pagination,
    sorting,
    mode,
    excludeExempt,
  });
  const {
    data: rowCount,
    isLoading: isLoagingCount,
    isFetching: isFetchingCount,
  } = useTripAccelerationSummariesCount({
    startTime,
    endTime,
    query,
    mode,
    excludeExempt,
  });

  const handleTableStateChange = (name) => (changeFn) => {
    setState((state) => ({ ...state, [name]: changeFn(state[name]) }));
  };

  async function handleDownloadClick() {
    setState((state) => ({ ...state, isDownloading: true }));

    const peopleResponse = await api
      .post('pipeline/people', {
        json: [{ $match: query }, { $project: { _id: false, code: true } }],
      })
      .json();

    const pipeline = [
      {
        $match: {
          startTime: { $gte: startTime, $lt: endTime },
          durationSeconds: { $gte: 60 },
          'driver.code': {
            $in: peopleResponse.map((person) => person.code),
          },
          ...(excludeExempt
            ? { classification: { $nin: exemptTripClassifications } }
            : {}),
          ...(mode === 'emergency'
            ? { 'equipmentActivations.emergencyOn': true }
            : mode === 'nonEmergency'
              ? { 'equipmentActivations.emergencyOn': { $ne: true } }
              : {}),
        },
      },
      {
        $project: {
          _id: false,
          code: '$driver.code',
          collarNumber: '$driver.collarNumber',
          name: {
            $concat: ['$driver.forenames', ' ', '$driver.surname'],
          },
          fleetNumber: '$vehicle.fleetNumber',
          registrationNumber: '$vehicle.registrationNumber',
          startTime: true,
          endTime: true,
          emergencyOn: {
            $cond: ['$equipmentActivations.emergencyOn', 'Yes', 'No'],
          },
          score: {
            $round: [
              {
                $multiply: [
                  {
                    $divide: [
                      {
                        $subtract: [
                          '$durationSeconds',
                          {
                            $add: [
                              '$excessAccelerationDurationSeconds',
                              '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
                              '$excessBrakingDurationSeconds',
                              '$excessBrakingWithEmergencyEquipmentDurationSeconds',
                              '$excessCorneringDurationSeconds',
                              '$excessCorneringWithEmergencyEquipmentDurationSeconds',
                              '$speedInfractionsDurationSeconds',
                              '$speedInfractionsWithEmergencyEquipmentDurationSeconds',
                            ],
                          },
                        ],
                      },
                      '$durationSeconds',
                    ],
                  },
                  100,
                ],
              },
              0,
            ],
          },
          distanceMiles: {
            $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
          },
          durationDays: { $divide: ['$durationSeconds', 86400] },
          accelerationDays: {
            $round: [
              {
                $divide: [
                  {
                    $add: [
                      '$excessAccelerationDurationSeconds',
                      '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
                    ],
                  },
                  86400,
                ],
              },
              15,
            ],
          },
          brakingDays: {
            $round: [
              {
                $divide: [
                  {
                    $add: [
                      '$excessBrakingDurationSeconds',
                      '$excessBrakingWithEmergencyEquipmentDurationSeconds',
                    ],
                  },
                  86400,
                ],
              },
              15,
            ],
          },
          corneringDays: {
            $round: [
              {
                $divide: [
                  {
                    $add: [
                      '$excessCorneringDurationSeconds',
                      '$excessCorneringWithEmergencyEquipmentDurationSeconds',
                    ],
                  },
                  86400,
                ],
              },
              15,
            ],
          },
          speedingDays: {
            $round: [
              {
                $divide: [
                  {
                    $add: [
                      '$speedInfractionsDurationSeconds',
                      '$speedInfractionsWithEmergencyEquipmentDurationSeconds',
                    ],
                  },
                  86400,
                ],
              },
              15,
            ],
          },
        },
      },
    ];

    const columns = [
      { header: 'Staff ID', key: 'code' },
      { header: 'Collar#', key: 'collarNumber' },
      { header: 'Name', key: 'name' },
      { header: 'Fleet#', key: 'fleetNumber' },
      { header: 'Registration', key: 'registrationNumber' },
      { header: 'Start Time', key: 'startTime' },
      { header: 'End Time', key: 'endTime' },
      { header: 'Warning Equipment', key: 'emergencyOn' },
      { header: 'Score (%)', key: 'score' },
      { header: 'Distance (miles)', key: 'distanceMiles' },
      { header: 'Duration', key: 'durationDays' },
      { header: 'Acceleration', key: 'accelerationDays' },
      { header: 'Braking', key: 'brakingDays' },
      { header: 'Cornering', key: 'corneringDays' },
      { header: 'Speeding', key: 'speedingDays' },
    ];

    downloadData(
      'vehicleTripAccelerationSummaries',
      'trip-summaries.csv',
      pipeline,
      columns,
    )
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setState((state) => ({ ...state, isDownloading: false })));
  }

  function renderRowActions(cell) {
    return (
      <Stack spacing={0.5} direction="row">
        <ReplayLink
          collection="vehicleTripAccelerationSummaries"
          identifier={cell.row.original.identifier}
        />
        <DownloadPollsLink
          imei={cell.row.original.vehicle?.telematicsBoxImei}
          startTime={cell.row.original.startTime}
          endTime={cell.row.original.endTime}
        />
      </Stack>
    );
  }

  function renderBottomToolbar() {
    return (
      <Stack
        direction="row"
        sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box>
          <Tooltip title="Download data">
            <IconButton size="small" onClick={handleDownloadClick}>
              {isDownloading ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                <DownloadIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <MRT_TablePagination table={table} />
      </Stack>
    );
  }

  const table = useMaterialReactTable({
    data: data || [],
    columns: activityColumns,
    state: {
      density: 'compact',
      sorting,
      pagination,
      isLoading: isLoading || isFetching || isLoagingCount || isFetchingCount,
    },
    rowCount: rowCount ?? 0,
    onPaginationChange: handleTableStateChange('pagination'),
    onSortingChange: handleTableStateChange('sorting'),
    defaultColumn: { size: 0 },
    enableTopToolbar: false,
    enableBottomToolbar: true,
    enablePagination: true,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableGlobalFilter: false,
    enableRowActions: true,
    manualPagination: true,
    manualSorting: true,
    positionActionsColumn: 'last',
    renderRowActions,
    renderBottomToolbar,
  });

  return <MaterialReactTable table={table} />;
}
