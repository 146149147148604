import {
  useDeleteObjective,
  useDocumentTitle,
  useObjective,
  useOptions,
  usePeople,
  useUpsertObjective,
  useUserInfo,
} from '@/hooks';
import { isAuthorised } from '@/utils';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ObjectiveForm } from './ObjectiveForm';

export function Objective() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: objective } = useObjective(id);
  useDocumentTitle(
    ['IR3', 'Objectives', objective?._id ? objective.title : 'New Objective']
      .filter(Boolean)
      .join(' | '),
  );
  const { mutate: upsertObjective } = useUpsertObjective();
  const { mutate: deleteObjective } = useDeleteObjective();
  const { data: people } = usePeople();
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'objectives', true);
  const emailAddresses = useMemo(
    () =>
      [...new Set(people.map((item) => item.emailAddress).filter(Boolean))]
        .sort()
        .map((item) => ({ label: item, value: item })),
    [people],
  );
  const { data: activeDirectoryGroups } = useOptions('activeDirectoryGroup');

  function handleDelete(id) {
    deleteObjective(id, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function handleSubmit(values) {
    upsertObjective(values, {
      onSuccess: (data) => {
        navigate(`../${data.identifier}`, { replace: true });
      },
    });
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1 }}>
      <ObjectiveForm
        key={[objective?._id, objective?.lastEdit?.time]}
        defaultValues={objective}
        canEdit={canEdit}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        emailAddresses={emailAddresses}
        activeDirectoryGroups={activeDirectoryGroups}
      />
    </Box>
  );
}
