import { useMap } from 'react-map-gl/maplibre';

export function MapImage({ name, url, sdf }) {
  const { current: map } = useMap();

  if (map.isStyleLoaded() && !map.hasImage(name)) {
    map.loadImage(url).then(({ data }) => {
      if (!map.hasImage(name)) {
        map.addImage(name, data, { sdf });
      }
    });
  }

  return null;
}
