import { getStatusColor, startCase } from '@/utils';
import { PriorityHigh as PriorityHighIcon } from '@mui/icons-material';
import {
  Avatar,
  Chip,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';

export function IncidentListItem({ item: { size, start }, feature, ...props }) {
  const date = feature.properties.date
    ? format(new Date(feature.properties.date), 'dd/MM/yyyy')
    : '';

  return (
    <ListItemButton
      {...props}
      dense
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
    >
      <ListItemAvatar>
        <Tooltip
          title={startCase(feature.properties?.status)}
          placement="left-end"
        >
          <Avatar
            sx={{
              bgcolor: getStatusColor(feature.properties?.status),
            }}
          >
            {feature.properties?.grade || <PriorityHighIcon />}
          </Avatar>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        primary={feature.properties.reference ?? ''}
        primaryTypographyProps={{ noWrap: true }}
        secondary={date}
        secondaryTypographyProps={{ noWrap: true }}
      />
      {feature.properties?.type?.code && (
        <Chip
          size="small"
          label={feature.properties?.type?.code}
          sx={{ fontSize: '.6rem', height: 18 }}
        />
      )}
    </ListItemButton>
  );
}
