import { api } from '@/apis';
import { useMutation } from '@tanstack/react-query';
import { useUserInfo } from './useUserInfo';

/**
 * @typedef {object} Variables
 * @property {string} identifier - The identifier of the accelerometer alert.
 */

/**
 * A hook for claiming an accelerometer alert.
 * @returns {import('@tanstack/react-query').UseMutationResult<void, unknown, Variables, unknown>} The useMutation object for claiming an accelerometer alert.
 */
export function useClaimAccelerometerAlert() {
  const { data: userInfo } = useUserInfo();

  return useMutation({
    mutationFn: ({ identifier }) => {
      return api
        .patch(`accelerometerAlerts/${identifier}`, {
          json: {
            claimedBy: userInfo?.username(),
          },
          headers: {
            'content-type': 'application/merge-patch+json',
          },
        })
        .json();
    },
  });
}
