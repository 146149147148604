import {
  CREATE_TAG,
  DELETE_TAG,
  FETCH_TAG,
  FETCH_TAG_SUCCESS,
  UPDATE_TAG,
} from '@/actions';
import { Section } from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import {
  ContentField,
  CounterTextField,
  Field,
  ItemsField,
  StyledField,
  required,
} from '@/components/fields';
import { useDocumentTitle } from '@/hooks';
import { doesIdExist } from '@/utils';
import { tagForm } from '@/utils/config';
import { Assignment as AssignmentIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardHeader,
  Divider,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { dequal } from 'dequal';
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const initialValues = {
  type: 'Tag',
};

export function Tag() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const tag = useSelector((state) => state.tags.tag, dequal);
  const error = useSelector((state) => state.tags.error);
  const [deleteOpen, setDeleteOpen] = useState(false);
  useDocumentTitle(['IR3', 'Tag', tag?.identifier].filter(Boolean).join(' | '));

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_TAG_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_TAG,
        payload: id,
      });
    }
  }, [id, dispatch]);

  function handleDelete() {
    if (tag) {
      dispatch({
        type: DELETE_TAG,
        payload: encodeURIComponent(tag.identifier),
        navigate,
      });
    }
  }

  async function validate(values) {
    const errors = {};

    if (!tag) {
      if (values.identifier) {
        if (values.identifier === 'new') {
          errors.identifier = 'Invalid';
        }
        const exists = await doesIdExist('tags', values.identifier);
        if (exists) {
          errors.identifier = 'Exists';
        }
      }
    }

    return errors;
  }

  function onSubmit(values) {
    if (tag) {
      dispatch({
        type: UPDATE_TAG,
        payload: values,
      });
    } else {
      dispatch({
        type: CREATE_TAG,
        payload: values,
        navigate,
      });
    }
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <Form
        initialValues={tag || initialValues}
        validate={validate}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          form: { reset },
          submitting,
          pristine,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={1}>
              <CardHeader
                avatar={
                  <Avatar>
                    <AssignmentIcon />
                  </Avatar>
                }
                title={values.identifier}
              />
              <Box>
                {values.created && (
                  <Box sx={{ pl: 2 }}>
                    <Typography variant="caption">
                      {`Created by ${values.created.userId} ${format(
                        new Date(values.created.time),
                        'dd/MM/yyyy HH:mm',
                      )}`}
                    </Typography>
                  </Box>
                )}
                {values.lastEdit && (
                  <Box sx={{ pl: 2 }}>
                    <Typography variant="caption">
                      {`Last edited by ${values.lastEdit.userId} ${format(
                        new Date(values.lastEdit.time),
                        'dd/MM/yyyy HH:mm',
                      )}`}
                    </Typography>
                  </Box>
                )}
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <Section>
                  <StyledField
                    name="identifier"
                    component={CounterTextField}
                    label="Code"
                    validate={required}
                    disabled={tag && 'identifier' in tag}
                    {...tagForm.identifier.componentProps}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Description</ListSubheader>
                <Section>
                  <Field
                    name="description"
                    component={ContentField}
                    sx={{ flex: 1, mb: 1 }}
                  />
                </Section>
                <Divider />
                {!_.isEmpty(values.items) && (
                  <Fragment>
                    <ListSubheader disableSticky>Items</ListSubheader>
                    <Fragment>
                      {values.items && (
                        <Field name="items" component={ItemsField} />
                      )}
                    </Fragment>
                  </Fragment>
                )}
              </Box>
              <CardActions disableSpacing>
                <Button
                  color="primary"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Cancel
                </Button>
                <Button
                  color="error"
                  onClick={() => setDeleteOpen(true)}
                  disabled={tag === null}
                >
                  Delete
                </Button>
              </CardActions>
            </Stack>
            <ConfirmationDialog
              action="Delete"
              open={deleteOpen}
              itemId={values.identifier}
              onOk={handleDelete}
              onCancel={() => setDeleteOpen(false)}
            />
          </form>
        )}
      />
    </Box>
  );
}
