import { useTripClassifications } from '@/hooks';
import {
  Dialog,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';

export function ClassificationDialog({ onClose, open }) {
  const { data: tripClassifications } = useTripClassifications();

  function handleClose() {
    onClose();
  }

  const handleListItemClick = (value) => () => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="classification-dialog-title"
      open={open}
    >
      <DialogTitle id="classification-dialog-title">
        Select classification
      </DialogTitle>
      <List>
        <ListItemButton onClick={handleListItemClick(null)}>
          <ListItemText primary="None" />
        </ListItemButton>
        {tripClassifications.map(({ value, label }) => (
          <ListItemButton onClick={handleListItemClick(value)} key={value}>
            <ListItemText primary={label} />
          </ListItemButton>
        ))}
      </List>
    </Dialog>
  );
}
