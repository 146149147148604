import { FETCH_LIVE_PERSON } from '@/actions/types';
import { TagControl } from '@/components/controls';
import { useDocumentTitle, useOptionValues, usePrevious } from '@/hooks';
import { startCase } from '@/utils';
import { liveOptions, useDallasKeys } from '@/utils/config';
import {
  Edit as EditIcon,
  LocationSearching as FollowIcon,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Table,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { groupsToLabelAccessors, toLabelAccessors } from '../utilities';
import { CallSignLiveListItem } from './CallSignLiveListItem';
import { IncidentLiveListItem } from './IncidentLiveListItem';
import { ItemAvatar } from './ItemAvatar';
import { ItemRows } from './ItemRows';

const { offDutyNames = [] } = liveOptions;

function dutiesToLabelAccessors(duties) {
  return (duties || []).map((d) => ({
    label: format(new Date(d.startTime), 'EEE d/M'),
    value: d.name,
    disabled: offDutyNames.some(
      (o) => 0 === o.localeCompare(d.name, undefined, { sensitivity: 'base' }),
    ),
  }));
}

export function PersonLiveItem({
  item,
  onFollowToggle,
  followedIdsByType,
  onSubItemClick,
  onSubItemHover,
  hoveredId,
}) {
  const title = item.name || item.radioSsi;
  useDocumentTitle(
    ['IR3', 'Live', 'People', title].filter(Boolean).join(' | '),
  );
  const hideFollow = !item.position;
  const personRecord = useSelector(
    (state) => state.live.personRecordsById[item.id],
  );
  const personGroups = useOptionValues('personGroup');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const prevId = usePrevious(item.id);
  useEffect(() => {
    if (prevId !== item.id && !personRecord) {
      dispatch({
        type: FETCH_LIVE_PERSON,
        payload: item.id,
      });
    }
  }, [dispatch, item.id, prevId, personRecord]);

  const incidents = useSelector((state) => state.live.incidents);
  const incident =
    item.assignments?.incident?.number &&
    (incidents[item.assignments.incident.number] || {
      id: item.assignments.incident.number,
    });

  const callSigns = useSelector((state) => state.live.callSigns);
  const callSign = callSigns[item.assignments?.callSign?.code];

  const type = 'people';
  const following = followedIdsByType?.[type]?.[item.id];

  const sections = {
    details: [
      { label: 'Role', value: item.role },
      {
        label: 'Home Station',
        value: item.homeStation,
      },
      { label: 'Status', value: startCase(item.status) },
      { label: 'Radio SSI', value: item.radioSsi },
      {
        label: 'Emergency Button',
        value: item.emergencyButtonOn ? 'On' : 'Off',
      },
      // { label: 'GPS Available', value: item.gpsAvailable ? 'Yes' : 'No' },
      // { label: "Call sign", value: item.callSign},
      {
        label: 'Last poll time',
        value: item.lastPollTime
          ? format(new Date(item.lastPollTime), 'dd/MM/yyyy HH:mm:ss')
          : '-',
      },
      item.driverKeysArray.length > 0 && {
        label: useDallasKeys ? 'Dallas Keys' : 'RFIDs',
        value: item.driverKeysArray?.join(', ') || '',
      },
      ...groupsToLabelAccessors(personGroups, item.groups),
    ].filter(Boolean),
    skills: toLabelAccessors(item.skills, 'type', 'name'),
    duties: dutiesToLabelAccessors(item.duties),
    locations: toLabelAccessors(item.currentLocations, 'type', 'name'),
  };

  const link = `/resources/${type}/${item.id}`;

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={<ItemAvatar item={item} type="people" />}
        title={title}
        // subheader={item.collarNumber}
        action={
          <Fragment>
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(link)} size="large">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {!hideFollow && (
              <Tooltip title={following ? 'Stop following' : 'Follow'}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onFollowToggle('people', item.id);
                  }}
                  size="large"
                >
                  <FollowIcon
                    titleAccess="Follow"
                    fontSize="small"
                    color={following ? 'primary' : 'disabled'}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Fragment>
        }
      />
      {personRecord && personRecord.picture && (
        <CardMedia style={{ height: 420 }} image={personRecord.picture} />
      )}

      <CardContent>
        <TagControl live item={item} type={type} sx={{ py: 2 }} label="Tags" />
        {item.assignments && (
          <Fragment>
            {callSign && (
              <Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Call Sign
                </Typography>
                <CallSignLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  item={callSign}
                />
              </Fragment>
            )}
            {incident && (
              <Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Assigned Incident
                </Typography>
                <IncidentLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  item={incident}
                  highlighted={hoveredId === incident.id}
                />
              </Fragment>
            )}
          </Fragment>
        )}
        {Object.keys(sections)
          .filter((key) => sections[key] && sections[key].length > 0)
          .map((key) => (
            <Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {startCase(key)}
              </Typography>
              <Table size="small" sx={{ mt: 1, mb: 2 }}>
                <TableBody>{ItemRows(sections[key], item)}</TableBody>
              </Table>
            </Fragment>
          ))}
      </CardContent>
    </Card>
  );
}
