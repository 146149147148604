import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useAddNestedOption() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ name, value, option }) => {
      return api
        .post(`options/${name}-${value}/values`, { json: option })
        .json();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['options'] }),
  });
}
