import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useAuditOptions() {
  return useQuery({
    queryKey: ['auditOptions'],
    queryFn: async () => {
      const json = [
        {
          $group: {
            _id: null,
            users: { $addToSet: '$user' },
            dataTypes: { $addToSet: '$dataType' },
            actions: { $addToSet: '$action' },
          },
        },
        {
          $project: {
            _id: false,
            users: { $sortArray: { input: '$users', sortBy: 1 } },
            dataTypes: { $sortArray: { input: '$dataTypes', sortBy: 1 } },
            actions: { $sortArray: { input: '$actions', sortBy: 1 } },
          },
        },
      ];

      const response = await api.post('pipeline/audits', { json }).json();

      return {
        users:
          response[0].users?.map((user) => ({
            label: user,
            value: user,
          })) ?? [],
        dataTypes:
          response[0].dataTypes?.map((dataType) => ({
            label: dataType,
            value: dataType,
          })) ?? [],
        actions:
          response[0].actions?.map((action) => ({
            label: action,
            value: action,
          })) ?? [],
      };
    },
    placeholderData: (previousData) =>
      previousData ?? {
        users: [],
        dataTypes: [],
        actions: [],
      },
    staleTime: 1000 * 60 * 60 * 24,
  });
}
