import { Dialog } from '@mui/material';
import { UserQueryForm } from './UserQueryForm';

export function SaveDialog({
  open,
  onClose,
  collection,
  value,
  enableInterval,
  username,
}) {
  function handleClose() {
    onClose();
  }

  function submitCallback(values) {
    onClose(values);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <UserQueryForm
        key={value}
        value={value}
        onSubmit={submitCallback}
        onCancel={handleClose}
        collection={collection}
        username={username}
        enableInterval={enableInterval}
      />
    </Dialog>
  );
}
