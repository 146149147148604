import {
  CREATE_LOCATION,
  DELETE_LOCATION,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCESS,
  UPDATE_EVENTS_PARAMETERS,
  UPDATE_LOCATION,
} from '@/actions';
import {
  BoundaryMap,
  GroupAncestors,
  Section,
  TagControl,
} from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import {
  AvatarField,
  CounterTextField,
  DateTimeField,
  Field,
  GeometryField,
  GroupCodesField,
  GroupsField,
  SelectField,
  StyledField,
  TextField,
  required,
  requiredDateAfter,
  requiredDateBefore,
} from '@/components/fields';
import {
  useDocumentTitle,
  useGroupOptions,
  useLocationTypes,
  useOptionValues,
  useUserInfo,
} from '@/hooks';
import { doesIdExist, isAuthorised } from '@/utils';
import { baseType, locationForm, showTranmanIdentifier } from '@/utils/config';
import {
  Box,
  Button,
  CardActions,
  CardHeader,
  Divider,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
} from '@mui/material';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export function Location() {
  const { data: locationTypes } = useLocationTypes();
  const { id, locationType = Object.keys(locationTypes)[0] } = useParams();
  const dispatch = useDispatch();
  const location = useSelector((state) => state.locations.location, dequal);
  const error = useSelector((state) => state.locations.error);
  const groupOptions = useGroupOptions();
  const typeOptions = useOptionValues('groupType');
  const locationGroups = useOptionValues('locationGroup');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'locations', true);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  useDocumentTitle(
    ['IR3', 'Locations', location?.name].filter(Boolean).join(' | '),
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_LOCATION_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_LOCATION,
        payload: id,
      });
    }
  }, [id, dispatch]);

  async function onSubmit(values) {
    if (location) {
      dispatch({
        type: UPDATE_LOCATION,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('locations', values.code);
      if (exists) {
        return { code: 'Exists' };
      }

      dispatch({
        type: CREATE_LOCATION,
        payload: values,
        navigate,
      });
    }
  }

  function handleDelete() {
    if (location) {
      dispatch({
        type: DELETE_LOCATION,
        payload: encodeURIComponent(location.code),
        navigate,
      });
    }
  }

  function isDisabled(fieldName) {
    return !(
      canEdit &&
      (locationForm[fieldName]?.editable || location === null)
    );
  }

  function validate(values) {
    let errors = {};

    // start/end validation
    errors.startTime = requiredDateBefore(values.endTime)(values.startTime);
    errors.endTime = requiredDateAfter(values.startTime)(values.endTime);

    return errors;
  }

  const navigateToEvents = (eventType, pathname, type) => () => {
    dispatch({
      type: UPDATE_EVENTS_PARAMETERS,
      payload: {
        eventType,
        parameters: {
          'location.code': {
            condition: 'includes',
            selection: [id],
          },
        },
      },
    });

    navigate(`/events/${type}/${pathname}`);
  };

  function handleEventsClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleEventsClose() {
    setAnchorEl(null);
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <Form
        initialValues={location || { type: locationTypes[locationType]?.value }}
        onSubmit={onSubmit}
        validate={validate}
        render={({
          handleSubmit,
          form: { reset },
          submitting,
          dirty,
          pristine,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={1}>
              <CardHeader
                avatar={
                  <Field
                    name="picture"
                    component={AvatarField}
                    icon={
                      <SvgIcon>
                        <path d={locationTypes[locationType]?.d} />
                      </SvgIcon>
                    }
                    disabled={isDisabled('picture')}
                  />
                }
                title={values.name || ''}
                subheader={values.code || ''}
              />
              <Box>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <Section>
                  <StyledField
                    name="code"
                    component={CounterTextField}
                    label="Code"
                    validate={required}
                    disabled={!(canEdit && location === null)}
                    {...locationForm.code.componentProps}
                  />
                  <StyledField
                    name="name"
                    component={CounterTextField}
                    label="Name"
                    validate={required}
                    disabled={isDisabled('name')}
                    {...locationForm.name.componentProps}
                  />
                  {showTranmanIdentifier && values.type === baseType.label && (
                    <StyledField
                      name="tranmanIdentifier"
                      component={TextField}
                      label="Tranman Identifier"
                      disabled={isDisabled('tranmanIdentifier')}
                    />
                  )}
                  <StyledField
                    name="subtype"
                    component={SelectField}
                    label="Subype"
                    values={locationTypes[locationType]?.values ?? []}
                    disabled={isDisabled('subtype')}
                  />
                  <Field
                    name="groupCodes"
                    component={GroupCodesField}
                    groupOptions={groupOptions}
                    disabled={isDisabled('groupCodes')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>
                  Ancestor Groups & Areas
                </ListSubheader>
                <Section>
                  <GroupAncestors
                    groupAncestors={values?.groupAncestors}
                    typeOptions={typeOptions}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Active Time Period</ListSubheader>
                <Section>
                  <StyledField
                    name="startTime"
                    component={DateTimeField}
                    label="Start Time"
                    maxDate={values.endTime || '2100-01-01'}
                    validate={required}
                    disabled={isDisabled('startTime')}
                  />
                  <StyledField
                    name="endTime"
                    component={DateTimeField}
                    label="End Time"
                    minDate={values.startTime || '1900-01-01'}
                    validate={required}
                    disabled={isDisabled('endTime')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Categories</ListSubheader>
                <Section>
                  <Field
                    name="groups"
                    groups={locationGroups}
                    component={GroupsField}
                    disabled={isDisabled('groups')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Tags</ListSubheader>
                <Section>
                  <TagControl
                    item={{ id }}
                    type="locations"
                    sx={{ pb: 1 }}
                    disabled={id === 'new' || !id}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Boundary</ListSubheader>
                {isDisabled('boundary') ? (
                  <BoundaryMap boundary={values.boundary} />
                ) : (
                  <Field
                    name="boundary"
                    component={GeometryField}
                    geoType="Polygon"
                    validate={required}
                  />
                )}
              </Box>
              <CardActions disableSpacing>
                <Button
                  color="primary"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  aria-owns={anchorEl ? 'events-menu' : undefined}
                  aria-haspopup="true"
                  onClick={handleEventsClick}
                  disabled={dirty || submitting || location === null}
                >
                  Events
                </Button>
                <Menu
                  id="events-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleEventsClose}
                >
                  <MenuItem
                    onClick={navigateToEvents(
                      'vehicleLocationVisits',
                      'locationvisits',
                      'vehicles',
                    )}
                  >
                    Vehicle Visits
                  </MenuItem>
                  <MenuItem
                    onClick={navigateToEvents(
                      'personLocationVisits',
                      'locationvisits',
                      'people',
                    )}
                  >
                    Person Visits
                  </MenuItem>
                </Menu>
                {canEdit && (
                  <Button
                    color="error"
                    onClick={() => setDeleteOpen(true)}
                    disabled={location === null}
                  >
                    Delete
                  </Button>
                )}
              </CardActions>
            </Stack>
            <ConfirmationDialog
              action="Delete"
              open={deleteOpen}
              itemId={values.name || values.code}
              onOk={handleDelete}
              onCancel={() => setDeleteOpen(false)}
            />
          </form>
        )}
      />
    </Box>
  );
}
