import { Box, Tab, Tabs, Toolbar } from '@mui/material';
import { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

const tabs = [
  { label: 'BCU', value: '/highlights/basiccommandunit' },
  { label: 'Sector', value: '/highlights/sector' },
  { label: 'Team', value: '/highlights/team' },
  { label: 'Fleet', value: '/highlights/fleet' },
];

export function HighlightsToolbar() {
  const { pathname } = useLocation();

  return (
    <Fragment>
      <Toolbar variant="dense" disableGutters>
        <Tabs
          textColor="inherit"
          indicatorColor="secondary"
          value={pathname === '/highlights' ? tabs[0].value : pathname}
        >
          {tabs.map(({ label, value }) => (
            <Tab
              key={value}
              label={label}
              value={value}
              to={value}
              component={Link}
            />
          ))}
        </Tabs>
        <Box sx={{ flex: 1 }} />
      </Toolbar>
    </Fragment>
  );
}
