import { formatGroupBy, shortHumanizer } from '@/utils';
import {
  brown,
  cyan,
  green,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { atom } from 'jotai';

export const stateAtom = atom({
  groupBy: 'all',
  sorting: [{ id: 'group', desc: false }],
  hours: [...Array(24).keys()],
  days: [...Array(7).keys()],
  query: {},
  parameters: {},
});

export const staticGroupByOptions = {
  all: { label: 'All', value: null },
  date: { label: 'Date', value: '$time' },
  month: {
    label: 'Month',
    value: {
      $dateTrunc: {
        date: '$time',
        unit: 'month',
        timezone: 'Europe/London',
      },
    },
  },
  code: { label: 'Payroll Number', value: '$person.code' },
  collarNumber: { label: 'Collar Number', value: '$person.collarNumber' },
  role: { label: 'Role', value: '$person.role' },
  homeStation: { label: 'Home Station', value: '$person.homeStation' },
};

export const columnsFn = (groupBy) => [
  {
    header: staticGroupByOptions[groupBy]?.label ?? 'Group',
    accessorKey: 'group',
    Cell: ({ cell }) => formatGroupBy(groupBy)(cell.getValue()),
  },
  {
    header: 'Person Count',
    accessorKey: 'resourceCount',
  },
  {
    header: 'On Radio',
    accessorKey: 'loggedInHours',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 3600000),
  },
  {
    header: 'Driving',
    accessorKey: 'drivingHours',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 3600000),
  },
  {
    header: 'In Base',
    accessorKey: 'baseHours',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 3600000),
  },
  {
    header: 'In Home Ward',
    accessorKey: 'homeWardHours',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 3600000),
  },
  {
    header: 'Responding to Incidents',
    accessorKey: 'respondingHours',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 3600000),
  },
  {
    header: 'Attending Objectives',
    accessorKey: 'attendingHours',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 3600000),
  },
  {
    header: 'Double Crewing',
    accessorKey: 'doubleCrewingHours',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 3600000),
  },
  {
    header: 'Total Miles',
    accessorKey: 'totalMiles',
  },
  {
    header: 'Average Miles',
    accessorKey: 'averageMiles',
  },
  {
    header: 'Daily Miles',
    accessorKey: 'dailyMiles',
  },
  {
    header: 'Total Base Visits',
    accessorKey: 'totalBaseVisits',
  },
  {
    header: 'Average Base Visits',
    accessorKey: 'averageBaseVisits',
  },
  {
    header: 'Daily Base Visits',
    accessorKey: 'dailyBaseVisits',
  },
];

export const compareFn =
  (key = 'group', desc = false) =>
  (a, b) => {
    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      return desc ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);
    }

    return desc ? b[key] - a[key] : a[key] - b[key];
  };

export const bars = [
  {
    name: 'loggedInHours',
    label: 'On Radio',
    colour: green[500],
  },
  {
    name: 'drivingHours',
    label: 'Driving',
    colour: purple[500],
  },
  {
    name: 'baseHours',
    label: 'In Base',
    colour: brown[500],
  },
  {
    name: 'homeWardHours',
    label: 'In Home Ward',
    colour: orange[500],
  },
  {
    name: 'respondingHours',
    label: 'Responding to Incidents',
    colour: red[500],
  },
  {
    name: 'attendingHours',
    label: 'Attending Objectives',
    colour: teal[500],
  },
  {
    name: 'doubleCrewingHours',
    label: 'Double Crewing',
    colour: cyan[500],
  },
];
