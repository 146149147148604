import {
  CREATE_RFID_CARD,
  DELETE_RFID_CARD,
  FETCH_RFID_CARD,
  FETCH_RFID_CARD_SUCCESS,
  REMOVE_RFID_CARD,
  UPDATE_RFID_CARD,
} from '@/actions';
import { Section } from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import {
  SelectField,
  StyledField,
  TextField,
  required,
} from '@/components/fields';
import { useDocumentTitle, useOptions, useUserInfo } from '@/hooks';
import { doesIdExist, isAuthorised } from '@/utils';
import { useDallasKeys, useRfidCardCategory } from '@/utils/config';
import {
  Nfc as NfcIcon,
  Person as PersonIcon,
  RemoveCircle as RemoveCircleIcon,
  VpnKey as VpnKeyIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { dequal } from 'dequal';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

export function RfidCard() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const rfidCard = useSelector((state) => state.rfidCards.rfidCard, dequal);
  const { data: tripClassifications } = useOptions('tripClassification');
  const { data: rfidCardTypes } = useOptions('rfidCardType');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [removePerson, setRemovePerson] = useState(null);
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'people', true);
  useDocumentTitle(
    ['IR3', useDallasKeys ? 'Dallas Keys' : 'RFID Cards', rfidCard?.reference]
      .filter(Boolean)
      .join(' | '),
  );

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_RFID_CARD_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_RFID_CARD,
        payload: id,
      });
    }
  }, [id, dispatch]);

  async function onSubmit(values) {
    if (rfidCard) {
      dispatch({
        type: UPDATE_RFID_CARD,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('rfidCards', values.reference);
      if (exists) {
        return { reference: 'Exists' };
      }

      dispatch({
        type: CREATE_RFID_CARD,
        payload: values,
        navigate,
      });
    }
  }

  function handleDelete() {
    if (rfidCard) {
      dispatch({
        type: DELETE_RFID_CARD,
        payload: rfidCard.reference,
        navigate,
      });
    }
  }

  function isDisabled() {
    return !(canEdit || rfidCard === null);
  }

  const handleRemoveClick = (person) => (event) => {
    event.preventDefault();

    setRemovePerson(person);
  };

  function handleRemove() {
    const body = {
      code: removePerson.code,
      rfidCards: removePerson.rfidCards.filter((card) => card.reference !== id),
    };

    dispatch({
      type: REMOVE_RFID_CARD,
      payload: { id, body },
    });

    setRemovePerson(null);
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <Form
        initialValues={rfidCard || {}}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          form: { reset },
          submitting,
          pristine,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={1}>
              <CardHeader
                avatar={
                  <Avatar aria-label="RFID Card">
                    {useDallasKeys ? <VpnKeyIcon /> : <NfcIcon />}
                  </Avatar>
                }
                title={values.reference}
                subheader={values.type}
              />
              <Box>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <Section>
                  <StyledField
                    name="reference"
                    component={TextField}
                    label="Serial Number"
                    validate={required}
                    disabled={!(canEdit && rfidCard === null)}
                    parse={(value) =>
                      value && !isNaN(parseInt(value, 16))
                        ? parseInt(value, 16).toString(16)
                        : ''
                    }
                  />
                  {useRfidCardCategory && (
                    <StyledField
                      name="category"
                      component={SelectField}
                      label="Classification"
                      values={tripClassifications}
                      disabled={isDisabled('category')}
                    />
                  )}
                  <StyledField
                    name="type"
                    component={SelectField}
                    label="Type"
                    values={rfidCardTypes}
                    disabled={isDisabled('type')}
                  />
                  <StyledField
                    name="label"
                    component={TextField}
                    label="Label"
                    disabled={isDisabled('label')}
                  />
                </Section>
                <Divider />
                <ListSubheader disableSticky>Assigned People</ListSubheader>
                <Section>
                  {rfidCard && rfidCard.people.length > 0 ? (
                    rfidCard.people.map((person) => (
                      <Card
                        key={person.code}
                        variant="outlined"
                        sx={{ width: 320, mr: 1, mb: 1 }}
                      >
                        <CardActionArea
                          component={Link}
                          to={`/resources/people/${encodeURIComponent(
                            person.code,
                          )}`}
                        >
                          <CardHeader
                            avatar={
                              <Avatar src={person.picture}>
                                <PersonIcon />
                              </Avatar>
                            }
                            action={
                              <Tooltip title="Remove">
                                <IconButton
                                  onClick={handleRemoveClick(person)}
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <RemoveCircleIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            title={`${person.forenames} ${person.surname}`}
                            subheader={person.collarNumber}
                          />
                        </CardActionArea>
                      </Card>
                    ))
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      There is no one assigned to this card
                    </Typography>
                  )}
                </Section>
              </Box>
              <CardActions>
                <Button
                  color="primary"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Cancel
                </Button>
                <Button
                  color="error"
                  onClick={() => setDeleteOpen(true)}
                  disabled={rfidCard === null}
                >
                  Delete
                </Button>
              </CardActions>
            </Stack>
            <ConfirmationDialog
              action="Delete"
              open={deleteOpen}
              itemId={values.reference}
              onOk={handleDelete}
              onCancel={() => setDeleteOpen(false)}
            />

            <ConfirmationDialog
              action="Remove"
              open={!!removePerson}
              itemId={
                removePerson
                  ? `${removePerson.forenames || ''} ${
                      removePerson.surname || ''
                    }` ||
                    removePerson.collarNumber ||
                    removePerson.code
                  : ''
              }
              onOk={handleRemove}
              onCancel={() => setRemovePerson(null)}
            />
          </form>
        )}
      />
    </Box>
  );
}
