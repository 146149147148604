import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import { ScoreCardView } from './ScoreCardView';
import { SummaryTableView } from './SummaryTableView';
import { measures } from './utils';
import { TopBottomView } from './TopBottomView';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-100.ttf',
      fontWeight: 100,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-200.ttf',
      fontWeight: 200,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-400.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-500.ttf',
      fontWeight: 500,
    },

    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf',
      fontWeight: 800,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-900.ttf',
      fontWeight: 900,
    },
  ],
});

export function FleetUtilisationPdfDocument({
  groupNames,
  durationMonths,
  summary,
  childType,
  childSummary = [],
  topAndBottom,
}) {
  return (
    <Document style={{ fontFamily: 'Open Sans' }}>
      <Page size="A4" style={{ paddingHorizontal: 24, paddingVertical: 32 }}>
        <Text
          fixed
          style={{
            fontSize: 8,
            fontWeight: 'bold',
            color: '#0006',
            position: 'absolute',
            top: 16,
            left: 16,
          }}
        >
          IR3
        </Text>
        <Text
          fixed
          style={{
            fontSize: 8,
            color: '#0006',
            position: 'absolute',
            top: 16,
            right: 16,
            thextAlign: 'right',
          }}
        >
          Lightbulb Analytics
        </Text>
        <Text
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Fleet Utilisation
        </Text>
        <Text style={{ fontSize: 12, textAlign: 'center' }}>
          {groupNames.join(' > ')}
        </Text>
        <Text
          style={{
            fontSize: 12,
            fontWeight: 'light',
            textAlign: 'center',
          }}
        >
          {durationMonths} months to {new Date().toLocaleDateString()}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 8,
            padding: 8,
          }}
        >
          {measures.map(({ key, label, color }) => (
            <ScoreCardView
              key={key}
              title={label}
              value={summary?.[key]}
              color={color}
            />
          ))}
        </View>
        <SummaryTableView title={`By ${childType}`} rows={childSummary} />
        {measures.map(({ key, label }) => (
          <TopBottomView key={key} label={label} data={topAndBottom?.[key]} />
        ))}
        <Text
          style={{
            position: 'absolute',
            bottom: 16,
            left: 0,
            right: 0,
            textAlign: 'center',
            fontSize: 8,
            color: '#0006',
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
