import {
  AutocompleteController,
  DateTimePickerController,
  TextFieldController,
} from '@/components/controls';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useForm } from 'react-hook-form';

export function RetentionForm({
  defaultValues,
  onSubmit,
  onCancel,
  telematicsBoxes,
}) {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
  });
  const isNew = !defaultValues?._id;

  return (
    <form id="user-query-form" onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle id="dialog-title">
        {isNew ? 'Add a data retention period' : 'Update retention record'}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ pt: 0.75 }}>
          <DialogContentText>
            This ensures that all telematics box polls for the chosen IMEI will
            be retained beyond the scheduled data retention period.
          </DialogContentText>
          <AutocompleteController
            name="imei"
            label="IMEI"
            control={control}
            single
            fullWidth
            options={telematicsBoxes.map(({ imei }) => ({
              label: imei,
              value: imei,
            }))}
            disabled={!isNew}
          />
          <DateTimePickerController
            name="startTime"
            label="Start Time"
            control={control}
            defaultValue=""
            rules={{ required: 'Required' }}
            fullWidth
            minDate={new Date('1900-01-01')}
            maxDate={watch('endTime') ?? new Date('2100-01-01')}
            disabled={!isNew}
          />
          <DateTimePickerController
            name="endTime"
            label="End Time"
            control={control}
            defaultValue=""
            rules={{ required: 'Required' }}
            fullWidth
            minDate={watch('startTime') ?? new Date('1900-01-01')}
            maxDate={new Date('2100-01-01')}
            disabled={!isNew}
          />
          <TextFieldController
            name="notes"
            label="Notes"
            control={control}
            defaultValue=""
            fullWidth
            placeholder="Why is this data is being retained?"
            multiline
            minRows={4}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </DialogActions>
    </form>
  );
}
