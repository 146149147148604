import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subDays } from 'date-fns';

export function useSubgroupActivityAverages(
  groupType,
  groupCode,
  subgroupType,
  days,
) {
  return useQuery({
    queryKey: [
      'subgroupActivityAverages',
      groupType,
      groupCode,
      subgroupType,
      days,
    ],
    queryFn: () => {
      const start = subDays(startOfDay(new Date()), days);
      const json = [
        {
          $match: {
            time: { $gte: start },
            'person.groupCodes': groupCode,
          },
        },
        {
          $lookup: {
            from: 'groups',
            localField: 'person.groupCodes',
            foreignField: 'code',
            as: 'group',
            pipeline: [{ $match: { type: subgroupType } }],
          },
        },
        subgroupType !== 'COLLAR_NUMBER' && {
          $unwind: {
            path: '$group',
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $group: {
            _id: {
              $ifNull: [
                subgroupType === 'COLLAR_NUMBER'
                  ? '$person.collarNumber'
                  : '$group.code',
                'UNKNOWN',
              ],
            },
            loggedInSeconds: { $avg: '$loggedIn.all.durationSeconds' },
            baseSeconds: { $avg: '$visiting.all.baseSeconds' },
            respondingSeconds: { $avg: '$responding.all.durationSeconds' },
            attendingSeconds: { $avg: '$attending.all.durationSeconds' },
            drivingSeconds: { $avg: '$driving.all.durationSeconds' },
            homeWardSeconds: { $avg: '$visiting.all.homeWardSeconds' },
            doubleCrewingSeconds: {
              $avg: '$doubleCrewing.all.durationSeconds',
            },
          },
        },
        {
          $project: {
            _id: false,
            identifier: '$_id',
            loggedInHours: { $divide: ['$loggedInSeconds', 3600] },
            baseHours: { $divide: ['$baseSeconds', 3600] },
            respondingHours: { $divide: ['$respondingSeconds', 3600] },
            attendingHours: { $divide: ['$attendingSeconds', 3600] },
            drivingHours: { $divide: ['$drivingSeconds', 3600] },
            homeWardHours: { $divide: ['$homeWardSeconds', 3600] },
            doubleCrewingHours: { $divide: ['$doubleCrewingSeconds', 3600] },
          },
        },
        { $sort: { baseSeconds: 1 } },
      ].filter(Boolean);

      return api.post('pipeline/personDailyActivities', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
