import { encodeParams, getUser, reviveDate } from '@/utils';
import { baseURL } from '@/utils/constants';
import ky from 'ky';
import { ajax } from 'rxjs/ajax';

export const test = ky.create({
  prefixUrl: 'http://localhost:4000',
});

export const police = ky.create({
  prefixUrl: 'https://data.police.uk/api',
});

export function fromAjax(path, { method = 'GET', body, headers, params }) {
  const encodedParams = encodeParams(params);
  return ajax({
    url: `${baseURL}${path}${encodedParams ? `?${encodedParams}` : ''}`,
    method: method,
    body: body ? JSON.stringify(body) : undefined,
    headers,
  });
}

export const api = ky.create({
  prefixUrl: baseURL,
  timeout: false,
  hooks: {
    beforeRequest: [
      (request) => {
        const user = getUser();

        if (user?.access_token) {
          request.headers.set('authorization', `Bearer ${user?.access_token}`);
        }
      },
    ],
  },
  parseJson: (json) => JSON.parse(json, reviveDate),
});
