import { useEffect, useRef } from 'react';

/**
 * Hook that runs the effect function only once, similar to `useEffect` with an empty dependency array.
 * @param {Function} effect - The effect function to be executed.
 */
export function useEffectOnce(effect) {
  const hasRun = useRef(false);
  useEffect(() => {
    if (hasRun.current) {
      return;
    }
    hasRun.current = true;
    return effect();
  });
}
