import { useOptions } from '@/hooks';
import { highlights } from '@/utils/config';
import { useTheme } from '@emotion/react';
import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useMemo } from 'react';
import {
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from 'recharts';
import { VehicleCustomTooltip } from './VehicleCustomTooltip';
import { useExcessiveAccelerometerReadings } from './useExcessiveAccelerometerReadings';

const {
  vehicleExcessiveSummariesChart: {
    ignitionOnPeriodDays: {
      default: ignitionOnPeriodDaysDefault,
      min: ignitionOnPeriodDaysMin,
      max: ignitionOnPeriodDaysMax,
    },
    excessPercentageThreshold,
  },
} = highlights;

const xecessiveAccelerometerReadingsStateAtom = atomWithStorage(
  'xecessiveAccelerometerReadings',
  {
    vehicleType: '',
    activityType: '',
    ignitionOnPeriodDays: ignitionOnPeriodDaysDefault,
  },
);

export function ExcessiveAccelerometerReadingsCard({ groupCode }) {
  const { palette } = useTheme();
  const { data: vehicleTypes } = useOptions('vehicleType');
  const [{ vehicleType, activityType, ignitionOnPeriodDays }, setState] =
    useAtom(xecessiveAccelerometerReadingsStateAtom);
  const { data } = useExcessiveAccelerometerReadings(
    groupCode,
    ignitionOnPeriodDays,
  );

  const filteredData = useMemo(() => {
    return data.filter(
      (entry) =>
        (activityType === '' ||
          entry.percentageInExcess[activityType] >=
            excessPercentageThreshold) &&
        (vehicleType === '' || vehicleType === entry.type),
    );
  }, [data, activityType, vehicleType]);

  function handleVehicleTypeChange(event) {
    setState((prev) => ({ ...prev, vehicleType: event.target.value }));
  }

  function handleActivityTypeChange(event) {
    setState((prev) => ({ ...prev, activityType: event.target.value }));
  }

  function handleIgnitionOnPeriodDaysChange(event) {
    if (
      event.target.value <= ignitionOnPeriodDaysMax &&
      event.target.value >= ignitionOnPeriodDaysMin
    ) {
      setState((prev) => ({
        ...prev,
        ignitionOnPeriodDays: parseInt(event.target.value),
      }));
    }
  }

  return (
    <Card variant="outlined" sx={{ flexGrow: 1 }}>
      <CardContent>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Excessive Accelerometer Readings
        </Typography>
        <Box sx={{ pt: 1, fontSize: 12 }}>
          <ResponsiveContainer width="99%" height={520}>
            <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
              <CartesianGrid />
              <XAxis
                type="number"
                dataKey="durationHours"
                label={{
                  value: 'Ignition On Hours',
                  offset: -6,
                  position: 'insideBottom',
                }}
              />
              <YAxis
                type="number"
                dataKey={
                  activityType
                    ? `percentageInExcess.${activityType}`
                    : 'percentageInExcess.total'
                }
                label={{
                  value: '%',
                  angle: -90,
                  offset: 20,
                  position: 'insideLeft',
                  style: { textAnchor: 'middle' },
                }}
              />
              <Scatter data={filteredData} fill={palette.primary.main} />
              <ChartTooltip
                content={<VehicleCustomTooltip activityType={activityType} />}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </Box>
        <Stack
          direction="row"
          spacing={1}
          sx={{ w: 1, display: 'flex', justifyContent: 'center' }}
        >
          <TextField
            sx={{ width: 240 }}
            size="small"
            select
            label="Vehicle Type"
            value={vehicleType}
            onChange={handleVehicleTypeChange}
          >
            <MenuItem value="">
              <em>Any</em>
            </MenuItem>
            {vehicleTypes.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: 240 }}
            size="small"
            select
            label="Activity Type"
            value={activityType}
            onChange={handleActivityTypeChange}
          >
            <MenuItem value="">
              <em>Any</em>
            </MenuItem>
            <MenuItem key="acceleration" value="acceleration">
              Acceleration
            </MenuItem>
            <MenuItem key="braking" value="braking">
              Braking
            </MenuItem>
            <MenuItem key="cornering" value="cornering">
              Cornering
            </MenuItem>
          </TextField>
          <TextField
            sx={{ width: 240 }}
            size="small"
            type="number"
            label={`Days to review (${ignitionOnPeriodDaysMin} - ${ignitionOnPeriodDaysMax})`}
            value={ignitionOnPeriodDays}
            inputProps={{
              min: ignitionOnPeriodDaysMin,
              max: ignitionOnPeriodDaysMax,
            }}
            onChange={handleIgnitionOnPeriodDaysChange}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
