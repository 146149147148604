import { parseSort, shortHumanizer } from '@/utils';
import {
  baseType,
  isFleet,
  retrospective,
  useDallasKeys,
} from '@/utils/config';
import { format, isValid } from 'date-fns';
import _ from 'lodash';

export const sources = isFleet
  ? {
      area: [
        { label: 'Vehicle Stop Count', value: 'vehicleStopCount' },
        { label: 'Vehicle Idle Count', value: 'vehicleIdleCount' },
        { label: 'Vehicle Visit Count', value: 'vehicleVisitCount' },
        { label: 'Vehicle Time', value: 'vehicleTime' },
        // { label: 'Accelerometer Alert Count', value: 'accelerometerAlertCount' },
      ],
      bubble: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        // { label: 'Accelerometer Alerts', value: 'accelerometerAlerts' },
      ],
      heat: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Polls', value: 'vehiclePolls' },
        // { label: 'Accelerometer Alerts', value: 'accelerometerAlerts' },
      ],
      shape: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Trips', value: 'vehicleTrips' },
        { label: 'Vehicle Speed Infractions', value: 'speedInfractions' },
        { label: 'Vehicle Known Visits', value: 'vehicleVisits' },
        { label: 'Vehicle Custom Visits', value: 'vehicleCustomVisits' },
        { label: 'Accelerometer Events', value: 'accelerometerEvents' },
        { label: 'Locations', value: 'locations' },
      ],
      file: [
        { label: 'GeoJSON', value: 'geojson' },
        { label: 'KML', value: 'kml' },
      ].filter(Boolean),
    }
  : {
      area: [
        { label: 'Vehicle Stop Count', value: 'vehicleStopCount' },
        { label: 'Vehicle Idle Count', value: 'vehicleIdleCount' },
        { label: 'Vehicle Visit Count', value: 'vehicleVisitCount' },
        { label: 'Vehicle Time', value: 'vehicleTime' },
        { label: 'Person Visit Count', value: 'personVisitCount' },
        { label: 'Person Time', value: 'personTime' },
        { label: 'Incident Count', value: 'incidentCount' },
        // { label: 'Accelerometer Alert Count', value: 'accelerometerAlertCount' },
      ].filter(Boolean),
      bubble: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        {
          label: 'Incidents',
          value: 'incidents',
        },
      ].filter(Boolean),
      heat: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Polls', value: 'vehiclePolls' },
        // { label: 'Accelerometer Alerts', value: 'accelerometerAlerts' },
        { label: 'Person Polls', value: 'personPolls' },
        {
          label: 'Incidents',
          value: 'incidents',
        },
      ].filter(Boolean),
      shape: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Trips', value: 'vehicleTrips' },
        { label: 'Vehicle Known Visits', value: 'vehicleVisits' },
        { label: 'Vehicle Custom Visits', value: 'vehicleCustomVisits' },
        { label: 'Vehicle Speed Infractions', value: 'speedInfractions' },
        { label: 'Person Trails', value: 'personTrails' },
        { label: 'Person Known Visits', value: 'personVisits' },
        { label: 'Person Custom Visits', value: 'personCustomVisits' },
        {
          label: 'Incidents',
          value: 'incidents',
        },
        { label: 'Locations', value: 'locations' },
        { label: 'Accelerometer Events', value: 'accelerometerEvents' },
      ].filter(Boolean),
      file: [
        { label: 'GeoJSON', value: 'geojson' },
        { label: 'KML', value: 'kml' },
      ].filter(Boolean),
    };

export function compareLabels(a, b) {
  return a.label.localeCompare(b.label);
}

export function getBoundaries(state) {
  return {
    Location: state.locations.locations.reduce((accumulator, location) => {
      if (!accumulator[location.type]) {
        accumulator[location.type] = [];
      }
      accumulator[location.type].push({
        label: location.name,
        value: location.code,
      });

      return accumulator;
    }, {}),
    Objective: state.objectives.objectives.map((objective) => ({
      label: objective.title,
      value: objective.identifier,
    })),
  };
}

export function getClientFilters(features) {
  const properties = features
    .map(({ properties }) => ({
      ...properties,
      sort: properties.startTime ?? properties.openedTime ?? properties.time,
    }))
    .sort((a, b) => a.sort - b.sort);

  function uniqueValues(entity, key) {
    const values = [
      ...new Set(
        properties
          .map(({ [entity]: { [key]: value } = {}, [key]: altValue }) =>
            value === undefined ? altValue : value,
          )
          .filter(Boolean)
          .sort(),
      ),
    ];

    return (values.length === 0 ? ['none'] : values).map((value) => ({
      label: value,
      value,
    }));
  }

  function uniqueLocationsValues(keyInLocation) {
    const values = [
      ...new Set(
        properties.flatMap((property) =>
          (property.locations ?? [])
            .map((location) => location[keyInLocation])
            .filter(Boolean),
        ),
      ),
    ].sort();

    return (values.length === 0 ? ['none'] : values).map((value) => ({
      label: value,
      value,
    }));
  }

  function fileValues(key) {
    const values = [
      ...new Set(
        properties
          .map(({ fileProperties }) => fileProperties || {})
          .map(({ [key]: value = {} }) => value)
          .filter(Boolean),
      ),
    ].map((item) => ({
      label: item.toString(),
      value: item,
    }));

    return values;
  }

  const file = Object.entries(properties[0].fileProperties || {})
    .map(([key]) => ({
      [key]: {
        label: key,
        type: 'multiselect',
        values: fileValues(key),
      },
    }))
    .reduce((acc, item) => ({ ...acc, ...item }), {});

  // a person can have several aliases so need to try them all
  function uniquePersonValues(property) {
    const values = [
      ...new Set(
        properties
          .map(
            ({
              person: { [property]: value } = {},
              driver: { [property]: otherValue } = {},
              lastDriver: { [property]: anotherValue } = {},
            }) => value || otherValue || anotherValue,
          )
          .filter(Boolean)
          .sort(),
      ),
    ];

    return (values.length === 0 ? ['none'] : values).map((value) => ({
      label: value,
      value,
    }));
  }

  const location = {
    code: {
      label: 'Code',
      type: 'multiselect',
      values: uniqueValues('location', 'code'),
    },
    name: {
      label: 'Name',
      type: 'multiselect',
      values: uniqueValues('location', 'name'),
    },
    type: {
      label: 'Type',
      type: 'multiselect',
      values: uniqueValues('location', 'type'),
    },
    subtype: {
      label: 'Subtype',
      type: 'multiselect',
      values: uniqueValues('location', 'subtype'),
    },
  };

  // visitLocations have fewer types but as this is on the client side,
  // the filtering has already been done so these are the same
  const visitLocation = location;

  const person = {
    // code: {
    //   label: 'Payroll Number',
    //   values: state.people.people.map(({ code }) => ({
    //     label: code,
    //     value: code
    //   }))
    // },
    collarNumber: {
      label: 'Collar Number',
      type: 'multiselect',
      values: uniquePersonValues('collarNumber'),
    },
    radioSsi: {
      label: 'Radio SSI',
      type: 'multiselect',
      values: uniquePersonValues('radioSsi'),
    },
    'rank.code': {
      label: 'Rank',
      type: 'multiselect',
      values: _.uniqBy(
        properties.map(({ person, driver, lastDriver }) => ({
          label: (person || driver || lastDriver)?.rank?.name,
          value: (person || driver || lastDriver)?.rank?.code,
        })),
        'value',
      ).filter((v) => v.value),
    },
    role: {
      label: 'Role',
      type: 'multiselect',
      values: uniquePersonValues('role'),
    },
    homeStation: {
      label: `Home ${baseType.label}`,
      type: 'multiselect',
      values: uniquePersonValues('homeStation'),
    },
  };
  const clientPerson = _.omit(person, 'radioSsi');
  const driver = {
    ...clientPerson,
    identificationReference: {
      label: useDallasKeys ? 'Dallas Key' : 'RFID Card',
      type: 'multiselect',
      values: uniquePersonValues('identificationReference'),
    },
  };
  const lastDriver = driver;

  return {
    vehicle: {
      identificationNumber: {
        label: 'VIN',
        type: 'multiselect',
        values: uniqueValues('vehicle', 'identificationNumber'),
      },
      registrationNumber: {
        label: 'Registration',
        type: 'multiselect',
        values: uniqueValues('vehicle', 'registrationNumber'),
      },
      fleetNumber: {
        label: 'Fleet Number',
        type: 'multiselect',
        values: uniqueValues('vehicle', 'fleetNumber'),
      },
      role: {
        label: 'Role',
        type: 'multiselect',
        values: uniqueValues('vehicle', 'role'),
      },
      type: {
        label: 'Type',
        type: 'multiselect',
        values: uniqueValues('vehicle', 'type'),
      },
      homeStation: {
        label: `Home ${baseType.label}`,
        type: 'multiselect',
        values: uniqueValues('vehicle', 'homeStation'),
      },
    },
    person,
    clientPerson,
    driver,
    lastDriver,
    location,
    visitLocation,
    incident: {
      openedTime: {
        label: 'Opened Time',
        type: 'date',
      },
      'locations.type': {
        label: 'Location Type',
        type: 'multiselect',
        values: uniqueLocationsValues('type'),
      },
      'locations.name': {
        label: 'Location',
        type: 'multiselect',
        values: uniqueLocationsValues('name'),
      },
      date: {
        label: 'Date',
        type: 'date',
        onlyEqual: true,
        parse: (x) => {
          const date = new Date(x);
          if (isValid(date)) {
            return format(date, 'yyyy-MM-dd');
          }
          return '';
        },
      },
      reference: {
        label: 'Incident Number',
        value: 'reference',
        type: 'number',
        onlyEqual: true,
        // parse: (x) => x?.toString() || '',
      },
      'type.code': {
        label: 'Type',
        type: 'multiselect',
        values: uniqueValues('type', 'code'),
      },
      'responseCategory.code': {
        label: 'Response Category',
        type: 'multiselect',
        values: uniqueValues('responseCategory', 'code'),
      },
      responseSeconds: {
        label: 'Response Duration',
        type: 'duration',
      },
      status: {
        label: 'Status',
        type: 'multiselect',
        values: uniqueValues('incident', 'status'),
      },
      'ward.name': {
        label: 'Ward',
        type: 'multiselect',
        values: uniqueValues('incident', 'ward.name'),
      },
      'ward.type': {
        label: 'Ward Type',
        type: 'select',
        values: uniqueValues('incident', 'ward.type'),
      },
      // 'closingCodes@code': {
      //   label: 'Closing Code',
      //   type: 'autocomplete',
      //   values: [
      //     ...new Set(
      //       features.map(
      //         ({
      //           properties: {
      //             closingCodes: { code: value }
      //           }
      //         }) => value
      //       )
      //     )
      //   ].map(value => ({
      //     label: value,
      //     value
      //   }))
      // }
    },
    trip: {
      startTime: {
        label: 'Start Time',
        type: 'date',
      },
      endTime: {
        label: 'End Time',
        type: 'date',
      },
      durationSeconds: {
        label: 'Duration',
        type: 'duration',
      },
      maxSpeedKilometresPerHour: {
        label: 'Max Speed',
        type: 'miles',
        unit: 'mph',
      },
      // 'startLocations@name': {
      //   label: 'Start Location Name',
      //   type: 'autocomplete',
      //   values: state.locations.locations
      //     .filter(location => location.name)
      //     .map(({ name }) => ({
      //       label: name,
      //       value: name
      //     }))
      // },
      // 'startLocations@type': {
      //   label: 'Start Location Type',
      //   type: 'select',
      //   values: Object.values(locationTypes).sort(compareLabels)
      // },
      // 'endLocations@name': {
      //   label: 'End Location Name',
      //   type: 'autocomplete',
      //   values: state.locations.locations
      //     .filter(location => location.name)
      //     .map(({ name }) => ({
      //       label: name,
      //       value: name
      //     }))
      // },
      // 'endLocations@type': {
      //   label: 'End Location Type',
      //   type: 'select',
      //   values: Object.values(locationTypes).sort(compareLabels)
      // }
    },
    personTrail: {
      startTime: {
        label: 'Start Time',
        type: 'date',
      },
      endTime: {
        label: 'End Time',
        type: 'date',
      },
    },
    personPoll: {
      time: {
        label: 'Time',
        type: 'date',
      },
    },
    stop: {
      startTime: {
        label: 'Start Time',
        type: 'date',
      },
      endTime: {
        label: 'End Time',
        type: 'date',
      },
      durationSeconds: {
        label: 'Duration',
        type: 'duration',
      },
      // 'locations@name': {
      //   label: 'Location Name',
      //   type: 'autocomplete',
      //   values: state.locations.locations
      //     .filter(location => location.name)
      //     .map(({ name }) => ({
      //       label: name,
      //       value: name
      //     }))
      // },
      // 'locations@type': {
      //   label: 'Location Type',
      //   type: 'select',
      //   values: Object.values(locationTypes).sort(compareLabels)
      // }
    },
    file,
    aggregate: {
      count: {
        label: 'Count',
        type: 'number',
      },
    },
  };
}

export const searchFilter =
  (searchText) =>
  ({ properties: { vehicle, person, driver, location, ...event } }) => {
    if (!searchText) {
      return true;
    } else {
      const isMatch = Object.values({
        ...vehicle,
        ...person,
        ...driver,
        ...location,
        ...event,
      }).some((value) =>
        value
          ?.toString()
          ?.toLowerCase()
          ?.includes((searchText || '').toLowerCase()),
      );

      return isMatch;
    }
  };

export function match(value, condition, filterValue) {
  switch (condition) {
    case '$eq':
      return value === filterValue;
    case '$ne':
      return value !== filterValue;
    case '$gt':
      return value > filterValue;
    case '$lt':
      return value < filterValue;
    case '$gte':
      return value >= filterValue;
    case '$lte':
      return value <= filterValue;
    case '$in':
      return filterValue.length === 0 || filterValue.includes?.(value) || false;
    default:
      return false;
  }
}

export const fieldFilter = (filters) => (feature) => {
  for (let filter of filters) {
    const fieldName = Object.keys(filter)[0];
    const value = fieldName.split('.').reduce((o, i) => {
      if (Array.isArray(o)) {
        return o.map((elementInArray) => elementInArray[i]);
      } else {
        return o?.[i];
      }
    }, feature.properties.fileProperties || feature.properties);
    const [condition, filterValue] = Object.entries(filter[fieldName])[0];
    let isMatch;
    if (Array.isArray(value)) {
      isMatch = value.some((e) => match(e, condition, filterValue));
    } else {
      isMatch = match(value, condition, filterValue);
    }
    if (!isMatch) {
      return false;
    }
  }

  return true;
};

export function mongoizeFilters(filters = {}) {
  const units = {
    s: 1,
    m: 60,
    h: 3600,
    d: 86400,
  };

  let mongoizedFilters = [];
  Object.keys(filters).forEach((group) => {
    filters[group]
      .filter(({ value }) =>
        Array.isArray(value)
          ? value.length > 0
          : value !== undefined && value !== '',
      )
      .forEach(({ field, condition, value, unit }) => {
        if (Array.isArray(value)) {
          condition = '$in';
        }

        mongoizedFilters.push({
          [group === 'event' || group === 'file' ? field : `${group}.${field}`]:
            {
              [condition]: unit ? value * units[unit] : value,
            },
        });
      });
  });

  return mongoizedFilters;
}

// Temporary functions
export function getPrimaryText(feature) {
  const props = feature.properties;

  switch (props.source) {
    case 'speedInfractions':
    case 'vehicleTrips':
    case 'vehicleStops':
    case 'vehicleCustomVisits':
    case 'vehicleVisits':
    case 'vehicleIdles':
      return (
        (props.vehicle || props)?.registrationNumber ||
        (props.vehicle || props)?.fleetNumber ||
        (props.vehicle || props)?.telematicsBoxImei
      );
    case 'accelerometerEvents':
    case 'accelerometerAlerts':
    case 'vehiclePolls':
      return (
        (props.vehicle || props)?.registrationNumber ||
        (props.vehicle || props)?.fleetNumber ||
        props?.imei
      );
    case 'incidents':
      return `Incident ${props.reference}, ${props.date
        .split('-')
        .reverse()
        .join('/')}`;
    case 'personPolls':
    case 'personTrails':
    case 'personCustomVisits':
    case 'personVisits':
      return (
        (props.person ?? props)?.collarNumber ||
        (props.person ?? props)?.code ||
        (props.person ?? props)?.personCode ||
        props.person?.radioSsi ||
        props.ssi
      );
    case 'clusters':
      return props.id;
    case 'areas':
    case 'groups':
    case 'locations':
      return props.name;
    default:
      return '';
  }
}

export function getSecondaryText(feature) {
  const props = feature.properties;

  switch (feature.properties.source) {
    case 'speedInfractions':
    case 'vehicleTrips':
    case 'vehicleStops':
    case 'vehicleCustomVisits':
    case 'vehicleVisits':
    case 'vehicleIdles':
    case 'personTrails':
    case 'personCustomVisits':
    case 'personVisits':
    case 'accelerometerEvents':
      return `${format(
        new Date(props.startTime),
        'dd/MM/yyyy HH:mm',
      )} - ${format(new Date(props.endTime), 'dd/MM/yyyy HH:mm')}`;
    case 'accelerometerAlerts':
    case 'vehiclePolls':
    case 'personPolls':
      return format(
        new Date(props.time ?? props.startTime),
        'dd/MM/yyyy HH:mm',
      );
    case 'incidents':
      return `${props.type?.code ?? ''} ${props.type?.name ?? ''}`;
    case 'areas':
    case 'groups':
      if (props.measure !== undefined && props.measure.includes('Time')) {
        return shortHumanizer(props.count);
      } else {
        return `${props.count} ${_.lowerCase(
          _.replace(props.measure, 'Count', ''),
        )}s`;
      }
    case 'locations':
      return props.code;
    case 'clusters':
      return '';
    default:
      return '';
  }
}

export function tooManyMapItems(layer) {
  const mapItems = (layer.featureCollection?.features || []).reduce(
    (total, feature) =>
      total +
      (feature.geometry?.coordinates || []).reduce(
        (subtotal, coordinateArray) =>
          subtotal +
          (Array.isArray(coordinateArray) ? coordinateArray.length : 0),
        0,
      ),
    0,
  );
  const virtualizeAt = retrospective?.virtualizeAt?.mapFeaturesSize || 20000;

  return mapItems > virtualizeAt;
}

export function orderAndFilterFeatures(layer) {
  const filters = mongoizeFilters(layer?.clientFilters || {});
  const { searchText, sort } = layer;
  const orderBy = parseSort(sort);

  return _.orderBy(
    (layer?.featureCollection?.features || [])
      .filter(searchFilter(searchText))
      .filter(fieldFilter(filters)),
    orderBy.fields,
    orderBy.directions,
  );
}

const limits = retrospective?.limits;
export function getLimits(layer) {
  const defaultLimit = limits?.default;
  const typeDefault = limits?.[layer.type]?.default;
  const typeAndSourceLimit = limits?.[layer.type]?.[layer.source];

  // if these are all undefined limit will be {} not undefined
  return _.merge(defaultLimit, typeDefault, typeAndSourceLimit);
}

export function exceedsLimits(layer) {
  const limit = getLimits(layer);

  // if limit has no keys there's no limit; reach for the sky
  if (Object.keys(limit).length === 0) {
    return false;
  }

  let brokenLimits = {};
  Object.keys(limit).forEach((key) => {
    if (limit[key] < layer.estimate?.[key]) {
      brokenLimits[key] = {
        limit: limit[key],
        estimate: layer.estimate?.[key],
      };
    }
  });

  // if nothing is broken return false, else return the broken limits
  return Object.keys(brokenLimits).length > 0 && brokenLimits;
}

const totalLimitKey = 'mapFeaturesSize'; // we only care about this for the total
export function totalExceedsLimits(layers) {
  const allEstimates = layers.map((layer) => layer.estimate);
  const totalEstimate = allEstimates.reduce(
    (total, estimate) =>
      _.mergeWith(
        total,
        estimate,
        (totalValue = 0, estimateValue = 0) => totalValue + estimateValue,
      ),
    {},
  );

  const totalLimitsExceeded = exceedsLimits({ estimate: totalEstimate });
  if (totalLimitsExceeded?.[totalLimitKey]) {
    return {
      limitsExceeded: _.pick(totalLimitsExceeded, totalLimitKey),
      totalEstimate,
    };
  }

  return false;
}

export const defaultLayerValues = {
  blur: 10,
  radius: 10,
  precision: 3,
  distance: 7,
};
