import { EventListItem } from './EventListItem';
import { IncidentListItem } from './IncidentListItem';
import { LocationListItem } from './LocationListItem';
import { ObjectiveListItem } from './ObjectiveListItem';
import { PersonListItem } from './PersonListItem';
import { VehicleListItem } from './VehicleListItem';

export function ListItem({ tab, ...props }) {
  switch (tab) {
    case 'vehicles':
      return <VehicleListItem {...props} />;
    case 'people':
      return <PersonListItem {...props} />;
    case 'locations':
      return <LocationListItem {...props} />;
    case 'incidents':
      return <IncidentListItem {...props} />;
    case 'objectives':
      return <ObjectiveListItem {...props} />;
    case 'events':
      return <EventListItem {...props} />;
    default:
      return null;
  }
}
