import { easeInOutQuint } from '@/utils/constants';
import { Settings as SettingsIcon } from '@mui/icons-material';
import {
  Badge,
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { elementScroll, useVirtualizer } from '@tanstack/react-virtual';
import { centerOfMass } from '@turf/turf';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { replayActionsAtom, sortOptions, types } from '.';
import { SearchBox, SortField } from '../controls';
import { Filter } from './Filter';
import { ListItem } from './ListItem';

export function Sidebar({
  featureCollections,
  hoveredFeature,
  hiddenTabs,
  onTabClick,
  onHover,
  search,
  onSearchChange,
  sort,
  onSortChange,
  filter,
  onFilterChange,
}) {
  const parentRef = useRef();
  const [tab, setTab] = useState('vehicles');
  const [showFilters, setShowFilters] = useState(false);
  const scrollingRef = useRef();
  const [{ panTo }, setActions] = useAtom(replayActionsAtom);
  const scrollToFn = useCallback((offset, canSmooth, instance) => {
    const duration = 1000;
    const start = parentRef.current.scrollTop;
    const startTime = (scrollingRef.current = Date.now());

    function run() {
      if (scrollingRef.current !== startTime) return;
      const now = Date.now();
      const elapsed = now - startTime;
      const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
      const interpolated = start + (offset - start) * progress;

      if (elapsed < duration) {
        elementScroll(interpolated, canSmooth, instance);
        requestAnimationFrame(run);
      } else {
        elementScroll(interpolated, canSmooth, instance);
      }
    }

    requestAnimationFrame(run);
  }, []);
  const rowVirtualizer = useVirtualizer({
    count: featureCollections[tab].features.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 10,
    scrollToFn,
  });

  function handleTabChange(event, newValue) {
    setTab(newValue);
  }

  function handleSearchChange(event) {
    onSearchChange((search) => ({ ...search, [tab]: event.target.value }));
  }

  function handleFiltersToggle() {
    setShowFilters(!showFilters);
  }

  function handleFilterChange(value) {
    onFilterChange((filter) => ({ ...filter, [tab]: value }));
  }

  const handleSortByChange = (tab) => (event) => {
    onSortChange((sort) => ({
      ...sort,
      [tab]: {
        ...sort[tab],
        sortBy: event.target.value,
      },
    }));
  };

  const handleSortToggle = (tab) => () => {
    onSortChange((sort) => ({
      ...sort,
      [tab]: {
        ...sort[tab],
        sortDesc: !sort[tab].sortDesc,
      },
    }));
  };

  const handleTabClick = (value) => (event) => {
    if (value === tab) {
      onTabClick(event, value);
    }
  };

  const handleMouseOver = (feature, tab) => () => {
    if (feature) {
      const centre = centerOfMass(feature);

      onHover({
        longitude: centre.geometry.coordinates[0],
        latitude: centre.geometry.coordinates[1],
        feature: {
          ...feature,
          source: tab,
        },
      });
    } else {
      onHover(null);
    }
  };

  const handleClick = (feature) => () => {
    if (feature) {
      panTo(feature);
    }
  };

  const tabAndScrollToFn = useCallback(
    (clickedFeature) => {
      if (clickedFeature) {
        const index = featureCollections[
          clickedFeature.source
        ].features.findIndex(
          (feature) =>
            feature.properties[types[clickedFeature.source].idField] ===
            clickedFeature.properties[types[clickedFeature.source].idField],
        );

        setTab(clickedFeature.source);

        if (index !== -1) {
          setTimeout(() => {
            rowVirtualizer.scrollToIndex(index);
          }, 0);
        }
      }
    },
    [featureCollections, rowVirtualizer],
  );
  useEffect(() => {
    setActions((actions) => ({ ...actions, scrollTo: tabAndScrollToFn }));
  }, [tabAndScrollToFn, setActions]);

  return (
    <Stack height={1}>
      <Box>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {Array.from(Object.entries(types), ([key, { label, Icon }]) => (
            <Tab
              sx={{ minWidth: 0 }}
              icon={
                <Tooltip title={label} placement="top">
                  <Badge
                    color="secondary"
                    variant="dot"
                    invisible={
                      featureCollections[key].features.length ===
                      featureCollections[key].total
                    }
                  >
                    <Icon
                      color={hiddenTabs.includes(key) ? 'disabled' : 'action'}
                    />
                  </Badge>
                </Tooltip>
              }
              key={key}
              value={key}
              onClick={handleTabClick(key)}
            />
          ))}
        </Tabs>
        <Stack direction="row" sx={{ p: 1 }} spacing={1} alignItems="center">
          <SearchBox
            value={search[tab]}
            onChange={handleSearchChange}
            count={`${featureCollections[tab].features.length}/${featureCollections[tab].total}`}
            sx={{ flexGrow: 1 }}
          />
          <Tooltip title={showFilters ? 'Hide settings' : 'Show settings'}>
            <IconButton size="small" onClick={handleFiltersToggle}>
              <SettingsIcon
                fontSize="inherit"
                color={showFilters ? 'primary' : 'inherit'}
              />
            </IconButton>
          </Tooltip>
        </Stack>
        <Collapse in={showFilters} unmountOnExit>
          <Stack sx={{ p: 1 }} spacing={1}>
            <SortField
              sortBy={sort[tab].sortBy}
              onSortByChange={handleSortByChange(tab)}
              sortDesc={sort[tab].sortDesc}
              onSortDescToggle={handleSortToggle(tab)}
              options={sortOptions[tab]}
            />
            <Divider>
              <Typography variant="caption" color="textSecondary">
                Filters
              </Typography>
            </Divider>
            <Filter
              tab={tab}
              value={filter[tab]}
              onChange={handleFilterChange}
              featureCollection={featureCollections[tab]}
            />
          </Stack>
        </Collapse>
        <Divider />
      </Box>
      <Box ref={parentRef} sx={{ height: 1, overflow: 'auto' }}>
        <Box
          sx={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((item) => {
            const feature = featureCollections[tab].features[item.index];

            return (
              <ListItem
                key={item.index}
                tab={tab}
                item={item}
                feature={feature}
                selected={
                  hoveredFeature?.properties[
                    types[hoveredFeature?.source].idField
                  ] === feature.properties[types[tab].idField]
                }
                onMouseEnter={handleMouseOver(feature, tab)}
                onMouseLeave={handleMouseOver()}
                onClick={handleClick(feature)}
              />
            );
          })}
        </Box>
      </Box>
    </Stack>
  );
}
