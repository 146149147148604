import { useDocumentTitle } from '@/hooks';
import { useDallasKeys } from '@/utils/config';
import { Stack } from '@mui/material';
import { OptionsTable } from './OptionsTable';

export function RfidCardOptions() {
  useDocumentTitle(
    useDallasKeys ? 'IR3 | Dallas Key Options' : 'IR3 | RFID Card Options',
  );

  return (
    <Stack direction="row" spacing={1} sx={{ p: 1 }}>
      <OptionsTable name="rfidCardType" title="Types" />
    </Stack>
  );
}
