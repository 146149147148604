import { RetrospectiveTypeIcon } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { startCase } from '@/utils';
import {
  Flag as FlagIcon,
  OutlinedFlag as OutlinedFlagIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import humanizeDuration from 'humanize-duration';
import { Fragment } from 'react';

export function VehicleIdle({
  color,
  background,
  driver,
  vehicle,
  startTime,
  endTime,
}) {
  const { title, subheader } = vehicle?.unassociated
    ? {
        title: 'Unassociated IMEI',
        subheader: vehicle?.telematicsBoxImei,
      }
    : {
        title: vehicle?.registrationNumber,
        subheader: vehicle?.fleetNumber,
      };
  useDocumentTitle(['IR3', 'Idle', title].filter(Boolean).join(' | '));

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Tooltip title="Vehicle Idle">
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="vehicleIdles" />
            </Avatar>
          </Tooltip>
        }
        title={title}
        subheader={subheader}
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Vehicle
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Role
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{vehicle.role}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Home Station
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{vehicle.homeStation}</Typography>
              </TableCell>
            </TableRow>
            {Object.entries(vehicle.groups ?? {}).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {startCase(key)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {(value ?? []).join(', ')}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {(driver || {}).identificationReference && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Driver
            </Typography>
            <ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${driver.forenames || ''}  ${driver.surname || ''}`}
                secondary={driver.collarNumber}
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical">
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <FlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              Start
            </StepLabel>
            <StepContent />
          </Step>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <OutlinedFlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              End
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {humanizeDuration(new Date(endTime) - new Date(startTime))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
