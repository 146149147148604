import { useDocumentTitle, useFilteredSnapshot, useInterval } from '@/hooks';
import { Box } from '@mui/material';
import { addSeconds, startOfSecond } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sortOptions, types } from '.';
import { Map } from './Map';
import { Popup } from './Popup';
import { ReplayButton } from './ReplayButton';
import { Sidebar } from './Sidebar';
import { Source } from './Source';

const speedOptions = [
  { step: 5, label: '5s' },
  { step: 60, label: '1m' },
  { step: 600, label: '10m' },
  { step: 3600, label: '1h' },
];

export function Replay() {
  useDocumentTitle('IR3 | Replay');
  const [searchParams, setSearchParams] = useSearchParams();
  const time = useMemo(
    () => new Date(searchParams.get('time') ?? startOfSecond(Date.now())),
    [searchParams],
  );
  const [search, setSearch] = useState(
    Object.fromEntries(Array.from(Object.keys(types), (key) => [key, ''])),
  );
  const [sort, setSort] = useState(
    Object.fromEntries(
      Array.from(Object.keys(types), (key) => [
        key,
        { sortBy: sortOptions[key][0].value, sortDesc: false },
      ]),
    ),
  );
  const [filter, setFilter] = useState(
    Object.fromEntries(Array.from(Object.keys(types), (key) => [key, {}])),
  );
  const { data: snapshot, isFetching } = useFilteredSnapshot(
    time,
    search,
    sort,
    filter,
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [mapHoverInfo, setMapHoverInfo] = useState(null);
  const [listHoverInfo, setListHoverInfo] = useState(null);
  const [speed, setSpeed] = useState(0);
  const [hiddenSources, setHiddenSources] = useState([]);
  const [last, setLast] = useState(startOfSecond(Date.now()));

  useInterval(
    () => {
      const now = startOfSecond(Date.now());
      setLast(now);
      if (time >= now) {
        setIsPlaying(false);
        setSearchParams({ time: now.toISOString() }, { replace: true });
      } else {
        setSearchParams(
          {
            time: addSeconds(time, speedOptions[speed].step).toISOString(),
          },
          { replace: true },
        );
      }
    },
    isPlaying && !isFetching
      ? Math.max(0, 5000 - (startOfSecond(Date.now()) - last))
      : null,
  );

  const handleHover = useCallback((event) => {
    const hoveredFeature = event.features && event.features[0];

    setMapHoverInfo({
      feature: hoveredFeature,
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  }, []);

  function handlePlayPauseClick() {
    setIsPlaying((isPlaying) => !isPlaying);
  }

  function handleSpeedClick() {
    setSpeed((speed) => (speed === speedOptions.length - 1 ? 0 : speed + 1));
  }

  function handleTimeAccept(value) {
    setSearchParams({ time: value.toISOString() }, { replace: true });
  }

  function handleTabClick(event, value) {
    setHiddenSources((hiddenSources) =>
      hiddenSources.includes(value)
        ? hiddenSources.filter((source) => source !== value)
        : [...hiddenSources, value],
    );
  }

  function handleListItemHover(hoverInfo) {
    setListHoverInfo(hoverInfo);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          width: 320,
          height: 'calc(100vh - 48px)',
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
        }}
      >
        <Sidebar
          featureCollections={snapshot}
          hoveredFeature={mapHoverInfo?.feature}
          hiddenTabs={hiddenSources}
          onTabClick={handleTabClick}
          onHover={handleListItemHover}
          search={search}
          onSearchChange={setSearch}
          sort={sort}
          onSortChange={setSort}
          filter={filter}
          onFilterChange={setFilter}
        />
      </Box>
      <Map
        sx={{ flexGrow: 1, height: 'calc(100vh - 48px)' }}
        interactiveLayerIds={Object.keys(snapshot).map(
          (key) => `${key}-symbol-layer`,
        )}
        onHover={handleHover}
      >
        <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
          <ReplayButton
            time={time}
            onTimeChange={handleTimeAccept}
            isPlaying={isPlaying}
            togglePlaying={handlePlayPauseClick}
            speed={speedOptions[speed].label}
            onSpeedClick={handleSpeedClick}
          />
        </Box>
        {Object.entries(snapshot)
          .sort(([id1], [id2]) => types[id1].zIndex - types[id2].zIndex)
          .map(([id, data]) => (
            <Source
              key={id}
              id={id}
              data={data}
              visibility={hiddenSources.includes(id) ? 'none' : 'visible'}
            />
          ))}
        {mapHoverInfo?.feature && <Popup hoverInfo={mapHoverInfo} />}
        {listHoverInfo && <Popup hoverInfo={listHoverInfo} />}
      </Map>
    </Box>
  );
}
