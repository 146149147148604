import { stripKey } from '@/utils';
import {
  AddCircle as AddIcon,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';

export function GroupsPicker({ value, onChange, groups, disabled }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCodesChange = (type) => (event, selections) => {
    onChange({
      ...value,
      [type]: selections,
    });
  };

  const handleDeleteClick = (type) => () => {
    const { [type]: _, ...newValue } = value;
    onChange({
      ...newValue,
    });
  };

  const handleAddMenuClicked = (type) => () => {
    onChange({
      ...value,
      [type]: [],
    });
    setAnchorEl(null);
  };

  function handleAddClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleAddMenuClose() {
    setAnchorEl(null);
  }

  function getStyle(type, option) {
    return type in groups &&
      groups[type].values.map((v) => v.value).includes(option)
      ? undefined
      : { bgcolor: 'error.light' };
  }

  const getOptionLabel = (type) => (option) =>
    (groups[type]?.values ?? []).find((val) => val.value === option)?.label ||
    option;

  const renderTags = (type) => (value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        key={option}
        size="small"
        sx={getStyle(type, option)}
        label={getOptionLabel(type)(option)}
        {...stripKey(getTagProps({ index }))}
      />
    ));

  return (
    <Stack sx={{ width: 1 }} spacing={1}>
      {Object.entries(value).map(([type, identifiers]) => (
        <Stack direction="row" spacing={1} key={type}>
          <Autocomplete
            fullWidth
            size="small"
            multiple
            options={[
              ...new Map(
                [
                  ...value[type].map((value) => ({
                    label: getOptionLabel(type)(value),
                    value,
                  })),
                  ...(groups[type]?.values ?? []),
                ].map((option) => [option.value, option]),
              ).values(),
            ]
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((option) => option.value)}
            getOptionLabel={getOptionLabel(type)}
            renderTags={renderTags(type)}
            value={identifiers}
            onChange={handleCodesChange(type)}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                label={groups[type]?.label ?? type}
                disabled={disabled}
              />
            )}
          />
          {!disabled && (
            <Tooltip title="Remove" placement="left">
              <IconButton onClick={handleDeleteClick(type)} size="small">
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      ))}
      {(Object.entries(groups) || []).filter(
        ([type]) => !(type in (value || {})),
      ).length > 0 && (
        <Stack direction="row" spacing={1}>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Add" placement="left">
            <Box component="span">
              <IconButton
                onClick={handleAddClick}
                size="small"
                disabled={disabled}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Menu
            id="add-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleAddMenuClose}
          >
            {(Object.entries(groups) || [])
              .filter(([type]) => !(type in (value || {})))
              .sort((a, b) => a[1].label.localeCompare(b[1].label))
              .map(([type, group]) => (
                <MenuItem
                  key={type}
                  onClick={handleAddMenuClicked(type)}
                  value={type}
                >
                  {group.label}
                </MenuItem>
              ))}
          </Menu>
        </Stack>
      )}
    </Stack>
  );
}
