import {
  useDeleteRetention,
  useDocumentTitle,
  useRetention,
  useRetentions,
  useTelematicsBoxes,
  useUpsertRetention,
} from '@/hooks';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Avatar, IconButton, Stack, Tooltip } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useState } from 'react';
import { RetentionEditorDialog } from './RetentionEditorDialog';
import { format } from 'date-fns';
import { ConfirmationDialog } from '@/components/dialogs';

const columns = [
  {
    header: 'IMEI',
    accessorKey: 'imei',
  },
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Current Vehicle',
    id: 'vehicle',
    columns: [
      {
        header: 'VIN',
        id: 'vehicle.identificationNumber',
        accessorFn: ({ vehicle: { identificationNumber } = {} } = {}) =>
          identificationNumber ?? '',
      },
      {
        header: 'Registration',
        id: 'vehicle.registrationNumber',
        accessorFn: ({ vehicle: { registrationNumber } = {} } = {}) =>
          registrationNumber ?? '',
      },
      {
        header: 'Fleet Number',
        id: 'vehicle.fleetNumber',
        accessorFn: ({ vehicle: { fleetNumber } = {} } = {}) =>
          fleetNumber ?? '',
      },
      {
        header: 'Role',
        id: 'vehicle.role',
        accessorFn: ({ vehicle: { role } = {} } = {}) => role ?? '',
      },
      {
        header: 'Division',
        id: 'vehicle.groups.division',
        accessorFn: ({
          vehicle: { groups: { division = [] } = {} } = {},
        } = {}) => division.join(', ') ?? '',
      },
      {
        header: 'Type',
        id: 'vehicle.type',
        accessorFn: ({ vehicle: { type } = {} } = {}) => type ?? '',
      },
    ],
  },
];

export function DataRetention() {
  useDocumentTitle('IR3 | Data Retention');
  const { data, isFetching } = useRetentions();
  const [editIdentifier, setEditIdentifier] = useState(null);
  const [deleteIdentifier, setDeleteIdentifier] = useState(null);
  const { data: retention } = useRetention(editIdentifier);
  const { mutate: upsertRetention } = useUpsertRetention();
  const { mutate: deleteRetention } = useDeleteRetention();
  const { data: telematicsBoxes } = useTelematicsBoxes();

  function onEditorClose(values) {
    if (values) {
      upsertRetention(values, {
        onSuccess: () => {
          setEditIdentifier(null);
        },
      });
    } else {
      setEditIdentifier(null);
    }
  }

  function handleAddClick() {
    setEditIdentifier('new');
  }

  const handleDeleteClick = (identifier) => () => {
    setDeleteIdentifier(identifier);
  };

  function handleDeleteOk() {
    deleteRetention(deleteIdentifier, {
      onSuccess: () => {
        setDeleteIdentifier(null);
      },
    });
  }

  function handleDeleteCancel() {
    setDeleteIdentifier(null);
  }

  const handleRowClick = (code) => () => {
    setEditIdentifier(code);
  };

  function renderTopToolbarCustomActions() {
    return (
      <Stack
        spacing={0.5}
        direction="row"
        sx={{ py: '3px', alignItems: 'center' }}
      >
        <Tooltip title="Add a retention period" placement="left">
          <IconButton size="small" onClick={handleAddClick}>
            <Avatar
              sx={{
                color: 'secondary.contrastText',
                bgcolor: 'secondary.main',
                width: 24,
                height: 24,
                fontSize: 16,
                m: 0.25,
              }}
            >
              <AddIcon fontSize="inherit" />
            </Avatar>
          </IconButton>
        </Tooltip>
        <RetentionEditorDialog
          key={editIdentifier}
          value={retention}
          open={Boolean(editIdentifier)}
          onClose={onEditorClose}
          telematicsBoxes={telematicsBoxes}
        />
        <ConfirmationDialog
          title="Delete retention period"
          text="Are you sure?"
          okButtonText="yes"
          cancelButtonText="no"
          open={Boolean(deleteIdentifier)}
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />
      </Stack>
    );
  }

  function renderRowActions({ row }) {
    return (
      <Tooltip title="Delete" placement="left">
        <IconButton
          onClick={handleDeleteClick(row.original?.identifier)}
          color="error"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  }

  const table = useMaterialReactTable({
    data,
    columns,
    state: {
      density: 'compact',
      isLoading: isFetching,
    },
    defaultColumn: { size: 0 },
    muiTablePaperProps: { elevation: 0 },
    muiTableContainerProps: {
      sx: {
        height: 'calc(100vh - 104px)', // 48 app header + 56 top bar + 56 bottom bar
      },
    },
    enableStickyHeader: true,
    enableBottomToolbar: false,
    enablePagination: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderTopToolbarCustomActions,
    renderRowActions,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: handleRowClick(row.original?.identifier),
      sx: {
        cursor: 'pointer',
        '&:last-child td, &:last-child th': { border: 0 },
      },
    }),
  });

  return <MaterialReactTable table={table} />;
}
