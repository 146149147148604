import { Avatar, IconButton } from '@mui/material';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';

export function LoggedOutAvatar({ auth }) {
  function handleClick() {
    auth.signinRedirect();
  }

  return (
    <IconButton sx={{ p: 0 }} onClick={handleClick} size="large">
      <Avatar
        sx={{
          width: 36,
          height: 36,
          fontSize: 16,
          bgcolor: 'primary.dark',
        }}
      >
        <AccountCircleIcon />
      </Avatar>
    </IconButton>
  );
}
