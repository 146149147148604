import {
  Close as CloseIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  Stop as StopIcon,
} from '@mui/icons-material';
import { green, grey, orange, red } from '@mui/material/colors';
import { atom } from 'jotai';

export const stateAtom = atom({
  roles: [],
  durationMonths: 3,
  showFilter: false,
});

export const durationMonthsOptions = [3, 6, 9, 12];

export function formatValue(value) {
  return `${Math.round(value)}%`;
}

export const measures = [
  {
    label: 'Used',
    key: 'usedPercentage',
    color: green[500],
    Icon: PlayArrowIcon,
  },
  {
    label: 'Unused',
    key: 'unusedPercentage',
    color: red[500],
    Icon: PauseIcon,
  },
  {
    label: 'Unavailable',
    key: 'unavailablePercentage',
    color: orange[500],
    Icon: StopIcon,
  },
  {
    label: 'Unaccountable',
    key: 'unaccountablePercentage',
    color: grey[500],
    Icon: CloseIcon,
  },
];
