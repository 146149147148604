import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useUserInfo() {
  return useQuery({
    queryKey: ['user-info'],
    queryFn: () => {
      return api.get('userinfo').json();
    },
    staleTime: 1000 * 60 * 60,
  });
}
