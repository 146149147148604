import { getMicrobeatFromText } from '@/utils';
import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';

export function MicrobeatsField({ input, meta, disabled }) {
  const [inputValue, setInputValue] = useState('');

  function handleChange(event, value, reason) {
    if (reason === 'createOption') {
      if (inputValue.length === 12) {
        const microbeat = getMicrobeatFromText(inputValue);

        if (!input.value.includes(microbeat)) {
          input.onChange([...input.value, microbeat]);
        }
      }
    } else {
      input.onChange(value);
    }
  }

  function handleInputChange(event, value, reason) {
    if (reason === 'reset' && inputValue.length === 12) {
      setInputValue(value);
    } else {
      if (
        reason === 'input' &&
        (value === '' || /^[0-9]+$/.test(value)) &&
        value.length < 13
      ) {
        setInputValue(value);
      }
    }
  }

  return (
    <Autocomplete
      fullWidth
      size="small"
      multiple
      disabled={disabled}
      freeSolo
      value={input.value || []}
      options={[]}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isOptionEqualToValue={() => false}
      renderInput={(params) => (
        <TextField
          label="Microbeats"
          {...params}
          helperText={meta.error ?? meta.submitError}
          error={meta.invalid}
        />
      )}
    />
  );
}
