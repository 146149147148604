import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfToday, subDays } from 'date-fns';

export function useAverageInStationTime(groupCode, subgroupType, days) {
  return useQuery({
    queryKey: ['inStationTime', groupCode, subgroupType, days],
    queryFn: () => {
      const json = [
        {
          $match: {
            time: {
              $gte: subDays(startOfToday(), days),
              $lt: startOfToday(),
            },
            'vehicle.groupCodes': groupCode,
          },
        },
        {
          $lookup: {
            from: 'groups',
            localField: 'vehicle.groupCodes',
            foreignField: 'code',
            as: 'group',
            pipeline: [{ $match: { type: subgroupType } }],
          },
        },
        {
          $unwind: {
            path: '$group',
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $group: {
            _id: { $ifNull: ['$group.code', 'UNKNOWN'] },
            stoppedBaseSeconds: {
              $avg: '$stopped.all.baseSeconds',
            },
          },
        },
        {
          $project: {
            _id: false,
            group: '$_id',
            hours: { $divide: ['$stoppedBaseSeconds', 3600] },
          },
        },
        { $sort: { hours: -1 } },
      ];

      return api.post('pipeline/vehicleDailyActivities', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!subgroupType,
  });
}
