import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subDays } from 'date-fns';

export function useTotalAttendedIncidents(
  groupType,
  groupCode,
  subgroupType,
  days,
) {
  return useQuery({
    queryKey: [
      'totalAttendedIncidents',
      groupType,
      groupCode,
      subgroupType,
      days,
    ],
    queryFn: async () => {
      const start = subDays(startOfDay(new Date()), days);
      const json = [
        {
          $match: {
            attended: true,
            startTime: { $gt: start },
            [`groups.person.${groupType}`]: groupCode,
          },
        },
        subgroupType !== 'COLLAR_NUMBER' && {
          $unwind: {
            path: `$groups.person.${subgroupType}`,
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $group: {
            _id: {
              identifier: {
                $ifNull: [
                  subgroupType === 'COLLAR_NUMBER'
                    ? '$person.collarNumber'
                    : `$groups.person.${subgroupType}`,
                  'UNKNOWN',
                ],
              },
              grade: '$incident.grade',
            },
            incidentNumbers: { $addToSet: '$incident.number' },
          },
        },
        {
          $project: {
            _id: false,
            identifier: '$_id.identifier',
            grade: '$_id.grade',
            count: { $size: '$incidentNumbers' },
          },
        },
      ].filter(Boolean);

      const response = await api
        .post('pipeline/personIncidentResponses', { json })
        .json();

      return response.reduce(
        (accumlator, { identifier, grade, count }) => {
          if (!accumlator.counts[identifier]) {
            accumlator.counts[identifier] = {};
          }

          accumlator.counts[identifier][grade ?? 'UNKNOWN'] = count;
          accumlator.grades.add(grade ?? 'UNKNOWN');

          return accumlator;
        },
        { counts: {}, grades: new Set() },
      );
    },
    placeholderData: { counts: {}, grades: new Set() },
    staleTime: 1000 * 60 * 60,
  });
}
