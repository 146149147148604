import {
  DatePicker,
  FilterPicker,
  Table,
  TablePagination,
} from '@/components/controls';
import { useDocumentTitle, useOdometers } from '@/hooks';
import { downloadCSV, filterLocally } from '@/utils';
import { odometerErrorExplanations, rowsPerPageOptions } from '@/utils/config';
import {
  GetApp as GetAppIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { format, startOfDay } from 'date-fns';
import { atom, useAtom } from 'jotai';

function getErrorExplanation(errorCode) {
  return odometerErrorExplanations[errorCode] || {};
}

function ErrorExplanation({ entry }) {
  const { short, long } = getErrorExplanation(entry.reasonCode);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flex: 3, marginRight: 3 }}>{short}</Box>
      {entry.reasonCode !== '' && (
        <Tooltip
          sx={{ flex: 1 }}
          title={
            <Box component="span" sx={{ fontSize: 14 }}>
              {long}
            </Box>
          }
        >
          <InfoIcon color="error" />
        </Tooltip>
      )}
    </Box>
  );
}

const headers = [
  {
    key: 'registrationNumber',
    type: 'text',
    label: 'Registration',
    filter: true,
  },
  {
    key: 'fleetNumber',
    type: 'text',
    label: 'Fleet Number',
    filter: true,
  },
  {
    key: 'readingTime',
    type: 'date',
    label: 'Reading Date & Time',
    filter: false,
  },
  {
    key: 'readingMiles',
    type: 'number',
    label: 'Reading Miles',
    filter: false,
  },
  {
    key: 'pollAfterReadingTime',
    type: 'text',
    label: 'Next Poll Date & Time',
    filter: false,
  },
  {
    key: 'pollAfterReadingMiles',
    type: 'number',
    label: 'Next Poll Miles',
    filter: false,
  },
  {
    key: 'pollAfterGapHours',
    type: 'number',
    label: 'Next Poll Gap Hours',
    filter: false,
  },
  {
    key: 'latestPollTime',
    type: 'text',
    label: 'Latest Poll Date & Time',
    filter: false,
  },
  {
    key: 'latestPollMiles',
    type: 'number',
    label: 'Latest Poll Miles',
    filter: false,
  },
  {
    key: 'calculatedMiles',
    type: 'number',
    label: 'Calculated Miles',
    filter: false,
  },
  {
    key: 'reasonCode',
    type: 'component',
    component: ErrorExplanation,
    label: 'Error Explanation',
    filter: false,
  },
];

const stateAtom = atom({
  page: 0,
  rowsPerPage: rowsPerPageOptions[0],
  order: 'asc',
  orderBy: 'startTime',
  date: startOfDay(new Date(), 'day'),
  fields: {
    registrationNumber: null,
    fleetNumber: null,
  },
});

export function Mileage() {
  useDocumentTitle('IR3 | Mileage');
  const [state, setState] = useAtom(stateAtom);
  const { data, isFetching, isLoading } = useOdometers(state.date);

  function updateState(update) {
    setState((state) => ({
      ...state,
      ...update,
    }));
  }

  function handlePageChange(event, page) {
    updateState({ page });
  }

  function handleRowsPerPageChange(event) {
    updateState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateState({ order });
  }

  function handleOrderByChange(orderBy) {
    updateState({
      orderBy,
      order: 'asc',
    });
  }

  function handleDateChange(date) {
    updateState({ date });
  }

  function formatDate(date) {
    return date ? format(new Date(date), 'dd/MM/yyyy HH:mm:ss') : '-';
  }

  function getErrorExplanation(errorCode) {
    return odometerErrorExplanations[errorCode] || {};
  }

  function handleDownloadClick() {
    const filename = 'Mileage.csv';
    const formattedData = filteredReadings.map((reading) => ({
      ...reading,
      readingTime: formatDate(reading.readingTime),
      latestPollTime: formatDate(reading.latestPollTime),
      pollAfterReadingTime: formatDate(reading.pollAfterReadingTime),
      reasonCode: getErrorExplanation(reading.reasonCode).short,
    }));

    downloadCSV(formattedData, filename, headers);
  }

  const filteredReadings = filterLocally(state, data);

  const mappedReadings = filteredReadings.map((reading) => ({
    ...reading,
    pollAfterReadingTime: formatDate(reading.pollAfterReadingTime),
    latestPollTime: formatDate(reading.latestPollTime),
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box sx={{ p: 1, height: 'calc(100% - 48px)' }}>
          <Toolbar variant="dense" disableGutters sx={{ px: 1, pb: 1 }}>
            <Typography
              sx={{ flexGrow: 1 }}
              variant="subtitle1"
              // component="div"
            >
              Mileage
            </Typography>
            {(isFetching || isLoading) && (
              <CircularProgress sx={{ m: 1 }} size={16} thickness={6} />
            )}
            <DatePicker
              value={state.date}
              onChange={handleDateChange}
              sx={{ width: 180, pr: 1 }}
            />
            <FilterPicker
              headers={headers}
              data={data}
              filter={state}
              onFilterChange={updateState}
            />
            <Tooltip title="Download">
              <Box component="span">
                <IconButton
                  disabled={filteredReadings.length === 0}
                  onClick={handleDownloadClick}
                  sx={{ pl: 1 }}
                >
                  <GetAppIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Toolbar>
          <Paper sx={{ width: 1, height: 1, overflow: 'none' }}>
            <Table
              styles={{
                tableContainer: {
                  height: 'calc(100% - 48px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={mappedReadings}
              headers={headers}
              rowsPerPage={state.rowsPerPage}
              page={state.page}
              order={state.order}
              orderBy={state.orderBy}
              onOrderChange={handleOrderChange}
              onOrderByChange={handleOrderByChange}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={mappedReadings.length}
              rowsPerPage={state.rowsPerPage}
              page={state.page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
