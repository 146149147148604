import { WeeklySchedulePicker } from '@/components/controls';
import { FormControl, FormHelperText } from '@mui/material';
import { useController } from 'react-hook-form';

export function WeeklySchedulePickerController({
  name,
  control,
  disabled,
  rules,
  sx,
}) {
  const {
    field: { value, onChange },
    fieldState: { error, invalid },
  } = useController({ name, control, rules });

  return (
    <FormControl error={invalid} variant="standard" disabled={disabled} sx={sx}>
      <WeeklySchedulePicker
        value={
          value ??
          Array(7)
            .fill()
            .map(() => Array(24).fill(false))
        }
        onChange={onChange}
        disabled={disabled}
      />
      <FormHelperText sx={{ pl: 1 }}>{error?.message}</FormHelperText>
    </FormControl>
  );
}
