import { getStatusColor, startCase } from '@/utils';
import { DataUsage as DataUsageIcon } from '@mui/icons-material';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';

export function ObjectiveListItem({
  item: { size, start },
  feature,
  ...props
}) {
  const startTime = new Date(feature.properties.startTime);
  const endTime = new Date(feature.properties.endTime);

  return (
    <ListItemButton
      {...props}
      dense
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
      // component={Link}
      // to={'.'}
    >
      <ListItemAvatar>
        <Tooltip title={startCase(feature.properties?.status)} placement="top">
          <Avatar
            sx={{
              bgcolor: getStatusColor(feature.properties.status),
            }}
          >
            <DataUsageIcon />
          </Avatar>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        primary={feature.properties.title}
        primaryTypographyProps={{ noWrap: true }}
        secondary={`${format(startTime, 'dd/MM/yyyy')} - ${format(
          endTime,
          'dd/MM/yyyy',
        )}`}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </ListItemButton>
  );
}
