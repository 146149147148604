import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useObjectives() {
  return useQuery({
    queryKey: ['objectives'],
    queryFn: () => {
      log('Read', 'Objectives');

      return api
        .get('objectives', {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              title: true,
              startTime: true,
              endTime: true,
              wards: true,
              created: true,
              boundaryType: true,
            },
          }),
        })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
