import {
  AutocompleteController,
  GroupCodesController,
} from '@/components/controls';
import { usePeople, useVehicles } from '@/hooks';
import { useMemo } from 'react';

export function ApplicableToControllers({ control, resourceType, isDisabled }) {
  const { data: people } = usePeople();
  const { data: vehicles } = useVehicles();
  const peopleCodes = useMemo(
    () =>
      people
        .filter(
          (person) => !person.leavingDate || person.leavingDate > new Date(),
        )
        .sort(
          (a, b) =>
            (a.surname ?? '').localeCompare(b.surname ?? '') ||
            (a.forenames ?? '').localeCompare(b.forenames ?? ''),
        )
        .map((person) => ({
          label:
            [
              person.forenames,
              person.surname,
              person.collarNumber && `[${person.collarNumber}]`,
            ]
              .filter(Boolean)
              .join(' ')
              .trim() || person.code,
          value: person.code,
        })),
    [people],
  );
  const vehicleIdentificationNumbers = useMemo(
    () =>
      vehicles
        .filter(
          (vehicle) =>
            !vehicle.disposalDate || vehicle.disposalDate > new Date(),
        )
        .sort((a, b) =>
          (a.registrationNumber ?? a.identificationNumber ?? '').localeCompare(
            b.registrationNumber ?? b.identificationNumber ?? '',
          ),
        )
        .map((vehicle) => ({
          label:
            [
              vehicle.registrationNumber,
              vehicle.fleetNumber && `[${vehicle.fleetNumber}]`,
            ]
              .filter(Boolean)
              .join(' ')
              .trim() ?? vehicle.identificationNumber,
          value: vehicle.identificationNumber,
        })),
    [vehicles],
  );

  switch (resourceType) {
    case 'people':
      return (
        <AutocompleteController
          name="personCodes"
          control={control}
          fullWidth
          options={peopleCodes}
          disabled={isDisabled('personCodes')}
        />
      );
    case 'vehicles':
      return (
        <AutocompleteController
          name="vehicleIdentificationNumbers"
          control={control}
          fullWidth
          options={vehicleIdentificationNumbers}
          disabled={isDisabled('vehicleIdentificationNumbers')}
        />
      );
    case 'personGroups':
      return (
        <GroupCodesController
          name="personGroupCodes"
          control={control}
          disabled={isDisabled('personGroupCodes')}
        />
      );
    case 'vehicleGroups':
      return (
        <GroupCodesController
          name="vehicleGroupCodes"
          control={control}
          disabled={isDisabled('vehicleGroupCodes')}
        />
      );
    default:
      return null;
  }
}
