import {
  brown,
  cyan,
  green,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { atom } from 'jotai';

export const stateAtom = atom({
  hours: [...Array(24).keys()],
  days: [...Array(7).keys()],
  query: {},
  parameters: {},
});

export const csvColumns = [
  {
    label: 'Hour',
    key: 'hour',
    type: 'text',
  },
  {
    label: 'On Radio',
    key: 'loggedInMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Driving',
    key: 'drivingMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'In Base',
    key: 'baseMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Responding to Incidents',
    key: 'respondingMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Attending Objectives',
    key: 'attendingMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'In Home Ward',
    key: 'homeWardMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Double Crewing',
    key: 'doubleCrewingMinutes',
    type: 'number',
    precision: 6,
  },
];

export const bars = [
  { name: 'loggedInMinutes', label: 'On Radio', colour: green[500] },
  { name: 'drivingMinutes', label: 'Driving', colour: purple[500] },
  { name: 'baseMinutes', label: 'In Base', colour: brown[500] },
  {
    name: 'respondingMinutes',
    label: 'Responding to Incidents',
    colour: red[500],
  },
  {
    name: 'attendingMinutes',
    label: 'Attending Objectives',
    colour: teal[500],
  },
  { name: 'homeWardMinutes', label: 'In Home Ward', colour: orange[500] },
  { name: 'doubleCrewingMinutes', label: 'Double Crewing', colour: cyan[500] },
];
