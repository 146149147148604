import { highlightsStateAtom } from '@/atoms';
import { useDocumentTitle, useGroups } from '@/hooks';
import { getGroupType } from '@/utils';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { useAtom } from 'jotai';
import { CurrentObjectivesCard } from './CurrentObjectivesCard';
import { DailyActivityCard } from './DailyActivityCard';
import { TotalAttendedIncidentsCard } from './TotalAttendedIncidentsCard';

export function Team() {
  useDocumentTitle('IR3 | Highlights | Team');
  const [{ team }, setState] = useAtom(highlightsStateAtom);
  const groupType = getGroupType('TEAM');
  const { data: groups } = useGroups({
    types: [getGroupType(groupType)],
  });

  function handleTeamChange(event, value) {
    setState((state) => ({ ...state, team: value }));
  }

  return (
    <Stack sx={{ p: 1 }} spacing={1}>
      <Stack
        direction="row"
        flexDirection="row-reverse"
        sx={{ width: 1, pt: 0.5 }}
      >
        <Autocomplete
          size="small"
          value={team}
          onChange={handleTeamChange}
          options={groups.map(({ code, name }) => ({
            value: code,
            label: name,
          }))}
          renderInput={(params) => <TextField label="Team" {...params} />}
          isOptionEqualToValue={(option, value) =>
            option.value ?? option === value.value ?? value
          }
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          sx={{ minWidth: 200 }}
        />
      </Stack>
      <Stack spacing={1} direction="row" alignItems="baseline">
        <Stack spacing={1}>
          <CurrentObjectivesCard
            groupType={groupType}
            groupCode={team?.value}
          />
        </Stack>
        <Stack spacing={1} sx={{ flexGrow: 1 }}>
          <DailyActivityCard
            groupType={groupType}
            groupCode={team?.value}
            subgroupType="COLLAR_NUMBER"
          />
          <TotalAttendedIncidentsCard
            groupType={groupType}
            groupCode={team?.value}
            subgroupType="COLLAR_NUMBER"
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
