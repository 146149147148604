import {
  green,
  lightBlue,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { atom } from 'jotai';

export const stateAtom = atom({
  hours: [...Array(24).keys()],
  days: [...Array(7).keys()],
  query: {},
  parameters: {},
});

export const csvColumns = [
  {
    label: 'Hour',
    key: 'hour',
    type: 'text',
  },
  {
    label: 'Moving',
    key: 'movingMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Stopped @ Home Base',
    key: 'stoppedHomeBaseMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Stopped @ Other Base',
    key: 'stoppedOtherBaseMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Idle @ Home Base',
    key: 'idleHomeBaseMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Idle @ Other Base',
    key: 'idleOtherBaseMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Stopped @ Workshop',
    key: 'stoppedWorkshopMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Idle @ Workshop',
    key: 'idleWorkshopMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Stopped Elsewhere',
    key: 'stoppedElsewhereMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Idle Elsewhere',
    key: 'idleElsewhereMinutes',
    type: 'number',
    precision: 6,
  },
  {
    label: 'Unaccountable',
    key: 'unaccountableMinutes',
    type: 'number',
    precision: 6,
  },
];

export const bars = [
  {
    name: 'stoppedHomeBaseMinutes',
    colour: red[800],
    label: 'Stopped @ Home Base',
  },
  {
    name: 'stoppedOtherBaseMinutes',
    colour: red[500],
    label: 'Stopped @ Other Base',
  },
  {
    name: 'idleHomeBaseMinutes',
    colour: purple[700],
    label: 'Idle @ Home Base',
  },
  {
    name: 'idleOtherBaseMinutes',
    colour: purple[300],
    label: 'Idle @ Other Base',
  },
  {
    name: 'stoppedWorkshopMinutes',
    colour: orange[800],
    label: 'Stopped @ Workshop',
  },
  {
    name: 'idleWorkshopMinutes',
    colour: orange[500],
    label: 'Idle @ Workshop',
  },
  {
    name: 'stoppedElsewhereMinutes',
    colour: teal[800],
    label: 'Stopped Elsewhere',
  },
  { name: 'idleElsewhereMinutes', colour: teal[500], label: 'Idle Elsewhere' },
  { name: 'movingMinutes', colour: green[500], label: 'Moving' },
  {
    name: 'unaccountableMinutes',
    colour: lightBlue[400],
    label: 'Unaccountable',
  },
];
