import { RetrospectiveTypeIcon } from '@/components/controls';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { getPrimaryText, getSecondaryText } from '../constants';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef } from 'react';
import { useDocumentTitle } from '@/hooks';

export function Cluster({
  color,
  background,
  subItems,
  count,
  id: clusterId,
  originalSource,
}) {
  useDocumentTitle(['IR3', 'Cluster', clusterId].filter(Boolean).join(' | '));
  const { id, layerIndex, itemIndex } = useParams();
  const parentRef = useRef(null);
  const rowVirtualizer = useVirtualizer({
    count: subItems.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 10,
  });

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Tooltip title="Cluster">
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="clusters" />
            </Avatar>
          </Tooltip>
        }
        title={`${count} item${count === 1 ? '' : 's'} in cluster`}
        subheader={clusterId}
      />
      <Divider />
      <CardContent sx={{ p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flex: [1, 1, 'auto'],
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          {subItems.length > 0 && (
            <Box
              ref={parentRef}
              sx={{ overflow: 'auto', height: 'calc(100vh - 161px)' }}
            >
              <Box
                sx={{
                  height: `${rowVirtualizer.getTotalSize()}px`,
                  width: '100%',
                  position: 'relative',
                }}
              >
                {rowVirtualizer
                  .getVirtualItems()
                  .map(({ index, size, start }) => {
                    const feature = {
                      properties: {
                        ...subItems[index],
                        source: originalSource,
                      },
                    };

                    return (
                      <ListItemButton
                        dense
                        key={index}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: `${size}px`,
                          transform: `translateY(${start}px)`,
                        }}
                        component={Link}
                        to={`/retrospective/${
                          id || 'untitled'
                        }/${layerIndex}/${itemIndex}/${index}`}
                        // onMouseEnter={() => {
                        //   // because ie11 is a pos and this event fires every time the mouse moves inside an element
                        //   if (hoveredItemIndex.itemIndex !== index) {
                        //     onHover({
                        //       layerIndex: parseInt(layerIndex),
                        //       // itemIndex: index
                        //       itemIndex: feature.id,
                        //     });
                        //   }
                        // }}
                        // onMouseLeave={() => onHover({})}
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              background,
                              color,
                            }}
                          >
                            <RetrospectiveTypeIcon type={originalSource} />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={getPrimaryText(feature)}
                          secondary={getSecondaryText(feature)}
                        />
                      </ListItemButton>
                    );
                  })}
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
