import { userQueryTitleDoesNotExist } from '@/utils';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { TextFieldController } from '../controllers';

export function UserQueryForm({
  value,
  onSubmit,
  onCancel,
  collection,
  username,
  enableInterval,
}) {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      title: value.title ?? '',
      interval: value.interval ?? '',
    },
  });

  function titleDoesNotExist(title) {
    return userQueryTitleDoesNotExist(value._id, title, collection, username);
  }

  return (
    <form id="user-query-form" onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle id="dialog-title">Save query</DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ pt: 0.75 }}>
          <TextFieldController
            name="title"
            label="Title"
            control={control}
            defaultValue=""
            rules={{ required: 'Required', validate: titleDoesNotExist }}
            fullWidth
          />
          {enableInterval && (
            <TextFieldController
              name="interval"
              label="Email Frequency"
              control={control}
              defaultValue=""
              helperText="Choose weekly or daily if you wish to have the report emailed to you"
              options={[
                { label: 'Never', value: '' },
                { label: 'Daily', value: 'daily' },
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
              ]}
              fullWidth
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </DialogActions>
    </form>
  );
}
