import {
  getTextWidth,
  max,
  range,
  round,
  shortHumanizer,
  startCase,
} from '@/utils';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { dequal } from 'dequal';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CartesianGrid,
  Cell,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { LocationTypeIcon } from './LocationTypeIcon';
import { useDocumentTitle } from '@/hooks';

function CustomTooltip({ active, payload, measure }) {
  if (active) {
    return (
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>{payload[2].value}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  {payload[0].name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{payload[0].value}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  {payload[1].name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {measure.includes('Time')
                    ? shortHumanizer(payload[1].value)
                    : payload[1].value}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  return null;
}

// We need to pass these props in order to avoid having a warning about x, y, width, height being NaN coming from the Cell below
const cellDummyProps = {
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

export function Area({
  color,
  background,
  name,
  type,
  subtype,
  count,
  quantile,
  measure,
}) {
  useDocumentTitle(['IR3', 'Area', name].filter(Boolean).join(' | '));
  const { layerIndex } = useParams();
  const retrospective = useSelector(
    (state) => state.retrospectives.retrospective,
    dequal,
  );
  const theme = useTheme();
  const data =
    retrospective.layers[layerIndex]?.featureCollection?.features
      .map(({ properties: { name, count, quantile } }) => ({
        name,
        percentile: round(quantile * 100, 0),
        count,
      }))
      .filter((entry) => entry.name !== name)
      .concat({
        name,
        percentile: round(quantile * 100, 0),
        count,
      }) ?? [];

  function formatYAxis(tickItem) {
    return measure !== undefined && measure.includes('Time')
      ? shortHumanizer(tickItem)
      : tickItem;
  }

  function getTicks() {
    const maxResult = max(...data.map((item) => item.count));
    let interval;

    if (maxResult > 86400000 * 28) {
      interval = 604800000;
    } else if (maxResult > 86400000 * 3) {
      interval = 86400000;
    } else if (maxResult > 3600000 * 3) {
      interval = 3600000;
    } else {
      interval = 60000;
    }

    const rangeResult = range(interval, maxResult, interval);
    return [...rangeResult, rangeResult[rangeResult.length - 1] + interval];
  }

  function getYAxisWidth() {
    if (measure !== undefined && measure.includes('Time')) {
      return (
        max(
          getTicks().map((item) =>
            getTextWidth(shortHumanizer(item), '12px Roboto'),
          ),
        ) + 18
      );
    } else {
      return (
        max(
          data.map((item) =>
            getTextWidth((item.count || 0).toString(), '12px Roboto'),
          ),
        ) + 18
      );
    }
  }

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Tooltip title={type}>
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <LocationTypeIcon type={type} />
            </Avatar>
          </Tooltip>
        }
        title={name}
        subheader={subtype}
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Box align="center">
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ mt: 0.5, mb: 0.5 }}
          >
            {startCase(measure)}
          </Typography>
        </Box>
        <ResponsiveContainer width="99%" height={280}>
          <ScatterChart>
            <CartesianGrid />
            <XAxis dataKey="percentile" type="number" name="Percentile">
              <Label value="Percentile" position="bottom" offset={-4} />
            </XAxis>
            <YAxis
              dataKey="count"
              type="number"
              name="Count"
              width={getYAxisWidth()}
              tickFormatter={formatYAxis}
              ticks={measure && (measure.includes('Time') ? getTicks() : null)}
            >
              <Label value="Count" position="left" angle={-90} offset={-1} />
            </YAxis>
            <ZAxis dataKey="name" name="Name" />
            <Scatter data={data}>
              {data.map((entry, index) => (
                <Cell
                  key={index}
                  fill={
                    data[index].name === name
                      ? theme.palette.primary.main
                      : theme.palette.grey[500]
                  }
                  {...cellDummyProps}
                />
              ))}
            </Scatter>
            <ChartTooltip content={<CustomTooltip measure={measure} />} />
          </ScatterChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
