import { objectivesStateAtom } from '@/atoms';
import { SearchBox, SortField } from '@/components/controls';
import {
  useDocumentTitle,
  useLocations,
  useObjectives,
  useUserInfo,
} from '@/hooks';
import { isAuthorised } from '@/utils';
import {
  Add as AddIcon,
  DataUsage as DataUsageIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Collapse,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { useMemo, useRef } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';

export function Objectives() {
  useDocumentTitle('IR3 | Objectives');
  const { id } = useParams();
  const [{ searchText, sortBy, sortDesc, filter, showSettings }, setState] =
    useAtom(objectivesStateAtom);
  const { data: objectives } = useObjectives();
  const { data: wards } = useLocations({ type: 'Ward' });
  const parentRef = useRef();
  const { data: userInfo } = useUserInfo();

  const canCreate = isAuthorised(userInfo?.authorisation, 'objectives', true);

  const sortOptions = [
    { label: 'Title', value: 'title' },
    { label: 'Identifier', value: 'identifier' },
    { label: 'Start Date', value: 'startTime' },
    { label: 'End Date', value: 'endTime' },
  ];

  const filteredObjectives = useMemo(() => {
    function createdByFilter(objective) {
      if (filter.createdBy === '') {
        return true;
      }

      if (!objective.created) {
        return false;
      }

      return objective.created.userId === filter.createdBy;
    }

    function wardFilter(objective) {
      if (filter.ward === '') {
        return true;
      }

      if (!objective.wards) {
        return false;
      }

      return objective.wards.includes(filter.ward);
    }

    function statusFilter(objective) {
      const now = new Date();

      switch (filter.status) {
        case 'Active':
          return (
            new Date(objective.startTime) <= now &&
            now < new Date(objective.endTime)
          );
        case 'Upcoming':
          return new Date(objective.startTime) > now;
        case 'Expired':
          return new Date(objective.endTime) < now;
        default:
          return true;
      }
    }

    function boundaryTypeFilter(objective) {
      if (filter.boundaryType === '') {
        return true;
      }

      if (!objective.boundaryType) {
        return false;
      }

      return objective.boundaryType === filter.boundaryType;
    }

    return _.orderBy(
      objectives
        .filter(
          (objective) =>
            `${objective.title}+${objective.identifier}`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) > -1 || searchText === '',
        )
        // .filter(areaFilter)
        .filter(createdByFilter)
        .filter(wardFilter)
        .filter(statusFilter)
        .filter(boundaryTypeFilter),
      [sortBy],
      [sortDesc ? 'desc' : 'asc'],
    );
  }, [filter, objectives, searchText, sortBy, sortDesc]);

  const rowVirtualizer = useVirtualizer({
    count: filteredObjectives.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 10,
  });

  function handleSearchChange(event) {
    setState((state) => ({
      ...state,
      searchText: event.target.value,
    }));
  }

  function handleSettingsToggle() {
    setState((state) => ({
      ...state,
      showSettings: !state.showSettings,
    }));
  }

  function handleSortByChange(event) {
    setState((state) => ({
      ...state,
      sortBy: event.target.value,
    }));
  }

  function handleSortToggle() {
    setState((state) => ({
      ...state,
      sortDesc: !state.sortDesc,
    }));
  }

  const handleFilterChange = (field) => (event) => {
    setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        [field]: event.target.value,
      },
    }));
  };

  function getCreatorIds() {
    const ids = new Set(
      objectives
        .filter((objective) => objective.created)
        .map((objective) => objective.created.userId)
        .sort(),
    );

    return Array.from(ids.values());
  }

  function getWards() {
    const ids = new Set(
      [].concat.apply(
        [],
        objectives
          .filter((objective) => 'wards' in objective)
          .map((objective) => objective.wards)
          .sort(),
      ),
    );

    const list = Array.from(ids.values()).map((id) => {
      const ward = wards.find((ward) => ward.code === id);

      return {
        value: id,
        label: ward ? ward.name : id,
      };
    });

    return list;
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 48px)' }}>
      <Stack
        sx={{
          width: 280,
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
          height: 1,
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ p: 1, alignItems: 'center' }}
          >
            <SearchBox
              value={searchText}
              onChange={handleSearchChange}
              count={`${filteredObjectives.length}/${objectives.length}`}
              sx={{ flexGrow: 1 }}
            />
            <Box>
              <Tooltip title={showSettings ? 'Hide settings' : 'Show settings'}>
                <IconButton onClick={handleSettingsToggle} size="small">
                  <SettingsIcon
                    fontSize="inherit"
                    color={showSettings ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            {canCreate && (
              <Box>
                <Tooltip title="Add new">
                  <IconButton component={Link} to="new" size="small">
                    <Avatar
                      sx={{
                        color: 'secondary.contrastText',
                        backgroundColor: 'secondary.main',
                        width: 24,
                        height: 24,
                        fontSize: 16,
                      }}
                    >
                      <AddIcon fontSize="inherit" />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Stack>
          <Collapse in={showSettings} unmountOnExit>
            <Stack sx={{ p: 1 }} spacing={1}>
              <SortField
                sortBy={sortBy}
                onSortByChange={handleSortByChange}
                sortDesc={sortDesc}
                onSortDescToggle={handleSortToggle}
                options={sortOptions}
              />
            </Stack>
            <Stack sx={{ px: 1, pb: 1 }} spacing={1}>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Filters
                </Typography>
              </Divider>
              <TextField
                size="small"
                fullWidth
                select
                label="Ward"
                value={filter.ward}
                onChange={handleFilterChange('ward')}
              >
                <MenuItem key="" value="">
                  <em>Any</em>
                </MenuItem>
                {getWards().map((ward) => (
                  <MenuItem key={ward.value} value={ward.value}>
                    {ward.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                fullWidth
                select
                label="Created By"
                value={filter.createdBy}
                onChange={handleFilterChange('createdBy')}
              >
                <MenuItem key="" value="">
                  <em>Any</em>
                </MenuItem>
                {getCreatorIds().map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                fullWidth
                select
                label="Status"
                value={filter.status}
                onChange={handleFilterChange('status')}
              >
                <MenuItem key="" value="">
                  <em>Any</em>
                </MenuItem>
                {['Active', 'Upcoming', 'Expired'].map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                fullWidth
                select
                label="Boundary Type"
                value={filter.boundaryType}
                onChange={handleFilterChange('boundaryType')}
              >
                <MenuItem key="" value="">
                  <em>Any</em>
                </MenuItem>
                {['Location', 'Custom', 'Microbeats'].map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Collapse>
        </Box>
        <Divider />
        <Box ref={parentRef} sx={{ overflow: 'auto', height: 1 }}>
          <Box
            sx={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map(({ index, size, start }) => {
              const objective = filteredObjectives[index];
              const status =
                objective.endTime < new Date()
                  ? 'EXPIRED'
                  : objective.startTime > new Date()
                    ? 'UPCOMING'
                    : 'ACTIVE';

              return (
                <ListItemButton
                  dense
                  key={index}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${size}px`,
                    transform: `translateY(${start}px)`,
                  }}
                  component={Link}
                  to={`/objectives/${encodeURIComponent(objective.identifier)}`}
                  selected={objective.identifier === decodeURIComponent(id)}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor:
                          status === 'EXPIRED'
                            ? 'error.main'
                            : status === 'UPCOMING'
                              ? 'warning.main'
                              : 'success.main',
                      }}
                    >
                      <DataUsageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={objective.title}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={`${format(new Date(objective.startTime), 'dd/MM/yyyy')} - ${format(new Date(objective.endTime), 'dd/MM/yyyy')}`}
                    secondaryTypographyProps={{ noWrap: true }}
                  />
                </ListItemButton>
              );
            })}
          </Box>
        </Box>
      </Stack>
      <Box sx={{ overflow: 'auto', flex: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
