import {
  Error as ErrorIcon,
  Input as InputIcon,
  Key as KeyIcon,
  RvHookup as RvHookupIcon,
  VolumeUp as VolumeUpIcon,
  Warning as WarningIcon,
  WbIncandescent as WbIncandescentIcon,
} from '@mui/icons-material';
import { Stack, Tooltip, useTheme } from '@mui/material';
import {
  AlarmLight as AlarmLightIcon,
  CarLightHigh as CarLightHighIcon,
  CarSide as CarSideIcon,
  Engine as EngineIcon,
  RadioHandheld as RadioHandheldIcon,
} from 'mdi-material-ui';
import { cloneElement } from 'react';
import { EquipmentStatusIcon } from './EquipmentStatusIcon';

export function VehicleEquipmentIcons({ properties, sx }) {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={0.25} sx={sx}>
      <EquipmentStatusIcon
        label="Ignition"
        Icon={KeyIcon}
        isActive={properties.ignitionOn}
      />
      <EquipmentStatusIcon
        label="Sirens"
        Icon={VolumeUpIcon}
        isActive={properties.sirensOn}
      />
      <EquipmentStatusIcon
        label="Beacons"
        Icon={AlarmLightIcon}
        isActive={properties.beaconsOn}
      />
      <EquipmentStatusIcon
        label="Flashing Headlights"
        Icon={CarLightHighIcon}
        isActive={properties.headlightsFlashOn}
      />
      <EquipmentStatusIcon
        label="Malfunction Indicator Light"
        Icon={EngineIcon}
        isActive={properties.malfunctionIndicatorLightOn}
      />
      <EquipmentStatusIcon
        label="Accelerometer Alert"
        Icon={WarningIcon}
        isActive={properties.accelerometerAlert}
      />
      <EquipmentStatusIcon
        label="Rear Blue Lights"
        Icon={WbIncandescentIcon}
        isActive={properties.rearBlueLightsOn}
        color="primary"
      />
      <EquipmentStatusIcon
        label="Rear Red Lights"
        Icon={WbIncandescentIcon}
        isActive={properties.rearRedLightsOn}
        color="error"
      />
      <EquipmentStatusIcon
        label="Strike Button"
        Icon={ErrorIcon}
        isActive={properties.strikeButtonOn}
      />
      <EquipmentStatusIcon
        label="Airwave"
        Icon={RadioHandheldIcon}
        isActive={properties.airwaveOn}
      />
      <EquipmentStatusIcon
        label="Ancillary Equipment"
        Icon={InputIcon}
        isActive={properties.ancillaryEquipmentOn}
      />
      <EquipmentStatusIcon
        label="Amber Lights"
        Icon={AlarmLightIcon}
        isActive={properties.amberLightsOn}
      />
      <EquipmentStatusIcon
        label="Trailer"
        Icon={RvHookupIcon}
        isActive={properties.trailerOn}
      />
      <EquipmentStatusIcon
        label="Radio"
        Icon={RadioHandheldIcon}
        isActive={properties.radioOn}
      />
      {(properties.frontPWEOn !== undefined ||
        properties.rearPWEOn !== undefined) && (
        <Tooltip title="Public Warning Equipment">
          <CarSideIcon
            sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
            component={(svgProps) => {
              return (
                <svg {...svgProps}>
                  <defs>
                    <linearGradient id="gradient1">
                      <stop
                        offset="50%"
                        stopColor={
                          properties.rearPWEOn
                            ? theme.palette.primary.dark
                            : theme.palette.action.disabled
                        }
                      />
                      <stop
                        offset="50%"
                        stopColor={
                          properties.frontPWEOn
                            ? theme.palette.primary.dark
                            : theme.palette.action.disabled
                        }
                      />
                    </linearGradient>
                  </defs>
                  {cloneElement(svgProps.children[0], {
                    fill: 'url(#gradient1)',
                  })}
                </svg>
              );
            }}
          />
        </Tooltip>
      )}
    </Stack>
  );
}
