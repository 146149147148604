import { CustomTooltip } from '@/components/controls';
import { useGroupOptions, useOptions } from '@/hooks';
import { getTextWidth, humanizeHours } from '@/utils';
import { highlights } from '@/utils/config';
import { useTheme } from '@emotion/react';
import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useAverageInStationTime } from './useAverageInStationTime';

const {
  averageInStationTime: { highlightSize, lookbackDays },
} = highlights;
const averageInStationStateAtom = atomWithStorage('averageInStation', '');

export function AverageInStationTimeCard({ groupCode }) {
  const { data: typeOptions } = useOptions('groupType');
  const [groupType, setGroupType] = useAtom(averageInStationStateAtom);
  const { data: averages } = useAverageInStationTime(
    groupCode,
    groupType,
    lookbackDays,
  );
  const options = useGroupOptions();
  const [[min, max], setRange] = useState([1, 2]);
  const theme = useTheme();
  const data = (averages ?? [])
    .map((average, index) => ({ index, ...average }))
    .slice(min - 1, max);

  const formatLabel = (value) => options[value]?.name ?? value;

  useEffect(() => {
    setRange([1, averages?.length ?? 2]);
  }, [averages]);

  const xAxisHeight =
    data.length > 0
      ? Math.ceil(
          Math.max(
            ...data.map((item) =>
              getTextWidth(
                item.group ? formatLabel(item.group) : '',
                '12px Roboto',
              ),
            ),
          ),
        )
      : 30;

  function handleGroupTypeChange(event) {
    setGroupType(event.target.value);
  }

  function handleRangeChange(event, newValue) {
    setRange(newValue);
  }

  return (
    <Card variant="outlined" sx={{ flexGrow: 1 }}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Availability
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {`Average in station time, per vehicle, per day, over the last ${lookbackDays} days`}
          </Typography>
        </Stack>
        <Box sx={{ pt: 1, fontSize: 12 }}>
          <ResponsiveContainer width="99%" height={520}>
            <BarChart
              data={data}
              margin={{ right: 16, bottom: 32, top: 8, left: 16 }}
            >
              <XAxis
                dataKey="group"
                angle={-90}
                textAnchor="end"
                interval={0}
                height={xAxisHeight}
                tickFormatter={formatLabel}
              />
              <YAxis>
                <Label value="Hours" angle={-90} position="left" offset={8} />
              </YAxis>
              <Tooltip
                cursor={false}
                content={
                  <CustomTooltip
                    variant="outlined"
                    valueFormatter={humanizeHours}
                    labelFormatter={formatLabel}
                  />
                }
              />
              <Bar dataKey="hours" name="Time">
                {data.map((entry) => (
                  <Cell
                    key={entry.index}
                    fill={
                      entry.index < (highlightSize ?? 5)
                        ? theme.palette.error.main
                        : entry.index >
                            averages.length - ((highlightSize ?? 5) + 1)
                          ? theme.palette.success.main
                          : theme.palette.warning.main
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Stack spacing={2} direction="row" sx={{ w: 1 }}>
          <TextField
            sx={{ width: 240 }}
            size="small"
            select
            label="Group by"
            value={groupType}
            onChange={handleGroupTypeChange}
          >
            {typeOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <Box sx={{ px: 1, pt: 0.5, flex: 1 }}>
            <Slider
              size="small"
              value={[min, max]}
              min={1}
              max={averages?.length ?? 1}
              onChange={handleRangeChange}
              valueLabelDisplay="auto"
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}
