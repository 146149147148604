import { api } from '@/apis';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

interface TripClassification {
  value: string;
  label: string;
  applicableSpeedRules?: string[];
  exempt?: boolean;
}

export function useTripClassifications(): UseQueryResult<TripClassification[]> {
  return useQuery({
    queryKey: ['tripClassifications'],
    queryFn: () => {
      const json = [
        { $match: { name: 'tripClassification' } },
        {
          $project: {
            _id: false,
            value: true,
            label: true,
            applicableSpeedRules: true,
            exempt: true,
          },
        },
      ];

      return api.post('pipeline/options', { json }).json();
    },
    placeholderData: (previousData) => previousData ?? [],
    staleTime: 1000 * 60 * 60 * 24,
  });
}
