import { mapStyleAtom } from '@/atoms';
import { transformRequest } from '@/utils';
import { maxBounds, maxZoom, minZoom, styles } from '@/utils/config';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  Delete as DeleteIcon,
  Create as DrawIcon,
  Layers as LayersIcon,
  Stop as StopIcon,
  Upload as UploadIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import {
  Backdrop,
  CircularProgress,
  FormHelperText,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  useTheme,
} from '@mui/material';
import { bbox } from '@turf/turf';
import { useAtom } from 'jotai';
import 'maplibre-gl/dist/maplibre-gl.css';
import { enqueueSnackbar } from 'notistack';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Layer,
  Map,
  ScaleControl,
  Source,
  useControl,
} from 'react-map-gl/maplibre';
import { MapBranding } from './MapBranding';
import { MapButton } from './MapButton';

const DrawControl = forwardRef(function DrawControl(props, ref) {
  const drawRef = useControl(
    () => new MapboxDraw(props),
    ({ map }) => {
      map.on('draw.create', props.onCreate);
      map.on('draw.update', props.onUpdate);
      map.on('draw.delete', props.onDelete);
      map.on('draw.modechange', props.onModeChange);
    },
    ({ map }) => {
      map.off('draw.create', props.onCreate);
      map.off('draw.update', props.onUpdate);
      map.off('draw.delete', props.onDelete);
      map.off('draw.modechange', props.onModeChange);
    },
    { position: props.position },
  );

  useImperativeHandle(ref, () => drawRef, [drawRef]);

  return null;
});

export function BoundaryEditor({
  value,
  onChange,
  disabled,
  error,
  isDirty,
  sx,
}) {
  const mapRef = useRef();
  const drawRef = useRef();
  const [style, setStyle] = useAtom(mapStyleAtom);
  const [styleMenuAnchorEl, setStyleMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const [viewState, setViewState] = useState({
    minZoom,
    maxZoom,
    maxBounds,
    initialViewState: {
      bounds: value ? bbox(value) : maxBounds,
      fitBoundsOptions: { padding: 40 },
    },
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [drawMode, setDrawMode] = useState('simple_select');

  useEffect(() => {
    if (!isDirty && drawRef.current) {
      drawRef.current.deleteAll();
      setDrawMode('simple_select');
      drawRef.current.changeMode('simple_select');
      if (value) {
        mapRef.current.fitBounds(bbox(value), { padding: 40, duration: 1000 });
      }
    }
  }, [isDirty, value]);

  function handleMove(event) {
    setViewState(event.viewState);
  }

  function handleStyleClick(event) {
    setStyleMenuAnchorEl(event.currentTarget);
  }

  function handleStyleMenuClose() {
    setStyleMenuAnchorEl(null);
  }

  const handleStyleMenuClick = (path) => () => {
    setStyle(path);
    setStyleMenuAnchorEl(null);
  };

  function handleZoomInClick() {
    mapRef.current.zoomIn();
  }

  function handleZoomOutClick() {
    mapRef.current.zoomOut();
  }

  function handleFitClick() {
    mapRef.current.fitBounds(bbox(value), { padding: 40, duration: 1000 });
  }

  function handleLoad() {
    setIsLoaded(true);
  }

  function handleError(errorEvent) {
    setIsLoaded(true);

    throw errorEvent.error;
  }

  function handleCreate(event) {
    drawRef.current.deleteAll();
    onChange(event.features[0].geometry);
  }

  function handleDrawClick() {
    if (drawMode === 'draw_polygon') {
      drawRef.current.deleteAll();
      setDrawMode('simple_select');
      drawRef.current.changeMode('simple_select');
    } else if (value) {
      onChange(null);
    } else {
      setDrawMode('draw_polygon');
      drawRef.current.changeMode('draw_polygon');
    }
  }

  function handleModeChange(event) {
    setDrawMode(event.mode);
  }

  function handleFileUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onabort = () =>
      enqueueSnackbar('File reading was aborted', { variant: 'warning' });
    reader.onerror = () =>
      enqueueSnackbar('File reading has failed', { variant: 'error' });
    reader.onload = function (event) {
      const maxAllowedSize = 5 * 1024 * 1024;
      if (event.target.files?.[0].size > maxAllowedSize) {
        event.target.value = '';
        enqueueSnackbar('File size exceeds 5MB', { variant: 'error' });
        return;
      }

      const json = JSON.parse(event.target.result);
      const geometry = json.features?.[0]?.geometry ?? json.geometry ?? json;

      if (geometry.type === 'Polygon') {
        onChange(geometry);
      } else {
        enqueueSnackbar('Could not find geometry in file', {
          variant: 'error',
        });
      }
    };
    reader.readAsText(file);
  }

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{ position: 'relative', ...sx, borderColor: error && 'error.main' }}
    >
      <Map
        {...viewState}
        mapStyle={style}
        onMove={handleMove}
        transformRequest={transformRequest(style)}
        cooperativeGestures={true}
        cursor="auto"
        ref={mapRef}
        onLoad={handleLoad}
        onError={handleError}
        style={{ borderRadius: 3 }}
      >
        <ScaleControl />
        {error && (
          <FormHelperText error sx={{ position: 'absolute', top: 8, left: 64 }}>
            {error}
          </FormHelperText>
        )}
        <Stack sx={{ position: 'absolute', top: 8, left: 8 }}>
          <DrawControl
            ref={drawRef}
            displayControlsDefault={false}
            onCreate={handleCreate}
            onUpdate={handleCreate}
            onModeChange={handleModeChange}
          />
          <MapButton
            title="Zoom in"
            onClick={handleZoomInClick}
            disabled={
              mapRef.current &&
              mapRef.current.getZoom() === mapRef.current.getMaxZoom()
            }
          >
            <ZoomInIcon />
          </MapButton>
          <MapButton
            title="Zoom out"
            onClick={handleZoomOutClick}
            disabled={
              mapRef.current &&
              mapRef.current.getZoom() === mapRef.current.getMinZoom()
            }
          >
            <ZoomOutIcon />
          </MapButton>
          <MapButton title="Fit map" onClick={handleFitClick} disabled={!value}>
            <ZoomOutMapIcon />
          </MapButton>
          <MapButton title="Change map style" onClick={handleStyleClick}>
            <LayersIcon />
          </MapButton>
          <Menu
            anchorEl={styleMenuAnchorEl}
            open={Boolean(styleMenuAnchorEl)}
            onClose={handleStyleMenuClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuList
              disablePadding
              dense
              subheader={<ListSubheader disableSticky>Type</ListSubheader>}
            >
              {styles.map((layer) => (
                <MenuItem
                  key={layer.path}
                  onClick={handleStyleMenuClick(layer.path)}
                  selected={layer.path === style}
                >
                  {layer.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <MapButton
            title="Upload boundary"
            component="label"
            color={theme.palette.secondary.contrastText}
            bgcolor={theme.palette.secondary.main}
            disabled={disabled}
          >
            <UploadIcon />
            <input
              type="file"
              accept={['.geojson', '.json']}
              hidden
              onClick={(event) => (event.target.value = null)}
              onChange={handleFileUpload}
            />
          </MapButton>
          <MapButton
            title={
              drawMode === 'draw_polygon'
                ? 'Stop drawing'
                : value
                  ? 'Delete boundary'
                  : 'Draw boundary'
            }
            color={
              drawMode === 'draw_polygon'
                ? theme.palette.common.white
                : value
                  ? theme.palette.error.main
                  : theme.palette.common.white
            }
            bgcolor={
              drawMode === 'draw_polygon'
                ? theme.palette.error.main
                : value
                  ? null
                  : theme.palette.primary.main
            }
            onClick={handleDrawClick}
            disabled={!drawRef.current || disabled}
          >
            {drawMode === 'draw_polygon' ? (
              <StopIcon />
            ) : value ? (
              <DeleteIcon />
            ) : (
              <DrawIcon />
            )}
          </MapButton>
        </Stack>
        {value && (
          <Source id="boundary" type="geojson" data={value ?? null}>
            <Layer
              type="fill"
              paint={{
                'fill-color': theme.palette.secondary.main,
                'fill-opacity': 0.4,
                'fill-outline-color': theme.palette.secondary.dark,
              }}
            />
          </Source>
        )}
        <MapBranding style={style} />
      </Map>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
        }}
        open={!isLoaded}
      >
        <CircularProgress />
      </Backdrop>
    </Paper>
  );
}
