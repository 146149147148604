import {
  FETCH_AUDIT_LOG_ENTRIES,
  FETCH_AUDIT_LOG_ENTRIES_CANCELLED,
  FETCH_AUDIT_LOG_ENTRIES_FAILURE,
  FETCH_AUDIT_LOG_ENTRIES_SUCCESS,
  FETCH_PERSON_AVAILABILITY,
  FETCH_PERSON_AVAILABILITY_CANCELLED,
  FETCH_PERSON_AVAILABILITY_FAILURE,
  FETCH_PERSON_AVAILABILITY_SUCCESS,
  FETCH_VEHICLES_IN_LOCATIONS,
  FETCH_VEHICLES_IN_LOCATIONS_CANCELLED,
  FETCH_VEHICLES_IN_LOCATIONS_FAILURE,
  FETCH_VEHICLES_IN_LOCATIONS_SUCCESS,
  FETCH_VEHICLE_AVAILABILITY,
  FETCH_VEHICLE_AVAILABILITY_CANCELLED,
  FETCH_VEHICLE_AVAILABILITY_FAILURE,
  FETCH_VEHICLE_AVAILABILITY_SUCCESS,
  FETCH_VEHICLE_IN_BASE_TIME,
  FETCH_VEHICLE_IN_BASE_TIME_CANCELLED,
  FETCH_VEHICLE_IN_BASE_TIME_FAILURE,
  FETCH_VEHICLE_IN_BASE_TIME_SUCCESS,
  FETCH_VEHICLE_ODOMETERS,
  FETCH_VEHICLE_ODOMETERS_FAILURE,
  FETCH_VEHICLE_ODOMETERS_SUCCESS,
  FILTER_PERSON_AVAILABILITY,
  FILTER_VEHICLE_AVAILABILITY,
  LOAD_VEHICLES_IN_LOCATIONS,
  LOAD_VEHICLES_IN_LOCATIONS_FAILURE,
  LOAD_VEHICLES_IN_LOCATIONS_SUCCESS,
  LOAD_VEHICLE_IN_BASE_TIME,
  LOAD_VEHICLE_IN_BASE_TIME_FAILURE,
  LOAD_VEHICLE_IN_BASE_TIME_SUCCESS,
  UPDATE_VEHICLES_IN_LOCATIONS_PARAMETERS,
  UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY,
  UPDATE_VEHICLE_IN_BASE_TIME_PARAMETERS,
  UPDATE_VEHICLE_ODOMETERS_FILTER,
} from '@/actions';
import { rowsPerPageOptions } from '@/utils/config';
import { startOfDay } from 'date-fns';

const INITIAL_STATE = {
  vehicleInBaseTime: {
    data: [],
    filter: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      locationName: [],
      locationType: [],
      groups: {},
    },
    query: {},
    parameters: {},
    filterValues: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      locationName: [],
      locationType: [],
      groups: {},
    },
    isLoading: false,
    error: null,
  },
  vehiclesInLocations: {
    data: [],
    filter: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      locationName: [],
      locationType: [],
      groups: {},
    },
    parameters: {},
    query: {},
    filterValues: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      locationName: [],
      locationType: [],
      groups: {},
    },
    isLoading: false,
    error: null,
  },
  mileage: {
    data: [],
    isLoading: false,
    error: null,
    filter: {
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      date: startOfDay(new Date(), 'day'),
      fields: {
        registrationNumber: null,
        fleetNumber: null,
      },
    },
  },
  vehicleAvailability: {
    homeOnly: true,
    data: [],
    filteredData: [],
    filter: {
      location: [],
      locationType: [],
      grouping: [],
    },
    filterOptions: {
      location: [],
      locationType: [],
      grouping: [],
    },
    isLoading: false,
    error: null,
  },
  personAvailability: {
    data: [],
    filteredData: [],
    filter: {
      homeStation: [],
      grouping: [],
    },
    filterOptions: {
      homeStation: [],
      grouping: [],
    },
    isLoading: false,
    error: null,
  },
  outages: [],
  auditLogEntries: [],
  doubleCrews: [],
  driverTrips: [],
  isLoading: false,
  error: null,
};

export default function reportsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_VEHICLE_AVAILABILITY:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          filteredData: [],
          data: [],
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_AVAILABILITY_FAILURE:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          filteredData: [],
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_AVAILABILITY_CANCELLED:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          filteredData: [],
          data: [],
          isLoading: false,
          error: null,
        },
      };
    case FILTER_VEHICLE_AVAILABILITY:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          filter: action.payload.filter,
          filteredData: state.vehicleAvailability.data.filter((record) =>
            Object.keys(action.payload.filter).every(
              (key) =>
                action.payload.filter[key].length === 0 ||
                action.payload.filter[key].includes(record[key]),
            ),
          ),
          isLoading: false,
          error: null,
        },
      };
    case UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY:
      return {
        ...state,
        vehicleAvailability: {
          ...state.vehicleAvailability,
          homeOnly: action.payload,
        },
      };
    case FETCH_PERSON_AVAILABILITY:
      return {
        ...state,
        personAvailability: {
          ...state.personAvailability,
          filteredData: [],
          data: [],
          isLoading: true,
          error: null,
        },
      };
    case FETCH_PERSON_AVAILABILITY_SUCCESS:
      return {
        ...state,
        personAvailability: {
          ...state.personAvailability,
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_PERSON_AVAILABILITY_FAILURE:
      return {
        ...state,
        personAvailability: {
          ...state.personAvailability,
          filteredData: [],
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_PERSON_AVAILABILITY_CANCELLED:
      return {
        ...state,
        personAvailability: {
          ...state.personAvailability,
          filteredData: [],
          data: [],
          isLoading: false,
          error: null,
        },
      };
    case FILTER_PERSON_AVAILABILITY:
      return {
        ...state,
        personAvailability: {
          ...state.personAvailability,
          filter: action.payload.filter,
          filteredData: state.personAvailability.data.filter((record) =>
            Object.keys(action.payload.filter).every(
              (key) =>
                action.payload.filter[key].length === 0 ||
                action.payload.filter[key].includes(record[key]),
            ),
          ),
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_IN_BASE_TIME:
    case LOAD_VEHICLE_IN_BASE_TIME:
      return {
        ...state,
        vehicleInBaseTime: {
          ...state.vehicleInBaseTime,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLE_IN_BASE_TIME_SUCCESS:
    case LOAD_VEHICLE_IN_BASE_TIME_SUCCESS:
      return {
        ...state,
        vehicleInBaseTime: { ...action.payload, isLoading: false, error: null },
      };
    case FETCH_VEHICLE_IN_BASE_TIME_FAILURE:
    case LOAD_VEHICLE_IN_BASE_TIME_FAILURE:
      return {
        ...state,
        vehicleInBaseTime: {
          ...state.vehicleInBaseTime,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_IN_BASE_TIME_CANCELLED:
      return {
        ...state,
        vehicleInBaseTime: {
          ...state.vehicleInBaseTime,
          isLoading: false,
        },
      };
    case UPDATE_VEHICLE_IN_BASE_TIME_PARAMETERS: {
      return {
        ...state,
        vehicleInBaseTime: {
          ...state.vehicleInBaseTime,
          parameters: action.payload,
        },
      };
    }
    case FETCH_VEHICLES_IN_LOCATIONS:
    case LOAD_VEHICLES_IN_LOCATIONS:
      return {
        ...state,
        vehiclesInLocations: {
          ...state.vehiclesInLocations,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLES_IN_LOCATIONS_SUCCESS:
    case LOAD_VEHICLES_IN_LOCATIONS_SUCCESS:
      return {
        ...state,
        vehiclesInLocations: {
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLES_IN_LOCATIONS_FAILURE:
    case LOAD_VEHICLES_IN_LOCATIONS_FAILURE:
      return {
        ...state,
        vehiclesInLocations: {
          ...state.vehiclesInLocations,
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLES_IN_LOCATIONS_CANCELLED:
      return {
        ...state,
        vehiclesInLocations: {
          ...state.vehiclesInLocations,
          isLoading: false,
        },
      };
    case UPDATE_VEHICLES_IN_LOCATIONS_PARAMETERS: {
      return {
        ...state,
        vehiclesInLocations: {
          ...state.vehiclesInLocations,
          parameters: action.payload,
        },
      };
    }
    case FETCH_AUDIT_LOG_ENTRIES:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_AUDIT_LOG_ENTRIES_SUCCESS:
      return {
        ...state,
        auditLogEntries: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_AUDIT_LOG_ENTRIES_FAILURE:
      return {
        ...state,
        auditLogEntries: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_AUDIT_LOG_ENTRIES_CANCELLED:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_VEHICLE_ODOMETERS:
      return {
        ...state,
        mileage: {
          ...state.mileage,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLE_ODOMETERS_SUCCESS:
      return {
        ...state,
        mileage: {
          ...state.mileage,
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_ODOMETERS_FAILURE:
      return {
        ...state,
        mileage: {
          ...state.mileage,
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    case UPDATE_VEHICLE_ODOMETERS_FILTER:
      return {
        ...state,
        mileage: {
          ...state.mileage,
          filter: action.payload,
        },
      };
    default:
      return state;
  }
}
