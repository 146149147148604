export const {
  wsRootUrl = 'ws://localhost:3030/socket',
  apiRootUrl = 'http://localhost:3030',
  apiPath = '/api',
  authenticationScheme = 'oauth2',
  authenticationRootUrl = 'https://login.microsoftonline.com',
  tenantId = 'dccbcc32-f7c8-4317-9ca2-eff8cca82344',
  clientId = 'ae4bb8f7-7432-48d2-9672-077650223cf7',
  resource = 'ae4bb8f7-7432-48d2-9672-077650223cf7',
  mapExtent = [-10.85, 49.82, 2.02, 59.48],
  showEstimatedTimeOfArrival = true,
  etaRequestDelay = 60,
  simplificationTolerance = 0.001,
  useDallasKeys = false,
  autoLogin = false,
  occurrenceNumberOnObjectives = true,
  maxUploadSize = 5242880,
  isFleet = false,
  showTranmanIdentifier = true,
  useRfidCardCategory = true,
  useRestricted = true,
  requireAdLogin = false,
  showAdGroups = true,
  sentry = {
    enable: false,
    dsn: 'https://7ea97d5c00f2051c2d920faf4f8cb7bf@o4507122649989120.ingest.de.sentry.io/4507122655821904',
    tracesSampleRate: 1,
  },
  defaultRouteLeavingHeader = 'Leave without saving?',
  defaultRouteLeavingText = 'Unsaved changes will be lost. Are you sure you want to leave without saving?',
  promptText = 'This is a one time prompt that the user accepts. It can be GDPR related. Once the user accepts then it will not be shown again',
  liveCanEditViewsGroup,
  liveCanEditViewsGroups = [
    'IR3 Everything',
    'Test Engineers',
    'Test Admins',
    'Test Users',
    'Test FCC Users',
    'Test Fleet Analysts',
    'Test Supervisors',
  ],
  liveCanEditGlobalViewsGroup,
  liveCanEditGlobalViewsGroups = [
    'IR3 Everything',
    'Test Admins',
    'Test Supervisors',
  ],
  allowDriverChange = true,
  rowsPerPageOptions = [10, 25, 50],
  lastContactDefaultDays = 14,
  dioStates = {
    headlightsFlashOn: 'Headlights Flash',
    sirensOn: 'Sirens',
    beaconsOn: 'Beacons PWE/Spare',
    unused4: '(4)',
    unused5: '(5)',
    unused6: '(6)',
  },
  dioOptions = {
    showDriverId: true,
    showTracking: false,
    hideLonLat: true,
    hideMap: false,
    manualPolls: true,
  },
  emergencyEquipmentEventTypes = ['BEACONS', 'SIRENS', 'AMBERLIGHTS'],
  vehicleEmergencyEquipment = [
    'amberLightsOn',
    'beaconsOn',
    'sirensOn',
    'rearRedLightsOn',
    'rearBlueLightsOn',
  ],
  speedRuleLabels = {
    '10PCTPLUS2': '10% + 2',
    LIGHTSONPLUS25: 'Lights on + 25',
    LIGHTSON30INA20: 'Lights on 30 in a 20',
    LIGHTSONDOUBLE: 'Lights on double',
    LIGHTSONEXCESSIVE100: 'Lights on > 100',
    LIGHTSONEXCESSIVE120: 'Lights on > 120',
    EXCESS: '> 80',
  },
  minimumSpeedInfractionSeconds = 45,
  minimumDoubleCrewSeconds = 45,
  baseType = { label: 'Base', value: 'base' },
  commonRegEx = {
    urlInvalidCharactersRegEx: /[\^%?/\\]+/,
    noSpecialCharsRegEx: /^[a-z0-9 ]*$/i,
    noSpecialOrSpaceCharsRegEx: /^[a-z0-9]*$/i,
  },
  tagForm = {
    identifier: {
      editable: false,
      componentProps: {
        maxLength: 50,
        validatorRegEx: 'noSpecialCharsRegEx',
      },
    },
  },
  vehicleForm = {
    identificationNumber: {
      editable: false,
      componentProps: {
        maxLength: 30,
        validatorRegEx: 'noSpecialOrSpaceCharsRegEx',
        upperCase: true,
      },
    },
    registrationNumber: {
      editable: true,
      componentProps: {
        maxLength: 20,
        validatorRegEx: 'noSpecialOrSpaceCharsRegEx',
        upperCase: true,
      },
    },
    fleetNumber: { editable: true, componentProps: { maxLength: 50 } },
    role: { editable: true },
    type: { editable: true },
    make: { editable: true },
    model: { editable: true },
    colour: { editable: true },
    fuelType: { editable: true },
    marked: { editable: true },
    homeStation: { editable: true },
    equipment: { editable: true },
    telematicsBoxImei: { editable: true },
    lastOdometerReading: { editable: true },
    disposalDate: { editable: true },
    picture: { editable: true },
    visibleTo: { editable: true },
    groups: { editable: true },
    groupCodes: { editable: true },
    callSign: { editable: true },
    installLocation: { editable: true },
    driverIdLocation: { editable: true },
    commissionDate: { editable: true },
    notes: { editable: true },
    keyNumber: { editable: true },
  },
  personForm = {
    code: {
      editable: false,
      componentProps: {
        maxLength: 30,
        validatorRegEx: 'noSpecialCharsRegEx',
      },
    },
    forenames: { editable: true, componentProps: { maxLength: 50 } },
    surname: { editable: true, componentProps: { maxLength: 50 } },
    collarNumber: {
      editable: true,
      componentProps: {
        maxLength: 30,
        validatorRegEx: 'noSpecialCharsRegEx',
        upperCase: true,
      },
    },
    rank: { editable: true },
    role: { editable: true },
    emailAddress: { editable: true },
    mobileNumber: { editable: true },
    supervisorCode: { editable: true },
    homeStation: { editable: true },
    wards: { editable: true },
    skills: { editable: true },
    radioSsi: { editable: true },
    rfidCards: { editable: true },
    picture: { editable: true },
    visibleTo: { editable: true },
    leavingDate: { editable: true },
    groups: { editable: true },
    groupCodes: { editable: true },
    callSign: { editable: true },
  },
  locationForm = {
    code: {
      editable: false,
      componentProps: {
        maxLength: 30,
        validatorRegEx: 'noSpecialCharsRegEx',
      },
    },
    name: { editable: true, componentProps: { maxLength: 50 } },
    tranmanIdentifier: { editable: true },
    subtype: { editable: true },
    district: { editable: true },
    startTime: { editable: true },
    endTime: { editable: true },
    boundary: { editable: true },
    picture: { editable: true },
    groups: { editable: true },
    groupCodes: { editable: true },
  },
  objectiveForm = {
    identifier: {
      editable: false,
      componentProps: {
        maxLength: 30,
        validatorRegEx: 'noSpecialOrSpaceCharsRegEx',
      },
    },
    title: { editable: true, componentProps: { maxLength: 50 } },
    occurenceNumber: { editable: true },
    description: { editable: true },
    startTime: { editable: false },
    endTime: { editable: true },
    schedule: { editable: false },
    requiredVisits: { editable: false },
    requiredFrequency: { editable: false },
    complianceSeconds: { editable: false },
    visibleTo: { editable: true },
    applicableTo: { editable: false },
    boundary: { editable: false },
    resourceType: { editable: false },
  },
  liveOptions = {
    mapLayerToggleOnList: false,
    mapClusterDistance: 2,
    mapFollowBorder: true,
    mapFollowBorderSize: 1,
    mapFollowBorderSuperSize: false,
    mapFollowOverridesOutlineAndGlyph: false,
    mapFollowLabel: true,
    mapFocusLabel: true,
    vehicleFeedThreshold: 5,
    personFeedThreshold: 15,
    stalePersonThreshold: 60,
    staleVehicleIgnitionOnThreshold: 60,
    staleVehicleIgnitionOffThreshold: 10080,
    showFeedStatus: false,
    incidentLookbackMinutes: 525600,
    incidentLookbackExemptGrades: [1, 2],
    radioLookbackMinutes: 120,
    accelAlertLookbackMinutes: 120,
    dummyMovingVehicle: 10,
    offDutyNames: ['REST DAY', 'OFF DUTY'],
    disableRotatedIconsOnMobile: false,
    overriddenFilterLabels: { bcu: 'BCU' },
    vehiclesHaveCallsigns: false,
    showCallsignsOnLegend: false,
    excludedFilters: {
      vehicles: [
        'colour',
        'type',
        'callSign',
        'incident',
        'equipmentArray',
        'fleetNumber',
        'callSign',
        'incident',
        'status',
      ],
      locations: ['subtype'],
      people: [
        'code',
        'driverKeys',
        'reducedAreas.sector',
        'reducedAreas.operation',
      ],
      incidents: ['category', 'closingCodes', 'group', 'type'],
    },
    includedFilters: {},
    excludedSorts: {},
    includedSorts: {},
    callSignStatusCategory: {
      'At Court': 'Unavailable',
      Assigned: 'En Route To Incident',
      'At Scene': 'At Scene',
      Available: 'Available',
      Committed: 'Available',
      'De-Assigned': 'Available',
      Emergency: 'Emergency',
      'En Route To Incident': 'En Route To Incident',
      'In Custody': 'Unavailable',
      'In Office': 'Available',
      'Not Available': 'Unavailable',
      'Not Deployable': 'Unavailable',
      'Off Duty': 'Unavailable',
      'On Duty': 'Available',
      'Prisoner Escort': 'Unavailable',
      Refreshments: 'Unavailable',
      'Restricted Duty': 'Unavailable',
      'Resuming Patrol': 'Available',
      Unknown: 'Unclassified',
    },
    statusColours: {
      vehicles: {
        default: ['rgb(255,255,255)', 'rgb(0,0,0)', 'rgb(255,255,255)'],
      },
      callSigns: {
        'En Route To Incident': [
          'rgb(255,255,255)',
          'rgb(244,67,54)',
          'rgb(255,255,255)',
        ],
        Assigned: ['rgb(255,255,255)', 'rgba(255,152,0)', 'rgb(255,255,255)'],
        'At Scene': [
          'rgb(255,255,255)',
          'rgb(72, 176, 86)',
          'rgb(255,255,255)',
        ],
        Available: ['rgb(255,255,255)', 'rgb(0,0,0)', 'rgb(255,255,255)'],
        Unclassified: [
          'rgb(255,255,255)',
          'rgba(158,158,158)',
          'rgb(255,255,255)',
        ],
        Unavailable: [
          'rgba(255,255,255,1.0)',
          'rgba(156,39,176,1.0)',
          'rgba(255,255,255,1.0)',
        ],
      },
    },
    showSortFieldInListItems: true,
    showOverriddenLabelInListItems: true,
    labelOverridePaths: { vehicles: 'assignments.callSign.code' },
    leftOfFirstLineOfListItemsPaths: {},
    rightOfFirstLineOfListItemsPaths: {},
    leftOfSecondLineOfListItemsPaths: { callSigns: 'names' },
    rightOfSecondLineOfListItemsPaths: {
      people: 'assignments.callSign.code',
    },
    filterOptionsOverrides: {},
    mapPolygonIconsByTypeAndSubtype: {
      incident: 4,
      event: 3,
      location: 8,
      objective: 6,
    },
    incidentDetailGroupByCallsign: false,
  },
  driverBehaviourOptions = {
    goodTripScoreThreshold: 95,
    okTripScoreThreshold: 85,
  },
  odometerErrorExplanations = {
    R01: {
      short: 'Never received',
      long: 'There is no telematics box record for the linked IMEI. This occurs when the live IR3 system has never received a poll from that IMEI',
    },
    R02: {
      short: 'No timely poll',
      long: 'The next poll after the last odometer reading must be close in time to limit any untracked mileage which would throw the total out',
    },
    R03: {
      short: 'No previous poll',
      long: 'No poll found before odometer report date',
    },
    R04: {
      short: 'Latest poll before reading',
      long: 'The most recent poll for the box is before the poll found for the last odometer reading. This would typically happen when running the report in the past',
    },
  },
  retrospective = {
    estimation: { sampleSize: 100, sampleDays: 1, clusterConstant: 3 },
    limits: {
      default: {
        items: 3000000,
        data: 50000000,
        mapFeaturesSize: 100000,
        clusters: 150000,
      },
    },
    virtualizeAt: { mapFeaturesSize: 20000 },
    visitLocationTypes: ['Base', 'Ward', 'Workshop'],
    boundaryAreaOverlapThreshold: 0.05,
  },
  events = {
    showTripOccupants: true,
    tripOccupantsMinKilometresThreshold: 0.2,
    tripOccupantsMinPercentageThreshold: 10,
    itemDownloadLimit: 100000,
    eventFilters: {
      trips: [
        {
          name: 'classification',
          label: 'Classification',
          type: 'select',
          multiple: true,
          options: [
            { label: 'Business', value: 'Business' },
            { label: 'Personal', value: 'Personal' },
            { label: 'Commute', value: 'Commute' },
          ],
        },
        {
          name: 'distanceKilometres',
          label: 'Distance',
          type: 'number',
          modifiers: [
            { label: 'm', value: 1.60934, title: 'Mile(s)' },
            { label: 'km', value: 1, title: 'Kilometre(s)' },
          ],
        },
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
        {
          name: 'maxSpeedKilometresPerHour',
          label: 'Max Speed',
          type: 'number',
          modifiers: [
            { label: 'mph', value: 1.60934, title: 'Mile(s) per hour' },
            { label: 'km/h', value: 1, title: 'Kilometre(s) per hour' },
          ],
        },
        {
          name: 'equipmentActivations.emergencyOn',
          label: 'Warning Equipment Used',
          type: 'select',
          multiple: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        {
          name: 'hasSpeedInfractions',
          label: 'Has Speed Infractions',
          type: 'select',
          multiple: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
      ],
      stops: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      idles: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      speedInfractions: [
        {
          name: 'distanceKilometres',
          label: 'Distance',
          type: 'number',
          modifiers: [
            { label: 'm', value: 1.60934, title: 'Mile(s)' },
            { label: 'km', value: 1, title: 'Kilometre(s)' },
          ],
        },
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
        {
          name: 'maxSpeedKilometresPerHour',
          label: 'Max Speed',
          type: 'number',
          modifiers: [
            { label: 'mph', value: 1.60934, title: 'Mile(s) per hour' },
            { label: 'km/h', value: 1, title: 'Kilometre(s) per hour' },
          ],
        },
      ],
      accelerometerEvents: [
        {
          name: 'maximumForces.absolute',
          label: 'Max Force',
          type: 'number',
          unit: { label: 'G', title: 'Gravity' },
        },
      ],
      vehicleLocationVisits: [
        {
          name: 'distanceKilometres',
          label: 'Distance',
          type: 'number',
          modifiers: [
            { label: 'm', value: 1.60934, title: 'Mile(s)' },
            { label: 'km', value: 1, title: 'Kilometre(s)' },
          ],
        },
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
        {
          name: 'maxSpeedKilometresPerHour',
          label: 'Max Speed',
          type: 'number',
          modifiers: [
            { label: 'mph', value: 1.60934, title: 'Mile(s) per hour' },
            { label: 'km/h', value: 1, title: 'Kilometre(s) per hour' },
          ],
        },
      ],
      personTrails: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      personLocationVisits: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      attendances: [
        {
          name: 'compliant',
          label: 'Compliant',
          type: 'select',
          multiple: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      vehicleEquipmentActivations: [
        {
          label: 'Equipment Type',
          name: 'eventSubtype',
          type: 'select',
          multiple: true,
          options: [
            { label: 'Beacons', value: 'BEACONSON' },
            { label: 'Headlights Flash', value: 'HEADLIGHTSFLASHON' },
            { label: 'Sirens', value: 'SIRENSON' },
          ],
        },
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      personDoubleCrews: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      incidentResponses: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
        {
          name: 'responseSeconds',
          label: 'Response Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
        {
          name: 'attended',
          label: 'Attended',
          type: 'select',
          multiple: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        {
          name: 'attendedSeconds',
          label: 'Attended Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
    },
  },
  mapLayers = [
    {
      title: 'Ordnance Survey Road',
      visible: true,
      sourceType: 'xyz',
      sourceOptions: {
        url: 'https://api.os.uk/maps/raster/v1/zxy/Road_3857/{z}/{x}/{y}.png?key=ATYMknYTBGj8kBTErHmHIuQ5mYMAU5tu',
      },
    },
    {
      title: 'Ordnance Survey Light',
      visible: false,
      sourceType: 'xyz',
      sourceOptions: {
        url: 'https://api.os.uk/maps/raster/v1/zxy/Light_3857/{z}/{x}/{y}.png?key=ATYMknYTBGj8kBTErHmHIuQ5mYMAU5tu',
      },
    },
    {
      title: 'Ordnance Survey Outdoor',
      visible: false,
      sourceType: 'xyz',
      sourceOptions: {
        url: 'https://api.os.uk/maps/raster/v1/zxy/Outdoor_3857/{z}/{x}/{y}.png?key=ATYMknYTBGj8kBTErHmHIuQ5mYMAU5tu',
      },
    },
    {
      title: 'Bing Road',
      visible: false,
      preload: null,
      sourceType: 'bing',
      sourceOptions: {
        key: 'AvVDNJYVo_13bnhapK-XtzCe9jL6vDG-G9EzGG9raMxWnToU2qQ26rKGz4SlAa2m',
        imagerySet: 'RoadOnDemand',
      },
    },
    {
      title: 'Bing Aerial (with labels)',
      visible: false,
      preload: null,
      sourceType: 'bing',
      sourceOptions: {
        key: 'AvVDNJYVo_13bnhapK-XtzCe9jL6vDG-G9EzGG9raMxWnToU2qQ26rKGz4SlAa2m',
        imagerySet: 'AerialWithLabelsOnDemand',
      },
    },
    {
      title: 'MapTiler Basic',
      visible: false,
      sourceType: 'tileJSON',
      sourceOptions: {
        url: 'https://api.maptiler.com/maps/basic/256/tiles.json?key=9m7RiHJttyiigsADWaEe',
        crossOrigin: 'anonymous',
      },
    },
    {
      title: 'MapTiler Hybrid',
      visible: false,
      sourceType: 'tileJSON',
      sourceOptions: {
        url: 'https://api.maptiler.com/maps/hybrid/256/tiles.json?key=9m7RiHJttyiigsADWaEe',
        crossOrigin: 'anonymous',
      },
    },
    {
      title: 'ESRI World Street Map',
      visible: false,
      sourceType: 'xyz',
      sourceOptions: {
        url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
        attributions: [
          'Tiles © <a href="https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer">ArcGIS</a>',
        ],
        attributionsCollapsible: false,
      },
    },
    {
      title: 'OpenStreetMap',
      visible: false,
      sourceType: 'xyz',
      sourceOptions: {
        url: 'https://osm.ir3.co.uk/osm/{z}/{x}/{y}.png',
        attributions: [
          '<a href="www.openstreetmap.org/copyright www.openstreetmap.org/copyright">© OpenStreetMap</a>',
        ],
        attributionsCollapsible: false,
      },
    },
    {
      title: 'Airbus Satellite',
      visible: false,
      sourceType: 'wmts',
      projection: 'EPSG:27700',
      sourceOptions: {
        url: 'https://ogc.apps.midgard.airbusds-cint.com/apgb/wmts?GUID=cf7e2094-7545-4977-b253-6007969c4f20&',
        layer: 'apgb:AP-25CM-GB-LATEST',
        matrixSet: 'BNG2',
        format: 'image/png',
        style: '',
        wrapX: false,
      },
      tileGridOptions: {
        origins: [
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
        ],
        resolutions: [
          2048, 1024, 512, 256, 100, 50, 25, 10, 5, 2.5, 1, 0.5, 0.25,
        ],
        matrixIds: [
          'BNG1:0',
          'BNG1:1',
          'BNG1:2',
          'BNG1:3',
          'BNG1:4',
          'BNG1:5',
          'BNG1:6',
          'BNG1:7',
          'BNG1:8',
          'BNG1:9',
          'BNG1:10',
          'BNG1:11',
          'BNG1:12',
        ],
      },
    },
  ],
  mapView = { center: [0, 0], zoom: 2, minZoom: 7, maxZoom: 20 },
  minZoom = 6,
  maxZoom = 18,
  maxBounds = [-10.76418, 49.528423, 1.9134116, 61.331151],
  ordnanceSurveyKey = 'ATYMknYTBGj8kBTErHmHIuQ5mYMAU5tu',
  styles = [
    {
      label: 'Ordnance Survey: Road',
      path: 'https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Road.json',
      source: 'ORDNANCE_SURVEY',
    },
    {
      label: 'Ordnance Survey: Greyscale',
      path: 'https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Greyscale.json',
      source: 'ORDNANCE_SURVEY',
    },
    {
      label: 'Ordnance Survey: Light',
      path: 'https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Light.json',
      source: 'ORDNANCE_SURVEY',
    },
    {
      label: 'Ordnance Survey: Dark',
      path: 'https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Dark.json',
      source: 'ORDNANCE_SURVEY',
    },
    {
      label: 'Protomaps',
      path: '/map-styles/protomaps.json',
      source: 'PROTOMAPS',
    },
    {
      label: 'Protomaps Dark',
      path: '/map-styles/protomaps-dark.json',
      source: 'PROTOMAPS',
    },
    {
      label: 'Protomaps White',
      path: '/map-styles/protomaps-white.json',
      source: 'PROTOMAPS',
    },
    {
      label: 'Protomaps Greyscale',
      path: '/map-styles/protomaps-greyscale.json',
      source: 'PROTOMAPS',
    },
    {
      label: 'Protomaps Sunny',
      path: '/map-styles/protomaps-sunny.json',
      source: 'PROTOMAPS',
    },
    {
      label: 'MapTiler',
      path: '/map-styles/maptiler.json',
      source: 'MAPTILER',
    },
    {
      label: 'OpenStreetMap',
      path: '/map-styles/openstreetmap.json',
      source: 'OPENSTREETMAP',
    },
  ],
  areaTypeGroupOptions = {
    Ward: [
      { label: 'Sector', value: 'SECTOR' },
      { label: 'BCU', value: 'BASIC_COMMAND_UNIT' },
    ],
  },
  hiddenRoutes = [],
  highlights = {
    vehicleExcessiveSummariesChart: {
      ignitionOnPeriodDays: { default: 28, min: 1, max: 50 },
      excessPercentageThreshold: 50,
    },
    objectiveCompliance: {
      lookbackDays: 28,
      defaultResourceType: 'people',
      defaultMaxCompliancePercent: 100,
    },
    averageInStationTime: { highlightSize: 5, lookbackDays: 28 },
    subgroupActivityAverages: { lookbackDays: 28 },
    totalIncidents: { lookbackDays: 28 },
    responseTimeByHour: { lookbackDays: 28 },
    totalAttendedIncidents: { lookbackDays: 28 },
  },
  announcement,
  maintenance,
  nominatimRootUrl = 'https://nominatim.ir3.dev',
  eventNotifications = {},
  disableNotifications = false,
  showClearCacheButton = false,
  groupTypeAliases = {},
  offsetHours = 7,
  showLegacyAudit = false,
  temp_locationTypeCodes = [
    'Police Station',
    'Base',
    'Workshop',
    'Hospital',
    'Court',
    'School',
    'Ward',
    'Local Authority',
    'BCU',
    'Force Boundary',
  ],
} = window.config ?? {};
