import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useGroupDescendants() {
  return useQuery({
    queryKey: ['groupDescendants'],
    queryFn: async () => {
      const json = [
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$code',
            connectFromField: 'code',
            connectToField: 'parentCodes',
            as: 'decendentCodes',
          },
        },
        {
          $project: {
            _id: false,
            code: true,
            decendentCodes: {
              $map: {
                input: '$decendentCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
          },
        },
      ];

      const groups = await api.post('pipeline/groups', { json }).json();

      log('Read', 'Groups');

      return groups.reduce((acc, group) => {
        acc[group.code] = [group.code, ...group.decendentCodes];

        return acc;
      }, {});
    },
    placeholderData: (previousData) => previousData ?? {},
    staleTime: 1000 * 60 * 60 * 12,
  });
}
