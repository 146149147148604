import { useDocumentTitle } from '@/hooks';
import { Stack } from '@mui/material';
import { OptionsTable } from './OptionsTable';

export function RadioOptions() {
  useDocumentTitle('IR3 | Radio Options');

  return (
    <Stack direction="row" spacing={1} sx={{ p: 1 }}>
      <OptionsTable name="radioType" title="Types" />
    </Stack>
  );
}
