import { Divider, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';

export function DetailsPanel({ title, entries, rightHeader }) {
  return (
    <Fragment>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {title && (
          <Typography variant="caption" fontWeight={500}>
            {title}
          </Typography>
        )}
        {rightHeader}
      </Stack>
      <Stack
        spacing={0.5}
        sx={{
          px: 1,
          py: 0.5,
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
        }}
      >
        {entries.map(({ label, value }, index) => (
          <Fragment key={index}>
            {index > 0 && <Divider />}
            <Stack
              key={label}
              spacing={1}
              direction="row"
              justifyContent="space-between"
            >
              <Typography noWrap variant="caption" color="text.secondary">
                {label}
              </Typography>
              <Typography noWrap variant="caption">
                {value}
              </Typography>
            </Stack>
          </Fragment>
        ))}
      </Stack>
    </Fragment>
  );
}
