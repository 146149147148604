import { createStore, del, get, set } from 'idb-keyval';

export function createIDBPersister(idbValidKey = 'react-query') {
  const store = createStore('ir3-store', 'keyval');

  return {
    persistClient: async (client) => {
      await set(idbValidKey, client, store);
    },
    restoreClient: async () => {
      return await get(idbValidKey, store);
    },
    removeClient: async () => {
      await del(idbValidKey, store);
    },
  };
}
