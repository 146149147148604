import {
  DELETE_LIVE_ESTIMATED_TIME_OF_ARRIVAL,
  FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL,
  UPDATE_LIVE_FILTER_OVERRIDE,
  UPDATE_LIVE_FOLLOW_OVERRIDE,
} from '@/actions';
import { TagControl } from '@/components/controls';
import { useDocumentTitle, usePrevious } from '@/hooks';
import {
  etaRequestDelay,
  liveOptions,
  showEstimatedTimeOfArrival,
} from '@/utils/config';
import { incidentStatusColours, liveFilters } from '@/utils/constants';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  ExpandLess,
  ExpandMore,
  FilterCenterFocus as FocusIcon,
  GpsFixed as FollowAllIcon,
  LocationSearching as FollowIcon,
  PinDrop as PinDropIcon,
  UnfoldLess,
  UnfoldMore,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  Tooltip,
  // Badge,
  Typography,
} from '@mui/material';
import { addSeconds, format, isAfter, subSeconds } from 'date-fns';
import { dequal } from 'dequal';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CallSignLiveListItem } from './CallSignLiveListItem';
import { ItemAvatar } from './ItemAvatar';
import { ItemRows } from './ItemRows';
import { PeopleLiveListItem } from './PeopleLiveListItem';
import { VehicleLiveListItem } from './VehicleLiveListItem';
import { incidentTitle, useLazyLoadIncident } from './incidentUtility';

const { incidentDetailGroupByCallsign } = liveOptions;

// example item
// $type: "add"
// description: "Domestic Violence"
// grade: 5
// number: "DEV-111111-1111"
// openedTime: "2020-01-01T00:00:00.000Z"
// point:
// $reql_type$: "GEOMETRY"
// coordinates: (2) [-0.0858705, 51255501]
// type: "Point"
// responseCategory:
//  code: "5"
//  name: "Grade 5"
// searchString: "domestic violence+5+dev-111111-1111+2020-01-01t00:00:00.000z+[object object]+[object object]+opened+test1+[object object]+add+true"
// status: "opened"
// tagChanged: true
// tags: Array(1)
// 0: "test1"
// length: 1
// type:
//  code: "Test"
//  name: "Test/Training"

function FindAssignedResources(incidentNumber, resources) {
  return Object.keys(resources)
    .map((key) => {
      const resource = resources[key];
      return (resource?.assignments?.incident?.number ||
        resource?.incident?.number) === incidentNumber
        ? {
            ...resource,
            id: key,
          }
        : null;
    })
    .filter(Boolean);
}

function CreateFilterOverride(
  incidentNumber,
  assignedVehicles,
  assignedPeoples,
) {
  // a true dictionary allows faster lookup than an array of ids, and quicker
  // to deep compare than a dictionary of objects
  function toTrueDictionary(idArray) {
    return Object.fromEntries(idArray.map((resource) => [resource.id, true]));
  }
  const assignedVehiclesDict = toTrueDictionary(assignedVehicles);
  const assignedPeopleDict = toTrueDictionary(assignedPeoples);

  return {
    // make every type filtered out
    ...Object.fromEntries(Object.keys(liveFilters).map((k) => [k, {}])),
    // except for assigned vehicles & people and the incident
    vehicles: assignedVehiclesDict,
    people: assignedPeopleDict,
    incidents: { [incidentNumber]: true },
    // and set the owner of the override (when we nav away etc.)
    owner: incidentNumber,
  };
}

// if the address not available then do a reverse geo code look up for approximate address
function getAddress(incident) {
  if (incident.address) {
    return [{ label: 'Address', value: incident.address }];
  }

  if (!incident.address && incident.reverseGeocode) {
    const { road, postCode } = incident.reverseGeocode;
    return [{ label: 'Approximate Address', value: `${road}, ${postCode}` }];
  }

  return [];
}

export function IncidentLiveItem({
  item,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  onFollowBulk,
  followedIdsByType,
  hoveredId,
}) {
  const incident = useLazyLoadIncident(item.id);
  const hideFollow = !incident.point;
  const rowItems = [
    //   { label: 'Type', value: incident.type && incident.type.name },
    //   { label: 'Status', value: incident.status },
    //{ label: 'Address', value: incident.address },
    ...getAddress(incident),
    { label: 'Description', value: incident.description },
    //   { label: 'Tags', value: incident.tags.join(',') },
    //   ...areasToLabelAccessors(incident.areas)
    { label: 'Response Category', value: incident.responseCategory?.name },
    ...(incident.locations || []).map((location) => ({
      label: location.type,
      value: location.name,
    })),
  ];
  useDocumentTitle(
    ['IR3', 'Live', 'Incidents', incident?.number].filter(Boolean).join(' | '),
  );

  // const gradeBackground = ['rgba(255,0,0,1.0)', 'rgba(255,0,0,1.0)', 'rgba(255,204,0,1.0)', 'rgba(0,255,0,1.0)', 'rgba(0,0,255,1.0)'][
  //   Math.max(incident.grade, 5) - 1
  // ];
  const {
    openedTime,
    assignedTime,
    attendedTime,
    closedTime,
    // responseCategory,
    // grade,
    closingCodes,
  } = incident;

  const type = 'incidents';
  const following = followedIdsByType?.[type]?.[incident.id];

  // find assigned resources
  const vehicles = useSelector((state) => state.live.vehicles);
  const people = useSelector((state) => state.live.people);
  const callSigns = useSelector((state) => state.live.callSigns);

  // get the eta for assigned vehicles as sorted quickest first
  const estimatedTimeOfArrivals = useSelector(
    (state) => state.live.estimatedTimeOfArrivals,
  ).sort((a, b) => a.time - b.time);

  const [expandedCallSigns, setExpandedCallSigns] = useState({});
  const [nextEtaRequestTime, setNextEtaRequestTime] = useState(
    subSeconds(new Date(), 1),
  );

  const totalResources =
    Object.keys(vehicles).length +
    Object.keys(people).length +
    Object.keys(callSigns).length;
  const prevTotalResources = usePrevious(totalResources);

  const dispatch = useDispatch();

  // have to do this every time in case a vehicle/person assignment changes
  const assignedVehicles = FindAssignedResources(incident.id, vehicles);
  const assignedPeople = FindAssignedResources(incident.id, people);
  const assignedCallSigns = FindAssignedResources(incident.id, callSigns);

  if (showEstimatedTimeOfArrival) {
    assignedVehicles.forEach((vehicle) => {
      const etaForVehicle = estimatedTimeOfArrivals.find(
        (eta) => eta?.vin === vehicle.identificationNumber,
      );

      if (etaForVehicle) {
        const { eta, time } = etaForVehicle;
        vehicle.eta = eta;
        vehicle.etaLabel = eta ? `ETA: ${eta}` : undefined;
        vehicle.time = time;
      }
    });

    const M = Number.MAX_VALUE;
    assignedVehicles.sort((a, b) => (a.time ?? M) - (b.time ?? M)); // sort as quickest first

    // Add the minimum ETA for this incident
    if (assignedVehicles[0]) {
      rowItems.push({
        label: 'Estimated Time of First Arrival',
        value: assignedVehicles[0]?.eta ?? '-',
      });
    }
  }

  let assignedCallSignsWithResources = {};
  if (incidentDetailGroupByCallsign) {
    assignedCallSigns.forEach((callSign) => {
      const inCallSign = (r) => r.assignments?.callSign?.code === callSign.id;
      const assignedVehiclesForCallSign = assignedVehicles.filter(inCallSign);

      assignedCallSignsWithResources[callSign.id] = {
        callSign: {
          ...callSign,
          etaLabel: assignedVehiclesForCallSign[0]?.etaLabel,
        }, // include minimum(quickest) of ETA from all vehicles
        people: assignedPeople.filter(inCallSign),
        vehicles: assignedVehiclesForCallSign,
      };
    });
  }

  // if they don't want to group by callsign, list individuals as if no callsign
  const noCallSign = (r) =>
    !incidentDetailGroupByCallsign || !r.assignments?.callSign?.code;
  const assignedVehiclesWithoutCallSign = assignedVehicles.filter(noCallSign);
  const assignedPeopleWithoutCallSign = assignedPeople.filter(noCallSign);

  const allResourcesFollowed =
    assignedVehicles.every((v) => followedIdsByType['vehicles']?.[v.id]) &&
    assignedPeople.every((p) => followedIdsByType['people']?.[p.id]);

  const override = CreateFilterOverride(
    incident.id,
    assignedVehicles,
    assignedPeople,
  );

  const prevOverride = usePrevious(override);

  const filterOverride = useSelector(
    (state) => state.live.filteredInIdsByTypeOverride,
  );
  const followOverride = useSelector(
    (state) => state.live.followedIdsByTypeOverride,
  );

  // if the incident overrides the follow or filter it'll be the owner
  const incidentOverridesFollow = followOverride?.owner === incident.id;
  const incidentOverridesFilter = filterOverride?.owner === incident.id;

  const handleFilterToggle = () => {
    dispatch({
      type: UPDATE_LIVE_FILTER_OVERRIDE,
      payload: incidentOverridesFilter ? null : override,
    });
  };

  const handleFollowToggle = () => {
    onFollowToggle(type, incident.id);
  };

  const handleFollowAllToggle = () => {
    const newFollow = !allResourcesFollowed;
    function toIdDict(itemArray) {
      const idDict = {};
      itemArray.forEach((item) => (idDict[item.id] = true));
      return idDict;
    }

    const items = {
      vehicles: toIdDict(assignedVehicles),
      people: toIdDict(assignedPeople),
    };
    onFollowBulk(items, newFollow);
    // assignedVehicles.forEach(v => onFollowToggle('vehicles', v.id, newFollow));
    // assignedPeople.forEach(p => onFollowToggle('people', p.id, newFollow));
  };

  // if the assigned resources changes dispatch a new override (if overriding)
  useEffect(() => {
    const overrideChanged = !dequal(override, prevOverride);
    const numResourcesChanged = totalResources !== prevTotalResources;

    if (overrideChanged || numResourcesChanged) {
      if (incidentOverridesFollow) {
        dispatch({
          type: UPDATE_LIVE_FOLLOW_OVERRIDE,
          payload: override,
        });
      }

      if (incidentOverridesFilter) {
        dispatch({
          type: UPDATE_LIVE_FILTER_OVERRIDE,
          payload: override,
        });
      }
    }
  }, [
    dispatch,
    incidentOverridesFilter,
    incidentOverridesFollow,
    override,
    prevOverride,
    prevTotalResources,
    totalResources,
  ]);

  function toPersonListItem(person) {
    return (
      <PeopleLiveListItem
        key={person.id}
        onClick={onSubItemClick}
        highlighted={hoveredId === person.id}
        onHoverItem={onSubItemHover}
        onFollowToggle={onFollowToggle}
        followedIdsByType={followedIdsByType}
        item={person}
      />
    );
  }

  function toVehicleListItem(vehicle) {
    return (
      <VehicleLiveListItem
        key={vehicle.id}
        onClick={onSubItemClick}
        highlighted={hoveredId === vehicle.id}
        onHoverItem={onSubItemHover}
        onFollowToggle={onFollowToggle}
        followedIdsByType={followedIdsByType}
        item={vehicle}
        hideLive={false}
        primaryPath={showEstimatedTimeOfArrival ? 'etaLabel' : undefined}
        ignoreOverride
      />
    );
  }

  const prevAssignedVehicles = usePrevious(assignedVehicles);
  useEffect(() => {
    if (showEstimatedTimeOfArrival) {
      const idMap = (v) => v.identificationNumber;
      const prevIds = (prevAssignedVehicles ?? []).map(idMap);
      const currIds = assignedVehicles.map(idMap);

      // if any vehicle de-assigned from call sign, remove the eta
      const deassignedIds = _.difference(prevIds, currIds);
      if (deassignedIds.length > 0) {
        dispatch({
          type: DELETE_LIVE_ESTIMATED_TIME_OF_ARRIVAL,
          payload: deassignedIds,
        });
      }

      // make request if new vehicles are assigned or if it's time for an update
      const newlyAssignedIds = _.difference(currIds, prevIds);
      if (
        newlyAssignedIds.length > 0 ||
        isAfter(new Date(), nextEtaRequestTime)
      ) {
        setNextEtaRequestTime(addSeconds(new Date(), etaRequestDelay));

        assignedVehicles
          .filter((v) => v.assignments?.callSign?.status !== 'At Scene')
          .forEach((vehicle) => {
            dispatch({
              type: FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL,
              payload: { vehicle, incident: item },
            });
          });
      }
    }
  }, [
    dispatch,
    item,
    assignedVehicles,
    prevAssignedVehicles,
    nextEtaRequestTime,
  ]);

  function ExpandableCallSignItem({
    callSign: { callSign, people, vehicles },
    onOpen,
    onClose,
    open,
  }) {
    return (
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: 1 }}>
            <CallSignLiveListItem
              onClick={onSubItemClick}
              highlighted={hoveredId === callSign.id}
              onHoverItem={onSubItemHover}
              onFollowToggle={onFollowToggle}
              followedIdsByType={followedIdsByType}
              item={callSign}
              secondaryPath={
                showEstimatedTimeOfArrival ? 'etaLabel' : undefined
              }
              // tertiaryPath={'assignments.callSign.code'}
              // hideFollow={true}
            />
          </Box>
          {open ? (
            <ExpandLess sx={{ m: 1 }} onClick={onClose} fontSize="small" />
          ) : (
            <ExpandMore sx={{ m: 1 }} onClick={onOpen} fontSize="small" />
          )}
        </Box>
        <Collapse
          sx={{ width: 1, flexDirection: 'column', display: 'flex', pl: 2 }}
          in={open}
          timeout="auto"
          unmountOnExit
        >
          {people.map(toPersonListItem)}
          {vehicles.map(toVehicleListItem)}
        </Collapse>
      </Box>
    );
  }

  const allExpanded = Object.keys(assignedCallSignsWithResources).every(
    (key) => expandedCallSigns[key],
  );

  function handleExpandToggle() {
    if (allExpanded) {
      setExpandedCallSigns({});
    } else {
      let expanded = {};

      Object.keys(assignedCallSignsWithResources).forEach((key) => {
        expanded[key] = true;
      });

      setExpandedCallSigns(expanded);
    }
  }

  function followButton() {
    return (
      <Tooltip title="Toggle follow all">
        <IconButton onClick={handleFollowAllToggle} size="large">
          <FollowAllIcon
            fontSize="small"
            color={allResourcesFollowed ? 'primary' : 'inherit'}
          />
        </IconButton>
      </Tooltip>
    );
  }

  const title = incidentTitle(incident);
  const secondary = `${incident?.type?.code ?? ''} ${
    incident?.type?.name ?? ''
  }`;

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={
          <ItemAvatar item={incident} type="incidents" />
          // <Badge
          //   badgeContent={
          //     responseCategory && responseCategory.code
          //       ? responseCategory.code
          //       : grade || '?'
          //   }
          //   color="primary"
          // >
          // {avatarForItem(incident, 'incidents')}
          // </Badge>
        }
        title={title}
        subheader={secondary}
        action={
          <Fragment>
            <Tooltip title="Focus while incident selected">
              <IconButton onClick={handleFilterToggle} size="large">
                <FocusIcon
                  fontSize="small"
                  color={incidentOverridesFilter ? 'primary' : 'inherit'}
                />
              </IconButton>
            </Tooltip>

            {!hideFollow && (
              <Tooltip title="Toggle follow incident">
                <IconButton onClick={handleFollowToggle} size="large">
                  <FollowIcon
                    fontSize="small"
                    color={following ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Fragment>
        }
        // action={<Avatar style={{background: gradeBackground}}>{incident.responseCategory.code}</Avatar>}
      />
      <CardContent>
        <TagControl
          live
          item={incident}
          type={'incidents'}
          sx={{ py: 2 }}
          label="Tags"
        />
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>{ItemRows(rowItems, incident)}</TableBody>
        </Table>
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical">
          {openedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: incidentStatusColours['opened'],
                    }}
                  >
                    <AddIcon fontSize="inherit" />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {openedTime &&
                      format(new Date(openedTime), 'dd/MM/yyyy HH:mm:ss')}
                  </Typography>
                }
              >
                Opened
              </StepLabel>
              <StepContent>
                {incident.type && (
                  <Typography variant="caption">
                    {incident.type.code}
                  </Typography>
                )}
              </StepContent>
            </Step>
          )}
          {assignedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: incidentStatusColours['assigned'],
                    }}
                  >
                    <DoneIcon fontSize="inherit" />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {format(new Date(assignedTime), 'dd/MM/yyyy HH:mm:ss')}
                  </Typography>
                }
              >
                Assigned
              </StepLabel>
              <StepContent />
            </Step>
          )}
          {attendedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: incidentStatusColours['attended'],
                    }}
                  >
                    <PinDropIcon fontSize="inherit" />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {format(new Date(attendedTime), 'dd/MM/yyyy HH:mm:ss')}
                  </Typography>
                }
              >
                Attended
              </StepLabel>
              <StepContent />
            </Step>
          )}
          {closedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: incidentStatusColours['closed'],
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {format(new Date(closedTime), 'dd/MM/yyyy HH:mm:ss')}
                  </Typography>
                }
              >
                Closed
              </StepLabel>
              <StepContent>
                {!closingCodes
                  ? ''
                  : (closingCodes || []).map((cc, index) => (
                      <Typography variant="caption" key={index}>
                        {cc.code}
                      </Typography>
                    ))}
              </StepContent>
            </Step>
          )}
        </Stepper>
        {incidentDetailGroupByCallsign && assignedCallSigns.length > 0 && (
          <Fragment>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                Assigned Call Signs
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              {followButton()}
              <Tooltip title={allExpanded ? 'Collapse all' : 'Expand all'}>
                <IconButton onClick={handleExpandToggle} size="large">
                  {allExpanded ? (
                    <UnfoldLess fontSize="small" />
                  ) : (
                    <UnfoldMore fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            {Object.keys(assignedCallSignsWithResources).map((key) => (
              <Box key={key}>
                <ExpandableCallSignItem
                  callSign={assignedCallSignsWithResources[key]}
                  onOpen={() =>
                    setExpandedCallSigns({
                      ...expandedCallSigns,
                      [key]: true,
                    })
                  }
                  onClose={() =>
                    setExpandedCallSigns({
                      ...expandedCallSigns,
                      [key]: false,
                    })
                  }
                  open={expandedCallSigns[key]}
                />
              </Box>
            ))}
          </Fragment>
        )}
        {assignedVehiclesWithoutCallSign.length +
          assignedPeopleWithoutCallSign.length >
          0 && (
          <Fragment>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                Assigned Resources
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              {!incidentDetailGroupByCallsign && followButton()}
            </Box>
            {assignedPeopleWithoutCallSign.map(toPersonListItem)}
            {assignedVehiclesWithoutCallSign.map(toVehicleListItem)}
          </Fragment>
        )}
      </CardContent>
    </Card>
  );

  // return (
  //   <Fragment>
  //     <Box sx={{ root: { flexGrow: 1 } }}>
  //       <Grid container style={{ padding: '8px' }}>
  //         <Grid item xs={12}>
  //           <h4>Incident Status</h4>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <Typography sx={classes.cardText}>
  //             Number: {incident.number}
  //           </Typography>
  //           <Typography sx={classes.cardText}>
  //             Description: {incident.description}
  //           </Typography>
  //           <Typography sx={classes.cardText}>
  //             Grade: {incident.grade}
  //           </Typography>
  //           <Typography sx={classes.cardText}>
  //             Opened: {format(new Date(incident.openedTime), 'dd/MM/yyyy hh:mm:ss')}
  //           </Typography>
  //           {incident.type && (
  //             <Typography sx={classes.cardText}>
  //               Type: {incident.type.name}
  //             </Typography>
  //           )}
  //           {incident.category && (
  //             <Typography sx={classes.cardText}>
  //               Category: {incident.category.name}
  //             </Typography>
  //           )}
  //         </Grid>
  //       </Grid>
  //     </Box>
  //   </Fragment>
  // );
}
