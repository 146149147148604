import {
  CustomTooltip,
  Parameters,
  SelectMultiple,
} from '@/components/controls';
import {
  sortBy,
  useDocumentTitle,
  useFilteredActiveIntersectionChanges,
  useOptionValues,
} from '@/hooks';
import { downloadCSV, getFilenameForDownload, startCase } from '@/utils';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  CardContent,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { amber, blue } from '@mui/material/colors';
import { useVirtualizer } from '@tanstack/react-virtual';
import { format } from 'date-fns';
import { atom, useAtom } from 'jotai';
import { Fragment, useMemo, useRef, useState } from 'react';
import {
  Area,
  AreaChart,
  Brush,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const headers = [
  { label: 'Registration', key: 'registrationNumber' },
  { label: 'Fleet Number', key: 'fleetNumber' },
  { label: 'Vehicle Role', key: 'role' },
  { label: 'Location Name', key: 'locationName' },
  { label: 'Location Type', key: 'locationType' },
  { label: 'Time', key: 'time' },
  { label: 'Change', key: 'change' },
];

const stateAtom = atom({
  filter: {
    registrationNumber: [],
    fleetNumber: [],
    role: [],
    type: [],
    locationName: [],
    locationType: [],
    groups: {},
  },
  query: {},
  parameters: {},
});

export function TimelineInLocations() {
  useDocumentTitle('IR3 | Timeline in Location');
  const [{ filter, query, parameters }, setState] = useAtom(stateAtom);
  const { data, cancel, filterValues, isLoading, isFetching } =
    useFilteredActiveIntersectionChanges(query, filter);
  const sortedData = useMemo(() => (data ?? []).sort(sortBy('time')), [data]);
  const parentRef = useRef();
  const rowVirtualizer = useVirtualizer({
    count: sortedData.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 330,
    overscan: 10,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenAreas, setHiddenAreas] = useState([]);
  const vehicleGroups = useOptionValues('vehicleGroup');

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    setState((state) => ({ ...state, query }));
  }

  function handleCancel() {
    cancel();
  }

  const handleFilterFieldChanged = (name) => (value) => {
    const newFilter =
      name in filter
        ? { ...filter, [name]: value || [] }
        : {
            ...filter,
            groups: {
              ...filter.groups,
              [name]: value || [],
            },
          };

    setState((state) => ({ ...state, filter: newFilter }));
  };

  const handleLegendClick = (selectedArea) => () => {
    const index = hiddenAreas.indexOf(selectedArea);

    if (index === -1) {
      setHiddenAreas(hiddenAreas.concat(selectedArea));
    } else {
      setHiddenAreas(
        hiddenAreas.slice(0, index).concat(hiddenAreas.slice(index + 1)),
      );
    }
  };

  function getGroupsHeaders() {
    return Object.keys(filterValues.groups).map((key) => ({
      label: startCase(key),
      key,
    }));
  }

  function formatLabel(epochSeconds) {
    // + is shorthand to convert string to number
    return format(new Date(+epochSeconds), 'dd/MM HH:mm:ss');
  }

  function handleParametersChange(parameters) {
    setState((state) => ({ ...state, parameters }));
  }

  async function handleDownloadClick() {
    const allHeaders = headers.concat(getGroupsHeaders());
    const filename = getFilenameForDownload(
      'Timeline In Locations',
      'csv',
      parameters?.startTime,
      parameters?.endTime,
    );

    downloadCSV(data, filename, allHeaders); //TODO: Check if this is broken or not
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Parameters
        collection="intersections"
        onFetch={handleFetch}
        onCancel={handleCancel}
        value={parameters}
        onChange={handleParametersChange}
        isFetching={isLoading || isFetching}
        sx={{ mt: 1, width: 264 }}
        vehicle
        location
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Vehicles in Location Timelines
          </Typography>
          <Box
            sx={{
              pl: 8,
              pr: 2,
              pb: 1,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                p: 0.5,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleLegendClick('resident')}
            >
              <Avatar
                sx={{ width: 12, height: 12, mr: 0.5 }}
                style={{
                  backgroundColor:
                    !hiddenAreas.includes('resident') && blue[500],
                }}
              >
                <Fragment />
              </Avatar>
              <Typography variant="caption">Home Vehicles</Typography>
            </Box>
            <Box
              sx={{
                p: 0.5,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleLegendClick('visitor')}
            >
              <Avatar
                sx={{ width: 12, height: 12, mr: 0.5 }}
                style={{
                  backgroundColor:
                    !hiddenAreas.includes('visitor') && amber[500],
                }}
              >
                <Fragment />
              </Avatar>
              <Typography variant="caption">Visiting Vehicles</Typography>
            </Box>
          </Box>
          <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
            <IconButton onClick={handleFilterToggle}>
              <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download data">
            <Box component="span">
              <IconButton
                disabled={data.length === 0}
                onClick={handleDownloadClick}
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1} sx={{ flex: 1, p: 1 }}>
            <SelectMultiple
              label="Registration Number"
              placeholder="Select..."
              value={filter.registrationNumber}
              labelValue
              onChange={handleFilterFieldChanged('registrationNumber')}
              suggestions={filterValues.registrationNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Fleet Number"
              placeholder="Select..."
              value={filter.fleetNumber}
              labelValue
              onChange={handleFilterFieldChanged('fleetNumber')}
              suggestions={filterValues.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Role"
              placeholder="Select..."
              value={filter.role}
              labelValue
              onChange={handleFilterFieldChanged('role')}
              suggestions={filterValues.role.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Location Name"
              placeholder="Select..."
              value={filter.locationName}
              labelValue
              onChange={handleFilterFieldChanged('locationName')}
              suggestions={filterValues.locationName.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Location Type"
              placeholder="Select..."
              value={filter.locationType}
              labelValue
              onChange={handleFilterFieldChanged('locationType')}
              suggestions={filterValues.locationType.map((value) => ({
                label: value,
                value,
              }))}
            />
            {Object.entries(vehicleGroups).map(([key, { label, values }]) => {
              return (
                <SelectMultiple
                  key={key}
                  label={label}
                  placeholder="Select..."
                  value={filter.groups[key] || []}
                  labelValue
                  onChange={handleFilterFieldChanged(key)}
                  suggestions={values}
                />
              );
            })}
          </Stack>
        </Collapse>
        {sortedData.length > 0 && (
          <Box
            ref={parentRef}
            sx={{ overflow: 'auto', height: 'calc(100vh - 112px)' }}
          >
            <Box
              sx={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer
                .getVirtualItems()
                .map(({ index, size, start }) => {
                  const series = sortedData[index];

                  return (
                    <Box
                      key={index}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: `${size}px`,
                        transform: `translateY(${start}px)`,
                      }}
                    >
                      <Typography sx={{ p: [1, 2] }} variant="subtitle2">
                        {series.name}
                      </Typography>
                      <Paper sx={{ m: 1, minWidth: 240, fontSize: 12 }}>
                        <CardContent sx={{ p: 0, pt: 4 }}>
                          <ResponsiveContainer width="99%" height={200}>
                            <AreaChart
                              data={series.values}
                              margin={{
                                top: 8,
                                right: 16,
                                left: 0,
                                bottom: 32,
                              }}
                            >
                              <XAxis
                                dataKey="time"
                                type="number"
                                domain={['dataMin', 'dataMax']}
                                tickFormatter={formatLabel}
                              >
                                <Label
                                  value="Time"
                                  position="bottom"
                                  offset={36}
                                />
                              </XAxis>
                              <YAxis interval={0} allowDecimals={false}>
                                <Label
                                  value="Count"
                                  angle={-90}
                                  position="left"
                                  offset={-24}
                                />
                              </YAxis>
                              <ChartTooltip
                                content={
                                  <CustomTooltip
                                    unit="vehicles"
                                    labelFormatter={formatLabel}
                                  />
                                }
                              />
                              <Brush
                                dataKey="time"
                                height={24}
                                tickFormatter={formatLabel}
                              />
                              <Area
                                type="stepAfter"
                                dataKey="residentCount"
                                name="Home Vehicles"
                                dot={false}
                                stroke={blue[500]}
                                fill={blue[500]}
                                strokeWidth={1}
                                stackId="1"
                                hide={hiddenAreas.includes('resident')}
                                isAnimationActive={false}
                              />
                              <Area
                                type="stepAfter"
                                dataKey="visitorCount"
                                name="Visiting Vehicles"
                                dot={false}
                                stroke={amber[500]}
                                fill={amber[500]}
                                strokeWidth={1}
                                stackId="1"
                                hide={hiddenAreas.includes('visitor')}
                                isAnimationActive={false}
                              />
                              );
                            </AreaChart>
                          </ResponsiveContainer>
                        </CardContent>
                      </Paper>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
