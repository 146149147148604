// import { useMemo } from 'react';
import { useMemo } from 'react';
import { useLocations } from './useLocations';

export function useLocationOptions() {
  const { data } = useLocations();

  return useMemo(() => {
    return data
      .concat()
      .sort((a, b) => -(b.type ?? '').localeCompare(a.type ?? ''))
      .reduce(
        (accumulator, { code, name, type }) => ({
          ...accumulator,
          [code]: { name, type },
        }),
        {},
      );
  }, [data]);
}
