import { Text, View } from '@react-pdf/renderer';
import { measures } from './utils';

function SummaryRow({ first, code, ...row }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        borderTopWidth: first ? 0 : 1,
        borderTopColor: '#ccc',
        borderTopStyle: 'solid',
      }}
    >
      <View style={{ flex: 1, padding: 4 }}>
        <Text>{code}</Text>
      </View>
      {measures.map(({ key, color }) => (
        <View
          key={key}
          style={{
            width: '6%',
            textAlign: 'right',
            color,
            fontWeight: 'bold',
            borderLeftWidth: 1,
            borderLeftColor: '#ccc',
            borderLeftStyle: 'solid',
            padding: 4,
          }}
        >
          <Text>{Math.round(row[key])}%</Text>
        </View>
      ))}
    </View>
  );
}

export function SummaryTableView({ title, rows }) {
  return (
    <View style={{ gap: 8, paddingVertical: 8 }}>
      <Text
        style={{
          fontSize: 12,
          fontWeight: 'bold',
        }}
      >
        {title}
      </Text>
      <View
        style={{
          border: 1,
          borderColor: '#ccc',
          borderStyle: 'solid',
          borderRadius: 4,
          fontSize: 10,
        }}
      >
        {rows.map((row, index) => (
          <SummaryRow key={row.code} first={index === 0} {...row} />
        ))}
      </View>
    </View>
  );
}
