import { Autocomplete, TextField } from '@mui/material';

export function FilterField({ value = [], onChange, options, label }) {
  return (
    <Autocomplete
      size="small"
      multiple
      value={value}
      onChange={onChange}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
